import React from "react";
import Loading from "../../../Shared/Loading";
import Body from "./Body";
import { USER_NOTIFICATIONS } from "../queries";
import { useQuery } from "@apollo/client";

const Notifications = ({ userId }) => {
  let data, loading, error;
  ({ data, loading, error } = useQuery(USER_NOTIFICATIONS, {
    fetchPolicy: "network-only",
    variables: { userId },
  }));

  if (loading) return <Loading />;
  if (error) return <p>Error</p>;

  if (data.userNotifications) {
    return (
      <Body
        notifications={data.userNotifications}
        timeZone={data.userProfileData.timeZone}
      />
    );
  }
};

export default Notifications;
