import React, { Fragment, useState } from "react";
import styles from "../../userDetails.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencil } from "@fortawesome/pro-light-svg-icons";
import TagsModal from "./TagsModal/TagsModal";

const Tags = ({ user, refetch }) => {
  const [isTags, setIsTags] = useState();

  const tagsData = user.userTags;

  const toggleTags = () => {
    setIsTags(!isTags);
  };

  return (
    <Fragment>
      <div className="page-section bottom-border">
        <div className="page-section__heading">Tags</div>
        <div
          className={styles.editTags}
          onClick={() => {
            toggleTags();
          }}
        >
          <FontAwesomeIcon className="tag-times" icon={faPencil} /> Edit Tags
        </div>
        <div className={styles.userTagsContainer}>
          {tagsData.map((tag) => (
            <div className={styles.tagCell}>{tag.name}</div>
          ))}
        </div>
      </div>
      <TagsModal
        open={isTags}
        toggleTags={toggleTags}
        user={user}
        tagsData={tagsData}
        refetchProfile={refetch}
      />
    </Fragment>
  );
};

export default Tags;
