import {
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";
import styles from "./userDashboard.module.scss";
import { useState } from "react";
import moment from "moment-timezone";

const Separator = (props) => {
  const [showTooltip, setShowTooltip] = useState(false);
  return (
    <div
      style={{
        position: "absolute",
        height: "100%",
        transform: `rotate(${props.turns}turn)`,
      }}
    >
      <div
        onMouseEnter={() => setShowTooltip(true)}
        onMouseLeave={() => setShowTooltip(false)}
        // style={props.style}
        className={props.className}
      >
        {showTooltip && <Tooltip content={props.content} turns={props.turns} />}
      </div>
    </div>
  );
};

const RadialSeparators = (props) => {
  const turns = 1 / props.count;
  return (
    <Separator
      turns={turns}
      className={props.className}
      content={props.content}
    />
  );
};

const Tooltip = ({ content, turns }) => {
  return (
    <div
      className={styles.tooltip}
      style={{ transform: `rotate(-${turns}turn) translate(-50%, -150%)` }}
    >
      <div className={styles.tip} />
      {content}
    </div>
  );
};

const CircularStatsBar = ({
  value,
  count,
  display,
  miniDisplay,
  notchContent,
}) => {
  return (
    <div className={styles.circularContainer} id="circular-container">
      <CircularProgressbarWithChildren
        value={value}
        styles={buildStyles({
          pathColor: "#128dff",
          textColor: "#414141",
          textSize: "28px",
        })}
      >
        <div style={{ fontSize: 32, fontWeight: 700 }}>
          {display}
          <span style={{ fontSize: 12 }}> {miniDisplay}</span>
        </div>
        <RadialSeparators
          count={count}
          content={notchContent}
          className={styles.circularContainerSeperator}
        />
      </CircularProgressbarWithChildren>
    </div>
  );
};

const WeekBarChart = ({ weeklydata }) => {
  return (
    <div className={styles.barChartContainer}>
      <div className={styles.barChart}>
        {weeklydata.map((source, index) => (
          <div
            className={styles.bar}
            style={{ height: `${(source.hours / 24) * 100}%` }}
          ></div>
        ))}
      </div>
      <div className={styles.label}>
        <span>M</span>
        <span>T</span>
        <span>W</span>
        <span>T</span>
        <span>F</span>
        <span>S</span>
        <span>S</span>
      </div>
    </div>
  );
};

const Removed = (status) => {
  const statuses = ["blacklisted", "closed", "deleted"];
  return statuses.includes(status);
};

const Inactive = (status) => {
  const statuses = ["signed_up", "in_progress"];
  return statuses.includes(status);
};

function lastBookingFormat(start, end, timeZone) {
  const startDate = moment(start).tz(timeZone).format("MMMM Do, YYYY");
  const endDate = moment(end).tz(timeZone).format("MMMM Do, YYYY");

  const startTime = moment(start).tz(timeZone).format("hA");
  const endTime = moment(end).tz(timeZone).format("hA");

  const timeZoneString = moment(end).tz(timeZone).format("z");

  return `${startTime} ${
    startDate != endDate ? startDate : ""
  } - ${endTime} ${endDate} ${timeZoneString}`;
}

const dateXDaysAgo = (x) => {
  const date = new Date();
  date.setDate(date.getDate() - x);
  return moment(date).format("MMM D, YYYY");
};

const statusColor = (source) => {
  //if shown, assume failed
  if (source.impliedStatus == "not_connected") return { opacity: "0.5" };
  else if (
    source.impliedStatus == "connected" ||
    source.impliedStatus == "active"
  )
    return { color: "green" };
  else return { color: "red" };
};

const CellBlock = (props) => {
  const { children, subcontent, ...otherProps } = props;

  return (
    <div className={styles.contentBlock}>
      <div className={styles.statCell} {...otherProps}>
        {children}
      </div>
      <div className={styles.subscript}>{subcontent}</div>
    </div>
  );
};

const MoneyBlock = (props) => {
  const { children, subcontent, average, ...otherProps } = props;

  const moneyStyle = {
    color: children < 0 ? "red" : "",
  };

  return (
    <div className={styles.contentBlock}>
      <div style={moneyStyle} className={styles.statCell} {...otherProps}>
        {new Intl.NumberFormat("us-US", {
          style: "currency",
          currency: "USD",
        }).format(children)}
      </div>
      <div className={styles.subscript}>{subcontent}</div>
      <div className={styles.miniscript}>
        Average of all Drivers:{" "}
        {new Intl.NumberFormat("us-US", {
          style: "currency",
          currency: "USD",
        }).format(average)}
      </div>
    </div>
  );
};

const formatString = (str) => {
  const stringWithSpaces = str.replace(/_/g, " ");

  return (
    stringWithSpaces.charAt(0).toUpperCase() +
    stringWithSpaces.slice(1).toLowerCase()
  );
};

export {
  CircularStatsBar,
  WeekBarChart,
  Removed,
  Inactive,
  lastBookingFormat,
  dateXDaysAgo,
  statusColor,
  CellBlock,
  MoneyBlock,
  formatString,
};
