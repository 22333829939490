import gql from "graphql-tag";

const BATCH_CANCEL_BOOKING = gql`
  mutation batchCancelBookings($bookingIds: [ID!]!) {
    batchCancelBookings(bookingIds: $bookingIds) {
      success
      error
    }
  }
`;

const BATCH_RESTORE_BOOKING = gql`
  mutation batchRestoreBookings($bookingIds: [ID!]!) {
    batchRestoreBookings(bookingIds: $bookingIds) {
      success
      error
      rescheduled
      unavailable
    }
  }
`;

const BATCH_STATUS_CHANGE = gql`
  mutation BatchStatusChange(
    $userIds: [ID!]!
    $status: String!
    $changeComment: String!
    $tableName: String!
    $isAllSelected: Boolean!
    $citiesToFilter: [String!]
  ) {
    batchStatusChange(
      userIds: $userIds
      status: $status
      changeComment: $changeComment
      tableName: $tableName
      isAllSelected: $isAllSelected
      citiesToFilter: $citiesToFilter
    ) {
      users {
        accountStatus
      }
      errors {
        message
      }
    }
  }
`;

const BATCH_ADD_COMMENT = gql`
  mutation BatchComment(
    $userIds: [ID!]!
    $comment: String!
    $timestamp: DateTime
    $pinned: Boolean
    $tableName: String!
    $isAllSelected: Boolean!
    $citiesToFilter: [String!]
  ) {
    batchComment(
      userIds: $userIds
      comment: $comment
      timestamp: $timestamp
      pinned: $pinned
      tableName: $tableName
      isAllSelected: $isAllSelected
      citiesToFilter: $citiesToFilter
    ) {
      success
      users {
        id
      }
      errors {
        message
      }
    }
  }
`;

const BATCH_ADD_USER_TAG = gql`
  mutation batchAddTag(
    $userIds: [ID!]!
    $tags: [String!]!
    $tableName: String!
    $isAllSelected: Boolean!
    $citiesToFilter: [String!]
  ) {
    batchAddTag(
      userIds: $userIds
      tags: $tags
      tableName: $tableName
      isAllSelected: $isAllSelected
      citiesToFilter: $citiesToFilter
    ) {
      users {
        id
        firstName
      }
      success
      errors {
        message
      }
    }
  }
`;

const BATCH_COMMUNICATION = gql`
  mutation BatchMessage(
    $userIds: [ID!]!
    $subject: String!
    $body: String!
    $format: [String!]!
    $tableName: String!
    $isAllSelected: Boolean!
    $citiesToFilter: [String!]
  ) {
    batchMessage(
      userIds: $userIds
      subject: $subject
      body: $body
      format: $format
      tableName: $tableName
      isAllSelected: $isAllSelected
      citiesToFilter: $citiesToFilter
    ) {
      users {
        id
      }
      errors {
        message
      }
    }
  }
`;

const TICKET_BULK_ADD_COMMENT = gql`
  mutation bulkAddComment($ticketIds: [ID!]!, $comment: String!) {
    bulkAddComment(ticketIds: $ticketIds, comment: $comment) {
      success
      error
    }
  }
`;

const BULK_RECOVER_TICKETS = gql`
  mutation bulkRecover($ticketIds: [ID!]!) {
    bulkRecover(ticketIds: $ticketIds) {
      success
      error
    }
  }
`;

const BULK_ADMIN_REMINDER = gql`
  mutation bulkAddReminder(
    $ticketIds: [ID!]!
    $adminIds: [ID!]!
    $reminderDate: DateTime!
  ) {
    bulkAddReminder(
      ticketIds: $ticketIds
      adminIds: $adminIds
      reminderDate: $reminderDate
    ) {
      success
      error
    }
  }
`;

const BULK_ADD_TICKET_TAG = gql`
  mutation bulkAddTag($ticketIds: [ID!]!, $tags: [String!]!) {
    bulkAddTag(ticketIds: $ticketIds, tags: $tags) {
      success
      error
    }
  }
`;

const BULK_ASSIGN_AGENT = gql`
  mutation bulkAssignAgent($ticketIds: [ID!]!, $agentId: ID!) {
    bulkAssignAgent(ticketIds: $ticketIds, agentId: $agentId) {
      success
      error
    }
  }
`;

export {
  BATCH_CANCEL_BOOKING,
  BATCH_RESTORE_BOOKING,
  BATCH_STATUS_CHANGE,
  BATCH_ADD_COMMENT,
  BATCH_COMMUNICATION,
  TICKET_BULK_ADD_COMMENT,
  BULK_RECOVER_TICKETS,
  BULK_ADMIN_REMINDER,
  BULK_ADD_TICKET_TAG,
  BULK_ASSIGN_AGENT,
  BATCH_ADD_USER_TAG,
};
