import React from "react";
import { Fragment } from "react";
import { faSearch } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const AutocompleteContent = ({
  activeOption,
  filteredOptions,
  showOptions,
  userInput,
  onChange,
  onClick,
  onKeyAction,
  enterToAdd,
  newTag,
  renderOption,
  addable,
}) => {
  return (
    <Fragment>
      <div>
        <div
          className={
            showOptions && userInput !== "" && filteredOptions.length !== 0
              ? "autocomplete-open"
              : "autocomplete-close"
          }
        >
          <FontAwesomeIcon icon={faSearch} />
          <input
            className="ac-input-box"
            onChange={onChange}
            onKeyDown={onKeyAction}
            onKeyPress={addable && enterToAdd}
            value={newTag !== "" ? userInput : newTag}
            placeholder="Search"
          />
        </div>
        {showOptions && userInput && filteredOptions.length !== 0 && (
          <ul className="autocomplete-extend">
            {filteredOptions.map((optionName, index) => (
              <li
                key={optionName}
                className={
                  index === activeOption
                    ? "autocomplete-active"
                    : "autocomplete-not-match"
                }
                onClick={onClick}
              >
                {renderOption(optionName)}
              </li>
            ))}
          </ul>
        )}
      </div>
    </Fragment>
  );
};

export default AutocompleteContent;
