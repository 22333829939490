import React from "react";
import styles from "./batchActions.module.scss";
import Selections from "./Selections";
import ActionContainer from "./ActionContainer";
import Modal from "../../../artzu-ui/src/Modal";
import _ from "lodash";

const ActionModal = ({
  isOpen,
  selectedRows,
  selectedBatchAction,
  removeSelection,
  batchOptionsHash,
  closeModal,
  toggleRefresh,
  clearAllSelections,
  isAllSelected,
  toggleFeedbackModal,
  citiesFilter,
}) => {
  const selectedOption = batchOptionsHash[selectedBatchAction];
  const isStatus = selectedOption && selectedOption.value === "changeStatus";
  const selectedUsers = Object.keys(_.pickBy(selectedRows));
  return (
    <div className={styles.batchActionModal}>
      <Modal open={isOpen} padding="none" backgroundColor={"none"}>
        <div
          className={isStatus ? styles.actionModalLarge : styles.actionModal}
        >
          <Selections
            selectedUsers={selectedUsers}
            removeSelection={removeSelection}
            close={closeModal}
            isStatus={isStatus}
            isAllSelected={isAllSelected}
          />
          {selectedOption && (
            <ActionContainer
              selectedOption={selectedOption}
              selectedUsers={selectedUsers}
              close={closeModal}
              toggleRefresh={toggleRefresh}
              clearAllSelections={clearAllSelections}
              isAllSelected={isAllSelected}
              toggleFeedbackModal={toggleFeedbackModal}
              citiesFilter={citiesFilter}
            />
          )}
        </div>
      </Modal>
    </div>
  );
};

export default ActionModal;
