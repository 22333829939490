const getColor = (primary, color) =>
  `background: ${primary ? color : 'white'};
	border: 2px solid ${color};
	color: ${primary ? 'white' : color};`

const getSize = size => {
  switch (size) {
    case 'xl':
      return `
        height: 48px;
				font-size: 20px;`
    default:
      return `
			font-size: 14px;`
  }
}

const isDisabled = disabled => {
  if (disabled) {
    return `
			cursor: not-allowed;
			opacity: 0.15;
		`
  } else {
    return `
			cursor: pointer;
			opacity: 1;
			&:hover {
				transition: 0.25s;
				opacity: 0.9;
      }
      &:active {
        transition: 0.25s;
        transform: scale(0.96);
      }
		`
  }
}

export { getColor, getSize, isDisabled }
