import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import Login from "../Components/Login";
import Layout from "../Components/Layout";
import UsersIndex from "../Components/User/IndexPage";
import UserProfile from "../Components/User/index";
import Analytics from "../Queries/AnalyticIndex";
import AuthRoute from "./AuthRoute";
import Booking from "../Components/Booking";
import Invoices from "../Components/Payments/Invoices";
import PaymentIndex from "../Components/Payments";
import Communication from "../Components/Communication";
import Ticket from "../Components/Communication/Ticket";
import PriceMap from "../Components/PriceMap";
import CreateChat from "../Components/CsAi/CreateChat";
import CsAi from "../Components/CsAi";

const Root = () => (
  <BrowserRouter>
    <Switch>
      <Route exact path="/login" component={Login} />
      <Layout>
        <AuthRoute path="/" exact component={UsersIndex} />
        <AuthRoute path="/users" exact component={UsersIndex} />
        <AuthRoute path="/users/:id" exact component={UserProfile} />
        <AuthRoute path="/users/:id/:resource" exact component={UserProfile} />
        <AuthRoute
          path="/users/:id/:resource/:resourceId"
          exact
          component={UserProfile}
        />
        <AuthRoute path="/bookings" exact component={Booking} />
        <AuthRoute path="/prices" exact component={PriceMap} />
        <AuthRoute path="/payments" exact component={PaymentIndex} />
        <AuthRoute path="/payments/invoices/:id" exact component={Invoices} />
        <Route path="/payments/invoice/new" exact component={Invoices} />
        <Route path="/autzu-chat" exact component={CreateChat} />
        <Route path="/autzu-chat/:id" exact component={CsAi} />
        <AuthRoute path="/communications" exact component={Communication} />
        <Route path="/communications/tickets/:id" exact component={Ticket} />
        <Route path="/communications/ticket/new" exact component={Ticket} />
        <Route path="/analytics" component={Analytics} />
      </Layout>
    </Switch>
  </BrowserRouter>
);

export default Root;
