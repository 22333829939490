import React, { useState } from "react";
import moment from "moment";
import _ from "lodash";
import { useQuery } from "@apollo/client";
import NewNavigation from "./NewNavigation";
import NavBar from "../InformationTable/NavBar";
import NewAvailabilityTable from "./NewAvailabilityTable";
import { ACTIVE_PARKING_LOTS } from "./queries";
import Loading from "../Shared/Loading";
import styles from "./vehicles_available.module.scss";

const PriceMap = ({ selectedCities }) => {
  const [criteria, setCriteria] = useState("hourlyPrice");
  const [parkingLotId, setParkingLotId] = useState();
  const [fleetTier, setFleetTier] = useState();
  const [startDate, setStartDate] = useState(
    moment().startOf("isoWeek").format("YYYY-MM-DD")
  );

  const { loading, error, data, refetch } = useQuery(ACTIVE_PARKING_LOTS, {
    variables: { cityIds: selectedCities },
  });

  const getPrevWeek = () => {
    setStartDate(moment(startDate).subtract(1, "week").format("YYYY-MM-DD"));
  };
  const getNextWeek = () => {
    setStartDate(moment(startDate).add(1, "week").format("YYYY-MM-DD"));
  };

  const onCriteriaChange = (e) => {
    setCriteria(e);
  };

  const onParkingLotChange = (e) => {
    setParkingLotId(e);
  };

  const onFleetChange = (e) => {
    setFleetTier(e);
  };

  if (loading) {
    return (
      <div
        style={{
          width: "100%",
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Loading size="5x" />
      </div>
    );
  }

  if (data) {
    const getActiveParkingLots = data.cityOperationalParkingLots;
    if (getActiveParkingLots.length > 0) {
      const chosenParkingLot =
        _.find(getActiveParkingLots, (lot) => lot.id == parkingLotId) ||
        getActiveParkingLots[0];
      const choseParkingLotId = chosenParkingLot.id;

      return (
        <div className={styles.avaPage}>
          <NavBar navbar={"Availability"} />
          <div className={styles.avaCanvas}>
            <NewNavigation
              parkingLots={getActiveParkingLots}
              chosenParkingLot={chosenParkingLot}
              fleetsAtSelectedLot={chosenParkingLot.availableTiers}
              getNextWeek={getNextWeek}
              getPrevWeek={getPrevWeek}
              onParkingLotChange={onParkingLotChange}
              onFleetChange={onFleetChange}
              onCriteriaChange={onCriteriaChange}
              startDate={startDate}
            />
            <NewAvailabilityTable
              criteria={criteria}
              parkingLotId={choseParkingLotId}
              timeZone={chosenParkingLot.timeZoneData.name}
              startDate={startDate}
              fleetTier={fleetTier}
            />
          </div>
        </div>
      );
    } else {
      return <div> No active parking lots for this user.</div>;
    }
  }
};

export default PriceMap;
