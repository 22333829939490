import React from "react";
import Linkify from "react-linkify";

const NormalMessage = ({ msg, setCurUrl, toggleImg, defaultAdmin }) => {
  const isAutomated =
    msg.direction === "incoming" &&
    (msg.text === "New signed up user" ||
      msg.text === "From website contact us form");

  return msg.label === "image" ? (
    <div className="message-tag-container">
      <img
        src={msg.text}
        className="image-msg"
        onClick={() => {
          setCurUrl(msg.text);
          toggleImg();
        }}
      ></img>
      {msg.fromAdmin && (
        <span className="internal-sender">
          By:{" "}
          {msg.senderName === defaultAdmin.fullName ? "You" : msg.senderName}
        </span>
      )}
    </div>
  ) : msg.label === "normal" ? (
    <div className="message-tag-container">
      <div
        className={
          msg.internal
            ? "message-tag-internal"
            : msg.direction === "outgoing"
            ? "message-tag-outgoing"
            : msg.fromAdmin
            ? "message-tag-outgoing"
            : "message-tag"
        }
      >
        <Linkify
          componentDecorator={(decoratedHref, decoratedText, key) => (
            <a target="blank" href={decoratedHref} key={key}>
              {decoratedText}
            </a>
          )}
        >
          <span style={{ whiteSpace: "pre-line" }}>
            <span style={{ fontWeight: 600 }}>
              {isAutomated ? "AUTOMATED MESSAGE: " : null}
            </span>{" "}
            {msg.text}
          </span>
        </Linkify>
      </div>
      {msg.fromAdmin && (
        <span className="internal-sender">
          By:{" "}
          {msg.senderName === defaultAdmin.fullName ? "You" : msg.senderName}
        </span>
      )}
    </div>
  ) : null;
};

export default NormalMessage;
