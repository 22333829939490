import React, { useState } from "react";
import Option from "../../../artzu-ui/src/Option";
import SelectField from "../../../artzu-ui/src/SelectField";
import styles from "./dropdown.module.scss";
import DropdownField from "./DropdownField";
import OptionStyle from "./OptionStyle";

const Dropdown = ({
  items,
  onChange,
  label,
  initialValue,
  error,
  width,
  height,
  disabled,
}) => {
  const initialItem = items.find((item) => item.value == initialValue);
  const prompt = (initialItem && initialItem.name) || "Select one";
  return (
    <div>
      <DropdownField
        initialValue={initialValue}
        borderRadius="12px"
        prompt={prompt}
        height={height ? height : "24px"}
        width={width}
        fontSize="16px"
        fontWeight="400"
        primaryColor={"#414141"}
        title={label}
        onChange={(value, _) => onChange(value)}
        error={error}
        disabled={disabled}
      >
        {items.map((item) => (
          <OptionStyle
            fontSize="16px"
            fontWeight="400"
            label={item.name}
            value={item.value}
          />
        ))}
      </DropdownField>
    </div>
  );
};

export default Dropdown;
