import React from "react";
import Icon from "../../../artzu-ui/src/Icon";
import styles from "./dropdown.module.scss";

const DropdownHeader = ({ heading, theme, height, width }) => (
  <div
    className={styles.dropdownHeader}
    style={{ height: height, width: width }}
  >
    {heading}
    <Icon name="DownArrow" color="#128dff" size="xs" />
  </div>
);

export default DropdownHeader;
