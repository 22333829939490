import React, { useState } from "react";
import closeButton from "../../../../../../images/buttons/close-button.svg";
import addButton from "../../../../../../images/buttons/add-button.svg";
import { faSearch, faTimes } from "@fortawesome/pro-regular-svg-icons";
import { faRedoAlt } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Modal from "../../../../../../artzu-ui/src/Modal";
import NewTemplateModal from "./NewTemplateModal";
import CurrentTemplateModal from "./CurrentTemplateModal";
import { Fragment } from "react";
import { useEffect } from "react";

const TemplateModal = ({
  open,
  toggleTemplate,
  setData,
  allTemplates,
  refetch,
}) => {
  const [isNew, setIsNew] = useState();
  const [curTemplate, setCurTemplate] = useState();
  const [isCur, setIsCur] = useState();
  const [searchValue, setSearchValue] = useState();
  const [valueToSearch, setValueToSearch] = useState();
  const [displayTemplates, setDisplayTemplates] = useState(
    allTemplates && allTemplates
  );

  useEffect(() => {
    setDisplayTemplates(allTemplates);
  }, [allTemplates]);

  const handleChange = (e) => {
    setSearchValue(e.target.value);
  };

  const clearSearch = () => {
    setSearchValue("");
    setValueToSearch(undefined);
    setDisplayTemplates(allTemplates);
  };

  const triggerSearch = (evt) => {
    if (evt.key === "Enter") {
      setValueToSearch(searchValue);
    }
  };

  const toggleNew = () => {
    setIsNew(!isNew);
  };

  const closeNew = () => {
    setIsNew(false);
  };

  const toggleCur = () => {
    setIsCur(!isCur);
  };

  const clearCurrentTemplate = () => {
    setCurTemplate(undefined);
  };

  const triggerCurrentTemplate = (templateId) => {
    let curTemp = displayTemplates.find((temp) => temp.id === templateId);
    setCurTemplate(curTemp);
  };

  useEffect(() => {
    if (valueToSearch) {
      const temp = valueToSearch
        ? allTemplates.filter((temp) =>
            temp.name.toLowerCase().includes(valueToSearch.toLowerCase())
          )
        : allTemplates && allTemplates.getAllTemplates;
      setDisplayTemplates(temp);
    }
  }, [valueToSearch]);

  return (
    <Fragment>
      <Modal open={open} backgroundColor="none">
        <div className="template-container">
          <div className="template-title">
            Templates
            <img
              src={closeButton}
              onClick={toggleTemplate}
              style={{ cursor: "pointer" }}
            ></img>
          </div>
          <div className="search-and-add">
            <div className="search-bar-container">
              <div className="template-input-container">
                <div className="search-icon">
                  <FontAwesomeIcon icon={faSearch} color="#A2A2A2" />
                </div>
                <input
                  className="input-style"
                  placeholder="Search by template name"
                  onChange={handleChange}
                  onKeyPress={triggerSearch}
                  value={searchValue}
                />
                {searchValue && (
                  <FontAwesomeIcon
                    onClick={clearSearch}
                    icon={faTimes}
                    size="lg"
                  />
                )}
              </div>
              <FontAwesomeIcon
                icon={faRedoAlt}
                style={{ marginLeft: "10px" }}
                onClick={clearSearch}
              />
            </div>

            <img
              src={addButton}
              onClick={toggleNew}
              style={{ cursor: "pointer", width: "26px" }}
            ></img>
          </div>
          <div className="template-box">
            {displayTemplates &&
              displayTemplates.map((template) => (
                <div className="single-template-container">
                  <div
                    className="template-preview"
                    style={{ whiteSpace: "pre-line" }}
                    onClick={() => {
                      triggerCurrentTemplate(template.id);
                      toggleCur();
                    }}
                  >
                    <div className="template-text-content">
                      <div className="shadow"></div>
                      {template.text}
                    </div>
                  </div>
                  <div style={{ fontWeight: "700", width: "168px" }}>
                    {template.name}
                  </div>
                </div>
              ))}
          </div>
        </div>
        <NewTemplateModal
          open={isNew}
          toggleNew={toggleNew}
          toggleTemplate={toggleTemplate}
          refetch={refetch}
          closeNew={closeNew}
          curTemplate={curTemplate}
          isCreate={true}
          clearCurrentTemplate={clearCurrentTemplate}
        />
        <CurrentTemplateModal
          open={isCur}
          curTemplate={curTemplate}
          toggleCur={toggleCur}
          toggleNew={toggleNew}
          toggleTemplate={toggleTemplate}
          refetch={refetch}
          isNew={isNew}
          closeNew={closeNew}
          clearCurrentTemplate={clearCurrentTemplate}
          setData={setData}
        />
      </Modal>
    </Fragment>
  );
};

export default TemplateModal;
