import React from "react";
import { useState } from "react";
import ToggleButton from "../../../../Shared/ToggleButton";
import { faEllipsisH } from "@fortawesome/pro-regular-svg-icons";
import OptionMenu from "./OptionMenu";
import { Fragment } from "react";
import TicketActionPopup from "./TicketActionPopup";
import { BACKLOG_TO_IN_PROGRESS, IN_PROGRESS_TO_BACKLOG } from "../queries";
import { useMutation } from "@apollo/client";

const ActionButtons = ({
  editTicket,
  cancelEdit,
  isEdit,
  ticketData,
  refreshTicketCallback,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isPopup, setIsPopup] = useState();
  const [curAction, setCurAction] = useState();

  const toggleOpenState = () => setIsOpen(!isOpen);
  const togglePopup = () => {
    setIsPopup(!isPopup);
  };

  const [backlogTicketMutation, { loading: isBacklogLoading }] = useMutation(
    IN_PROGRESS_TO_BACKLOG
  );
  const handleBacklog = () => {
    backlogTicketMutation({
      variables: {
        ticketId: ticketData && ticketData.id,
      },
    }).then(({ data: { backlogSupportTicket } }) => {
      if (backlogSupportTicket.success) {
        refreshTicketCallback({ ticketId: ticketData && ticketData.id });
        console.log("Backlog Ticket Succeed!");
      } else {
        console.log("Backlog Ticket error!");
      }
    });
  };

  const [inProgressTicketMutation, { loading: isInProgressLoading }] =
    useMutation(BACKLOG_TO_IN_PROGRESS);
  const handleInProgress = () => {
    inProgressTicketMutation({
      variables: {
        ticketId: ticketData && ticketData.id,
      },
    }).then(({ data: { inProgressSupportTicket } }) => {
      if (inProgressSupportTicket.success) {
        refreshTicketCallback({ ticketId: ticketData && ticketData.id });
        console.log("In Progress Ticket Succeed!");
      } else {
        console.log("In Progress Ticket error!");
      }
    });
  };

  return (
    <Fragment>
      <div className="action-buttons-container">
        <ToggleButton
          isOpen={isOpen}
          toggleOpenState={toggleOpenState}
          faIcon={faEllipsisH}
          ticketData={ticketData}
          ticketSpecific={true}
        />
        <OptionMenu
          isOpen={isOpen}
          isEdit={isEdit}
          editTicket={() => {
            editTicket();
            toggleOpenState();
          }}
          cancelEdit={() => {
            cancelEdit();
            toggleOpenState();
          }}
          deleteTicket={() => {
            togglePopup();
            toggleOpenState();
          }}
          fleetReview={() => {
            togglePopup();
            toggleOpenState();
          }}
          backlogTicket={() => {
            handleBacklog();
            toggleOpenState();
          }}
          inProgressTicket={() => {
            handleInProgress();
            toggleOpenState();
          }}
          setCurAction={setCurAction}
          ticketData={ticketData}
        />
      </div>
      <TicketActionPopup
        open={isPopup}
        action={curAction === "Delete" ? "Delete ticket" : "Fleet review"}
        refreshTicketCallback={refreshTicketCallback}
        togglePopup={togglePopup}
        ticketData={ticketData}
      />
    </Fragment>
  );
};

export default ActionButtons;
