import React, { Fragment } from "react";
import { TableCell, TableRow } from "../../../Shared/Table";
import { titleize } from "../../../../utils";
import moment from "moment-timezone";
import { GET_TEXT_MESSAGES } from "../../queryStrings";
import { useQuery } from "@apollo/client";
import Loading from "../../../Shared/Loading";

const Messages = ({ userId }) => {
  const { data, loading } = useQuery(GET_TEXT_MESSAGES, {
    variables: { id: userId },
  });

  if (loading) return <Loading />;

  const textMessages = data && data.userProfileData.recentTextMessages;

  const timeZone = data && data.userProfileData.timeZone;

  if (!textMessages) {
    return <div>No recent messages found</div>;
  }

  let textArray = [...textMessages];
  textArray && textArray.sort((p1, p2) => (p1.sentAt > p2.sentAt ? -1 : 1));
  return (
    <Fragment>
      <div style={{ backgroundColor: "white", padding: "0 32px" }}>
        <TableRow
          align="center"
          padding="32px 0 16px"
          borderB="1px solid #414141"
        >
          <TableCell width="30%" bold>
            Time
          </TableCell>
          <TableCell width="10%" bold>
            Sent by
          </TableCell>
          <TableCell width="60%" bold>
            Body
          </TableCell>
        </TableRow>
        {textArray.map((log, index) => {
          return (
            <TableRow borderT="1px solid #dedede" padding="16px 0" key={index}>
              <TableCell width="30%">
                {moment(log.sentAt)
                  .tz(timeZone)
                  .format("MMM D, YYYY h:mm:ss A z")}
              </TableCell>
              <TableCell width="10%">
                {!log.fromDriver && log.fromSystem ? "System" : "Driver"}
              </TableCell>
              <TableCell width="60%">{titleize(log.message)}</TableCell>
            </TableRow>
          );
        })}
      </div>
    </Fragment>
  );
};

export default Messages;
