import styles from "../../bookingActions.module.scss";
import React, { Fragment } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLongArrowRight } from "@fortawesome/pro-regular-svg-icons";
import DisplayVehicle from "./DisplayVehicle";
import CurrentVehicle from "./CurrentVehicle";
import NewVehicle from "./NewVehicle";
import ArrowRight from "./ArrowRight";

const VehiclePreview = ({ currentCar, newCar, openVehicleList }) => (
  <Fragment>
    <div className={styles.title}>Change vehicle</div>
    <div className={styles.vehiclesContainer}>
      <CurrentVehicle car={currentCar} />
      <ArrowRight />
      <NewVehicle newCar={newCar} openVehicleList={openVehicleList} />
    </div>
  </Fragment>
);

export default VehiclePreview;
