import React from "react";
import Modal from "../../../../artzu-ui/src/Modal";
import styles from "./importPopup.module.scss";
import moment from "moment";
import Button from "../../../Shared/Button";
import RowSelect from "../../CellValues/RowSelect";
import _ from "lodash";

const TableContent = ({
  open,
  selectedRows,
  isAllSelected,
  csvHeader,
  csvData,
  addRow,
  setSelectedRows,
  toggleTableModal,
  ableToUpload,
  bulkImport,
  selectAllRows,
  csvType,
}) => {
  return (
    <Modal open={open} backgroundColor="none">
      <div
        className={
          csvType === "invoices"
            ? styles.currentTable
            : styles.currentTableLarge
        }
      >
        <div className={styles.titles}>
          <div className={styles.checkBoxTitle}>
            <RowSelect
              onClick={() => {
                selectAllRows();
              }}
              isSelected={isAllSelected}
            />
          </div>
          {csvHeader &&
            csvHeader.map((head) => <div className={styles.title}>{head}</div>)}
        </div>
        <div className={styles.dataTable}>
          {csvData &&
            csvData.map((dataRow, id) => (
              <div className={styles.dataRow}>
                <div className={styles.checkBox}>
                  <RowSelect
                    onClick={() => {
                      addRow(dataRow);
                    }}
                    isSelected={
                      selectedRows &&
                      selectedRows.some((data) => _.isEqual(data, dataRow))
                    }
                  />
                </div>
                {dataRow.map((data) => (
                  <div className={styles.dataChunk}>{data}</div>
                ))}
              </div>
            ))}
        </div>
        <div className={styles.tableButtons}>
          <Button
            label={"Close"}
            fullWidth="152px"
            height="40px"
            color="#128DFF"
            backColor="#ffffff"
            radius="20px"
            size="16px"
            weight="700"
            border="1px solid #FFFFFF"
            onClick={() => {
              setSelectedRows([]);
              toggleTableModal();
            }}
          />
          <Button
            label={"Submit"}
            fullWidth="152px"
            height="40px"
            color={ableToUpload ? "#128DFF" : "rgba(18, 141, 255, 0.5)"}
            backColor="#ffffff"
            radius="20px"
            size="16px"
            weight="700"
            border="1px solid #FFFFFF"
            onClick={() => {
              ableToUpload && bulkImport();
              ableToUpload && toggleTableModal();
              // setSelectedRows([]);
              // toggleTableModal();
            }}
          />
        </div>
      </div>
    </Modal>
  );
};

export default TableContent;
