import React, { Fragment, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { titleize, m } from "../../../../utils";
import moment from "moment-timezone";

const BookingDetails = ({ booking }) => {
  const car = booking.car;
  const timeZone = booking.timeZone;
  return (
    <Fragment>
      <div className="page-section__canvas padded">
        <div className="page-section__canvas--half">
          <div className="key">Booking status</div>
          <div className="value">{titleize(booking.status)}</div>
          <div className="key">Vehicle</div>
          <a
            className="value"
            href={
              car &&
              `${process.env.REACT_APP_FLEET_CLIENT_URL}/resource/vehicle/${car?.uuid}`
            }
            target="_blank"
            style={{ textDecoration: "none", color: "#128dff" }}
          >
            {(car && car.plate) || "N/A"}
          </a>
          <div className="key">Hub</div>
          <div className="value">{booking.parkinglot.region}</div>
          <div className="key">Date booked</div>
          <div className="value">
            {moment(booking.createdAt).tz(timeZone).format("h:mmA ddd")}{" "}
            <span>
              {moment(booking.createdAt).tz(timeZone).format("MMM D, YYYY z")}
            </span>
          </div>
          <div className="key">Scheduled start</div>
          <div className="value">
            {moment(booking.startTime).tz(timeZone).format("h:mmA ddd")}{" "}
            <span>
              {moment(booking.startTime).tz(timeZone).format("MMM D, YYYY z")}
            </span>
          </div>
          <div className="key">Actual start</div>
          {booking.pickup ? (
            <div className="value">
              {moment(booking.pickup).tz(timeZone).format("h:mmA ddd")}{" "}
              <span>
                {moment(booking.pickup).tz(timeZone).format("MMM D, YYYY z")}
              </span>
            </div>
          ) : (
            <div className="value">-</div>
          )}
          <div className="key">Scheduled end</div>
          <div className="value">
            {moment(booking.endTime).tz(timeZone).format("h:mmA ddd")}{" "}
            <span>
              {moment(booking.endTime).tz(timeZone).format("MMM D, YYYY z")}
            </span>
          </div>
          <div className="key">Actual end</div>
          {booking.dropoff ? (
            <div className="value">
              {moment(booking.dropoff).tz(timeZone).format("h:mmA ddd")}{" "}
              <span>
                {moment(booking.dropoff).tz(timeZone).format("MMM D, YYYY z")}
              </span>
            </div>
          ) : (
            <div className="value">-</div>
          )}
          <div className="key">Insurance Deductible Reduction Schedules</div>
          <div className="value">
            {booking.insuranceDeductibleReductionSchedules.map((schedule) => {
              return (
                <div>
                  {moment(schedule.startTime)
                    .tz(timeZone)
                    .format("h:mmA ddd MMM D, YYYY")}
                  {" - "}
                  {moment(schedule.endTime)
                    .tz(timeZone)
                    .format("h:mmA ddd MMM D, YYYY z")}
                </div>
              );
            })}
          </div>
        </div>
        <div className="page-section__canvas--half">
          <div className="key">Booking ID</div>
          <div className="value">{booking.id}</div>
          <div className="key">Rate</div>
          <div className="value">${booking.rate}/hour</div>
          <div className="key">Total fees</div>
          <div className="value">
            {Math.round(
              Math.abs(booking.bookingFee + booking.additionalFees) * 100
            ) / 100}
          </div>
          <div className="key">Duration</div>
          <div className="value">
            {moment(booking.endTime).diff(moment(booking.startTime), "hours")}{" "}
            hours
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default BookingDetails;
