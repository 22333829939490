import React, { Fragment, useState } from "react";
import { Link } from "react-router-dom";
import SidePanel from "./SidePanel";
import Dashboard from "./Dashboard";
import Details from "./Details";
import Bookings from "./Bookings";
import Rideshare from "./Rideshare";
import Activity from "./Activity";
import { titleize } from "../../utils";
import { faChevronRight } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Helmet } from "react-helmet";

import "./userProfile.css";
import Payments from "./Payments";
import Communication from "./Communication";
import Referral from "./Referral";

const UserProfile = ({
  userId,
  userProfileData,
  resource,
  resourceId,
  refetch,
}) => {
  const [activeSection, setActiveSection] = useState(resource || "dashboard");
  const user = userProfileData;
  const hasRideshare = process.env.REACT_APP_COMPANY_CONFIG === "autzu";

  const { referrals, referralCode } = userProfileData;

  const sections = [
    "dashboard",
    "details",
    "communication",
    "bookings",
    "payments",
    "activity",
    "referral",
  ];

  if (hasRideshare) {
    sections.push("rideshare");
  }

  const profileContent = () => {
    switch (activeSection) {
      case "dashboard":
        return <Dashboard id={userId} refetch={refetch} />;
      case "details":
        return <Details id={userId} refetch={refetch} />;
      case "communication":
        return <Communication id={userId} refetch={refetch} />;
      case "bookings":
        return <Bookings resourceId={resourceId} userId={userId} />;
      case "payments":
        return (
          <Payments resourceId={resourceId} id={userId} refetch={refetch} />
        );
      case "rideshare":
        return <Rideshare id={userId} refetch={refetch} />;
      case "activity":
        return (
          <Activity resourceId={resourceId} id={userId} refetch={refetch} />
        );
      case "referral":
        return (
          <Referral
            resourceId={resourceId}
            id={userId}
            refetch={refetch}
            referralsData={referrals}
            referralCode={referralCode}
          />
        );
    }
  };

  const selectSection = (activeSection) => {
    window.history.replaceState("", "", `/users/${userId}/${activeSection}`);
    setActiveSection(activeSection);
  };

  return (
    <Fragment>
      <Helmet>
        <title>
          {user.firstName} {user.lastName} | Users
        </title>
      </Helmet>
      <div className="user-profile">
        <SidePanel
          sections={sections}
          userProfileData={user}
          activeSection={activeSection}
          selectSection={selectSection}
        />
        <div className="user-page">
          <div className="path-head unselectable">
            <Link
              to="/users"
              style={{
                color: "#414141",
                textDecoration: "none",
                fontWeight: "400",
              }}
            >
              Drivers
            </Link>
            <FontAwesomeIcon
              icon={faChevronRight}
              size="sm"
              style={{ margin: "0 0.5em" }}
            />
            <Link
              to={`/users/${userId}`}
              style={{
                color: "#414141",
                textDecoration: "none",
                fontWeight: "400",
              }}
            >
              {user.firstName} {user.lastName}
            </Link>
            <FontAwesomeIcon
              icon={faChevronRight}
              size="sm"
              style={{ margin: "0 0.5em" }}
            />
            <Link
              to={`/users/${userId}/${activeSection}`}
              style={{
                color: "#414141",
                textDecoration: "none",
                fontWeight: "bold",
              }}
            >
              {titleize(activeSection)}
            </Link>
            {resourceId ? (
              <Fragment>
                <FontAwesomeIcon
                  icon={faChevronRight}
                  size="sm"
                  style={{ margin: "0 0.5em" }}
                />
                <span>ID {resourceId}</span>
              </Fragment>
            ) : null}
          </div>
          {profileContent()}
        </div>
      </div>
    </Fragment>
  );
};

export default UserProfile;
