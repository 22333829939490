import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhoneAlt, faPhoneSquareAlt } from "@fortawesome/pro-light-svg-icons";

const CallRequestMessage = ({
  msg,
  ticketStatus,
  assignTicket,
  handleCallback,
  handleCall,
  handleTagRemove,
}) =>
  msg.callRequest &&
  (msg.callRequest.status === "requested" ? (
    <div className="accident-report-message">
      <div className="ac-title">
        <FontAwesomeIcon icon={faPhoneSquareAlt} size="2x" />
        Call requested
      </div>
      <button
        className="view-report-button"
        onClick={() => {
          ticketStatus === "unassigned" && assignTicket();
          handleCallback(msg.callRequest.id);
          handleCall();
          handleTagRemove("Call requested");
        }}
      >
        Call
      </button>
    </div>
  ) : msg.callRequest.status === "cancelled" ? (
    <div className="message-tag-container">
      <div className="message-tag" style={{ padding: "8px 12px" }}>
        <FontAwesomeIcon
          icon={faPhoneAlt}
          style={{ rotate: "135deg" }}
          size="2x"
        />
        <div style={{ marginLeft: "16px" }}>Call request cancelled</div>
      </div>
    </div>
  ) : (
    msg.callRequest.status === "answered" && (
      <div className="accident-report-message">
        <div className="ac-title">
          <FontAwesomeIcon icon={faPhoneSquareAlt} size="2x" />
          Call requested
        </div>
        <button className="disabled-view-report-button">Call</button>
      </div>
    )
  ));

export default CallRequestMessage;
