import React, { useState } from "react";
import styles from "./filterRow.module.scss";
import _ from "lodash";
import TextInput from "./TextInput";

const TextInputs = ({ onChange, numberOfInputValues, initialValue, error }) => {
  const [values, setValues] = useState(initialValue || []);

  const onInputChange = (index, value) => {
    const valuesCopy = [...values];
    valuesCopy[index] = value;
    setValues(valuesCopy);
    onChange(valuesCopy);
  };

  const generateInputTitles = () => {
    if (numberOfInputValues == 1) {
      return ["Amount"];
    } else if (numberOfInputValues == 2) {
      return ["Lower limit", "Upper limit"];
    } else if (numberOfInputValues == 0) {
      return ["Amount"];
    } else {
      return _.time(numberOfInputValues, Number).map(
        (index) => `Value ${index}`
      );
    }
  };

  const inputTitles = generateInputTitles();
  return _.times(numberOfInputValues, Number).map((index) => {
    return (
      <div className={styles.dropdown}>
        <label className={styles.dropdownLabel}>{inputTitles[index]}</label>
        <TextInput
          error={error}
          value={values[index]}
          onChange={(value) => onInputChange(index, value)}
        />
      </div>
    );
  });
};

export default TextInputs;
