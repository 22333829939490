import React from "react";
import { USER_ONBOARDING_DATA } from "../queries";
import { useQuery } from "@apollo/client";
import Loading from "../../../Shared/Loading";
import OnboardingBody from "./Body";

const Onboarding = ({ userId }) => {
  const { data, loading, error } = useQuery(USER_ONBOARDING_DATA, {
    fetchPolicy: "network-only",
    variables: { userId },
  });

  if (loading) return <Loading />;

  if (error) return <p>Error</p>;

  if (data.userOnboardingData) {
    return (
      <OnboardingBody
        onboardingData={data.userOnboardingData}
        timeZone={data.userProfileData.timeZone}
      />
    );
  }
};

export default Onboarding;
