import React, { useState } from "react";
import SearchBar from "./Search";
import ColumnSettings from "./ColumnSettings";
import MiscSettings from "./MiscSettings";
import Filters from "./Filters";
import styles from "./actionBar.module.scss";
import Selections from "./Selections";
import _ from "lodash";
import CreateButton from "./CreateButton.js";
import CreateTicket from "./CreateTicket";
import TicketToggleButton from "./TicketToggleButton";
import CreateBooking from "./CreateBooking";
// import Edits from "./Edits";

const ActionBar = ({
  searchValue,
  columns,
  changeSearchValue,
  changeColumnsValue,
  filters,
  changeFilters,
  selectedRowsCount,
  clearAllSelections,
  rowIdToIdentifierObject,
  background,
  startTerms,
  startImport,
  isImportModalOpen,
  closeImport,
  startExport,
  isExportModalOpen,
  closeExport,
  userSpecific,
  popupSpecific,
  tableName,
  userId,
  currentAdminUserOnly,
  changeAdminUserOnly,
  reloadPage,
  presetSearch,
  bookingSpecific,
  timeZone,
}) => {
  const { available: availableFilters, savedGroups: savedFilterGroups } =
    filters;

  const [selectedFilterGroup, setSelectedFilterGroup] = useState();
  const [isFiltersOpen, setIsFiltersOpen] = useState(false);

  const selectFilterGroup = (filterGroupUuid) => {
    const selectedGroupIndex = savedFilterGroups.findIndex(
      (group) => filterGroupUuid === group.uuid
    );

    const selectedGroup = savedFilterGroups[selectedGroupIndex];
    let selectedGroupCopy = _.cloneDeep(selectedGroup);

    selectedGroupCopy = {
      ...selectedGroup,
      selectedGroupIndex: selectedGroupIndex,
    };
    setSelectedFilterGroup(selectedGroupCopy);
    setIsFiltersOpen(true);
  };

  const deleteFilterGroup = (groupUuid) => {
    const newFilterGroups = _.reject(
      savedFilterGroups,
      (group) => group.uuid == groupUuid
    ).map((group) =>
      group.filters.map((filter) => ({
        categoryUuid: filter.categoryUuid,
        uuid: filter.uuid,
        isExcluded: filter.isExcluded,
        values: filter.values,
      }))
    );
    changeFilters(newFilterGroups);
  };
  return (
    <div className={userSpecific ? styles.actionBarSmall : styles.actionBar}>
      <div className={styles.leftGroup}>
        {!userSpecific && !bookingSpecific && (
          <Filters
            availableFilters={availableFilters}
            changeFilters={changeFilters}
            selectedFilterGroup={selectedFilterGroup}
            savedFilterGroups={savedFilterGroups}
            isOpen={isFiltersOpen}
            setIsOpen={setIsFiltersOpen}
            clearSelectedFilter={() => setSelectedFilterGroup(null)}
            background={background}
            timeZone={timeZone}
          />
        )}
        {!bookingSpecific && (
          <SearchBar
            changeSearchValue={changeSearchValue}
            searchValue={searchValue}
            presetSearch={presetSearch}
            background={background}
            startTerms={startTerms}
          />
        )}
        <Selections
          savedFilterGroups={savedFilterGroups}
          selectFilterGroup={selectFilterGroup}
          setIsFiltersOpen={setIsFiltersOpen}
          deleteFilterGroup={deleteFilterGroup}
          selectedRowsCount={selectedRowsCount}
          clearAllSelections={clearAllSelections}
          tableName={tableName}
        />
      </div>
      {
        <div className={styles.rightGroup}>
          {tableName === "invoices" && !bookingSpecific && <CreateButton />}
          {tableName === "tickets" && !bookingSpecific && (
            <div className={styles.ticketButtons}>
              {!userSpecific && (
                <TicketToggleButton currentAdminUserOnly={currentAdminUserOnly} changeAdminUserOnly={changeAdminUserOnly} />
              )}
              <CreateTicket userId={userId} />
            </div>
          )}
          {tableName === "bookings" && userSpecific && (
            <div className={styles.ticketButtons}>
              <CreateBooking userId={userId} reloadPage={reloadPage} />
            </div>
          )}
          {!userSpecific &&
            (tableName === "users" || tableName === "tickets") && (
              <ColumnSettings
                columns={columns}
                changeColumnsValue={changeColumnsValue}
              />
            )}
          {!userSpecific && !popupSpecific && !bookingSpecific && (
            <MiscSettings
              startImport={startImport}
              isImportModalOpen={isImportModalOpen}
              closeImport={closeImport}
              startExport={startExport}
              closeExport={closeExport}
              isExportModalOpen={isExportModalOpen}
              tableName={tableName}
            />
          )}
        </div>
      }

      {/* {editable && (
        <div className={styles.rightGroup}>
          <Edits
            deleteEdit={deleteEdit}
            rowIdToIdentifierObject={rowIdToIdentifierObject}
            modifyEdits={modifyEdits}
            edits={edits}
            areButtonsShowing={isEditModeActivated}
            isEditModalOpen={isEditModalOpen}
            toggleEditModalOpen={toggleEditModalOpen}
            startEdit={startEdit}
            endEdits={endEdits}
            saveEdits={saveEdits}
            reviewEdits={reviewEdits}
          />
          <ColumnSettings
            columns={columns}
            changeColumnsValue={changeColumnsValue}
          />
          <MiscSettings startEdit={startEdit} startManageTag={startManageTag} />
        </div>
      )} */}
      {/* <ColumnSettings
        columns={columns}
        changeColumnsValue={changeColumnsValue}
      /> */}
    </div>
  );
};

export default ActionBar;
