import React, { Component, Fragment } from "react";
import "./index.css";
import { gql, useQuery } from "@apollo/client";
import { useState } from "react";
import LayoutContent from "./LayoutContent.js";

const Layout = (props) => {
  const citiesStored = JSON.parse(localStorage.getItem("accessibleCities"));
  const [selectedCities, setSelectedCities] = useState(
    citiesStored ? citiesStored.map((item) => item.id) : []
  );

  const GET_ADMIN_USER = gql`
    query CurrentAdminUser {
      currentAdminUser {
        accessibleCities {
          id
          name
        }
      timeZone
      }
    }
  `;
  const { loading, error, data, refetch } = useQuery(GET_ADMIN_USER);

  const handleCityCallback = (childData) => {
    setSelectedCities(childData);
  };

  return (
    <LayoutContent
      handleCityCallback={handleCityCallback}
      data={data}
      props={props}
      selectedCities={selectedCities}
    />
  );
};

export default Layout;
