import React, { Fragment } from "react";
import { useState } from "react";
import { useQuery } from "@apollo/client";
import { GET_AI_CHAT_QUERY } from "./queries";
import Loading from "../Shared/Loading";
import classNames from "classnames";
import ChatContainer from "./ChatContainer";
import { useParams } from "react-router-dom";

const CsAi = ({}) => {
  const { id } = useParams();
  const { data, loading, refetch } = useQuery(GET_AI_CHAT_QUERY, {
    variables: { chatId: id },
  });

  return loading ? (
    <Loading />
  ) : (
    <ChatContainer
      chat={data.getAutzuAiChat}
      initialMessages={data?.getAutzuAiChat?.messages}
      refetch={refetch}
    />
  );
};

export default CsAi;
