import React, { Fragment, useEffect } from "react";
import BookingPage from "./BookingPage";
import InformationTable from "../../InformationTable";
import CurrentBooking from "./CurrentBooking";
import { USER_ACTIVE_BOOKING } from "./queries";
import { useQuery } from "@apollo/client";
import Loading from "../../Shared/Loading";
import { useLocation } from "react-router";

const Bookings = ({ userId, resourceId, refetch }) => {
  const { data, loading, error } = useQuery(USER_ACTIVE_BOOKING, {
    variables: { userId },
    fetchPolicy: "network-only",
  });

  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  if (loading) return <Loading />;
  if (error) return null;

  const currentBooking = data.userActiveBooking;
  return (
    <div className="user-page__container">
      {resourceId ? (
        <BookingPage id={parseInt(resourceId)} userId={userId} />
      ) : (
        <Fragment>
          {currentBooking ? (
            <CurrentBooking
              currentBooking={currentBooking}
              refetch={refetch}
              userId={userId}
            />
          ) : null}
          <InformationTable
            rowComponentType="booking"
            tableName="bookings"
            navbar="Bookings"
            startTerms="Search By BookingID, Name and Plate"
            userSpecific={true}
            id={userId}
          />
        </Fragment>
      )}
    </div>
  );
};

export default Bookings;
