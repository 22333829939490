import React from "react";
import Loading from "../../../../Shared/Loading";
import AvailabilityBreakdown from "./AvailabilityBreakdown";

const Availability = ({ shiftAvailability, isShiftAvailabilityLoading }) => {
  if (isShiftAvailabilityLoading) {
    return <Loading />;
  } else if (shiftAvailability) {
    return <AvailabilityBreakdown shiftAvailability={shiftAvailability} />;
  } else {
    return <span style={{ opacity: "0.5" }}>No search input</span>;
  }
};

export default Availability;
