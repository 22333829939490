import React from "react";
import "../invoiceTabs.css";
import TransactionsTable from "./TransactionsTable";

const Transactions = ({ invoiceTransactions, create, status, timeZone }) => {
  return (
    <div className="canvas">
      <TransactionsTable
        invoiceTransactions={invoiceTransactions}
        create={create}
        status={status}
        timeZone={timeZone}
      />
    </div>
  );
};

export default Transactions;
