import PaymentsBlack from "../../../../assets/icons/PaymentsBlack.svg"
import styles from "../userDashboard.module.scss"
import { MoneyBlock } from "../utils"
import { Link } from "react-router-dom"


const PaymentsTab = ({data}) => {

    const displayedBalance = -1 * data.outstandingBalance;
    const displayedAverageBalance = -1 * data.averageOutstandingBalanceOfAllUsers

    return (
        <div className={styles.categoryTab}>
            <div className={styles.tabHeader}>
                <div className={styles.title}>
                    <img src={PaymentsBlack}/> 
                    Payments
                </div>
                <Link to={{ pathname: `/users/${data.id}/payments` }}  style={{textDecoration:"none",color:"#128dff"}}>
                    See more
                </Link>
            </div>
            {data.lifetimeHours?
            <div className={styles.tabContent}>
                <MoneyBlock subcontent="Current balance" average={displayedAverageBalance}>
                    {displayedBalance}
                </MoneyBlock>
                <MoneyBlock subcontent="Total credits" average={data.averageTotalCreditsOfAllUsers}>
                    {data.totalCredits}
                </MoneyBlock>
                <MoneyBlock subcontent="Total deductions" average={data.averageTotalDeductionsOfAllUsers}>
                    {data.totalDeductions}
                </MoneyBlock>
                <MoneyBlock subcontent="Average revenue per week" average={data.averageRevenuePerWeekOfAllUsers}>
                    {data.avgRevenuePerWeek}
                </MoneyBlock>
                <MoneyBlock subcontent="Lifetime value (LTV)" average={data.averageLifetimeValuesOfAllUsers}>
                    {data.lifeTimeValues}
                </MoneyBlock>
            </div>
            :<div className={styles.nullItem}>No payments</div>}
        </div>
    )
}

export {PaymentsTab}