import React, { Fragment } from "react";
import { TableCell, TableRow } from "../../../Shared/Table";
import moment from "moment-timezone";

const NotificationsBody = ({ notifications, timeZone }) => {
  if (!notifications) {
    return <div>No events found</div>;
  }
  return (
    <Fragment>
      <div style={{ backgroundColor: "white", padding: "0 32px" }}>
        <TableRow
          align="center"
          padding="32px 0 16px"
          borderB="1px solid #414141"
        >
          <TableCell padding="0 4px" width="17%" bold>
            Created at
          </TableCell>
          <TableCell padding="0 4px" width="12%" bold>
            Push message
          </TableCell>
          <TableCell padding="0 4px" width="12%" bold>
            Subject
          </TableCell>
          <TableCell padding="0 4px" width="30%" bold>
            Body
          </TableCell>
          <TableCell padding="0 4px" width="12%" bold>
            View
          </TableCell>
          <TableCell padding="0 4px" width="17%" bold>
            Read at
          </TableCell>
        </TableRow>
        {notifications.map((notification, index) => {
          return (
            <TableRow borderT="1px solid #dedede" padding="16px 0" key={index}>
              <TableCell padding="0 4px" width="17%">
                {moment(notification.createdAt)
                  .tz(timeZone)
                  .format("MMM D, YYYY h:mm:ss A z")}
              </TableCell>
              <TableCell padding="0 4px" width="12%">
                {notification.pushMessage}
              </TableCell>
              <TableCell padding="0 4px" width="12%">
                {notification.subject}
              </TableCell>
              <TableCell padding="0 4px" width="30%">
                {notification.body}
              </TableCell>
              <TableCell padding="0 4px" width="12%">
                {notification.view}
              </TableCell>
              <TableCell padding="0 4px" width="17%">
                {notification.readAt
                  ? moment(notification.readAt)
                      .tz(timeZone)
                      .format("MMM D, YYYY h:mm:ss A z")
                  : "--"}
              </TableCell>
            </TableRow>
          );
        })}
      </div>
    </Fragment>
  );
};

export default NotificationsBody;
