import React, { Fragment } from "react";
import { TableCell, TableRow } from "../../../Shared/Table";
import moment from "moment-timezone";

const Logs = ({ logs, timeZone }) => {
  if (!logs) {
    return <div>No events found</div>;
  }
  return (
    <Fragment>
      <div style={{ backgroundColor: "white", padding: "0 32px" }}>
        <TableRow
          align="center"
          padding="32px 0 16px"
          borderB="1px solid #414141"
        >
          <TableCell width="25%" bold>
            Time stamp
          </TableCell>
          <TableCell width="75%" bold>
            Event
          </TableCell>
        </TableRow>
        {logs.map((log, index) => {
          return (
            <TableRow borderT="1px solid #dedede" padding="16px 0" key={index}>
              <TableCell width="25%">
                {moment(log.timestamp)
                  .tz(timeZone)
                  .format("MMM D, YYYY h:mm:ss A z")}
              </TableCell>
              <TableCell width="75%">{log.description}</TableCell>
            </TableRow>
          );
        })}
      </div>
    </Fragment>
  );
};

export default Logs;
