import React from "react";
import moment from "moment-timezone";

const PaymentMethods = ({ paymentMethods, timeZone }) => (
  <div className="page-section">
    <div className="page-section__heading">Payment method</div>
    <div>
      {paymentMethods.map((source, index) => {
        {
          console.log(timeZone);
        }
        return (
          <div className="payment-field" key={index}>
            <div className="subscript"></div>
            {index === 0 && (
              <div style={{ fontSize: "12px", fontWeight: "300px" }}>
                Primary:
              </div>
            )}
            <div>
              {source.card.brand} ****{source.card.last4}
            </div>
            <div className="lite-font">
              Expiry: {source.card.expMonth > 9 ? "" : "0"}
              {source.card.expMonth}/{source.card.expYear.toString().slice(2)}
            </div>
            <div className="subscript translucent">
              Last used:{" "}
              {moment(source.created)
                .tz(timeZone)
                .format("h:mm A MMM D, YYYY z")}
            </div>
          </div>
        );
      })}
    </div>
  </div>
);

export default PaymentMethods;
