import React, { useState } from "react";
import Modal from "../../../../../../artzu-ui/src/Modal";
import styles from "./inspection.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Fragment } from "react";
import FullImage from "./FullImage";
import { faTimes } from "@fortawesome/pro-light-svg-icons";
import ExteriorImages from "./ExteriorImages";
import SafetyCheck from "./SafetyCheck";
import CleanlinessCheck from "./CleanlinessCheck";

const BookingInspection = ({
  inspectionData,
  isInspection,
  toggleInspection,
}) => {
  console.log(inspectionData);
  const [isFull, setIsFull] = useState(false);
  const [isSafeFull, setIsSafeFull] = useState(false);
  const [isCleanFull, setIsCleanFull] = useState(false);
  const [curImg, setCurImg] = useState();

  const toggleImg = (child) => {
    setIsFull(!isFull);
    setCurImg(child);
  };

  const toggleSafeImg = (child) => {
    setIsSafeFull(!isSafeFull);
    setCurImg(child);
  };

  const toggleCleanImg = (child) => {
    setIsCleanFull(!isCleanFull);
    setCurImg(child);
  };

  const fCenter = inspectionData.frontCenter;
  const fLeft = inspectionData.frontLeft;
  const fRight = inspectionData.frontRight;
  const rCenter = inspectionData.rearCenter;
  const rLeft = inspectionData.rearLeft;
  const rRight = inspectionData.rearRight;
  const safety1 =
    inspectionData.safetyImage1 !== "" ? inspectionData.safetyImage1 : null;
  const safety2 =
    inspectionData.safetyImage2 !== "" ? inspectionData.safetyImage2 : null;
  const safety3 =
    inspectionData.safetyImage3 !== "" ? inspectionData.safetyImage3 : null;
  const safety4 =
    inspectionData.safetyImage4 !== "" ? inspectionData.safetyImage4 : null;
  const safety5 =
    inspectionData.safetyImage5 !== "" ? inspectionData.safetyImage5 : null;
  const safety6 =
    inspectionData.safetyImage6 !== "" ? inspectionData.safetyImage6 : null;
  const clean1 =
    inspectionData.cleanlinessImage1 !== ""
      ? inspectionData.cleanlinessImage1
      : null;
  const clean2 =
    inspectionData.cleanlinessImage2 !== ""
      ? inspectionData.cleanlinessImage2
      : null;
  const clean3 =
    inspectionData.cleanlinessImage3 !== ""
      ? inspectionData.cleanlinessImage3
      : null;
  const clean4 =
    inspectionData.cleanlinessImage4 !== ""
      ? inspectionData.cleanlinessImage4
      : null;
  const clean5 =
    inspectionData.cleanlinessImage5 !== ""
      ? inspectionData.cleanlinessImage5
      : null;
  const clean6 =
    inspectionData.cleanlinessImage6 !== ""
      ? inspectionData.cleanlinessImage6
      : null;
  const plate = inspectionData?.car?.plate;

  var imageList = [fCenter, fLeft, fRight, rCenter, rLeft, rRight];
  imageList = imageList.filter((img) => img !== "");
  var safetyList = [safety1, safety2, safety3, safety4, safety5, safety6];
  safetyList = safetyList.filter((img) => img !== null);
  var cleanList = [clean1, clean2, clean3, clean4, clean5, clean6];
  cleanList = cleanList.filter((img) => img !== null);

  const curImgIndex = imageList.findIndex((ele) => ele === curImg);
  const curSafeIndex = safetyList.findIndex((ele) => ele === curImg);
  const curCleanIndex = cleanList.findIndex((ele) => ele === curImg);

  const notClean = inspectionData.cleanliness && inspectionData.cleanliness < 3;

  return (
    <Fragment>
      <Modal open={isInspection} backgroundColor="none">
        <div className={styles.inspectionContainer}>
          <div className={styles.inspectionTitle}>
            Vehicle inspection - {plate}
            <FontAwesomeIcon
              icon={faTimes}
              size="1x"
              onClick={toggleInspection}
              className={styles.toClick}
            />
          </div>
          <ExteriorImages imageList={imageList} toggleImg={toggleImg} />
          <SafetyCheck
            inspectionData={inspectionData}
            safetyList={safetyList}
            toggleSafeImg={toggleSafeImg}
          />
          <CleanlinessCheck
            inspectionData={inspectionData}
            notClean={notClean}
            cleanList={cleanList}
            toggleCleanImg={toggleCleanImg}
          />
        </div>
      </Modal>
      <FullImage
        isFull={isFull}
        imageList={imageList}
        toggleImg={toggleImg}
        curImgIndex={curImgIndex}
      />
      <FullImage
        isFull={isSafeFull}
        imageList={safetyList}
        toggleImg={toggleSafeImg}
        curImgIndex={curSafeIndex}
      />
      <FullImage
        isFull={isCleanFull}
        imageList={cleanList}
        toggleImg={toggleCleanImg}
        curImgIndex={curCleanIndex}
      />
    </Fragment>
  );
};

export default BookingInspection;
