import React from "react";
import { Link } from "react-router-dom";
import { faChevronRight } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const TicketHeader = ({ ticketData }) => {

  const ticketId = ticketData && ticketData.id;

  return (
    <div className="path-head unselectable">
      <Link
        to="/communications"
        style={{
          color: "#414141",
          textDecoration: "none",
          fontWeight: "400",
        }}
      >
        Communications
      </Link>
      <FontAwesomeIcon
        icon={faChevronRight}
        size="sm"
        style={{ margin: "0 0.5em" }}
      />

      <Link
        to={`/communications/tickets/${ticketId}`}
        style={{
          color: "#414141",
          textDecoration: "none",
          fontWeight: "400",
        }}
      >

        {ticketId && String(ticketId).padStart(6, "0")}
      </Link>
    </div>
  );
};

export default TicketHeader;
