import React from "react";
import styles from "../../userDetails.module.scss";
import Dropdown from "../../../../InformationTable/ActionBar/Filters/OptionsModal/Body/Filter/Dropdown";

const DropdownInput = ({ text, sex, genders, triggerSexChange }) => {
  return (
    <div className={styles.field}>
      <div className={styles.subscript}>{text}</div>
      <Dropdown
        initialValue={sex}
        items={genders}
        onChange={triggerSexChange}
        width="210px"
      />
    </div>
  );
};

export default DropdownInput;
