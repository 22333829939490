import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import { withStyles } from "@material-ui/core/styles";

const CustomDialog = ({ ...props }) => (
  <AutzuDialog open={props.open}>
    {props.body}
  </AutzuDialog>
)

const AutzuDialog = withStyles({
  paper: {
    width: "800px",
    borderRadius: "16px",
    maxWidth: "800px",
    backgroundColor: "#414141",
    overflowY: "unset"
  }
})(Dialog)

export default CustomDialog