import Loading from "../../../Shared/Loading";
import React, { Fragment, useState } from "react";
import BookingSearchArguments from "./BookingSearchArguments";
import Availability from "./Availability";
import ActionContainer from "./ActionContainer";
import moment from "moment-timezone";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import {
  GET_SHIFT_AVAILABILITY,
  USER_BOOKING_CONFIG,
  USER_CREATE_BOOKING,
} from "../queries";

const CreateBookingContainer = ({
  toggleCreate,
  userId,
  reloadPage,
  setErrorMsg,
  toggleErrorModal,
}) => {
  const [vehicleClass, setVehicleClass] = useState();
  const [duration, setDuration] = useState();
  const [parkingLot, setParkingLot] = useState();
  const [selectedDate, setSelectedDate] = useState();
  const [deductibleReductionSelected, setDeductibleReductionSelected] =
    useState(false);

  const changeVehicleClass = (newVehicleClass) => {
    setVehicleClass(newVehicleClass);
  };

  const changeDuration = (newDuration) => {
    setDuration(newDuration);
  };

  const changeParkingLot = (newParkingLot) => {
    setParkingLot(newParkingLot);
  };

  const changeSelectedDate = (newDate) => {
    setSelectedDate(newDate);
  };

  const changeInsuranceDeduction = (isSelected) => {
    setDeductibleReductionSelected(isSelected);
  };

  const generateShiftAvailabilityVariables = () => {};

  const clearSearch = () => {
    setVehicleClass(undefined);
    setSelectedDate(undefined);
    setParkingLot(undefined);
    setDuration(undefined);
  };

  const shiftAvailabilityVariables = {
    vehicleTier: vehicleClass,
    startDate: selectedDate && moment(selectedDate).format("YYYY-MM-DD"),
    endDate:
      selectedDate &&
      moment(selectedDate).add(duration, "hours").format("YYYY-MM-DD"),
    shiftStart: selectedDate && selectedDate.getHours(),
    shiftEnd: selectedDate && (selectedDate.getHours() + duration) % 24,
    userId: userId,
    parkingLotId: parkingLot,
  };

  const [
    getShiftAvailability,
    { loading: isShiftAvailabilityLoading, _, data, refetch },
  ] = useLazyQuery(GET_SHIFT_AVAILABILITY, { fetchPolicy: "no-cache" });

  const handleSearch = () => {
    getShiftAvailability({
      variables: shiftAvailabilityVariables,
    });
  };

  const shiftAvailability = data && data.userShiftAvailability;

  const { data: userBookingConfig, loading: isUserBookingConfigLoading } =
    useQuery(USER_BOOKING_CONFIG, {
      variables: { userId: userId },
    });

  const [createBookingMutation, { loading: isCreateBookingLoading }] =
    useMutation(USER_CREATE_BOOKING);

  const handleCreate = () => {
    const startHour = selectedDate.getHours();

    createBookingMutation({
      variables: {
        startHour: startHour,
        duration: duration,
        date: moment(selectedDate).format("YYYY-MM-DD"),
        vehicleClass: vehicleClass,
        rate: shiftAvailability[0].price,
        shiftRates: shiftAvailability[0].shiftRates,
        userId: userId,
        parkingLotId: Number(parkingLot),
        insuranceDeductibleReductionSelected: deductibleReductionSelected,
      },
    }).then(({ data: { createBooking } }) => {
      if (createBooking.booking) {
        refetch();
        clearSearch();
        reloadPage();
        toggleCreate();
      } else {
        toggleErrorModal();
        setErrorMsg(createBooking.error);
      }
    });
  };

  const ableToCreate = shiftAvailability && shiftAvailability.length > 0;

  return (
    <div className="create-booking-container">
      {isCreateBookingLoading ? (
        <Loading />
      ) : (
        <Fragment>
          <div className="relate-user-title">Add new booking</div>
          <div className="search-result-container">
            <div className="car-search-container">
              {isUserBookingConfigLoading ? (
                <Loading />
              ) : (
                <BookingSearchArguments
                  bookingConfiguration={userBookingConfig.userBookingConfig}
                  handleSearch={handleSearch}
                  changeVehicleClass={changeVehicleClass}
                  vehicleClass={vehicleClass}
                  changeDuration={changeDuration}
                  duration={duration}
                  changeParkingLot={changeParkingLot}
                  parkingLot={parkingLot}
                  changeSelectedDate={changeSelectedDate}
                  changeInsuranceDeduction={changeInsuranceDeduction}
                  deductibleReductionSelected={deductibleReductionSelected}
                  selectedDate={selectedDate}
                />
              )}
            </div>
            <div className="availability-container">
              <Availability
                shiftAvailability={shiftAvailability}
                isShiftAvailabilityLoading={isShiftAvailabilityLoading}
              />
            </div>
          </div>
          <ActionContainer
            ableToAdd={ableToCreate}
            handleCreate={handleCreate}
            toggleCreate={toggleCreate}
            clearSearch={clearSearch}
          />
        </Fragment>
      )}
    </div>
  );
};

export default CreateBookingContainer;
