import React, { useState } from "react";
import { Fragment } from "react";
import Button from "../../../Shared/Button";
import styles from "../batchActions.module.scss";
import { BATCH_COMMUNICATION } from "../queries";
import { useMutation } from "@apollo/client";
import ErrorModal from "../../../Payments/Invoices/ActionPopup/ErrorModal";
import Loading from "../../../Shared/Loading";
import CheckBox from "./CheckBox";
import { TextField } from "@material-ui/core";
import SelectAllConfirmModal from "./SelectAllConfirmModal";

const Communication = ({
  selectedOption,
  selections,
  close,
  clearAllSelections,
  toggleRefresh,
  isAllSelected,
  toggleFeedbackModal,
  citiesFilter,
}) => {
  const [format, setMessageFormat] = useState([]);
  const [notiChecked, setNotiChecked] = useState(false);
  const [smsChecked, setSmsChecked] = useState(false);
  const [subject, setSubject] = useState("");
  const [body, setBody] = useState("");
  const [isError, setIsError] = useState();
  const [errorMsg, setErrorMsg] = useState();
  const [isConfirm, setIsConfirm] = useState();

  const toggleConfirmModal = () => {
    setIsConfirm(!isConfirm);
  };

  const toggleErrorModal = () => {
    setIsError(!isError);
  };

  const handleMessageFormat = (messageFormat) => {
    let formatNew = format;
    formatNew.includes(messageFormat)
      ? (formatNew = formatNew.filter((m) => m != messageFormat))
      : formatNew.push(messageFormat);
    setMessageFormat(formatNew);
  };

  const [communicationMutation, { loading, error }] =
    useMutation(BATCH_COMMUNICATION);
  const sendMessage = () => {
    communicationMutation({
      variables: {
        userIds: selections,
        subject: subject,
        body: body,
        format: format,
        tableName: "users",
        isAllSelected: isAllSelected,
        citiesToFilter: citiesFilter || [],
      },
    }).then(({ data: { batchMessage } }) => {
      if (batchMessage.users) {
        console.log("Communication Succeed!");
        toggleRefresh();
      } else {
        toggleErrorModal();
        setErrorMsg("Communication not working");
        console.log("Communication error!");
      }
    });
  };

  const addable = format && subject && body;
  return loading ? (
    <Loading size="3x" />
  ) : (
    <Fragment>
      <div className={styles.darkText}>{selectedOption.text}</div>
      <div
        className={styles.contentContainer}
        style={{ marginTop: "0", height: "430px" }}
      >
        <div className="batch-form__checkboxes">
          <CheckBox
            label="Notification"
            value={notiChecked}
            onChange={() => {
              handleMessageFormat("notification");
              setNotiChecked(!notiChecked);
            }}
          />
          <CheckBox
            label="Text"
            value={smsChecked}
            onChange={() => {
              handleMessageFormat("sms");
              setSmsChecked(!smsChecked);
            }}
          />
        </div>
        <div className="batch-form__group" style={{ marginTop: "0" }}>
          <label>Title: </label>
          <div className="batch-form__field" style={{ display: "block" }}>
            <TextField
              fullWidth
              id="standard-multiline-static"
              multiline
              rows={2}
              value={subject}
              onChange={(e) => setSubject(e.target.value)}
              placeholder="Enter title here..."
              variant="outlined"
            />
            <div className="character-count">
              {subject.length}/40 characters
            </div>
          </div>
        </div>
        <div className="batch-form__group" style={{ marginTop: "0" }}>
          <label>Message: </label>
          <div className="batch-form__field" style={{ display: "block" }}>
            <TextField
              fullWidth
              id="standard-multiline-static"
              multiline
              rows={4}
              value={body}
              onChange={(e) => setBody(e.target.value)}
              placeholder="Enter message here..."
              variant="outlined"
            />
            <div className="character-count">{body.length}/200 characters</div>
          </div>
        </div>

        <div className={styles.notesSaveButton}>
          <Button
            label={"Save"}
            height="39px"
            fullWidth="150px"
            color={addable ? "#128dff" : "#87CEFA"}
            backColor="#ffffff"
            radius="20px"
            size="16px"
            weight="700"
            border="1px solid #FFFFFF"
            onClick={() => {
              addable && !isAllSelected && sendMessage();
              addable && !isAllSelected && clearAllSelections();
              addable && !isAllSelected && toggleFeedbackModal();
              addable && !isAllSelected && close();
              addable && isAllSelected && toggleConfirmModal();
            }}
          />
        </div>
      </div>
      <ErrorModal
        open={isError}
        errorMsg={errorMsg}
        toggleErrorModal={toggleErrorModal}
      />
      <SelectAllConfirmModal
        open={isConfirm}
        rowCount={selections.length}
        batchAction={selectedOption.text}
        batchActionFun={sendMessage}
        toggleConfirmModal={toggleConfirmModal}
        toggleFeedbackModal={toggleFeedbackModal}
        clearAllSelections={clearAllSelections}
        close={close}
      />
    </Fragment>
  );
};

export default Communication;
