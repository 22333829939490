import React, { Component, useState } from "react";
import BookingTimeline from "./BookingTimeline/index";
import BookingDetails from "./BookingDetails";
import BookingComments from "./BookingComments";
import { titleize } from "../../../../utils";
import InformationTable from "../../../InformationTable";
import styles from "../bookings.module.scss";

const BookingBody = ({ booking }) => {
  const [tab, setTab] = useState("timeline");
  const tabLabels = ["timeline", "details", "tickets", "invoices", "comments"];
  const renderTabContent = () => {
    switch (tab) {
      case "details":
        return <BookingDetails booking={booking} />;
      case "tickets":
        return (
          <div className={styles.ticketTableContainer}>
          <InformationTable
            rowComponentType="ticket"
            tableName="tickets"
            bookingSpecific={true}
            currentBookingId={booking.id}
          />
          </div>
        );
      case "invoices":
        return (
          <div className={styles.invoiceTableContainer}>
          <InformationTable
            rowComponentType="payment"
            tableName="invoices"
            bookingSpecific={true}
            currentBookingId={booking.id}
          />
          </div>
        );
      case "comments":
        return <BookingComments booking={booking} />;
      case "timeline":
      default:
        return <BookingTimeline booking={booking} />;
    }
  };
  return (
    <div className="user-page__container">
      <div
        className="page-section"
        style={{ minHeight: "calc(100vh - 114px)" }}
      >
        <div className="tab-container">
          {tabLabels.map((t, i) => {
            return (
              <div
                className={`detail-tab${tab === t ? " selected" : ""}`}
                key={i}
                style={{ width: "calc(100% / 6)" }}
                onClick={() => setTab(t)}
              >
                {titleize(t)}
              </div>
            );
          })}
        </div>
        <div
          style={{
            backgroundColor:
              tab === "tickets" || tab === "invoices" ? "#f2f2f2" : "white",
            padding: tab === "tickets" || tab === "invoices" ? 0 : "20px",
          }}
        >
          {renderTabContent()}
        </div>
      </div>
    </div>
  );
};

export default BookingBody;
