import React from 'react'
import styled, { withTheme } from 'styled-components'
import { getColor } from './selectHelpers.js'
import Icon from '../Icon'
import { isDisabled } from '../Button/buttonHelpers.js'

const StyledSelectHeader = styled.div`
	${({ primaryColor, disabled }) =>
		disabled ? isDisabled(disabled) : getColor(false, primaryColor)}

	position: absolute;
	max-width: 100%;
	width: ${({ width }) => width};
	margin: 0.5em auto;
	border: 2px solid ${({ primaryColor }) => primaryColor}
	border-radius: 26px;
	padding: 0.5em 1em;
	font-weight: 600;
	height: 32px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	cursor: pointer;
`

const SelectHeader = ({
	heading,
	theme,
	primaryColor = theme.main,
	...props
}) => (
		<StyledSelectHeader {...props} primaryColor={primaryColor}>
			{heading}
			<Icon name="DownArrow" color={primaryColor} size="xs" />
		</StyledSelectHeader>
	)

export default withTheme(SelectHeader)
