import React from "react";
import TopLeft from "./TopLeft";
import TopRight from "./TopRight";

const Top = ({
  driver,
  bookingId,
  invoiceData,
  create,
  isCreated,
  tmpTotal,
  driverId,
  refetch,
  financingCallback,
}) => {
  return (
    <div className="topContainer">
      <TopLeft
        invoiceData={invoiceData}
        driver={driver}
        driverId={driverId}
        bookingId={bookingId}
        tmpTotal={tmpTotal}
        create={create}
        refetch={refetch}
      />
      <TopRight
        invoiceData={invoiceData}
        create={create}
        isCreated={isCreated}
        financingCallback={financingCallback}
        refetch={refetch}
      />
    </div>
  );
};

export default Top;
