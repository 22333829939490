import { faTrashAlt } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "../../../userDetails.module.scss";

const AllTagsSection = ({
  allTags,
  selectedTag,
  setSelectedTag,
  searchValue,
  toggleDelete,
}) => {
  const isSelected = (tag) => {
    return tag.name == selectedTag.name;
  };

  return (
    <div className={styles.allTags}>
      {allTags
        .filter((tag) =>
          tag.name
            .toLowerCase()
            .includes(searchValue && searchValue.toLowerCase())
        )
        .map((tag) => (
          <div
            className={
              isSelected(tag)
                ? styles.tagContainerSelected
                : styles.tagContainer
            }
            onClick={() => {
              setSelectedTag(isSelected(tag) ? {} : tag);
            }}
          >
            {tag.name} ({tag.attachedToCount})
            {isSelected(tag) ? (
              <FontAwesomeIcon
                icon={faTrashAlt}
                size="sm"
                className={styles.tagTrash}
                onClick={(event) => {
                  event.stopPropagation();
                  toggleDelete();
                }}
              />
            ) : (
              ""
            )}
          </div>
        ))}
    </div>
  );
};

export default AllTagsSection;
