import React from "react";
import Modal from "../../../../../../artzu-ui/src/Modal";

const DiscardModal = ({
  open,
  toggleDiscard,
  clearCurrentTemplate,
  closeNew,
  clearInput,
}) => {
  return (
    <Modal open={open} backgroundColor="none">
      <div className="discard-modal">
        <div className="template-title">Discard template</div>
        <div>Are you sure you want to discard this template?</div>
        <div className="template-buttons">
          <button
            className="booking-cancel-button"
            onClick={() => {
              toggleDiscard();
            }}
          >
            No
          </button>
          <button
            className="booking-choose-button"
            onClick={() => {
              clearInput();
              clearCurrentTemplate();
              toggleDiscard();
              closeNew();
            }}
          >
            Yes
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default DiscardModal;
