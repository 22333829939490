import React from "react";
import styles from "../../userDetails.module.scss";
import DatePicker from "react-datepicker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar } from "@fortawesome/pro-regular-svg-icons";

const DateInput = ({ text, dateValue, manualInputs, setDateFunc, isHalf }) => {
  const dateFuncHelper = (childDate) => {
    text === "Date of birth" &&
      setDateFunc({ ...manualInputs, dateOfBirth: childDate });
    text === "Issue date" &&
      setDateFunc({ ...manualInputs, issueDate: childDate });
    text === "Expiry date" &&
      setDateFunc({ ...manualInputs, expiryDate: childDate });
  };

  return (
    <div className={styles.field}>
      <div className={styles.subscript}>{text}</div>
      <div className={styles.datepickerContainer}>
        <DatePicker
          selected={dateValue}
          onChange={(date) => {
            dateFuncHelper(date);
          }}
          dateFormat="MMM d, yyyy"
          todayButton={"Today"}
          placeholderText="Select date"
          popperPlacement="bottom"
          className={isHalf ? styles.dateInputHalf : styles.dateInput}
          showYearDropdown
          showMonthDropdown
        />
        <FontAwesomeIcon
          icon={faCalendar}
          className={styles.calendarIcon}
          size="lg"
        />
      </div>
    </div>
  );
};

export default DateInput;
