import React, { Fragment } from "react";
import { FormControl, Select, MenuItem } from "@material-ui/core";
import BlueCheckbox from "../../Shared/BlueCheckbox";
import { useStyles } from "./styles.js";
import { isSelected, lotSubsetTitle } from "../utils";

const Location = ({ parkingLotIds, locations, setParkingLotIds }) => {
  const classes = useStyles();

  const changeParkingLotByCity = (city) => {
    if (
      city.hubs.filter((h) => !parkingLotIds.includes(parseInt(h.id)))
        .length === 0
    ) {
      setParkingLotIds(
        parkingLotIds.filter(
          (pid) => !city.hubs.map((h) => parseInt(h.id)).includes(pid)
        )
      );
    } else {
      setParkingLotIds([
        ...parkingLotIds.filter(
          (pid) => !city.hubs.map((h) => parseInt(h.id)).includes(pid)
        ),
        ...city.hubs.map((h) => parseInt(h.id)),
      ]);
    }
  };

  const changeParkingLotByHub = (hub) => {
    if (parkingLotIds.includes(parseInt(hub.id))) {
      setParkingLotIds(parkingLotIds.filter((pid) => pid !== parseInt(hub.id)));
    } else {
      setParkingLotIds([...parkingLotIds, parseInt(hub.id)]);
    }
  };

  return (
    <div className="dataset-options__field">
      <label>Location</label>
      <FormControl variant="outlined" className={classes.formControl}>
        <Select
          value={isSelected(parkingLotIds)}
          renderValue={() => lotSubsetTitle(parkingLotIds, locations)}
          className={classes.select}
          onChange={(e) => {}}
        >
          {locations.map((city, i) => {
            if (city.hubs.length === 0) {
              return null;
            }
            return (
              <Fragment>
                <MenuItem
                  key={i}
                  className={classes.menuItem}
                  onClick={() => changeParkingLotByCity(city)}
                >
                  <BlueCheckbox
                    size="small"
                    checked={
                      city.hubs.filter(
                        (h) => !parkingLotIds.includes(parseInt(h.id))
                      ).length === 0
                    }
                    onChange={() => changeParkingLotByCity(city)}
                    inputProps={{ "aria-label": "secondary checkbox" }}
                  />
                  <div>{city.name}</div>
                </MenuItem>
                {city.hubs.map((hub, k) => {
                  return (
                    <MenuItem
                      key={k}
                      className={classes.menuItem}
                      style={{ marginLeft: "1em" }}
                      onClick={() => changeParkingLotByHub(hub)}
                    >
                      <BlueCheckbox
                        size="small"
                        checked={parkingLotIds.includes(parseInt(hub.id))}
                        onChange={() => changeParkingLotByHub(hub)}
                        inputProps={{ "aria-label": "secondary checkbox" }}
                      />
                      <div>{hub.region}</div>
                    </MenuItem>
                  );
                })}
              </Fragment>
            );
          })}
        </Select>
      </FormControl>
    </div>
  );
};

export default Location;
