import React, { useState } from "react";
import Modal from "../../../../artzu-ui/src/Modal";
import { Fragment } from "react";
import ErrorModal from "../../../Payments/Invoices/ActionPopup/ErrorModal";
import CreateBookingContainer from "./CreateBookingContainer";

const CreateBookingModal = ({ open, toggleCreate, reloadPage, userId }) => {
  const [isError, setIsError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  const toggleErrorModal = () => {
    setIsError(!isError);
  };

  return (
    <Fragment>
      <Modal open={open} backgroundColor="none">
        <CreateBookingContainer
          toggleCreate={toggleCreate}
          userId={userId}
          setErrorMsg={setErrorMsg}
          toggleErrorModal={toggleErrorModal}
          reloadPage={reloadPage}
        />
      </Modal>
      <ErrorModal
        open={isError}
        errorMsg={errorMsg}
        toggleErrorModal={toggleErrorModal}
      />
    </Fragment>
  );
};

export default CreateBookingModal;
