import React from "react";
import Modal from "../../../../artzu-ui/src/Modal";
import styles from "./importPopup.module.scss";

const ImportMessageBox = ({ open, message, toggleMsgModal }) => {
  return (
    <Modal open={open} backgroundColor="none">
      <div className={styles.msgModal}>
        <div className={styles.text}>
          Fees have been applied to invoices {"  "}
          {message &&
            message.map((msg) => (
              <div style={{ marginLeft: "6px" }}>{msg.id}</div>
            ))}
          .
        </div>
        <div className={styles.msgClose}>
          <button
            className={styles.msgCloseButton}
            onClick={() => {
              toggleMsgModal();
            }}
          >
            Close
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default ImportMessageBox;
