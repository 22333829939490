import { useState } from "react";
import TextInput from "../../../Documents/ManualEntry/TextInput";
import { CREATE_TAG } from "../../../queries";
import Modal from "../../../../../../artzu-ui/src/Modal";
import { useMutation } from "@apollo/client";
import ButtonGroup from "../../../../../Shared/ButtonGroup";
import styles from "../../../userDetails.module.scss";
import { Input } from "@material-ui/core";

const CreateTagModal = ({ open, toggleCreate, refetchAllTags }) => {
  const [newTag, setNewTag] = useState("");
  const [createTagMutation, { loading: isCreateLoading }] =
    useMutation(CREATE_TAG);

  const createTag = () => {
    createTagMutation({
      variables: {
        tagName: newTag,
      },
    }).then(({ data: { createTag } }) => {
      if (createTag.success) {
        refetchAllTags();
        console.log("Add Tag Succeed!");
      } else {
        console.log("Add Tag error!");
      }
    });
  };

  return (
    <Modal open={open} backgroundColor="none">
      <div className={styles.popupModalContainer}>
        <div className={styles.editTagsHead}>Create Tag</div>
        <div className={styles.searchBar}>
          <Input
            placeholder="Enter tag here"
            onChange={(evt) => setNewTag(evt.target.value)}
            value={newTag}
            disableUnderline
            fullWidth={true}
          />
        </div>
        <div className={styles.buttonsFooter}>
          <div className={styles.cancelButton} onClick={toggleCreate}>
            Cancel
          </div>
          <div
            className={styles.confirmButton}
            onClick={() => {
              createTag();
              toggleCreate();
              console.log(newTag);
            }}
          >
            Confirm
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default CreateTagModal;
