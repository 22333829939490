import React from 'react'

const SvgPlus = ({ color = '#373535', ...props }) => (
  <svg
    id="plus_svg__Layer_1"
    data-name="Layer 1"
    viewBox="0 0 58.51 58.51"
    {...props}>
    <defs>
      <style>
        {`.plus_svg__cls-1{fill:none;stroke:${color};stroke-linecap:round;stroke-miterlimit:10;stroke-width:7px}`}
      </style>
    </defs>
    <path className="plus_svg__cls-1" d="M29.26 3.5v51.51M55.01 29.26H3.5" />
  </svg>
)

export default SvgPlus
