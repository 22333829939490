import React from "react";
import styles from "./referral.module.scss";
import {
  CircularProgressbar,
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import _ from "lodash";
import moment from "moment";
import { Link } from "react-router-dom";

const ReferralDisplay = ({ referrals }) => {
  console.log(referrals);

  const Separator = (props) => {
    return (
      <div
        style={{
          position: "absolute",
          height: "100%",
          transform: `rotate(${props.turns}turn)`,
        }}
      >
        <div style={props.style} />
      </div>
    );
  };

  const RadialSeparators = (props) => {
    const turns = 1 / props.count;
    return <Separator turns={turns} style={props.style} />;
  };
  const hours = 30;
  return (
    <div className={styles.statsContainer}>
      <div className={styles.title}>Referrals</div>
      <div
        className={styles.contentContainer}
        style={{ justifyContent: "flex-start" }}
      >
        {referrals.map((ref) => (
          <div className={styles.referralBox}>
            <div className={styles.circularContainer}>
              <CircularProgressbarWithChildren
                value={ref.hoursDriven}
                styles={buildStyles({
                  strokeLinecap: "butt",
                  pathColor: "#128dff",
                  textColor: "#414141",
                  textSize: "28px",
                })}
              >
                <div style={{ fontSize: 32, fontWeight: 700 }}>
                  {ref.hoursDriven}
                </div>
                <RadialSeparators
                  count={1}
                  style={{
                    background: "#3dc700",
                    width: "3px",
                    height: `${8}%`,
                  }}
                />
                <RadialSeparators
                  count={5}
                  style={{
                    background: "#ff8900",
                    width: "3px",
                    height: `${8}%`,
                  }}
                />
              </CircularProgressbarWithChildren>
            </div>
            <Link
              to={`/users/${ref.referredUserId}/details`}
              style={{
                marginBottom: "5px",
                fontSize: "18px",
                color: "#128dff",
                textDecoration: "none",
              }}
            >
              {ref.referredUserName}
            </Link>
            <div style={{ fontSize: "14px" }}>
              Signed up on:{" "}
              {moment(ref.referredUserSignedUpDate).format("MMM D, YYYY")}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ReferralDisplay;
