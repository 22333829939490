import React from "react";
import { faAlarmClock } from "@fortawesome/pro-light-svg-icons";
import { faThumbtack } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { withStyles } from "@material-ui/core/styles";
import { TextField } from "@material-ui/core";
import UpsertComment from "../../../../Mutations/UpsertComment";
import { useState } from "react";
import ReminderModal from "./CommentsModal/ReminderModal";
import {
  PIN_USER_COMMENT,
  EDIT_USER_COMMENT,
  DELETE_USER_COMMENT,
  REMIND_USER_COMMENT,
} from "../../../../Queries/queryStrings";
import { useMutation } from "@apollo/client";
import EditModal from "./CommentsModal/EditModal";
import DeleteModal from "./CommentsModal/DeleteModal";
import SingleComment from "./SingleComment";

const CommentsBody = ({
  resourceId,
  pinnedComments,
  unpinnedComments,
  refetch,
  timeZone,
}) => {
  const [body, setBody] = useState("");
  const [pinned, setPinned] = useState();
  const [timestamp, setTimestamp] = useState();
  const [isReminder, setIsReminder] = useState();
  const [isEdit, setIsEdit] = useState();
  const [isDelete, setIsDelete] = useState();
  const [changedBody, setChangedBody] = useState();
  const [curId, setCurId] = useState();
  const [existingComment, setExistingComment] = useState(false);

  const constructComment = () => {
    return {
      resourceType: "User",
      resourceId: resourceId,
      body: body,
      pinned: pinned,
      timestamp: timestamp,
    };
  };

  const triggerText = (e) => {
    setBody(e.target.value);
  };

  const togglePin = () => {
    setPinned(!pinned);
  };

  const toggleReminder = () => {
    setIsReminder(!isReminder);
  };

  const toggleEdit = () => {
    setIsEdit(!isEdit);
  };

  const toggleDelete = () => {
    setIsDelete(!isDelete);
  };

  const [pinCommentMutation, { loading: isPinLoading }] =
    useMutation(PIN_USER_COMMENT);
  const handlePin = (commentId, pinned) => {
    pinCommentMutation({
      variables: {
        id: commentId,
        pinned: pinned,
      },
    }).then(({ data: { pinComment } }) => {
      if (pinComment.comment) {
        refetch();
        console.log("Pin Comment Succeed!");
      } else {
        console.log("Pin Comment error!");
      }
    });
  };

  const [editCommentMutation, { loading: isEditLoading }] =
    useMutation(EDIT_USER_COMMENT);
  const handleEdit = (commentId, changedBody) => {
    editCommentMutation({
      variables: {
        id: commentId,
        body: changedBody,
      },
    }).then(({ data: { editComment } }) => {
      if (editComment.comment) {
        refetch();
        console.log("Edit Comment Succeed!");
      } else {
        console.log("Edit Comment error!");
      }
    });
  };

  const [remindCommentMutation, { loading: isRemindLoading }] =
    useMutation(REMIND_USER_COMMENT);
  const handleRemind = (commentId, timestamp) => {
    remindCommentMutation({
      variables: {
        id: commentId,
        timestamp: timestamp,
      },
    }).then(({ data: { remindComment } }) => {
      if (remindComment.comment) {
        refetch();
        console.log("Add Reminder to Comment Succeed!");
      } else {
        console.log("Add Reminder to Comment error!");
      }
    });
  };

  const [deleteCommentMutation, { loading: isDeleteLoading }] =
    useMutation(DELETE_USER_COMMENT);
  const handleDelete = (commentId) => {
    deleteCommentMutation({
      variables: {
        id: commentId,
      },
    }).then(({ data: { deleteComment } }) => {
      if (deleteComment.success) {
        refetch();
        console.log("Delete Comment Succeed!");
      } else {
        console.log("Delete Comment error!");
      }
    });
  };

  return (
    <div className="user-page__body">
      <div className="page-section bottom-border">
        <div className="page-section__heading">New comment</div>
        <TextField
          id="standard-multiline-static"
          multiline
          rows={4}
          value={body}
          onChange={triggerText}
          placeholder="Enter comment here..."
          variant="outlined"
        />
        <div className="comment-actions">
          <div
            className={`action-button${timestamp ? " inverted" : ""}`}
            onClick={toggleReminder}
            style={{
              cursor: "pointer",
            }}
          >
            <FontAwesomeIcon icon={faAlarmClock} />
          </div>
          <div
            className={`action-button${pinned ? " inverted" : ""}`}
            onClick={togglePin}
            style={{ cursor: "pointer" }}
          >
            <FontAwesomeIcon icon={faThumbtack} />
          </div>
          <UpsertComment
            comment={constructComment()}
            callback={() => {
              refetch();
              setBody("");
              setTimestamp(undefined);
              togglePin();
            }}
          />
        </div>
      </div>
      <div className="page-section bottom-border">
        <div className="page-section__heading">Pinned comments</div>
        {pinnedComments.map((comment, index) => {
          return (
            <SingleComment
              comment={comment}
              index={index}
              timeZone={timeZone}
              handlePin={handlePin}
              setExistingComment={setExistingComment}
              toggleReminder={toggleReminder}
              setCurId={setCurId}
              setChangedBody={setChangedBody}
              toggleEdit={toggleEdit}
              toggleDelete={toggleDelete}
            />
          );
        })}
      </div>
      <div className="page-section bottom-border">
        <div className="page-section__heading">All comments</div>
        {unpinnedComments.map((comment, index) => {
          return (
            <SingleComment
              comment={comment}
              index={index}
              timeZone={timeZone}
              handlePin={handlePin}
              setExistingComment={setExistingComment}
              toggleReminder={toggleReminder}
              setCurId={setCurId}
              setChangedBody={setChangedBody}
              toggleEdit={toggleEdit}
              toggleDelete={toggleDelete}
            />
          );
        })}
      </div>
      <ReminderModal
        open={isReminder}
        timestamp={timestamp}
        setTimestamp={setTimestamp}
        toggleReminder={toggleReminder}
        existingComment={existingComment}
        setExistingComment={setExistingComment}
        handleRemind={handleRemind}
        curId={curId}
      />
      <EditModal
        open={isEdit}
        setChangedBody={setChangedBody}
        toggleEdit={toggleEdit}
        changedBody={changedBody}
        handleEdit={handleEdit}
        curId={curId}
      />
      <DeleteModal
        open={isDelete}
        toggleDelete={toggleDelete}
        handleDelete={handleDelete}
        curId={curId}
      />
    </div>
  );
};

const styles = (theme) => ({
  root: {
    width: "100%",
    marginTop: "0.5em",
    "&$focused": {
      color: "#128dff",
    },
    backgroundColor: "#ffffff",
  },
  statusControl: {
    width: "50%",
    borderRadius: "12px",
    backgroundColor: "#fff",
    height: "24px",
  },
  statusSelect: {
    borderRadius: "25px",
    height: "24px",
    backgroundColor: "#fff",
    "&$focused": {
      borderColor: "#128dff",
      color: "#128dff",
    },
  },
});

export default withStyles(styles)(CommentsBody);
