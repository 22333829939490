import React from "react";
import Modal from "../../../../artzu-ui/src/Modal";
import styles from "./popup.module.scss";

const ErrorModal = ({ open, errorMsg, toggleErrorModal }) => {
  return (
    <Modal open={open} backgroundColor="none">
      <div className={styles.errorModal}>
        <div className={styles.text}>{errorMsg}</div>
        <div className={styles.errorCancel}>
          <button
            className={styles.errorCancelButton}
            onClick={() => {
              toggleErrorModal();
            }}
          >
            Cancel
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default ErrorModal;
