import { useQuery } from "@apollo/client";
import React, { useState } from "react";
import { GET_USER_INFO } from "../queries";
import RelatedBooking from "./Booking/RelatedBooking";
import TicketReminder from "./TicketReminder";
import TicketTags from "./TicketTags";
import RelatedUser from "./User/RelatedUser";

const TicketRight = ({
  ticketData,
  refreshTicketCallback,
  userIdCallback,
  bookingIdCallback,
  relatedUserData,
  userId,
  isEdit,
  isCancel,
  defaultAdmin,
}) => {
  const userData = ticketData && ticketData.user;
  const bookingData = ticketData && ticketData.booking;
  const allTags = ticketData?.allValidTags || [];
  const tagData = ticketData && ticketData.ticketTags;
  const taggedAdmins = ticketData && ticketData.taggedUsers;

  return (
    <div className="right-container">
      <RelatedUser
        userData={userData}
        userIdCallback={userIdCallback}
        relatedUserData={relatedUserData}
      />
      <RelatedBooking
        bookingData={bookingData}
        userId={userId}
        bookingIdCallback={bookingIdCallback}
        isEdit={isEdit}
        ticketData={ticketData}
        isCancel={isCancel}
        refreshTicketCallback={refreshTicketCallback}
        userData={userData}
        relatedUserData={relatedUserData}
      />
      <TicketTags
        ticketData={ticketData}
        allTags={allTags}
        tagData={tagData}
        refreshTicketCallback={refreshTicketCallback}
      />
      <TicketReminder
        ticketData={ticketData}
        refreshTicketCallback={refreshTicketCallback}
        taggedAdmins={taggedAdmins}
        defaultAdmin={defaultAdmin}
      />
    </div>
  );
};

export default TicketRight;
