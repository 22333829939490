import React, { useState } from "react";
import { Fragment } from "react";
import Button from "../../../Shared/Button";
import Dropdown from "../../ActionBar/Filters/OptionsModal/Body/Filter/Dropdown";
import styles from "../batchActions.module.scss";
import classsNames from "classnames";
import { BATCH_STATUS_CHANGE } from "../queries";
import { useMutation } from "@apollo/client";
import ErrorModal from "../../../Payments/Invoices/ActionPopup/ErrorModal";
import Loading from "../../../Shared/Loading";
import FeedbackModal from "../../../Shared/Modal/FeedbackModal";
import SelectAllConfirmModal from "./SelectAllConfirmModal";

const ChangeUserStatus = ({
  selectedOption,
  selections,
  close,
  clearAllSelections,
  isAllSelected,
  toggleRefresh,
  toggleFeedbackModal,
  citiesFilter,
}) => {
  const [selected, setSelected] = useState("");
  const [comment, setComment] = useState("");
  const [isError, setIsError] = useState();
  const [errorMsg, setErrorMsg] = useState();
  const [isConfirm, setIsConfirm] = useState();

  const toggleConfirmModal = () => {
    setIsConfirm(!isConfirm);
  };

  const statuses = [
    { name: "closed", value: "closed" },
    { name: "blacklisted", value: "blacklisted" },
  ];

  const triggerChange = (e) => {
    setSelected(e);
  };

  const handleCommentInput = (e) => {
    setComment(e.target.value);
  };

  const toggleErrorModal = () => {
    setIsError(!isError);
  };

  const [statusChange, { loading, error }] = useMutation(BATCH_STATUS_CHANGE);
  const changeUserStatus = () => {
    statusChange({
      variables: {
        userIds: selections,
        status: selected,
        changeComment: comment,
        tableName: "users",
        isAllSelected: isAllSelected,
        citiesToFilter: citiesFilter || [],
      },
    }).then(({ data: { batchStatusChange } }) => {
      if (batchStatusChange.users) {
        console.log("Status Change Succeed!");
        toggleRefresh();
      } else {
        toggleErrorModal();
        setErrorMsg(batchStatusChange.errors);
        console.log("Status Change error!");
      }
    });
  };

  const addable = selected;

  return loading ? (
    <Loading size="3x" />
  ) : (
    <Fragment>
      <div className={styles.darkText}>{selectedOption.text}</div>
      <div className={styles.contentContainer}>
        <div className={styles.optionContainer}>
          <div className={styles.optionBox}>
            Change status to
            <Dropdown
              initialValue=""
              items={statuses}
              onChange={triggerChange}
            />
          </div>
        </div>
        <div className={styles.comment}>
          <h4>Comment</h4>
          <textarea
            className={classsNames(styles.textBox, styles.commentBox)}
            onChange={handleCommentInput}
          ></textarea>
        </div>
        <div className={styles.notesSaveButton}>
          <Button
            label={"Save"}
            height="39px"
            fullWidth="150px"
            color={addable ? "#128dff" : "#87CEFA"}
            backColor="#ffffff"
            radius="20px"
            size="16px"
            weight="700"
            border="1px solid #FFFFFF"
            onClick={() => {
              addable && !isAllSelected && changeUserStatus();
              addable && !isAllSelected && clearAllSelections();
              addable && !isAllSelected && toggleFeedbackModal();
              addable && !isAllSelected && close();
              addable && isAllSelected && toggleConfirmModal();
            }}
          />
        </div>
      </div>
      <ErrorModal
        open={isError}
        errorMsg={errorMsg}
        toggleErrorModal={toggleErrorModal}
      />
      <SelectAllConfirmModal
        open={isConfirm}
        rowCount={selections.length}
        batchAction={selectedOption.text}
        batchActionFun={changeUserStatus}
        toggleConfirmModal={toggleConfirmModal}
        toggleFeedbackModal={toggleFeedbackModal}
        clearAllSelections={clearAllSelections}
        close={close}
      />
    </Fragment>
  );
};

export default ChangeUserStatus;
