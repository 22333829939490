import React from 'react';
import { withStyles } from "@material-ui/core/styles"
import Checkbox from '@material-ui/core/Checkbox';

const BlueCheckbox = withStyles({
  root: {
    color: "#128dff",
    '&$checked': {
      color: "#128dff",
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

export default BlueCheckbox
