import { useMutation, useQuery } from "@apollo/client";
import React, { useEffect } from "react";
import { useState } from "react";
import Modal from "../../../../../../artzu-ui/src/Modal";
import Loading from "../../../../../Shared/Loading";
import { GET_ALL_USER_TAGS, MODIFY_USER_TAGS } from "../../../queries";
import styles from "../../../userDetails.module.scss";
import { faPlus } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Autocomplete from "../../../../../Shared/AutoComplete/Autocomplete";
import ManageTagsModal from "./ManageTagsModal";
import TagCellList from "./TagCellList";

const TagsModal = ({ open, toggleTags, user, tagsData, refetchProfile }) => {
  const [searchValue, setSearchValue] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [newTags, setNewTags] = useState([]);
  const [tagsToRemove, setTagsToRemove] = useState([]);
  const [selectedTags, setSelectedTags] = useState(
    tagsData.map((tag) => tag.name)
  );
  const [isManage, setIsManage] = useState();

  const { loading, error, data, refetch } = useQuery(GET_ALL_USER_TAGS, {
    fetchPolicy: "no-cache",
  });
  if (data) {
    refetch();
  }

  let allTags = data && data.getAllUserTags.map((tag) => tag.name);

  const [editTagsMutation, { loading: isTagsLoading }] =
    useMutation(MODIFY_USER_TAGS);

  const reset = () => {
    clearInputs();
    setNewTags([]);
    setErrorMessage("");
    setSelectedTags(tagsData.map((tag) => tag.name)); //find a cleaner solution for cancelling after removing
    setTagsToRemove([]);
    toggleTags();
  };

  const validTag = () => {
    return (
      searchValue &&
      !newTags.includes(searchValue) &&
      !selectedTags.includes(searchValue)
    );
  };

  const toggleManage = () => {
    setIsManage(!isManage);
  };

  useEffect(() => {
    setSelectedTags(tagsData.map((tag) => tag.name));
    refetchProfile();
    refetch();
  }, [tagsData]);

  const modifyTagMutation = () => {
    editTagsMutation({
      variables: {
        newTags: newTags,
        tagsToRemove: tagsToRemove,
        userId: user && user.id,
      },
    }).then(({ data: { modifyUserTags } }) => {
      if (modifyUserTags.success) {
        refetchProfile();
        console.log("Modify Tags Succeed!");
      } else {
        console.log("Modify Tags error!");
      }
    });
  };

  const handleRemoveTag = (tagToRemove) => {
    setTagsToRemove([...tagsToRemove, tagToRemove]);
    const newArray = selectedTags.filter((tag) => tag !== tagToRemove);
    setSelectedTags(newArray);
  };

  const clearInputs = () => {
    setSearchValue("");
  };

  const removeNewTag = (tag) => {
    const newArray = newTags.filter((str) => str !== tag);
    setNewTags(newArray);
  };

  const handleAddTag = (tagtoAdd) => {
    if (validTag()) {
      setNewTags([...newTags, tagtoAdd]);
      setErrorMessage("");
    } else {
      if (tagtoAdd) {
        setErrorMessage("Tag already exists");
      } else {
        setErrorMessage("Please enter a tag");
      }
    }
  };

  return (
    <Modal open={open} backgroundColor="none">
      {isTagsLoading ? (
        <Loading />
      ) : (
        <div className={styles.tagsModalContainer}>
          <div className={styles.editTagsHead}>
            Edit Tags
            <div className={styles.manageButton} onClick={toggleManage}>
              Manage All Tags
            </div>
          </div>
          <div className="subscript">Add Tags</div>
          <div className={styles.addTags}>
            <Autocomplete
              options={allTags}
              parentCallback={setSearchValue}
              newTag={searchValue}
              addable={validTag}
            />
            <div
              className={styles.addButton}
              style={validTag() ? null : { background: "#88beff" }}
              onClick={() => {
                handleAddTag(searchValue);
                clearInputs();
              }}
            >
              <FontAwesomeIcon icon={faPlus} />
              Add
            </div>
          </div>
          <TagCellList
            tags={newTags}
            searchValue={searchValue}
            parentCallback={removeNewTag}
          />
          <div className={styles.existingTagsHead}>
            <div className="subscript">Existing Tags</div>
            <div className={styles.errorMessage}>{errorMessage}</div>
          </div>
          <TagCellList
            tags={selectedTags}
            searchValue={searchValue}
            parentCallback={handleRemoveTag}
          />
          <div className={styles.buttonsFooter}>
            <div
              className={styles.cancelButton}
              onClick={() => {
                reset();
              }}
            >
              Cancel
            </div>
            <div
              className={styles.applyButton}
              onClick={() => {
                modifyTagMutation();
                reset();
              }}
            >
              Apply
            </div>
          </div>
          <ManageTagsModal
            open={isManage}
            toggleManage={toggleManage}
            user={user}
            allTagsData={data}
            refetchProfile={refetchProfile}
            refetchAllTags={refetch}
          />
        </div>
      )}
    </Modal>
  );
};

export default TagsModal;
