import React, { Component, Fragment, useState } from "react";
import InformationTable from "../InformationTable";
import { Helmet } from "react-helmet";

const Communication = ({ selectedCities }) => {
  return (
    <Fragment>
      <Helmet>
        <title>Communications | Autzu Admin</title>
      </Helmet>

      <InformationTable
        rowComponentType="ticket"
        tableName="tickets"
        navbar={"Communications"}
        startTerms="Search By name, userID, phone and email"
        cities={selectedCities}
      />
    </Fragment>
  );
};

export default Communication;
