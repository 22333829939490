import React, { Fragment, useState } from "react";
import styles from "../bookingActions.module.scss";
import TimeContainer from "./TimeContainer";
import ActionContainer from "../Shared/ActionContainer";
import CommentContainer from "../Shared/CommentContainer";
import moment from "moment";
import { useQuery, useMutation } from "@apollo/client";
import { USER_BOOKING_CONFIG, CHANGE_BOOKING_SCHEDULE } from "../../../queries";
import Loading from "../../../../../Shared/Loading";

const EditContainer = ({ close, userId, booking, showErrorMessage }) => {
  const [newStartTime, setStartTime] = useState();
  const [newEndTime, setEndTime] = useState();
  const [comment, setComment] = useState();

  const { data, loading: isUserBookingConfigLoading } = useQuery(
    USER_BOOKING_CONFIG,
    {
      variables: { userId: userId },
    }
  );

  const [changeSchedule, { loading: isChangeBookingLoading }] = useMutation(
    CHANGE_BOOKING_SCHEDULE
  );

  const handleSetComment = (newComment) => setComment(newComment.trim());

  const saveBooking = () => {
    changeSchedule({
      variables: {
        bookingId: booking.id,
        startTime: newStartTime || booking.startTime,
        endTime: newEndTime || booking.endTime,
        comment,
      },
    }).then(
      ({
        data: {
          changeSchedule: { booking, error },
        },
      }) => {
        if (booking) {
          close();
        } else {
          showErrorMessage(error);
        }
      }
    );
  };

  const canSubmit = () => (newStartTime || newEndTime) && comment;

  const userBookingConfig = data?.userBookingConfig;

  return (
    <div className={styles.actionContainer}>
      {isUserBookingConfigLoading || isChangeBookingLoading ? (
        <Loading />
      ) : (
        <Fragment>
          <div className={styles.title}>Edit scheduled time </div>
          <TimeContainer
            booking={booking}
            userBookingConfig={userBookingConfig}
            changeStartTime={setStartTime}
            changeEndTime={setEndTime}
          />
          <CommentContainer changeComment={handleSetComment} />
          <ActionContainer
            handleSave={saveBooking}
            closeContainer={close}
            valid={canSubmit()}
          />
        </Fragment>
      )}
    </div>
  );
};

export default EditContainer;
