import React from "react";
import Modal from "../../../../../artzu-ui/src/Modal";
import styles from "./bookingTimeline.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-light-svg-icons";
import missing from "../../../../../images/missing.png";

const ParkingModal = ({ isOpen, toggleParking, parking, isPersonal }) => {
  const spotNumber = parking?.caption1;
  const parkingImage = parking?.caption2;
  const personalPlate = isPersonal && parking?.caption3;
  const fleetClientUrl = process.env.REACT_APP_FLEET_CLIENT_URL;
  return (
    <Modal open={isOpen} backgroundColor="none">
      <div className={styles.feedbackModal}>
        <div className={styles.modalTitle}>
          {isPersonal ? "Personal vehicle" : "Parking spot"}{" "}
          <FontAwesomeIcon
            icon={faTimes}
            size="sm"
            onClick={toggleParking}
            className={styles.toClick}
          />
        </div>
        {isPersonal && (
          <div className={styles.parkingField}>
            <div className={styles.subscript}>
              Personal vehicle license plate
            </div>
            <div className={styles.fieldValue}>{personalPlate}</div>
          </div>
        )}
        <div className={styles.parkingField}>
          <div className={styles.subscript}>Parking spot number</div>
          <div className={styles.fieldValue}>{spotNumber}</div>
        </div>
        <img
          className={styles.parkingImage}
          src={parkingImage || missing}
        ></img>
        <a
          href={`${fleetClientUrl}/tasks/new`}
          target="_blank"
          style={{ textDecoration: "none" }}
        >
          <button className={styles.createTaskButton}>Create task</button>
        </a>
      </div>
    </Modal>
  );
};

export default ParkingModal;
