import moment from "moment-timezone";
import AmountValue from "../CellValues/Base/AmountValue";
import BubbleTextValue from "../CellValues/Base/BubbleTextValue";
import ExpandableList from "../CellValues/Base/ExpandableList";
import RequirementValue from "../CellValues/Base/RequirementValue";
import TextValue from "../CellValues/Base/TextValue";
import TimeValue from "../CellValues/Base/TimeValue";
import UserValue from "../CellValues/Base/UserValue";

import {
  baseDateProcessor,
  baseTextProcessor,
  baseImageProcessor,
} from "./shared";

const baseRequirementProcessor = (accessor) => {
  return (data) => {
    const listData = data[accessor].value;
    return {
      text: listData.replace(/[{}]/g, "").split(","),
    };
  };
};

const baseTagProcessor = (accessor) => {
  return (data) => {
    const listData = data[accessor].value;
    if (listData) {
      const mappedListData = listData.map((data) => ({
        text: data.name,
      }));
      return {
        listComponent: BubbleTextValue,
        listData: mappedListData,
      };
    } else {
      return {
        listComponent: BubbleTextValue,
        listData: [],
      };
    }
  };
};

const rowComponentsUser = (time_zone) => ({
  profileImage: {
    component: UserValue,
    dataProcessor: baseImageProcessor("userProfileImageId"),
  },
  userName: {
    component: TextValue,
    dataProcessor: baseTextProcessor("userName"),
  },
  userId: {
    component: TextValue,
    dataProcessor: baseTextProcessor("userId"),
  },
  accountStatus: {
    component: TextValue,
    dataProcessor: baseTextProcessor("accountStatus"),
  },
  email: {
    component: TextValue,
    dataProcessor: baseTextProcessor("email"),
  },
  phone: {
    component: TextValue,
    dataProcessor: baseTextProcessor("mobile"),
  },
  bookedHours: {
    component: TimeValue,
    dataProcessor: baseTextProcessor("bookedHours"),
  },
  futureBookedHours: {
    component: TimeValue,
    dataProcessor: baseTextProcessor("futureBookedHours"),
  },
  futureBookedCount: {
    component: TextValue,
    dataProcessor: baseTextProcessor("futureBookedCount"),
  },
  cumulativeBalance: {
    component: AmountValue,
    dataProcessor: baseTextProcessor("cumulativeBalance"),
  },
  unreadTexts: {
    component: TextValue,
    dataProcessor: baseTextProcessor("unreadTextsCount"),
  },
  validCookies: {
    component: TextValue,
    dataProcessor: baseTextProcessor("hasValidCookies"),
  },
  uberStatus: {
    component: TextValue,
    dataProcessor: baseTextProcessor("uberStatus"),
  },
  lyftStatus: {
    component: TextValue,
    dataProcessor: baseTextProcessor("lyftStatus"),
  },
  activeRideshareVehicles: {
    component: TextValue,
    dataProcessor: baseTextProcessor("activeRideshareVehicles"),
  },
  activeUberVehicles: {
    component: TextValue,
    dataProcessor: baseTextProcessor("activeUberVehicles"),
  },
  activeLyftVehicles: {
    component: TextValue,
    dataProcessor: baseTextProcessor("activeLyftVehicles"),
  },
  documentsVerified: {
    component: TextValue,
    dataProcessor: baseTextProcessor("documentsVerified"),
  },
  outstandingRequirements: {
    component: RequirementValue,
    dataProcessor: baseRequirementProcessor("outstandingRequirements"),
  },
  outstandingRequirementsCount: {
    component: TextValue,
    dataProcessor: baseTextProcessor("outstandingRequirementsCount"),
  },
  appVersion: {
    component: TextValue,
    dataProcessor: baseTextProcessor("appVersion"),
  },
  createdAt: {
    component: TextValue,
    dataProcessor: baseDateProcessor("createdAt", time_zone),
  },
  updatedAt: {
    component: TextValue,
    dataProcessor: baseDateProcessor("updatedAt", time_zone),
  },
  lastBookingUpdate: {
    component: TextValue,
    dataProcessor: baseDateProcessor("lastBookingUpdate", time_zone),
  },
  deviceOs: {
    component: TextValue,
    dataProcessor: baseTextProcessor("deviceOs"),
  },
  outstandingBalance: {
    component: AmountValue,
    dataProcessor: baseTextProcessor("outstandingBalance"),
  },
  parkingLotName: {
    component: TextValue,
    dataProcessor: baseTextProcessor("parkingLotName"),
  },
  firstBooking: {
    component: TextValue,
    dataProcessor: baseDateProcessor("firstBooking", time_zone),
  },
  lastLoggedIn: {
    component: TextValue,
    dataProcessor: baseDateProcessor("lastSignInAt", time_zone),
  },
  uberAuthPresent: {
    component: TextValue,
    dataProcessor: baseTextProcessor("uberAuthPresent"),
  },
  tags: {
    component: ExpandableList,
    dataProcessor: baseTagProcessor("tags"),
  },
  tickets: {
    component: TextValue,
    dataProcessor: baseTextProcessor("ticketsCount"),
  },
});

export { rowComponentsUser };
