import { useMutation } from "@apollo/client";
import React, { useState } from "react";
import { Fragment } from "react";
import Modal from "../../../../artzu-ui/src/Modal";
import { DENY_FINANCING } from "../../queries";
import ErrorModal from "./ErrorModal";
import styles from "./popup.module.scss";

const Deny = ({ open, toggleDeny, invoiceId, refetch, closeList }) => {
  const [curComment, setCurComment] = useState();
  const [isError, setIsError] = useState();
  const [errorMsg, setErrorMsg] = useState();

  const setComment = (e) => {
    setCurComment(e.target.value);
  };

  const toggleErrorModal = () => {
    setIsError(!isError);
  };

  const [denyFinancingMutation] = useMutation(DENY_FINANCING);
  const denyFinancing = () => {
    denyFinancingMutation({
      variables: {
        invoiceId: invoiceId,
        comments: curComment,
      },
    }).then(({ data: { denyInvoiceFinancing } }) => {
      if (denyInvoiceFinancing.success) {
        console.log("deny financing succeed!");
        // window.location.reload();
        refetch();
      } else {
        toggleErrorModal();
        setErrorMsg(denyInvoiceFinancing.error);
        console.log("deny financing error!");
      }
    });
  };
  return (
    <Fragment>
      <Modal open={open} backgroundColor="none">
        <div className={styles.denyPopup}>
          Please enter the reason for denial of this invoice.
          <textarea className={styles.denyReason} onChange={setComment}>
            {curComment}
          </textarea>
          <div className={styles.actionButtons}>
            <div>
              <button
                className={styles.cancelButton}
                onClick={() => {
                  toggleDeny();
                  closeList();
                }}
              >
                Cancel
              </button>
            </div>
            <div>
              <button
                className={styles.saveButton}
                onClick={() => {
                  toggleDeny();
                  denyFinancing();
                  closeList();
                }}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </Modal>
      <ErrorModal
        open={isError}
        errorMsg={errorMsg}
        toggleErrorModal={toggleErrorModal}
      />
    </Fragment>
  );
};

export default Deny;
