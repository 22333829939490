import React from 'react'

const SvgPencil = ({ color = '#231f20', ...props }) => (
  <svg data-name="Layer 1" viewBox="0 0 62.51 62.31" {...props}>
    <path
      d="M55.97 21.21A10.377 10.377 0 0 0 41.29 6.54L11.96 35.88a10.83 10.83 0 0 0-.91 1 10.47 10.47 0 0 0-1.62 2.53h0l-5.74 17h0a1.54 1.54 0 0 0 2.07 2.1h0l17.23-5.54a10.41 10.41 0 0 0 2.57-1.65 10.55 10.55 0 0 0 1-.9z"
      fill="none"
      stroke={color}
      strokeLinejoin="round"
      strokeWidth={7}
    />
  </svg>
)

export default SvgPencil
