import React from "react";
import classNames from "classnames";
import styles from "./baseValue.module.scss";
import { renderColor } from "../../../../utils";
import _, { capitalize } from "lodash";

const TextValue = ({ cellData, classes, columnName }) => {
  const { text, subtext } = cellData;
  const selectable =
    columnName === "bookingId" ||
    columnName === "userFullName" ||
    columnName === "driverId" ||
    columnName === "vehiclePlate" ||
    columnName === "invoiceNumber" ||
    columnName === "bookingId" ||
    columnName === "phone" ||
    columnName === "userId" ||
    columnName === "userName" ||
    columnName === "email";

  const getColor = (text) => {
    switch (text) {
      case "in_progress":
      case "signed_up":
        return "orange";
      case "active":
        return "#3dc700";
      case "suspended":
        return "red";
      case "connection_expired":
        return "#ff0000";
      default:
        break;
    }
  };
  const textColor =
    columnName === "accountStatus" ||
    columnName === "uberStatus" ||
    columnName === "lyftStatus"
      ? getColor(text)
      : "#414141";

  return (
    <div
      className={classNames(classes)}
      onClick={(evt) => (selectable ? evt.stopPropagation() : {})}
      style={{ cursor: selectable ? "auto" : "pointer" }}
    >
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div
          className={styles.textValue}
          style={{
            color: textColor,
          }}
        >
          {columnName === "accountStatus" ||
          columnName === "uberStatus" ||
          columnName === "lyftStatus"
            ? _.startCase(text)
            : columnName === "referenceNumber"
            ? String(text).padStart(6, "0")
            : columnName === "duration"
            ? parseInt(text)
            : text}
        </div>
        <div
          className={styles.subtext}
          style={{
            color: columnName === "userAppVersion" ? "#414141" : "#bebebe",
          }}
        >
          {subtext}
        </div>
      </div>
    </div>
  );
};

export default TextValue;
