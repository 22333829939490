import styles from "../bookingActions.module.scss";
import CommentContainer from "../Shared/CommentContainer";
import ActionContainer from "../Shared/ActionContainer";
import React from "react";

const EndBookingPrompt = ({
  closeEditBooking,
  handleEndBooking,
  setComment,
  comment,
}) => (
  <>
    <div className={styles.title}>End booking</div>
    <div className={styles.text}>
      Are you sure you want to end this booking?
    </div>
    <CommentContainer changeComment={setComment} title={"Reason"} />
    <ActionContainer
      closeContainer={closeEditBooking}
      handleSave={handleEndBooking}
      valid={comment}
      saveButtonName={"Yes"}
      cancelButtonName={"No"}
    />
  </>
);

export default EndBookingPrompt;
