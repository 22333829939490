import { gql } from "@apollo/client";

const START_AI_CHAT_MUTATION = gql`
  mutation startAiChat {
    startAiChat {
      chat {
        id
      }
    }
  }
`;

const GET_AI_CHAT_QUERY = gql`
  query getAutzuAiChat($chatId: ID!) {
    getAutzuAiChat(chatId: $chatId) {
      id
      messages {
        id
        text
        source
        createdAt
      }
    }
  }
`;

const SUBMIT_AI_CHAT_MUTATION = gql`
  mutation submitMessage($chatId: ID!, $message: String!) {
    submitMessage(chatId: $chatId, message: $message) {
      success
    }
  }
`;

export { START_AI_CHAT_MUTATION, GET_AI_CHAT_QUERY, SUBMIT_AI_CHAT_MUTATION };
