import React from 'react'
import styled from 'styled-components'

const StyledIconContainer = styled.div`
  max-width: 100%;
  width: ${({ size }) => getIconSize(size)};
  height: ${({ size }) => getIconSize(size)};
  transform: ${({ rotation }) => `rotate(${rotation}deg)` || null};
  display: inline-flex;
  justify-content: center;
  align-items: center;
`

const IconToRender = ({ name, ...props }) => {
  try {
    require(`./icons/${name}`)
  } catch(error) {
    return null
  }
  const Comp = require(`./icons/${name}`).default
  return <Comp name={name} {...props} />
}

const Icon = props => (
  <StyledIconContainer {...props}>
    <IconToRender width="100%" height="100%" {...props} />
  </StyledIconContainer>
)

export default Icon

const getIconSize = (size = 'md') => {
  switch (size) {
    case 'xs':
      return '12px'
    case 'sm':
      return '18px'
    case 'md':
      return '24px'
    case 'lg':
      return '30px'
    case 'xl':
      return '36px'
    case '2x':
      return '42px'
    case '3x':
      return '48px'
    case '4x':
      return '64px'
    case '5x':
      return '96px'
    default:
      return '24px'
  }
}
