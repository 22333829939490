import React from "react";
import styles from "./locationSelect.module.scss";
import _ from "lodash";
import LocationSelectButtons from "./Buttons";
import City from "./DropdownCity";

const LocationSelectDropdown = ({
  lots,
  lotsSelected,
  selectLots,
  reset,
  store,
  toggleOpen,
  handleCityCallback,
}) => {
  return (
    <div className={styles.dropdown}>
      <City lots={lots} selectLots={selectLots} lotsSelected={lotsSelected} />
      <LocationSelectButtons
        lotsSelected={lotsSelected}
        reset={reset}
        store={store}
        toggleOpen={toggleOpen}
        handleCityCallback={handleCityCallback}
      />
    </div>
  );
};

export default LocationSelectDropdown;
