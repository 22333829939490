import React, { Fragment } from "react";

const DisplayVehicle = ({ imageLink, plate }) => (
  <Fragment>
    <img src={imageLink} />
    <div className="bold">{plate}</div>
  </Fragment>
);

export default DisplayVehicle;
