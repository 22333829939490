import { useMutation } from "@apollo/client";
import React, { useState } from "react";
import Dropdown from "../../ActionBar/Filters/OptionsModal/Body/Filter/Dropdown";
import styles from "../batchActions.module.scss";
import { BULK_ASSIGN_AGENT } from "../queries";

const AssignAgent = ({
  selections,
  close,
  clearAllSelections,
  adminData,
  reAssign,
}) => {
  const [newAdmin, setNewAdmin] = useState();

  const triggerAdmin = (childData) => {
    setNewAdmin(childData);
  };

  const allAdmins = adminData
    ? adminData.allAdminName.map((admin) => {
        return {
          name: admin.firstName + " " + admin.lastName,
          value: admin.firstName + " " + admin.lastName,
        };
      })
    : [];

  const [assignAgentMutation] = useMutation(BULK_ASSIGN_AGENT);
  const handleAssignAgent = () => {
    assignAgentMutation({
      variables: {
        ticketIds: selections,
        agentId: adminData.allAdminName.find(
          (item) => item.firstName + " " + item.lastName === newAdmin
        ).id,
      },
    }).then(({ data: { bulkAssignAgent } }) => {
      if (bulkAssignAgent.success) {
        clearAllSelections();
        close();
        console.log(
          reAssign ? "Re-assign to Agent Succeed!" : "Assign to Agent Succeed!"
        );
      } else {
        console.log(
          reAssign ? "Re-assign to Agent error!" : "Assign to Agent error!"
        );
      }
    });
  };

  const applyable = newAdmin !== undefined && newAdmin !== null;

  return (
    <div className={styles.ticketActionContainer} style={{ height: "260px" }}>
      <div className={styles.actionHeader}>
        {reAssign ? "Re-assign agent" : "Assign to agent"}
      </div>
      <span className="subscript">Assign to</span>
      <div style={{ height: "150px" }}>
        <div className={styles.searchAddContainer}>
          <Dropdown
            initialValue={newAdmin}
            items={allAdmins}
            onChange={triggerAdmin}
            width="200px"
            height="30px"
          />
        </div>
      </div>

      <div className={styles.ticketModalButtons} style={{ marginTop: "18px" }}>
        <div>
          <button
            className={styles.ticketModalCancelButton}
            onClick={() => {
              close();
            }}
          >
            Cancel
          </button>
        </div>
        <div>
          <button
            className={styles.ticketModalApplyButton}
            style={{ color: applyable ? "#128dff" : "#87caef" }}
            onClick={() => {
              applyable && handleAssignAgent();
            }}
          >
            Apply
          </button>
        </div>
      </div>
    </div>
  );
};

export default AssignAgent;
