import React, { Fragment, useState } from "react";
import Modal from "../../artzu-ui/src/Modal";
import ErrorModal from "../Payments/Invoices/ActionPopup/ErrorModal";
import styles from "./table.module.scss";
import { SELECT_ALL_AUTHENTICATION } from "./misc/gqlQueries";
import { useMutation } from "@apollo/client";

const PasswordModal = ({ open, togglePassword, selectAllRows }) => {
  const [password, setPassword] = useState();
  const [isError, setIsError] = useState();
  const [errorMsg, setErrorMsg] = useState();

  const handlePassword = (e) => {
    setPassword(e.target.value);
  };

  const toggleErrorModal = () => {
    setIsError(!isError);
  };

  const clearInput = () => {
    setPassword();
  };

  const [selectAllMutation] = useMutation(SELECT_ALL_AUTHENTICATION);
  const handleApply = () => {
    selectAllMutation({
      variables: {
        password: password,
      },
    }).then(({ data: { selectAllAuthentication } }) => {
      if (selectAllAuthentication.success) {
        clearInput();
        selectAllRows();
        console.log("Apply Password succeed!");
      } else {
        toggleErrorModal();
        setErrorMsg(selectAllAuthentication.error);
        console.log("Apply Password error!");
      }
    });
  };

  const ableToApply = password;
  return (
    <Fragment>
      <Modal open={open} backgroundColor="none">
        <div className={styles.passwordCheck}>
          <div className={styles.passwordTitle}>
            Please enter the password to select all
          </div>
          <div>
            Password: <input type="password" onChange={handlePassword}></input>
          </div>
          <div className={styles.actionButtons}>
            <div>
              <button
                className={styles.cancelButton}
                onClick={() => {
                  togglePassword();
                }}
              >
                Cancel
              </button>
            </div>
            <div>
              <button
                className={
                  ableToApply ? styles.saveButton : styles.notSaveButton
                }
                onClick={() => {
                  ableToApply && togglePassword();
                  ableToApply && handleApply();
                }}
              >
                Apply
              </button>
            </div>
          </div>
        </div>
      </Modal>
      <ErrorModal
        open={isError}
        errorMsg={errorMsg}
        toggleErrorModal={toggleErrorModal}
      />
    </Fragment>
  );
};

export default PasswordModal;
