import React from "react";
import styles from "../bookings.module.scss";
import classNames from "classnames";

const BookingActionPopup = ({ open, setBookingAction }) => (
  <div className={classNames(open || styles.hideComponent)}>
    <div className={styles.popupContainer}>
      <div onClick={() => setBookingAction("edit")}>Edit scheduled time</div>
      <div onClick={() => setBookingAction("swap")}>Change vehicle</div>
      <div
        className={styles.endBooking}
        onClick={() => setBookingAction("end")}
      >
        End booking
      </div>
    </div>
  </div>
);

export default BookingActionPopup;
