import React from "react";
import Input from '@material-ui/core/Input';
import { withStyles } from "@material-ui/core/styles";
import { capitalize } from "../../utils"

const TextInput = ({ name, value, type, handleChange, classes }) => (
  <Input
    placeholder={capitalize(name)}
    className={classes.text}
    classes={{underline: classes.underline}}
    name={name}
    onChange={handleChange}
    value={value}
    type={type}
  />
);

const styles = theme => ({
  text: {
    color: "#9e9e9e",
    width: "100%",
    marginBottom: "1em"
  },
  underline: {
    borderBottom: `1px solid white`,
    '&:after': {
      borderBottom: `1px solid white`
    }
  }
});

export default withStyles(styles)(TextInput);
