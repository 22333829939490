import { useMutation, useQuery } from "@apollo/client";
import React, { useState } from "react";
import styles from "../batchActions.module.scss";
import DatePicker from "react-datepicker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-light-svg-icons";
import { faCalendar, faPlus } from "@fortawesome/pro-regular-svg-icons";
import { GET_ADMIN_NAME } from "../../../Communication/Ticket/TicketPageContent/queries";
import { BULK_ADMIN_REMINDER } from "../queries";
import Dropdown from "../../ActionBar/Filters/OptionsModal/Body/Filter/Dropdown";
import "react-datepicker/dist/react-datepicker.css";

const AddReminder = ({ selections, close, clearAllSelections, adminData }) => {
  const [reminderDate, setReminderDate] = useState();
  const [newAdmin, setNewAdmin] = useState();
  const [agents, setAgents] = useState([]);

  const allAdmins = adminData
    ? adminData.allAdminName.map((admin) => {
        return {
          name: admin.firstName + " " + admin.lastName,
          value: admin.firstName + " " + admin.lastName,
        };
      })
    : [];

  const triggerAdmin = (childData) => {
    setNewAdmin(childData);
  };

  const handleAddAgent = (newAgent) => {
    setAgents((agents) => [...agents, newAgent]);
    setNewAdmin(null);
  };

  const removeAgent = (agent) => {
    let curAgents = [...agents];
    curAgents.includes(agent) &&
      (curAgents = curAgents.filter((admin) => admin !== agent));
    setAgents(curAgents);
  };

  const [addReminderMutation] = useMutation(BULK_ADMIN_REMINDER);
  const handleAddReminder = () => {
    addReminderMutation({
      variables: {
        ticketIds: selections,
        adminIds: agents.map(
          (agent) =>
            adminData.allAdminName.find(
              (item) => item.firstName + " " + item.lastName === agent
            ).id
        ),
        reminderDate: reminderDate,
      },
    }).then(({ data: { bulkAddReminder } }) => {
      if (bulkAddReminder.success) {
        clearAllSelections();
        close();
        console.log("Add Reminder Succeed!");
      } else {
        console.log("Add Reminder error!");
      }
    });
  };

  const addable = newAdmin !== undefined && newAdmin !== null;
  const applyable = agents.length !== 0 && reminderDate;

  return (
    <div className={styles.ticketActionContainer} style={{ height: "260px" }}>
      <div className={styles.actionHeader}>Add reminder</div>
      <div className={styles.timeUserContainer} style={{ height: "130px" }}>
        <div className={styles.timeContainer}>
          <div className="subscript">Date and time</div>
          <div>
            <DatePicker
              selected={reminderDate}
              onChange={(date) => {
                setReminderDate(date);
              }}
              dateFormat="MMM d, yyyy h:mm a"
              todayButton={"Today"}
              placeholderText="Select date"
              popperPlacement="bottom"
              className={styles.reminderDateInput}
              showTimeSelect
            />
            <FontAwesomeIcon
              icon={faCalendar}
              className={styles.calendarIcon}
              size="lg"
            />
          </div>
        </div>
        <div className={styles.timeContainer} style={{ width: "440px" }}>
          <div className="subscript">Add user</div>
          <div className={styles.searchAddContainer}>
            <Dropdown
              initialValue={newAdmin}
              items={allAdmins}
              onChange={triggerAdmin}
              width="200px"
              height="30px"
            />
            <div className={styles.addAgentButton}>
              <button
                className={
                  addable
                    ? "add-button-container"
                    : "invalid-add-button-container"
                }
              >
                <div
                  className="add-tag-button"
                  onClick={() => {
                    addable && handleAddAgent(newAdmin);
                  }}
                >
                  <FontAwesomeIcon icon={faPlus} className="plus-icon" />
                  <div style={{ fontWeight: "600" }}>Add</div>
                </div>
              </button>
            </div>
          </div>
          <div className={styles.ticketTagsContainer}>
            {agents.map((admin) => (
              <div className={styles.ticketTagBox}>
                {admin}
                <FontAwesomeIcon
                  icon={faTimes}
                  size="lg"
                  className="tag-times"
                  onClick={() => {
                    removeAgent(admin);
                  }}
                />
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className={styles.ticketModalButtons} style={{ marginTop: "11px" }}>
        <div>
          <button
            className={styles.ticketModalCancelButton}
            onClick={() => {
              close();
            }}
          >
            Cancel
          </button>
        </div>
        <div>
          <button
            className={styles.ticketModalApplyButton}
            style={{ color: applyable ? "#128dff" : "#87caef" }}
            onClick={() => {
              applyable && handleAddReminder();
            }}
          >
            Apply
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddReminder;
