import { useQuery } from "@apollo/client";
import React from "react";
import { useEffect } from "react";
import { Fragment } from "react";
import { useState } from "react";
import AssignAdminModal from "./AssignAdminModal";
import CancelConfirmModal from "./CancelConfirmModal";
import ChatBox from "./ChatBox";
import { GET_ADMIN_USER_INFO } from "../queries";
import StatusAction from "./StatusAction";
import {
  accidentSub,
  accountSub,
  appSub,
  bookingsSub,
  categories,
  paymentsSub,
  receiptSub,
  vehicleSub,
} from "./options";

const TicketLeft = ({
  params,
  ticketData,
  refreshTicketCallback,
  userId,
  bookingId,
  defaultAdmin,
  isEdit,
  toggleEdit,
  cancelEditCallback,
  token,
}) => {
  const [curCategory, setCurCategory] = useState();
  const [curSubject, setCurSubject] = useState();
  const [curOptions, setOptions] = useState([]);
  const [adminId, setAdminId] = useState();
  const [isAdmin, setIsAdmin] = useState();
  const [isCancel, setIsCancel] = useState();

  const changeCategory = (e) => {
    setCurCategory(e);
    if (e === "Account") {
      setOptions(accountSub);
    } else if (e === "Accident Report") {
      setOptions(accidentSub);
    } else if (e === "Vehicle Issue") {
      setOptions(vehicleSub);
    }
  };

  const changeSubject = (e) => {
    setCurSubject(e);
  };

  const toggleAdmin = () => {
    setIsAdmin(!isAdmin);
  };

  const adminIdCallback = (childData) => {
    setAdminId(childData);
  };

  const cancelEdit = () => {
    setIsCancel(!isCancel);
  };

  const { data, loading } = useQuery(GET_ADMIN_USER_INFO, {
    variables: { id: adminId ? adminId : null, name: "n/a" },
  });

  const chosenAdminData = data && data.adminUserData;
  const chosenAgentName = chosenAdminData && chosenAdminData.fullName;
  const ableToCreate = curCategory && curSubject && userId;

  const assignedAdminId = adminId
    ? adminId
    : ticketData && ticketData.adminUser
    ? ticketData.adminUser.id
    : defaultAdmin.id;

  useEffect(() => {
    setCurCategory(null);
  }, [isEdit]);

  return (
    <Fragment>
      <div className="left-container">
        <StatusAction
          ticketData={ticketData}
          isEdit={isEdit}
          curCategory={curCategory}
          categories={categories}
          changeCategory={changeCategory}
          curSubject={curSubject}
          setCurSubject={setCurSubject}
          changeSubject={changeSubject}
          curOptions={curOptions}
          chosenAgentName={chosenAgentName}
          defaultAdmin={defaultAdmin}
          toggleAdmin={toggleAdmin}
          refreshTicketCallback={refreshTicketCallback}
          ableToCreate={ableToCreate}
          userId={userId}
          bookingId={bookingId}
          assignedAdminId={assignedAdminId}
          adminIdCallback={adminIdCallback}
          toggleEdit={toggleEdit}
          cancelEdit={cancelEdit}
        />
        <ChatBox
          params={params}
          ticketData={ticketData}
          refreshTicketCallback={refreshTicketCallback}
          defaultAdmin={defaultAdmin}
          token={token}
        />
      </div>
      <AssignAdminModal
        open={isAdmin}
        toggleAdmin={toggleAdmin}
        adminIdCallback={adminIdCallback}
      />
      <CancelConfirmModal
        open={isCancel}
        toggleCancel={cancelEdit}
        setCurCategory={setCurCategory}
        setCurSubject={setCurSubject}
        setAdminId={setAdminId}
        ticketData={ticketData}
        cancelEditCallback={cancelEditCallback}
        toggleEdit={toggleEdit}
      />
    </Fragment>
  );
};

export default TicketLeft;
