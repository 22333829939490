import React from "react";
import styles from "./payment.module.scss";
import moment from "moment-timezone";
import { useQuery } from "@apollo/client";
import { USER_PAYMENT } from "./paymentQueries";
import ResponsiveLoading from "../../Shared/ResponsiveLoading";

const PaymentHeader = ({ userId }) => {
  const { loading, error, data } = useQuery(USER_PAYMENT, {
    variables: { id: userId },
    fetchPolicy: "network-only",
  });
  const userProfileData = data?.userProfileData;

  const timeZone = userProfileData?.timeZone;

  if (loading) {
    return (
      <div className={styles.paymentHeader}>
        <ResponsiveLoading />
      </div>
    );
  }

  if (userProfileData) {
    const curBalance = userProfileData
      ? userProfileData.outstandingBalance
      : "-";
    const paymentMethod = userProfileData?.paymentMethods;

    const defaultCard =
      paymentMethod?.length !== 0
        ? `${paymentMethod[0].card.brand} **** 
    ${paymentMethod[0].card.last4}`
        : "-";

    const cardStatus =
      paymentMethod?.length !== 0
        ? paymentMethod[0].status === "chargeable"
          ? "Active"
          : "Expired"
        : "-";

    const lastUpdated = userProfileData?.outstandingBalanceUpdatedAt;

    const getStatusColor = (status) => {
      switch (status) {
        case "Active":
          return "#3dc700";
        case "Expired":
          return "#ff0000";
        default:
          break;
      }
    };
    return (
      <div className={styles.paymentHeader}>
        <div className={styles.headerHalfLeft}>
          <div className={styles.title}>Current balance</div>
          <div
            className={styles.bold}
            style={{ color: curBalance < 0 ? "#3dc700" : "#414141" }}
          >
            $
            {curBalance < 0
              ? Math.abs(curBalance).toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }) + " Credit"
              : curBalance.toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
          </div>
          <div className={styles.subtext}>
            Last updated:{" "}
            {lastUpdated
              ? moment(lastUpdated)
                  .tz(timeZone)
                  .format("h:mm A ddd MMM D, YYYY z")
              : "-"}
          </div>
        </div>
        <div className={styles.headerHalfRight}>
          <div className={styles.title}>Payment method</div>
          <div className={styles.bold}>{defaultCard}</div>
          <div className={styles.subtext} style={{ color: "#414141" }}>
            status:{" "}
            <span style={{ color: getStatusColor(cardStatus) }}>
              {cardStatus}
            </span>
          </div>
        </div>
      </div>
    );
  }
};

export default PaymentHeader;
