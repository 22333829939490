import React from "react";
import styles from "./inspection.module.scss";

const ExteriorImages = ({ imageList, toggleImg }) => {
  return (
    <div className={styles.inspectionField}>
      <div className={styles.subscript}>Images</div>{" "}
      <div className={styles.imgContainer}>
        {imageList.length === 6 &&
          imageList.map((img) => (
            <div key={img}>
              <img
                className={styles.exteriorImg}
                style={{ marginRight: "16px" }}
                src={img}
                onClick={() => toggleImg(img)}
              ></img>
            </div>
          ))}
      </div>
    </div>
  );
};

export default ExteriorImages;
