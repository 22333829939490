import React from "react";
import { useState } from "react";
import fileUpload from "../../../../../images/icons/file.svg";
import templateUpload from "../../../../../images/icons/template.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperclip } from "@fortawesome/pro-regular-svg-icons";
import { useEffect } from "react";
import { Fragment } from "react";
import TemplateModal from "./Templates/TemplateModal";
import { useQuery } from "@apollo/client";
import { GET_ALL_TEMPLATES } from "../queries";

const MessageContent = ({
  handleSend,
  tab,
  ticketData,
  refreshTicketCallback,
  curSubs,
  assignTicket,
  userData,
}) => {
  const [data, setData] = useState("");
  const [imgSrc, setImgSrc] = useState();
  const [imgName, setImgName] = useState();
  const [notTyping, setNotTyping] = useState(false);
  const [isTemplate, setIsTemplate] = useState(false);

  const handleChange = (e) => {
    setData(e.target.value);
  };
  const clearInput = (e) => {
    // e.preventDefault();
    setData("");
    setImgSrc(null);
    setImgName(null);
  };

  const onKeyPress = (evt) => {
    if (evt.key === "Enter" && !evt.shiftKey) {
      evt.preventDefault();
      ableToSend && clearInput();
      ableToSend && handleSend(data);
      ticketData && ticketData.status === "unassigned" && assignTicket();
      refreshTicketCallback({ ticketId: ticketData.id });
    }
  };

  const encodeImage = () => {
    const file = document.querySelector("input[type=file]").files[0];
    const reader = new FileReader();
    setImgName(file && file.name);

    reader.onloadend = () => {
      // Use a regex to remove data url part

      setImgSrc([reader.result]);
      const base64String = reader.result
        .replace("data:", "")
        .replace(/^.+,/, "");

      setData(base64String);
    };
    file && reader.readAsDataURL(file);
  };

  const toggleTemplate = () => {
    setIsTemplate(!isTemplate);
  };

  const ableToSend = data !== "";

  const { data: templateData, loading, refetch } = useQuery(GET_ALL_TEMPLATES);
  // useEffect(() => {
  //   notTyping && setNotTyping(false);
  //   curSubs &&
  //     tab === "public" &&
  //     data !== "" &&
  //     data.length < 1000 &&
  //     curSubs.send({
  //       identifier: curSubs.identifier,
  //       message: "typing",
  //       messageType: "typing indicator",
  //       internal: false,
  //     });
  //   const timeout = setTimeout(() => {
  //     data.length < 1000 && setNotTyping(true);
  //   }, 5000);

  //   return () => {
  //     clearTimeout(timeout);
  //   };
  // }, [data]);

  // useEffect(() => {
  //   curSubs &&
  //     tab === "public" &&
  //     curSubs.send({
  //       identifier: curSubs.identifier,
  //       message: "not typing",
  //       messageType: "typing indicator",
  //       internal: false,
  //     });
  // }, [notTyping]);

  const isDisabled = !ticketData
    ? true
    : tab === "internal"
    ? false
    : ticketData.status === "deleted" ||
      ticketData.status === "resolved" ||
      !(userData.deviceOs && userData.appVersion)
    ? true
    : imgSrc
    ? true
    : false;

  return (
    <Fragment>
      <div className="content-container">
        <textarea
          className="input-box"
          style={{ backgroundColor: tab === "internal" ? "#fceea7" : null }}
          onChange={handleChange}
          placeholder={"Text message here..."}
          disabled={isDisabled}
          value={data.length > 1300 ? (data.includes(" ") ? data : null) : data}
          onKeyDown={onKeyPress}
        />
        <div className="buttons-row">
          <div className="image-upload">
            <label for="file-input">
              {/* <FontAwesomeIcon icon={faPaperclip} style={{ cursor: "pointer" }} /> */}
              <img src={fileUpload} style={{ cursor: "pointer" }}></img>
            </label>
            <input
              id="file-input"
              type="file"
              onChange={encodeImage}
              onClick={(e) => (e.target.value = null)}
              disabled={isDisabled}
            />
          </div>
          <div>
            <img
              src={templateUpload}
              style={{ cursor: "pointer", marginLeft: "12px" }}
              onClick={() => {
                !isDisabled && toggleTemplate();
              }}
            ></img>
          </div>

          <button
            className={
              ableToSend ? "send-message-button" : "send-message-invalid"
            }
            onClick={() => {
              ableToSend && clearInput();
              ableToSend && handleSend(data);
              ticketData &&
                ticketData.status === "unassigned" &&
                assignTicket();
              refreshTicketCallback({ ticketId: ticketData.id });
            }}
          >
            Send
          </button>
        </div>
        <div style={{ marginTop: "-5px" }}>{imgName}</div>
      </div>
      <TemplateModal
        open={isTemplate}
        toggleTemplate={toggleTemplate}
        setData={setData}
        allTemplates={templateData && templateData.getAllTemplates}
        refetch={refetch}
      />
    </Fragment>
  );
};

export default MessageContent;
