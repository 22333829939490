import React, { Component } from "react";
import gql from "graphql-tag";
import { Mutation } from "@apollo/client/react/components";
import { ApolloConsumer } from "@apollo/client";
import Button from "../artzu-ui/src/Button";

class UpsertComment extends Component {
  UPSERT_COMMENT = gql`
    mutation UpsertComment($comment: CommentAttributes!) {
      upsertComment(comment: $comment) {
        comment {
          id
          body
          resourceType
          resourceId
          authorType
          authorId
          pinned
          namespace
        }
        error
      }
    }
  `;

  render() {
    const { comment, callback, loading } = this.props;
    // const organization = "Autzu"
    return (
      <ApolloConsumer>
        {(client) => (
          <Mutation
            mutation={this.UPSERT_COMMENT}
            variables={{
              comment,
            }}
            onCompleted={(data) => {
              if (!data.upsertComment.error) {
                callback();
              }
            }}
          >
            {(upsertComment) => (
              <Button
                style={{
                  fontSize: "17px",
                  padding: "0 1.2em",
                  width: "max-content",
                  height: "34px",
                  border: "1px solid #128dff",
                  fontWeight: "400",
                }}
                disabled={comment.body.length === 0}
                onClick={() => {
                  upsertComment();
                  loading();
                }}
                label="Add comment"
              />
            )}
          </Mutation>
        )}
      </ApolloConsumer>
    );
  }
}

export default UpsertComment;
