import styles from "../bookingActions.module.scss";
import React from "react";

const CommentContainer = ({ changeComment, title = "Comment" }) => (
  <div className={styles.commentContainer}>
    <div className="subscript" style={{ marginBottom: "-5px" }}>
      {title}
    </div>
    <textarea
      className={styles.commentBox}
      onChange={(event) => changeComment(event.target.value)}
      placeholder={"Enter comment here..."}
    />
  </div>
);

export default CommentContainer;
