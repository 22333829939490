import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "./batchActions.module.scss";
const ActionButton = ({ icon, text, onClick }) => (
  <div onClick={onClick} className={styles.actionButton}>
    <FontAwesomeIcon icon={icon} className={styles.actionButtonIcon} />
    <div className={styles.actionButtonText}>{text}</div>
  </div>
);

export default ActionButton;
