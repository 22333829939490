import React from "react";
import classNames from "classnames";
import styles from "./baseValue.module.scss";
import { renderColor } from "../../../../utils";
import _, { capitalize } from "lodash";
import { te } from "date-fns/locale";

const UnreadValue = ({ cellData, classes, columnName }) => {
  const { text } = cellData;

  const textConverter = (text) => {
    if (text === "true") {
      return "Yes";
    } else if (text === "false") {
      return "No";
    }
  };

  const textColor = (text) => {
    if (text === "true") {
      return "#128dff";
    } else if (text === "false") {
      return "rgba(65, 65, 65, 0.5)";
    }
  };

  const isBold = (text) => {
    if (text === "true") {
      return "700";
    } else if (text === "false") {
      return "400";
    }
  };
  return (
    <div className={classNames(classes)}>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div
          className={styles.textValue}
          style={{
            color: textColor(text),
            fontWeight: isBold(text),
          }}
        >
          {textConverter(text)}
        </div>
      </div>
    </div>
  );
};

export default UnreadValue;
