import React, { Component } from "react";
import { Link } from "react-router-dom";
import autzu_logo from "../../images/autzu.png";
import xcarshare_logo from "../../images/xcarshare.png";
import "./autzuLogoLayout.css";

class AutzuLogoLayout extends Component {
  render() {
    const { children } = this.props;

    const logo = process.env.REACT_APP_COMPANY_CONFIG === "autzu" ? autzu_logo : xcarshare_logo;

    return (
      <section className="layout-section">
        <div
          className="container layout-background"
          style={{ backgroundColor: "#000000", left: "0px", width: "100%", height: "100vh" }}
        >
          <Link to="/" style={{ textDecoration: "none" }}>
            <img className="white-logo" src={logo} alt="Autzu Logo" />
          </Link>
          <div className="layout-form">{children}</div>
        </div>
      </section>
    );
  }
}

export default AutzuLogoLayout;
