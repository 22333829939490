import React, { Component, useState, useEffect } from "react";
import UserRideshareData from "../../../Queries/UserRideshareData";
import "./rideshare.css";
import { useLocation } from 'react-router-dom';

const Rideshare = ({ id, refetch }) => {
  const [activeTab, setActiveTab] = useState("uber");
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0,0);
  }, [location]);


  const pageContent = () => {
    switch (activeTab) {
      case "uber":
        return <UserRideshareData id={id} name={"uber"} />;
    }
  };
  return (
    <div className="user-details-container">
      <div className="tab-container">
        <div
          className={`unselectable detail-tab${
            activeTab === "uber" ? " selected" : ""
          }`}
          onClick={() => setActiveTab("uber")}
        >
          Uber
        </div>
      </div>
      {pageContent()}
    </div>
  );
};

export default Rideshare;
