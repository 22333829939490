import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  formControl: {
    width: "100%",
    borderRadius: "16px",
    backgroundColor: "#fff",
    height: "32px",
    padding: "0",
    marginBottom: "5px",
    "&:focus": {
      borderColor: "#128dff",
      color: "#128dff"
    }
  },
  select: {
    borderRadius: "16px",
    height: "32px",
    fontSize: "16px",
    backgroundColor: "#fff",
    padding: "0",
    "&:focus": {
      borderColor: "#128dff",
      color: "#128dff"
    },
    ".MuiSelect-outlined": {
      padding: "0"
    }
  },
  menuItem: {
    borderRadius: "16px",
    height: "32px",
    fontSize: "16px",
    backgroundColor: "#fff",
    padding: "0",
    "&:focus": {
      borderColor: "#128dff",
      color: "#128dff"
    }
  }
})

export {
  useStyles
};
