const createTimeRangeItems = (spread, currentTime, frequency) => {
  const possibleShifts = [];
  const startOfRange = currentTime.clone().subtract(spread, "hours");
  const endOfRange = currentTime.clone().add(spread, "hours");

  let timeToAdd = startOfRange;

  while (timeToAdd.isSameOrBefore(endOfRange)) {
    let itemToAdd = {
      value: timeToAdd.clone(),
      name: `${timeToAdd.format("h A")} ${
        timeToAdd.isSame(currentTime) ? "(Current)" : ""
      }`,
    };
    possibleShifts.push(itemToAdd);
    timeToAdd.add(frequency, "hours");
  }

  return possibleShifts;
};

export { createTimeRangeItems };
