import React, { useState, useEffect } from "react";
import Button from "../../../artzu-ui/src/Button";
import { faTimes } from "@fortawesome/pro-regular-svg-icons";
import CustomDialog from "../../Shared/CustomDialog";
import Location from "./Location";
import Metric from "./Metric";
import VehicleClass from "./VehicleClass";
import AccountStatus from "./AccountStatus";
import InvoiceStatus from "./InvoiceStatus";
import ChartType from "./ChartType";
import Axis from "./Axis";
import "../index.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TicketStatus from "./TicketStatus";
import OutstandingRequirement from "./OutstandingRequirement";
import SuspensionReason from "./SuspensionReason";
import {
  accountStatusRequired,
  invoiceStatusRequired,
  plotFormats,
  requirementTypeRequired,
  suspensionReasonRequired,
  ticketStatusRequired,
  vehicleClassRequired,
} from "../utils";
import _ from "lodash";
import Unit from "./Unit";

const DatasetOptions = ({
  dataset,
  datasetIndex,
  datasetLabel,
  datasets,
  addDataset,
  updateDataset,
  setSelectedDataset,
  setDatasetOptions,
  setDatasetIndex,
  locations,
  accountStatuses,
  invoiceStatuses,
  ticketStatuses,
  outstandingRequirements,
  suspensionReasons,
  metrics,
  yAxis1,
  yAxis2,
}) => {
  if (dataset) {
    datasets = datasets.filter((d) => d !== dataset);
    if (dataset.axis === "yAxis1") {
      yAxis1 = null;
    } else if (dataset.axis === "yAxis2") {
      yAxis2 = null;
    }
  }

  const plottedFormats = datasets.map((d) => d.plotFormat);

  const invalidPlotFormat = (formatOption) => {
    return (
      (metric &&
        ((plottedFormats.includes("stacked_bar") &&
          formatOption === "stacked_bar") ||
          (plottedFormats.includes("bar") && formatOption === "bar")) &&
        metrics.find((m) => m.name === metric).unit !==
          datasets.find((d) => d.plotFormat === formatOption).unit) ||
      (plottedFormats.includes("bar") && formatOption === "stacked_bar") ||
      (plottedFormats.includes("stacked_bar") && formatOption === "bar")
    );
  };

  const invalidAxis = (axisOption) => {
    if (axisOption === 1) {
      return (
        (yAxis1 &&
          metric &&
          yAxis1 !== metrics.find((m) => m.name === metric).unit) ||
        (yAxis2 &&
          metric &&
          yAxis2 === metrics.find((m) => m.name === metric).unit) ||
        (plotFormat &&
          ["stacked_bar", "bar"].includes(plotFormat) &&
          (plottedFormats.includes("stacked_bar") ||
            plottedFormats.includes("bar")) &&
          datasets
            .filter((d) => ["bar", "stacked_bar"].includes(d.plotFormat))
            .map((d) => d.axis)[0] !== "yAxis1")
      );
    } else if (axisOption === 2) {
      return (
        (yAxis2 &&
          metric &&
          yAxis2 !== metrics.find((m) => m.name === metric).unit) ||
        (yAxis1 &&
          metric &&
          yAxis1 === metrics.find((m) => m.name === metric).unit) ||
        (plotFormat &&
          ["stacked_bar", "bar"].includes(plotFormat) &&
          (plottedFormats.includes("stacked_bar") ||
            plottedFormats.includes("bar")) &&
          datasets
            .filter((d) => ["bar", "stacked_bar"].includes(d.plotFormat))
            .map((d) => d.axis)[0] !== "yAxis2")
      );
    }
  };

  const defaultAxis = () => {
    if (metric && yAxis1) {
      if (yAxis1 === metrics.find((m) => m.name === metric).unit) {
        return "yAxis1";
      } else if (!yAxis2) {
        return "yAxis2";
      }
    }
    if (metric && yAxis2) {
      if (yAxis2 === metrics.find((m) => m.name === metric).unit) {
        return "yAxis2";
      } else if (!yAxis1) {
        return "yAxis1";
      }
    }
    if (plotFormat && ["stacked_bar", "bar"].includes(plotFormat)) {
      if (
        plottedFormats.includes("bar") ||
        plottedFormats.includes("stacked_bar")
      ) {
        return datasets
          .filter((d) => ["bar", "stacked_bar"].includes(d.plotFormat))
          .map((d) => d.axis)[0];
      }
    }
    if (dataset) {
      return dataset.axis;
    }
    return "";
  };

  const isSubmitDisabled = () => {
    return !(
      parkingLotIds.length > 0 &&
      unit &&
      metric &&
      plotFormat &&
      axis &&
      (!vehicleClassRequired.includes(metric) || vehicleClass.length > 0) &&
      (!accountStatusRequired.includes(metric) || accountStatus.length > 0) &&
      (!invoiceStatusRequired.includes(metric) || invoiceStatus.length > 0) &&
      (!ticketStatusRequired.includes(metric) || ticketStatus?.length > 0) &&
      (!requirementTypeRequired.includes(metric) || requirement?.length > 0) &&
      (!suspensionReasonRequired.includes(metric) ||
        suspensionReason?.length > 0)
    );
  };

  const [parkingLotIds, setParkingLotIds] = useState(
    dataset ? dataset.parkingLotIds : []
  );
  const [allMetrics, setAllMetrics] = useState(metrics);
  const [metric, setMetric] = useState(dataset ? dataset.metric : "");
  const [unit, setUnit] = useState(dataset ? dataset.unit : "");

  const allUnits = _.uniqBy(metrics, "unit").map((m) => m.unit);

  const availableTiers = locations
    .map((l) => l.hubs)
    .flat()
    .filter((h) => parkingLotIds.includes(parseInt(h.id)))
    .map((h) => h.tierData)
    .flat()
    .filter((v, i, a) => a.indexOf(v) === i);
  const [vehicleClass, setVehicleClass] = useState(
    dataset ? dataset.vehicleClass : availableTiers
  );
  const [accountStatus, setAccountStatus] = useState(
    dataset ? dataset.accountStatus : []
  );
  const [invoiceStatus, setInvoiceStatus] = useState(
    dataset ? dataset.invoiceStatus : []
  );
  const [ticketStatus, setTicketStatus] = useState(
    dataset ? dataset.ticketStatus : []
  );
  const [requirement, setRequirement] = useState(
    dataset ? dataset.outstandingRequirement : []
  );
  const [suspensionReason, setSuspensionReason] = useState(
    dataset ? dataset.suspensionReason : []
  );

  const [plotFormat, setPlotFormat] = useState(
    dataset ? dataset.plotFormat : "line"
  );
  const [axis, setAxis] = useState(defaultAxis());

  useEffect(() => {
    if (
      (axis && axis === "yAxis1" && invalidAxis(1)) ||
      (axis === "yAxis2" && invalidAxis(2))
    ) {
      setAxis("");
    }
    if (plotFormat && invalidPlotFormat(plotFormat)) {
      setPlotFormat("");
    }
    if (vehicleClass.length === 0) {
      setVehicleClass(availableTiers);
    }
    setAxis(defaultAxis());
  }, [metric, plotFormat, parkingLotIds]);

  useEffect(() => {
    if (unit !== dataset?.unit) {
      setMetric("");
    }

    if (unit && unit === "users") {
      setAllMetrics(metrics.filter((m) => m.unit === "users"));
    } else if (unit && unit === "dollars") {
      setAllMetrics(metrics.filter((m) => m.unit === "dollars"));
    } else if (unit && unit === "percent") {
      setAllMetrics(metrics.filter((m) => m.unit === "percent"));
    } else if (unit && unit === "hours") {
      setAllMetrics(metrics.filter((m) => m.unit === "hours"));
    } else if (unit && unit === "bookings") {
      setAllMetrics(metrics.filter((m) => m.unit === "bookings"));
    } else if (unit && unit === "invoices") {
      setAllMetrics(metrics.filter((m) => m.unit === "invoices"));
    } else if (unit && unit === "tickets") {
      setAllMetrics(metrics.filter((m) => m.unit === "tickets"));
    } else if (unit && unit === "invoices") {
      setAllMetrics(metrics.filter((m) => m.unit === "vehicles"));
    } else if (unit && unit === "rating") {
      setAllMetrics(metrics.filter((m) => m.unit === "rating"));
    }
  }, [unit]);

  const body = () => {
    return (
      <div className="dataset-options">
        <div
          className="dataset-options__close"
          onClick={() => {
            setDatasetOptions(false);
            setSelectedDataset(null);
            setDatasetIndex(null);
          }}
        >
          <FontAwesomeIcon icon={faTimes} size="md" />
        </div>
        <label>Dataset {datasetLabel}</label>
        <Location
          parkingLotIds={parkingLotIds}
          locations={locations}
          setParkingLotIds={setParkingLotIds}
        />
        <Unit units={allUnits} unit={unit} setUnit={setUnit} />
        <Metric metrics={allMetrics} metric={metric} setMetric={setMetric} />
        {vehicleClassRequired.includes(metric) ? (
          <VehicleClass
            parkingLotIds={parkingLotIds}
            locations={locations}
            vehicleClass={vehicleClass}
            setVehicleClass={setVehicleClass}
          />
        ) : null}
        {accountStatusRequired.includes(metric) ? (
          <AccountStatus
            parkingLotIds={parkingLotIds}
            accountStatuses={accountStatuses}
            accountStatus={accountStatus}
            setAccountStatus={setAccountStatus}
          />
        ) : null}
        {invoiceStatusRequired.includes(metric) ? (
          <InvoiceStatus
            parkingLotIds={parkingLotIds}
            invoiceStatuses={invoiceStatuses}
            invoiceStatus={invoiceStatus}
            setInvoiceStatus={setInvoiceStatus}
          />
        ) : null}
        {ticketStatusRequired.includes(metric) ? (
          <TicketStatus
            parkingLotIds={parkingLotIds}
            ticketStatuses={ticketStatuses}
            ticketStatus={ticketStatus}
            setTicketStatus={setTicketStatus}
          />
        ) : null}
        {requirementTypeRequired.includes(metric) ? (
          <OutstandingRequirement
            parkingLotIds={parkingLotIds}
            outstandingRequirements={outstandingRequirements}
            requirement={requirement}
            setRequirement={setRequirement}
          />
        ) : null}
        {suspensionReasonRequired.includes(metric) ? (
          <SuspensionReason
            parkingLotIds={parkingLotIds}
            suspensionReasons={suspensionReasons}
            suspensionReason={suspensionReason}
            setSuspensionReason={setSuspensionReason}
          />
        ) : null}
        <ChartType
          plotFormats={plotFormats}
          invalidPlotFormat={invalidPlotFormat}
          plotFormat={plotFormat}
          setPlotFormat={setPlotFormat}
        />
        <Axis invalidAxis={invalidAxis} axis={axis} setAxis={setAxis} />
        <div
          className="dataset-options__field"
          style={{ marginBottom: "0", border: "none" }}
        >
          <Button
            disabled={isSubmitDisabled()}
            style={{ marginLeft: "auto", border: "none", height: "34px" }}
            label="Apply"
            primary
            padding="0"
            onClick={() => {
              if (dataset) {
                updateDataset(datasetIndex, {
                  parkingLotIds,
                  metric,
                  plotFormat,
                  axis,
                  vehicleClass,
                  accountStatus,
                  invoiceStatus,
                  ticketStatus,
                  outstandingRequirement: requirement,
                  suspensionReason,
                  colorway: dataset.colorway,
                });
                setSelectedDataset(null);
                setDatasetIndex(null);
              } else {
                addDataset({
                  parkingLotIds,
                  metric,
                  plotFormat,
                  axis,
                  vehicleClass,
                  accountStatus,
                  invoiceStatus,
                  ticketStatus,
                  outstandingRequirement: requirement,
                  suspensionReason,
                });
              }
              setDatasetOptions(false);
            }}
          />
        </div>
      </div>
    );
  };
  return <CustomDialog open={true} body={body()} />;
};

export default DatasetOptions;
