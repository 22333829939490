import React, { Component, useEffect } from "react";
import { Fragment } from "react";
import InformationTable from "../../InformationTable";
import { useLocation } from 'react-router-dom';

const Communication = ({ id, refetch }) => {
  console.log(id);
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0,0);
  }, [location]);
  
  return (
    <Fragment>
      <InformationTable
        rowComponentType="ticket"
        tableName="tickets"
        navbar={"Communications"}
        startTerms="Search By name, userID, phone and email"
        userSpecific={true}
        id={id}
      />
    </Fragment>
  );
};

export default Communication;
