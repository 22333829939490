const formatCityName = (city) => {
  switch (city) {
    case "New York":
      return "NYC";
    case "Toronto":
      return "TOR";
    case "San Francisco":
      return "SF";
    case "Ottawa":
      return "OTT";
    case "Multiple Cities":
      return "MULT";
    case "All Cities":
      return "ALL";
  }
};

export default formatCityName;
