import React, { useState, useEffect } from "react";
import Modal from "../../../../../artzu-ui/src/Modal";

const FullImageMessage = ({ isFull, imgUrl }) => {
  return (
    <Modal open={isFull} backgroundColor="none">
      <div className="full-image-container">
        <img src={imgUrl} className="full-display"></img>
      </div>
    </Modal>
  );
};

export default FullImageMessage;
