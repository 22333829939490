import React from "react";
import Button from "../../../../artzu-ui/src/Button";
import Modal from "../../../../artzu-ui/src/Modal";
import DeleteUser from "../../../../Mutations/DeleteUser";

const DeleteMessage = ({ open, toggleDelete, toggleEdit, userId, refetch }) => {
  return (
    <Modal open={open} backgroundColor="none">
      <div className="delete-confirmation">
        <div className="deleteMsg">
          Are you sure you want to delete this user?
        </div>
        <div className="actionButtons">
          <div>
            <button className="delete-no" onClick={toggleDelete}>
              No
            </button>
          </div>
          <div>
            <DeleteUser
              userId={userId}
              toggleDelete={toggleDelete}
              toggleEdit={toggleEdit}
              refetch={refetch}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default DeleteMessage;
