import React from "react";

import { USER_DOCUMENTS_DATA } from "../queries";
import { useQuery } from "@apollo/client";
import Loading from "../../../Shared/Loading";
import DocumentsBody from "./DocumentsBody";

const Documents = ({ id, refetchProfile }) => {
  const { loading, error, data, refetch } = useQuery(USER_DOCUMENTS_DATA, {
    variables: { id },
    fetchPolicy: "network-only",
  });

  if (loading) return <Loading />;
  if (error) return <p>Error</p>;

  if (data.userProfileData) {
    return (
      <DocumentsBody
        user={data.userProfileData}
        refetch={refetch}
        refetchProfile={refetchProfile}
        id={id}
      />
    );
  }
};

export default Documents;
