import React, { Component } from "react";
import { Query } from "@apollo/client/react/components";
import gql from "graphql-tag";
import Loading from "../Components/Shared/Loading";
import DocumentsCommentsBody from "../Components/User/Details/Documents/DocumentsCommentsBody";
import { userCommentsString } from "./queryStrings";

class UserDocumentsCommentsData extends Component {
  render() {
    const { id } = this.props;
    return (
      <Query
        query={gql`
          ${userCommentsString}
        `}
        variables={{ id }}
        pollInterval={600000}
      >
        {({ loading, error, data, refetch }) => {
          if (error) {
            return null;
          } else if (loading) {
            return (
              <div
                className="user-page__body"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <Loading />
              </div>
            );
          }

          if (data.userProfileData) {
            return (
              <DocumentsCommentsBody
                userId={id}
                userCommentsData={data.userProfileData}
                docComments={data.userProfileData.documentComments}
                refetch={refetch}
              />
            );
          } else {
            return null;
          }
        }}
      </Query>
    );
  }
}

export default UserDocumentsCommentsData;
