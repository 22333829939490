import React, { useState, Fragment } from "react";
import styles from "../../../Shared/ToggleButton/toggleButton.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/pro-regular-svg-icons";
import CreateBookingModal from "../../../User/Bookings/CreateBookingModal/index";

const CreateBooking = ({ userId, reloadPage }) => {
  const [isCreate, setIsCreate] = useState();

  const toggleCreate = () => {
    setIsCreate(!isCreate);
  };
  return (
    <Fragment>
      <div
        className={styles.toggleButtonContainer}
        style={{ marginRight: "12px" }}
        onClick={toggleCreate}
      >
        <FontAwesomeIcon icon={faPlus} />
      </div>
      <CreateBookingModal
        open={isCreate}
        toggleCreate={toggleCreate}
        reloadPage={reloadPage}
        userId={userId}
      />
    </Fragment>
  );
};

export default CreateBooking;
