import React from 'react'

const SvgChevronLeft = ({ color = '#373535', ...props }) => (
  <svg data-name="Layer 1" viewBox="0 0 37.73 68.57" {...props}>
    <path
      fill="none"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={7}
      d="M34.23 65.07L3.5 34.34v-.11L34.23 3.5"
    />
  </svg>
)

export default SvgChevronLeft
