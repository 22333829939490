import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhoneAlt } from "@fortawesome/pro-light-svg-icons";

const CallRecordMessage = ({
  record,
  admin,
  duration,
  ticketAssignedAdmin,
}) => {
  const callerName = admin?.fullName || ticketAssignedAdmin?.fullName;
  return (
    <div className="callrecord-box">
      <div className="callrecord-title">
        <FontAwesomeIcon
          icon={faPhoneAlt}
          style={{ rotate: "135deg" }}
          size="2x"
        />
        <div className="callrecord-info">
          Call ended - {new Date(1000 * duration).toISOString().substr(11, 8)}
          <div className="subscript" style={{ marginTop: "6px" }}>
            By: {callerName}
          </div>
        </div>
      </div>
      <div className="audio-player-container">
        <audio
          controls
          controlsList="nodownload noplaybackrate"
          style={{ width: "260px" }}
        >
          <source src={record.slice(0, -4)} type="audio/mp3" />
        </audio>
      </div>
    </div>
  );
};

export default CallRecordMessage;
