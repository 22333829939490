import React from 'react'

const SvgFilter = ({ color = '#414042', ...props }) => (
  <svg
    id="filter_svg__Layer_1"
    data-name="Layer 1"
    viewBox="0 0 105.14 80.39"
    {...props}>
    <defs>
      <style>
        {`.filter_svg__cls-1,.filter_svg__cls-2{fill:none;stroke:${color};stroke-linecap:round;stroke-linejoin:round;stroke-width:7px}.filter_svg__cls-2{fill:#fff}`}
      </style>
    </defs>
    <path
      className="filter_svg__cls-1"
      d="M3.5 12.51h98.14M3.5 40.19h98.14M3.5 67.88h98.14"
    />
    <circle className="filter_svg__cls-2" cx={27.11} cy={12.51} r={9.01} />
    <circle className="filter_svg__cls-2" cx={75.51} cy={40.19} r={9.01} />
    <circle className="filter_svg__cls-2" cx={45.13} cy={67.88} r={9.01} />
  </svg>
)

export default SvgFilter
