import React, { useState } from "react";
import { m, titleize } from "../../../../utils";
import { Fragment } from "react";
import DeleteMessage from "./DeleteMessage";
import UpdateUserDetails from "../../../../Mutations/UpdateUserDetails";
import EditableBody from "./EditableBody";
import Loading from "../../../Shared/Loading";

const EditableAccountDetails = ({ user, toggleEdit, refetch }) => {
  // console.log(user);
  const licenseData = user && user.driversLicenseData;
  const [curFirst, setCurFirst] = useState(user.firstName && user.firstName);
  const [curLast, setCurLast] = useState(user.lastName && user.lastName);
  const [curCity, setCurCity] = useState(
    titleize(user.operatingCity && user.operatingCity)
  );
  const [curHub, setCurHub] = useState(
    titleize(user.parkingLot && user.parkingLot.region)
  );
  const [curEmail, setCurEmail] = useState(user.email && user.email);
  const [curPhone, setCurPhone] = useState(user.mobile && user.mobile);
  const [curStatus, setCurStatus] = useState(
    user.accountStatus && user.accountStatus
  );
  const [isDelete, setIsDelete] = useState(false);
  const [changedFields, setChangedFields] = useState({
    firstName: curFirst,
    lastName: curLast,
    email: curEmail,
    mobile: curPhone,
    accountStatus: curStatus,
  });
  const [edited, setEdited] = useState(false);

  const availableCities = [
    { name: "Toronto", value: "Toronto" },
    { name: "New York City", value: "New York City" },
  ];

  const availableHubs = [
    { name: "Downtown", value: "Downtown" },
    { name: "Langhorne, PA", value: "Langhorne, PA" },
    { name: "Weekly Lease", value: "Weekly Lease" },
    { name: "LIC Temp Lot", value: "LIC Temp Lot" },
    { name: "Secaucus, NJ", value: "Secaucus, NJ" },
    { name: "Skyport", value: "Skyport" },
    { name: "Skyport (Outdoor)", value: "Skyport (Outdoor)" },
  ];

  const statuses = [];
  user.availableStatuses &&
    user.availableStatuses.map((status) =>
      statuses.push({
        name: status.replace(/_/g, " "),
        value: status,
      })
    );

  const onFirstChange = (e) => {
    const { name, value } = e.target;
    setCurFirst(value);
    setChangedFields((prevState) => ({
      ...prevState,
      firstName: value,
    }));
    setEdited(true);
  };
  const onLastChange = (e) => {
    const { name, value } = e.target;
    setCurLast(value);
    setChangedFields((prevState) => ({
      ...prevState,
      lastName: value,
    }));
    setEdited(true);
  };

  const onEmailChange = (e) => {
    const { name, value } = e.target;
    setCurEmail(value);
    setChangedFields((prevState) => ({
      ...prevState,
      email: value,
    }));
    setEdited(true);
  };
  const onMobileChange = (e) => {
    const { name, value } = e.target;
    setCurPhone(value);
    setChangedFields((prevState) => ({
      ...prevState,
      mobile: value,
    }));
    setEdited(true);
  };

  const onStatusChange = (e) => {
    setCurStatus(e);
    setChangedFields((prevState) => ({
      ...prevState,
      accountStatus: e,
    }));
    setEdited(true);
  };
  const onCityChange = (e) => {
    setCurCity(e);
    setEdited(true);
  };

  const onHubChange = (e) => {
    setCurHub(e);
    setEdited(true);
  };

  const toggleDelete = () => {
    setIsDelete(!isDelete);
  };
  return (
    <Fragment>
      <EditableBody
        curFirst={curFirst}
        onFirstChange={onFirstChange}
        curLast={curLast}
        onLastChange={onLastChange}
        curStatus={curStatus}
        onStatusChange={onStatusChange}
        user={user}
        curCity={curCity}
        onCityChange={onCityChange}
        availableCities={availableCities}
        curHub={curHub}
        onHubChange={onHubChange}
        curEmail={curEmail}
        onEmailChange={onEmailChange}
        curPhone={curPhone}
        onMobileChange={onMobileChange}
        licenseData={licenseData}
        statuses={statuses}
        toggleDelete={toggleDelete}
        availableHubs={availableHubs}
      />
      <DeleteMessage
        open={isDelete}
        toggleDelete={toggleDelete}
        toggleEdit={toggleEdit}
        userId={user.id}
        refetch={refetch}
      />
      <div className="edit-buttons">
        {" "}
        <button className="cancel-edit-button" onClick={toggleEdit}>
          Cancel
        </button>
        <UpdateUserDetails
          userFields={changedFields}
          userId={user.id}
          toggleEdit={toggleEdit}
          refetch={refetch}
          edited={edited}
        />
      </div>
    </Fragment>
  );
};

export default EditableAccountDetails;
