import React, { Component } from "react";
import gql from "graphql-tag";
import { Mutation } from "@apollo/client/react/components";
import { ApolloConsumer } from "@apollo/client";
import Button from "../Components/Shared/Button";
import { saveState } from "../utils/localStorage";

class CreateAdminSession extends Component {
  CREATE_ADMIN_SESSION = gql`
    mutation CreateAdminSession($email: String!, $password: String!) {
      createAdminSession(email: $email, password: $password) {
        adminUser {
          id
          email
          authenticationToken
        }
        error
      }
    }
  `;

  render() {
    const { email, password, callback, loading, displayError } = this.props;
    return (
      <ApolloConsumer>
        {(client) => (
          <Mutation
            mutation={this.CREATE_ADMIN_SESSION}
            variables={{
              email,
              password,
            }}
            onCompleted={(data) => {
              if (!data.createAdminSession.error) {
                const admin = data.createAdminSession.adminUser;
                if (admin) {
                  saveState({
                    auth: {
                      email: admin.email,
                      token: admin.authenticationToken,
                    },
                  });
                  callback(true);
                } else {
                  callback(false);
                }
              }
              else {
                displayError(data.createAdminSession.error);
              }
            }}
          >
            {(createAdminSession) => (
              <Button
                className="login-button"
                style={{
                  border: "none",
                  height: "32px",
                  color: "#414141",
                  width: "100%",
                }}
                onClick={() => {
                  createAdminSession();
                  loading();
                }}
                onTouch={() => {
                  createAdminSession();
                  loading();
                }}
                label="Sign in"
              />
            )}
          </Mutation>
        )}
      </ApolloConsumer>
    );
  }
}

export default CreateAdminSession;
