import React, { useState, useEffect } from "react";
import { createConsumer } from "@rails/actioncable";
import Message from "./Message";
import { useRef } from "react";
import { Fragment } from "react";
import { Device } from "twilio-client";
import { useMutation } from "@apollo/client";
import { ASSIGN_TICKET, REMOVE_TICKET_TAG } from "../queries";
import InputContainer from "./InputContainer";

const ChatBox = ({
  params,
  ticketData,
  refreshTicketCallback,
  defaultAdmin,
  token,
}) => {
  const [messages, setMessages] = useState([]);
  const [curSubs, setCurSubs] = useState();
  const [activeTab, setActiveTab] = useState("public");
  const [isInternal, setIsInternal] = useState(false);
  const [curDevice, setDevice] = useState(null);
  const [muted, setMuted] = useState();
  const [callStatus, setCallStatus] = useState("");
  const userPhone = ticketData && ticketData.user.mobile;
  const userData = ticketData && ticketData.user;

  useEffect(() => {
    const device = new Device();

    device.setup(token);

    device.on("ready", () => {
      setDevice(device);
    });
    device.on("connect", () => {
      setCallStatus("CONNECTED");
    });
    device.on("disconnect", () => {
      setCallStatus("DISCONNECTED");
    });
    device.on("cancel", () => {
      setCallStatus("CANCELLED");
    });
    device.on("reject", () => {
      setCallStatus("REJECTED");
    });

    return () => {
      device.destroy();
      setDevice(null);
      setCallStatus("OFFLINE");
    };
  }, [token]);

  const handleCall = () => {
    curDevice.connect({
      To: `+1${userPhone}`,
      From: defaultAdmin.id,
      user_id: ticketData && ticketData.user.id,
      ticket_id: ticketData && ticketData.id,
    });
  };
  const handleCancel = () => {
    curDevice.disconnectAll();
  };
  const handleMute = () => {
    setMuted(!muted);
    curDevice.activeConnection().mute(!muted);
  };

  const cable = useRef();
  useEffect(() => {
    if (!cable.current) {
      cable.current = createConsumer(`${process.env.REACT_APP_WEBSOCKET_URL}`);
    }
    const paramsToSend = {
      channel: "TicketChatChannel",
      ticketId: params.id,
      userEmail: defaultAdmin && defaultAdmin.email,
      userToken: defaultAdmin && defaultAdmin.authenticationToken,
    };
    const handlers = {
      connected: () => {
        console.log("connected");
      },
      disconnected: () => {
        console.log("disconnected");
        cable.current = null;
      },
      received: (data) => {
        console.log("data from backend: " + data.message);
        setMessages([...messages, data]);
      },
    };

    const subscription = cable.current.subscriptions.create(
      paramsToSend,
      handlers
    );

    setCurSubs(subscription);

    return function cleanup() {
      cable.current = null;
      subscription.unsubscribe();
    };
  }, [params.id, messages]);

  const handleSend = (childData, isInter) => {
    curSubs.send({
      identifier: curSubs.identifier,
      message: childData,
      messageType:
        childData.length > 1300
          ? childData.includes(" ")
            ? "text"
            : "image"
          : "text",
      internal: isInter ? isInter : isInternal,
    });
    refreshTicketCallback({ ticketId: params.id });
  };

  const historyMessages = ticketData ? ticketData.messages : [];

  useEffect(() => {
    const objDiv = document.getElementById("chat-box");
    objDiv.scrollTop = objDiv.scrollHeight;
  }, [historyMessages, messages]);

  const accidentReport =
    ticketData && ticketData.accidentReport && ticketData.accidentReport;
  const callRequests = ticketData && ticketData.callRequests;

  const [assignTicketMutation, loading] = useMutation(ASSIGN_TICKET);
  const assignTicket = () => {
    assignTicketMutation({
      variables: {
        ticketId: ticketData && ticketData.id,
        adminUserId: defaultAdmin.id,
      },
    }).then(({ data: { assignSupportTicket } }) => {
      if (assignSupportTicket.ticket) {
        refreshTicketCallback({ ticketId: assignSupportTicket.ticket.id });
        console.log("Assign Succeed!");
      } else {
        console.log("Assign error!");
      }
    });
  };

  const [removeTagMutation] = useMutation(REMOVE_TICKET_TAG);
  const handleTagRemove = (tagToRemove) => {
    removeTagMutation({
      variables: {
        ticketId: ticketData && ticketData.id,
        tagName: tagToRemove,
      },
    }).then(({ data: { removeTag } }) => {
      if (removeTag.success) {
        refreshTicketCallback(ticketData && ticketData.id);
        console.log("remove succeed!");
      } else {
        console.log("remove error!");
      }
    });
  };

  return (
    <Fragment>
      <div id="chat-box" className="chat-container">
        <Message
          messages={messages}
          historyMsg={historyMessages}
          refreshTicketCallback={refreshTicketCallback}
          ticketId={params.id}
          defaultAdmin={defaultAdmin}
          accidentReport={accidentReport}
          token={token}
          callRequests={callRequests}
          handleCall={handleCall}
          handleCancel={handleCancel}
          handleMute={handleMute}
          callStatus={callStatus}
          ticketData={ticketData}
          muted={muted}
          assignTicket={assignTicket}
          handleTagRemove={handleTagRemove}
        />
      </div>
      <InputContainer
        activeTab={activeTab}
        handleSend={handleSend}
        ticketData={ticketData}
        refreshTicketCallback={refreshTicketCallback}
        curSubs={curSubs}
        handleCall={handleCall}
        assignTicket={assignTicket}
        setActiveTab={setActiveTab}
        setIsInternal={setIsInternal}
        handleTagRemove={handleTagRemove}
        userData={userData}
      />
    </Fragment>
  );
};

export default ChatBox;
