import React from "react";

import styles from "./buttonGroup.module.scss";
import BaseButton from "./BaseButton";

const ButtonGroup = ({
  leftButtonText,
  leftButtonFunction,
  leftButtonProps,
  rightButtonText,
  rightButtonFunction,
  rightButtonProps
}) => (
  <>
    {leftButtonText && (
      <BaseButton
        label={leftButtonText}
        onClick={leftButtonFunction}
        additionalProps={{...{ color: "#414141" }, ...leftButtonProps}}
      />
    )}
    {rightButtonText && (
      <BaseButton label={rightButtonText} onClick={rightButtonFunction} additionalProps={rightButtonProps} />
    )}
  </>
);

export default ButtonGroup;
