import React from 'react'

const SvgRedX = ({ color = 'red', ...props }) => (
  <svg
    id="red-x_svg__Layer_1"
    data-name="Layer 1"
    viewBox="0 0 120.37 120.37"
    {...props}>
    <defs>
      <style>
        {`.red-x_svg__cls-1{fill:none;stroke:${color};stroke-linecap:round;stroke-miterlimit:10;stroke-width:7px}`}
      </style>
    </defs>
    <path
      className="red-x_svg__cls-1"
      d="M78.4 41.97L41.97 78.4M78.4 78.4L41.97 41.97"
    />
    <circle className="red-x_svg__cls-1" cx={60.19} cy={60.19} r={56.69} />
  </svg>
)

export default SvgRedX
