import React from "react";

const TransactionsHeader = ({ create, status }) => {
  return (
    !create &&
    status !== "pending" && (
      <div className="details-header">
        <div className="details-header-cell" style={{ width: "200px" }}>
          Date
        </div>
        <div className="details-header-cell" style={{ width: "180px" }}>
          Amount
        </div>
        <div className="details-header-cell" style={{ width: "300px" }}>
          Details
        </div>
        <div className="details-header-cell" style={{ width: "200px" }}>
          Reference
        </div>
      </div>
    )
  );
};

export default TransactionsHeader;
