import React, { Component } from 'react'
import styled, { keyframes } from 'styled-components'
import { centerItems, getAnimation, getFadeout } from './modalHelpers'

const dropIn = keyframes`${getAnimation(true)}`
const liftUp = keyframes`${getAnimation(false)}`
const fadeout = keyframes`${getFadeout()}`

const StyledOverlayContainer = styled.div`
	position: fixed;
	z-index: 300;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	overflow: auto;
	background-color: rgb(0, 0, 0);
	background-color: rgba(0, 0, 0, 0.55);
	${() => centerItems()}
	animation: ${({ exiting }) => exiting && fadeout} 0.3s;
`

const StyledModalBox = styled.div`
	width: ${({ width = '85vw' }) => width};
	height: ${({ height = "auto" }) => height};
	background-color: ${({ backgroundColor = 'white' }) => backgroundColor};
	border-radius: 26px;
	padding: ${({ padding = "2em" }) => padding};
	${() => centerItems()}
	animation: ${({ exiting }) => (exiting ? liftUp : dropIn)} 0.3s;
`

class Modal extends Component {
	constructor(props) {
		super(props)
		this.state = {
			exiting: false
		}
	}

	closeModal = () => this.setState({ exiting: false })
	wait = () => setTimeout(() => this.closeModal(), 300)

	componentDidUpdate(prevProps) {
		if (!this.props.open && prevProps.open) {
			this.setState({ exiting: true }, this.wait)
		}
	}

	render() {
		const { children, open = false, backgroundColor, padding, width, ...props } = this.props
		const { exiting } = this.state
		return (
			(open || exiting) && (
				<StyledOverlayContainer exiting={exiting} {...props}>
					<StyledModalBox width={width} exiting={exiting} padding={padding} backgroundColor={backgroundColor}>{children}</StyledModalBox>
				</StyledOverlayContainer>
			)
		)
	}
}

export default Modal
