import React, { useState } from "react";
import Button from "../../artzu-ui/src/Button";
import DatasetCard from "./DatasetCard";
import DatasetOptions from "./DatasetOptions";
import "./index.scss";

const DatasetPanel = ({
  datasets,
  addDataset,
  updateDataset,
  removeDataset,
  locations,
  accountStatuses,
  invoiceStatuses,
  ticketStatuses,
  outstandingRequirements,
  suspensionReasons,
  metrics,
  yAxis1,
  yAxis2,
}) => {
  const [datasetOptions, setDatasetOptions] = useState(null);
  const [datasetIndex, setDatasetIndex] = useState(null);
  const [selectedDataset, setSelectedDataset] = useState(null);
  return (
    <div className="dataset-panel">
      {datasets.map((dataset, index) => {
        return (
          <DatasetCard
            dataset={dataset}
            index={index}
            removeDataset={removeDataset}
            setSelectedDataset={setSelectedDataset}
            setDatasetIndex={setDatasetIndex}
            setDatasetOptions={setDatasetOptions}
            locations={locations}
          />
        );
      })}
      <Button
        className="dataset-new"
        color="#414141"
        label="+ Add Dataset"
        onClick={() => setDatasetOptions(true)}
      />
      {datasetOptions ? (
        <DatasetOptions
          dataset={selectedDataset}
          datasetIndex={datasetIndex}
          datasetLabel={
            datasetIndex === null ? datasets.length + 1 : datasetIndex + 1
          }
          datasets={datasets}
          locations={locations}
          accountStatuses={accountStatuses}
          invoiceStatuses={invoiceStatuses}
          ticketStatuses={ticketStatuses}
          outstandingRequirements={outstandingRequirements}
          suspensionReasons={suspensionReasons}
          metrics={metrics}
          addDataset={addDataset}
          updateDataset={updateDataset}
          setSelectedDataset={setSelectedDataset}
          setDatasetOptions={setDatasetOptions}
          setDatasetIndex={setDatasetIndex}
          yAxis1={yAxis1}
          yAxis2={yAxis2}
        />
      ) : null}
    </div>
  );
};

export default DatasetPanel;
