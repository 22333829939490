import React from "react";
import EditableAccountDetails from "./EditableAccountDetails";

import AccountDetailsSection from "./DriverDetails";
import PaymentMethods from "./PaymentMethods";
import Tags from "./Tags";

const DetailsBody = ({ user, isEdit, toggleEdit, refetch }) => {
  const licenseData = user && user.driversLicenseData;

  return isEdit ? (
    <div>
      <EditableAccountDetails
        user={user}
        toggleEdit={toggleEdit}
        refetch={refetch}
      />
    </div>
  ) : (
    <div className="user-page__body">
      <AccountDetailsSection user={user} licenseData={licenseData} />
      <Tags user={user} refetch={refetch} />
      <PaymentMethods
        paymentMethods={user.paymentMethods}
        timeZone={user.timeZone}
      />
    </div>
  );
};

export default DetailsBody;
