import styles from "../userDashboard.module.scss";
import IDVerificationBlack from "../../../../assets/icons/IDVerificationBlack.svg";
import { titleize } from "../../../../utils";
import moment from "moment-timezone";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faX } from "@fortawesome/pro-duotone-svg-icons";
import { Link } from "react-router-dom";

const IDVerificationTab = ({ data }) => {
  const currentDate = new Date();
  const timeZone = data.timeZone;

  const VerifySymbol = ({ valid }) => {
    return valid ? (
      <FontAwesomeIcon icon={faCheck} style={{ color: "#3DC700" }} />
    ) : (
      <FontAwesomeIcon icon={faX} style={{ color: "#FF0000" }} />
    );
  };

  return (
    <div className={styles.categoryTab}>
      <div className={styles.tabHeader}>
        <div className={styles.title}>
          <img src={IDVerificationBlack} />
          <div className={styles.textComponent}>
            ID Verification
            {data.driversLicenseData ? (
              <div className={styles.datescript}>
                Last updated:{" "}
                {moment(data.driversLicenseData.updatedAt)
                  .tz(timeZone)
                  .format("MMM DD, YYYY h:mmA z")}
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
        <Link
          to={{
            pathname: `/users/${data.id}/details`,
            state: { activeTab: "documents" },
          }}
          style={{ textDecoration: "none", color: "#128dff" }}
        >
          See more
        </Link>
      </div>
      {data.driversLicenseData ? (
        <div className={styles.tabContent}>
          <div className={styles.contentBlock}>
            <img
              className={styles.documentImageDriver}
              src={data.driversLicenseImage}
            />
            <div className={styles.subscript}>
              {data.driversLicenseImage ? (
                <VerifySymbol valid={data.driversLicenseImage} /> //assumes present front is always valid
              ) : (
                ""
              )}
              &nbsp;Front of license
            </div>
          </div>
          <div className={styles.contentBlock}>
            <div className={styles.documentImageDriver}>
              <div>
                Name: {titleize(data.driversLicenseData.firstName)}{" "}
                {titleize(data.driversLicenseData.lastName)}{" "}
              </div>
              <div>
                Type: {data.driversLicenseData.licenseClass?.toUpperCase()} -{" "}
                {data.driversLicenseData.province?.toUpperCase()},{" "}
                {data.driversLicenseData.country?.toUpperCase()}
              </div>
              <div
                style={
                  Date.parse(data.driversLicenseData.expiryDate) > currentDate
                    ? {}
                    : { color: "red" }
                }
              >
                Expiry:{" "}
                {moment(data.driversLicenseData.expiryDate)
                  .tz(timeZone)
                  .format("MMM DD, YYYY")}
                {Date.parse(data.driversLicenseData.expiryDate) > currentDate
                  ? ""
                  : " (Expired)"}
              </div>
            </div>
            <div className={styles.subscript}>
              {data.driversLicenseData ? (
                <VerifySymbol
                  valid={
                    Date.parse(data.driversLicenseData.expiryDate) > currentDate
                  }
                /> //validity only based on date, maybe update to look at regional validity?
              ) : (
                ""
              )}
              &nbsp;Back of license
            </div>
          </div>
          <div className={styles.contentBlock}>
            <img
              className={styles.documentImageProfile}
              src={data.uberProfileImage}
            />
            <div className={styles.subscript}>
              {data.uberProfileImage ? (
                <VerifySymbol
                  valid={data.profileImageVerification == "verified"}
                />
              ) : (
                ""
              )}
              &nbsp;Profile photo/verifcation
            </div>
          </div>
        </div>
      ) : (
        <div className={styles.nullItem}>Not verified</div>
      )}
    </div>
  );
};

export { IDVerificationTab };
