import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisH } from "@fortawesome/pro-regular-svg-icons";
import { titleize } from "../../../../utils";
import { faLockAlt, faLockOpenAlt } from "@fortawesome/pro-solid-svg-icons";
import ViewBooking from "../../../../assets/icons/ViewBooking.png";
import ViewLocation from "../../../../assets/icons/ViewLocation.png";
import ViewVehicle from "../../../../assets/icons/ViewVehicle.png";
import CallDriver from "../../../../assets/icons/CallDriver.png";
import React, { Fragment, useState } from "react";
import BookingActionPopup from "./BookingActionPopup";
import BookingActions from "./BookingActions";
import { statusColor, vehicleImage } from "./helpers";
import { useHistory } from "react-router-dom";
import moment from "moment-timezone";

const CurrentBooking = ({ currentBooking, refetch, userId }) => {
  const [editBookingTooltipOpen, setEditBookingTooltipOpen] = useState(false);

  const [bookingAction, setBookingAction] = useState(null /*"edit"*/);

  const history = useHistory();

  const timeZone = currentBooking.timeZone;
  const closeBookingAction = () => {
    setBookingAction(null);
    setEditBookingTooltipOpen(false);
  };

  return (
    <Fragment>
      <BookingActionPopup
        setBookingAction={setBookingAction}
        booking={currentBooking}
        open={editBookingTooltipOpen}
      />
      <BookingActions
        action={bookingAction}
        booking={currentBooking}
        open={!!bookingAction}
        refetch={refetch}
        vehicleImage={vehicleImage}
        closeBookingActions={closeBookingAction}
        userId={userId}
      />
      <div
        className="page-section"
        style={{
          marginBottom: "24px",
          paddingLeft: "32px",
          paddingRight: "32px",
          backgroundColor: "#ffffff",
        }}
      >
        <div className="current-booking-head">
          Current booking ID: {currentBooking.id}
          <div
            className={`current-booking-ellipsis${
              editBookingTooltipOpen ? " inverted" : ""
            }`}
            onClick={() => setEditBookingTooltipOpen(!editBookingTooltipOpen)}
          >
            <FontAwesomeIcon icon={faEllipsisH} size="lg" />
          </div>
        </div>
        <div className="page-section__canvas">
          <div
            className="page-section__canvas--column unselectable"
            style={{ width: "40%" }}
          >
            <div className="current-car-img">
              {currentBooking.car && (
                <img
                  src={vehicleImage(
                    currentBooking.car.model,
                    currentBooking.car.color
                  )}
                  alt="booking car"
                />
              )}
            </div>
            <div className="current-booking-actions">
              <div>
                <div>Booking status: </div>
                <div style={{ color: statusColor(currentBooking.status) }}>
                  {titleize(currentBooking.status)}
                </div>
                {currentBooking.status === "active" ? (
                  <div style={{ fontSize: "12px" }}>
                    Started -{" "}
                    {moment(currentBooking.pickup)
                      .tz(timeZone)
                      .format("h:mmA MMM D, YYYY z")}
                  </div>
                ) : null}
                {currentBooking.status === "ended" ? (
                  <div style={{ fontSize: "12px" }}>
                    Ended -{" "}
                    {moment(currentBooking.dropoff)
                      .tz(timeZone)
                      .format("h:mmA MMM D, YYYY z")}
                  </div>
                ) : null}
                {currentBooking.status === "late" ? (
                  <div style={{ fontSize: "12px" }}>
                    By {moment().diff(currentBooking.endTime, "minutes")}{" "}
                    minutes: ${currentBooking.lateFeeAmount} late fee
                  </div>
                ) : null}
              </div>
              <div
                className="current-booking-actions__button"
                style={{ marginLeft: "auto", backgroundColor: "#d30000" }}
              >
                <FontAwesomeIcon icon={faLockAlt} style={{ color: "white" }} />
              </div>
              <div
                className="current-booking-actions__button"
                style={{ margin: "0 20px", backgroundColor: "#319f00" }}
              >
                <FontAwesomeIcon
                  icon={faLockOpenAlt}
                  style={{ color: "white" }}
                />
              </div>
            </div>
          </div>
          <div
            className="page-section__canvas--column"
            style={{
              width: "20%",
              paddingLeft: "32px",
              borderRight: "1px solid #dedede",
            }}
          >
            <label>Model</label>
            <p>
              {currentBooking.car
                ? `${currentBooking.car.make} ${currentBooking.car.model}`
                : "N/A"}
            </p>
            <label>Plate</label>
            <p>{currentBooking.car ? currentBooking.car.plate : "N/A"}</p>
            <label>Spot</label>
            <p>
              {currentBooking.car
                ? currentBooking.car.parkingSpot || "-"
                : "N/A"}
            </p>
          </div>
          <div
            className="page-section__canvas--column"
            style={{
              width: "20%",
              paddingLeft: "32px",
              borderRight: "1px solid #dedede",
            }}
          >
            <label>Hub</label>
            <p>{currentBooking.parkinglot.region}</p>
            <label>Start</label>
            <p>
              {moment(currentBooking.startTime)
                .tz(timeZone)
                .format("ha MMM D, YYYY z")}
            </p>
            <label>Finish</label>
            <p>
              {moment(currentBooking.endTime)
                .tz(timeZone)
                .format("ha MMM D, YYYY z")}
            </p>
          </div>
          <div
            className="page-section__canvas--column"
            style={{ width: "20%", paddingLeft: "32px" }}
          >
            <div
              className="current-booking-link"
              style={{ opacity: "1" }}
              onClick={() =>
                history.push(
                  `/users/${currentBooking.driver.id}/bookings/${currentBooking.id}`
                )
              }
            >
              <div className="link-icon-container">
                <img src={ViewBooking} />
              </div>
              <div>View booking</div>
            </div>
            <div className="current-booking-link">
              <div className="link-icon-container">
                <img src={ViewLocation} />
              </div>
              <div>View location</div>
            </div>
            <div className="current-booking-link">
              <div className="link-icon-container">
                <img src={ViewVehicle} />
              </div>
              <div>View vehicle</div>
            </div>
            <div className="current-booking-link">
              <div className="link-icon-container">
                <img src={CallDriver} />
              </div>
              <div>Call driver</div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default CurrentBooking;
