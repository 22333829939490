import React, { useState } from "react";
import { faFilter } from "@fortawesome/pro-light-svg-icons";
import OptionsModal from "./OptionsModal";
import ToggleButton from "../../../Shared/ToggleButton";
import _ from "lodash";

const Filters = ({
  availableFilters,
  selectedFilterGroup,
  savedFilterGroups,
  changeFilters,
  isOpen,
  setIsOpen,
  clearSelectedFilter,
  background,
  timeZone,
}) => {
  const toggleOpenState = () => setIsOpen(!isOpen);

  const saveFilterGroup = (modifiedFilterGroup, selectedGroup) => {
    let allGroups;

    if (selectedGroup) {
      const savedFilterGroupsCopy = _.cloneDeep(savedFilterGroups);
      savedFilterGroupsCopy[selectedGroup.selectedGroupIndex] =
        modifiedFilterGroup;
      allGroups = savedFilterGroupsCopy;
    } else {
      allGroups = [...savedFilterGroups, modifiedFilterGroup];
    }
    const newFilters = allGroups.map((group) =>
      group.filters.map(({ categoryUuid, uuid, isExcluded, values }) => ({
        categoryUuid,
        uuid,
        isExcluded,
        values,
      }))
    );
    changeFilters(newFilters);
  };

  return (
    <div>
      <div style={{ marginRight: "12px" }}>
        <ToggleButton
          isOpen={isOpen}
          faIcon={faFilter}
          toggleOpenState={toggleOpenState}
          background={background}
        />
      </div>

      <OptionsModal
        clearSelectedFilter={clearSelectedFilter}
        isOpen={isOpen}
        closeModal={() => setIsOpen(false)}
        availableFilters={availableFilters}
        selectedFilterGroup={selectedFilterGroup}
        changeFilters={changeFilters}
        saveFilterGroup={saveFilterGroup}
        background={background}
        timeZone={timeZone}
      />
    </div>
  );
};

export default Filters;
