import React from "react";

const AvailabilityBreakdown = ({ shiftAvailability }) => {
  const availableCount =
    shiftAvailability.length === 0 ? 0 : shiftAvailability[0].availableCount;

  const availableRate =
    shiftAvailability.length === 0 ? "--" : shiftAvailability[0].price;
  return (
    <div className="count-and-rate">
      <div className="availability-box">
        <span style={{ fontSize: "96px", fontWeight: "600" }}>
          {availableCount}
        </span>
        <span>Availability</span>
      </div>

      <div className="rate-box">
        <span style={{ fontSize: "96px", fontWeight: "600" }}>
          {availableRate === "--" ? null : "$"}
          {availableRate}
        </span>
        <span>Rate</span>
      </div>
    </div>
  );
};

export default AvailabilityBreakdown;
