import React from "react";

import styles from "../../bookingActions.module.scss";
import moment from "moment-timezone";
import { createTimeRangeItems } from "../helpers";
import TimeDropdown from "./TimeDropdown";

const generateItems = (time, bookingConfig) => {
  const bookingEndTime = moment(time).tz(bookingConfig.timeZone);

  return createTimeRangeItems(4, bookingEndTime, bookingConfig.shiftFrequency);
};

const TimeContainer = ({
  changeEndTime,
  changeStartTime,
  userBookingConfig,
  booking: { startTime: initialStartTime, endTime: initialEndTime },
}) => {
  const startTimeItems = generateItems(initialStartTime, userBookingConfig);
  const endTimeItems = generateItems(initialEndTime, userBookingConfig);

  return (
    <div className={styles.timeContainer}>
      <TimeDropdown
        initialValue={initialStartTime}
        items={startTimeItems}
        onChange={changeStartTime}
      />
      <TimeDropdown
        initialValue={initialEndTime}
        items={endTimeItems}
        onChange={changeEndTime}
      />
    </div>
  );
};

export default TimeContainer;
