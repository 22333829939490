import React, { useEffect, useState } from "react";
import styles from "./popup.module.scss";
import DatePicker from "react-datepicker";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";

const PickDate = ({
  index,
  date,
  listData,
  datesCallback,
  presetInstallDates,
}) => {
  const [initialDate, setInitialDate] = useState(moment(date).toDate());
  const [curIdx, setIdx] = useState();

  useEffect(() => {
    datesCallback(initialDate, curIdx, presetInstallDates);
  }, [presetInstallDates && presetInstallDates.length]);

  useEffect(() => {
    datesCallback(initialDate, curIdx, presetInstallDates);
  }, [initialDate]);

  return (
    <div key={index} className={styles.elementContainer}>
      <div>Installment {index + 1} </div>
      <div onClick={() => setIdx(index + 1)}>
        <DatePicker
          selected={initialDate}
          onChange={(date) => setInitialDate(date)}
          className={styles.dateInput}
          dateFormat="MMM d, yyyy"
          todayButton={"Today"}
          popperPlacement="top"
          placeholderText="Select date"
        />
      </div>
    </div>
  );
};

export default PickDate;
