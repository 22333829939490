import React, { useState } from "react";
import Modal from "../../../../artzu-ui/src/Modal";
import { UPDATE_USER } from "../queries";
import styles from "../userDetails.module.scss";
import DocumentsImages from "./DocumentsImages";
import { useMutation } from "@apollo/client";
import LicenseDetailContent from "./LicenseDetailContent";
import RejectConfirmation from "./RejectConfirmation";

const DocumentsContent = ({ user, refetch, refetchProfile }) => {
  const [isProfile, setIsProfile] = useState();
  const [isDriver, setIsDriver] = useState();
  const [isReject, setIsReject] = useState(false);
  const [docToReject, setDocToReject] = useState("");

  const toggleProfile = (e) => {
    e.preventDefault();
    setIsProfile(true);
    document.addEventListener("click", closeProfile);
  };

  const closeProfile = () => {
    setIsProfile(false);
    document.removeEventListener("click", closeProfile);
  };

  const toggleDriver = (e) => {
    e.preventDefault();
    setIsDriver(true);
    document.addEventListener("click", closeDriver);
  };

  const closeDriver = () => {
    setIsDriver(false);
    document.removeEventListener("click", closeDriver);
  };

  const toggleReject = () => {
    setIsReject(!isReject);
  };

  const [updateUserMutation, { loading: isUpdateLoading }] =
    useMutation(UPDATE_USER);
  const handleUpdate = (userFields) => {
    updateUserMutation({
      variables: {
        userFields: userFields,
        userId: user.id,
      },
    }).then(({ data: { updateUser } }) => {
      if (updateUser.user) {
        refetchProfile();
        refetch();
        console.log("Update User Succeed!");
      } else {
        console.log("Update User error!");
      }
    });
  };
  const profileRejected = user.profileImageVerification === "rejected";
  const licenseRejected = user.driversLicenseVerification === "rejected";
  return (
    <div className={styles.documentsContent}>
      <DocumentsImages
        user={user}
        toggleProfile={toggleProfile}
        profileRejected={profileRejected}
        toggleReject={toggleReject}
        setDocToReject={setDocToReject}
        toggleDriver={toggleDriver}
        licenseRejected={licenseRejected}
      />
      <LicenseDetailContent user={user} />
      <Modal open={isProfile} backgroundColor="none">
        <img className={styles.fullDocumentImage} src={user.uberProfileImage} />
      </Modal>
      <Modal open={isDriver} backgroundColor="none">
        <img
          className={styles.fullDocumentImage}
          src={user.driversLicenseImage}
        />
      </Modal>
      <RejectConfirmation
        isReject={isReject}
        docToReject={docToReject}
        profileRejected={profileRejected}
        licenseRejected={licenseRejected}
        toggleReject={toggleReject}
        handleUpdate={handleUpdate}
      />
    </div>
  );
};

export default DocumentsContent;
