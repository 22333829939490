import React from "react";
import TransactionsTableContent from "./TransactionsTableContent";
import TransactionsHeader from "./TransactionsTableHeader";

const TransactionsTable = ({
  invoiceTransactions,
  create,
  status,
  timeZone,
}) => {
  return (
    <div>
      <TransactionsHeader create={create} status={status} />
      <TransactionsTableContent
        invoiceTransactions={invoiceTransactions}
        create={create}
        status={status}
        timeZone={timeZone}
      />
    </div>
  );
};

export default TransactionsTable;
