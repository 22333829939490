import React from "react";
import classNames from "classnames";
import styles from "./baseValue.module.scss";

const AmountValue = ({ cellData, classes }) => {
  const { text } = cellData;
  return (
    <div className={classNames(classes)}>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div
          className={styles.textValue}
          style={{ color: text < 0 ? "#3dc700" : "#414141" }}
        >
          $
          {text && text < 0
            ? Math.abs(parseFloat(text)).toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })
            : text &&
              parseFloat(text).toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
        </div>
      </div>
    </div>
  );
};

export default AmountValue;
