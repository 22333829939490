import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/pro-regular-svg-icons";
import { faTimes } from "@fortawesome/pro-light-svg-icons";
import { useState } from "react";
import { ADD_TICKET_TAG, REMOVE_TICKET_TAG } from "../queries";
import { Fragment } from "react";
import { useMutation, useQuery } from "@apollo/client";
import ErrorModal from "../../../../Payments/Invoices/ActionPopup/ErrorModal";
import Dropdown from "../../../../InformationTable/ActionBar/Filters/OptionsModal/Body/Filter/Dropdown";

const TicketTags = ({
  ticketData,
  allTags,
  tagData,
  refreshTicketCallback,
}) => {
  const [newTag, setNewTag] = useState();
  const [isError, setIsError] = useState();
  const [errorMsg, setErrorMsg] = useState();

  const ticketTags = tagData && tagData.map((tag) => tag.name);
  let arrayToSort = [...allTags];
  const allTagList = arrayToSort.sort().map((tag) => {
    return {
      name: tag,
      value: tag,
    };
  });

  const [addMutation] = useMutation(ADD_TICKET_TAG);
  const handleAddTag = () => {
    addMutation({
      variables: {
        ticketId: ticketData && ticketData.id,
        tagName: newTag,
      },
    }).then(({ data: { addTag } }) => {
      if (addTag.success) {
        setNewTag("");
        refreshTicketCallback(ticketData && ticketData.id);
        console.log("add succeed!");
      } else {
        toggleErrorModal();
        setErrorMsg(addTag.error);
        console.log("add error!");
      }
    });
  };

  const [removeMutation] = useMutation(REMOVE_TICKET_TAG);
  const handleRemoveTag = (tagToRemove) => {
    removeMutation({
      variables: {
        ticketId: ticketData && ticketData.id,
        tagName: tagToRemove,
      },
    }).then(({ data: { removeTag } }) => {
      if (removeTag.success) {
        refreshTicketCallback(ticketData && ticketData.id);
        console.log("remove succeed!");
      } else {
        toggleErrorModal();
        setErrorMsg(removeTag.error);
        console.log("remove error!");
      }
    });
  };

  const toggleErrorModal = () => {
    setIsError(!isError);
  };

  const triggerTag = (childData) => {
    setNewTag(childData);
  };

  const isDuplicate = ticketTags && ticketTags.includes(newTag);

  const addable = newTag !== undefined && newTag !== "" && !isDuplicate;

  return (
    <Fragment>
      <div className="tag-container">
        <div className="tag-header">
          <h2>Tags</h2>
        </div>
        <div className="subscript">Add tag</div>
        <div className="search-add-container">
          <Dropdown
            initialValue={newTag}
            items={allTagList}
            onChange={triggerTag}
            width="250px"
            disabled={!ticketData}
          />
          <button
            className={
              addable ? "add-button-container" : "invalid-add-button-container"
            }
          >
            <div
              className="add-tag-button"
              onClick={() => {
                addable && handleAddTag();
              }}
            >
              <FontAwesomeIcon icon={faPlus} size="md" className="plus-icon" />
              <div style={{ fontWeight: "600" }}>Add</div>
            </div>
          </button>
        </div>
        <div className="ticket-tags-container">
          {tagData &&
            tagData.map((tag) => (
              <div className="related-tag-container">
                {tag.name}
                <FontAwesomeIcon
                  icon={faTimes}
                  size="lg"
                  className="tag-times"
                  onClick={() => {
                    handleRemoveTag(tag.name);
                  }}
                />
              </div>
            ))}
        </div>
      </div>
      <ErrorModal
        open={isError}
        errorMsg={errorMsg}
        toggleErrorModal={toggleErrorModal}
      />
    </Fragment>
  );
};

export default TicketTags;
