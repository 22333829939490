import React from "react";
import { faTrashAlt } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { m, titleize } from "../../../../utils";
import Dropdown from "../../../InformationTable/ActionBar/Filters/OptionsModal/Body/Filter/Dropdown";

const EditableBody = ({
  curFirst,
  onFirstChange,
  curLast,
  onLastChange,
  curStatus,
  onStatusChange,
  user,
  curCity,
  onCityChange,
  availableCities,
  curHub,
  onHubChange,
  curEmail,
  onEmailChange,
  curPhone,
  onMobileChange,
  licenseData,
  statuses,
  toggleDelete,
  availableHubs,
}) => {
  const userSex = licenseData && licenseData.sex === "1" ? "Male" : "Female";
  return (
    <div className="user-page__body" style={{ height: "800px" }}>
      <div className="page-section">
        <div className="page-section__heading">Account details</div>
        <div className="page-section__canvas">
          <div className="page-section__half">
            <div className="details-field">
              <div className="subscript">First Name</div>
              <input
                className="text-input"
                type="text"
                value={curFirst}
                onChange={onFirstChange}
              ></input>
            </div>
            <div className="details-field">
              <div className="subscript">Last Name</div>
              <input
                className="text-input"
                type="text"
                value={curLast}
                onChange={onLastChange}
              ></input>
            </div>
            <div className="details-field">
              <div className="subscript">Status</div>
              {/* <div className="">{curStatus || "-"}</div> */}
              <Dropdown
                initialValue={curStatus}
                items={statuses}
                onChange={onStatusChange}
              />
            </div>
            <div className="details-field">
              <div className="subscript">User ID</div>
              <div className="detail-field">{user.id}</div>
            </div>
            <div className="details-field">
              <div className="subscript">Operating city</div>
              <Dropdown
                initialValue={curCity}
                items={availableCities}
                onChange={onCityChange}
              />
            </div>
            <div className="details-field">
              <div className="subscript">Hub</div>
              <Dropdown
                initialValue={curHub}
                items={availableHubs}
                onChange={onHubChange}
              />
            </div>
            <div className="details-field">
              <div className="subscript">Referral source</div>
              <div className="detail-field">
                {(licenseData && licenseData.refNumber) || "-"}
              </div>
            </div>
          </div>
          <div className="page-section__half left-border">
            <div className="details-field">
              <div className="subscript">Email</div>
              <input
                className="text-input"
                type="text"
                value={curEmail}
                onChange={onEmailChange}
              ></input>
            </div>
            <div className="details-field">
              <div className="subscript">Mobile phone</div>
              <input
                className="text-input"
                type="text"
                value={curPhone}
                onChange={onMobileChange}
              ></input>
            </div>
            <div className="details-field">
              <div className="subscript">Date of birth</div>
              <div className="detail-field">
                {m(licenseData && licenseData.dateOfBirth).format(
                  "MMM D, YYYY h:mm A"
                ) || "-"}
              </div>
            </div>
            <div className="details-field">
              <div className="subscript">Sex</div>
              <div className="detail-field">{userSex || "-"}</div>
            </div>
            <div className="details-field">
              <div className="subscript">Height</div>
              <div className="detail-field">
                {(licenseData && licenseData.height) || "-"}
              </div>
            </div>
            <div className="details-field">
              <div className="subscript">Eye color</div>
              <div className="detail-field">
                {(licenseData && licenseData.eyeColour) || "-"}
              </div>
            </div>
            <div className="details-field">
              <div className="subscript">Driver's license number</div>
              <div className="detail-field">
                {(licenseData && licenseData.licenseNumber) || "-"}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="page-section delete-button">
        <button className="delete-user" onClick={toggleDelete}>
          <div className="edit-profile-content">
            <FontAwesomeIcon icon={faTrashAlt} size="sm" />
            Delete user
          </div>
        </button>
      </div>
    </div>
  );
};

export default EditableBody;
