import React from 'react'

const SvgNav = ({ color = '#fff', ...props }) => (
  <svg
    id="nav_svg__Layer_1"
    data-name="Layer 1"
    viewBox="0 0 105.14 62.37"
    {...props}>
    <defs>
      <style>
        {`.nav_svg__cls-1{fill:none;stroke:${color};stroke-linecap:round;stroke-linejoin:round;stroke-width:7px}`}
      </style>
    </defs>
    <path
      className="nav_svg__cls-1"
      d="M3.5 3.5h98.14M3.5 31.18h98.14M3.5 58.87h98.14"
    />
  </svg>
)

export default SvgNav
