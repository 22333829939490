import styles from "../../bookingActions.module.scss";
import TimeSelect from "./TimeSelect";
import React from "react";

const TimeDropdown = ({ initialValue, items, onChange }) => (
  <div className={styles.time}>
    <div className={styles.subscript}>Start</div>
    <TimeSelect
      initialValue={initialValue}
      items={items}
      onChange={onChange}
      width="178px"
      height="32px"
    />
  </div>
);

export default TimeDropdown;
