import React, { useState } from "react";

import styles from "./searchBar.module.scss";
import SearchBar from "./SearchBar";

const Search = ({
  changeSearchValue,
  searchValue,
  background,
  startTerms,
  presetSearch,
}) => {
  const [localSearchValue, setLocalSearchValue] = useState(
    presetSearch ? presetSearch : searchValue
  );

  const checkForSubmit = (evt) => {
    if (evt.key === "Enter") {
      changeSearchValue(localSearchValue);
    }
  };

  const clearSearch = () => {
    setLocalSearchValue("");
    changeSearchValue("");
  };
  return (
    <div
      className={
        background === "white"
          ? styles.searchContainerWhiteBack
          : styles.searchContainer
      }
    >
      <div className={styles.searchBar}>
        <SearchBar
          clearSearch={clearSearch}
          placeholder={startTerms}
          width="100%"
          onChange={(e) => setLocalSearchValue(e.target.value)}
          onKeyPress={checkForSubmit}
          value={localSearchValue === null ? "" : localSearchValue}
          size="16px"
          color="#414141"
        />
      </div>
    </div>
  );
};

export default Search;
