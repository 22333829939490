import React from "react";
import { TableCell, TableRow } from "../../../../../Shared/Table";
import moment from "moment-timezone";
import { Fragment } from "react";
import EditableRow from "./EditableRow";
import { titleize } from "../../../../../../utils";

const DetailsTableContent = ({
  create,
  invoiceDetails,
  invoiceInfo,
  lineItemsCallBack,
  setLiveTotal,
  tmpTotal,
  isCreated,
  lineItems,
  toggleCreateInvoice,
}) => {
  const subTotal = invoiceInfo && invoiceInfo.subtotal;
  const chargedTax = invoiceInfo && invoiceInfo.chargedTax;
  const total = invoiceInfo && invoiceInfo.total;
  const taxRate = 0.13;
  const tmpTax = tmpTotal * taxRate;
  const realTotal = tmpTotal + tmpTax;
  return (
    <Fragment>
      {create && !isCreated ? (
        <EditableRow
          lineItemsCallBack={lineItemsCallBack}
          setLiveTotal={setLiveTotal}
          isCreated={isCreated}
          lineItems={lineItems}
          toggleCreateInvoice={toggleCreateInvoice}
        />
      ) : (
        <Fragment>
          {invoiceDetails &&
            invoiceDetails.map((item) => (
              <TableRow
                justify="space-between"
                align="center"
                padding="15px 24px"
                borderB="1px solid #dedede"
              >
                <TableCell width="180px" align="left">
                  {titleize(item.category)}
                </TableCell>
                <TableCell width="300px" align="left" wrap="break-word">
                  {item.name}
                </TableCell>
                <TableCell width="600px" align="left" wrap="break-word">
                  {item.description}
                </TableCell>
                <TableCell
                  width="300px"
                  align="left"
                  color={item.category === "credit" ? "#3DC700" : "#414141"}
                >
                  $
                  {item.category === "credit"
                    ? Math.abs(item.amount).toLocaleString(undefined)
                    : item.amount.toLocaleString(undefined)}
                </TableCell>
              </TableRow>
            ))}
        </Fragment>
      )}
      <TableRow
        justify="space-between"
        align="center"
        padding="15px 24px 0 24px"
      >
        <TableCell width="180px" align="left" bold>
          Sub total
        </TableCell>
        <TableCell width="300px" align="left" bold></TableCell>
        <TableCell width="600px" align="left" bold></TableCell>
        <TableCell
          width="300px"
          align="left"
          color={
            create
              ? tmpTotal
                ? tmpTotal < 0
                  ? "#3DC700"
                  : "#414141"
                : "rgba(64,64,64,0.5)"
              : subTotal < 0
              ? "#3DC700"
              : "#414141"
          }
          bold
        >
          $
          {subTotal
            ? subTotal < 0
              ? Math.abs(subTotal).toLocaleString(undefined)
              : subTotal.toLocaleString(undefined)
            : tmpTotal
            ? Math.abs(tmpTotal).toLocaleString(undefined)
            : "00.00"}
        </TableCell>
      </TableRow>
      <TableRow
        justify="space-between"
        align="center"
        padding={create ? "15px 24px 0 24px" : "15px 24px"}
        borderB={create ? null : "1px solid #dedede"}
      >
        <TableCell width="180px" align="left" bold>
          Tax
        </TableCell>
        <TableCell width="300px" align="left" bold></TableCell>
        <TableCell width="600px" align="left" bold></TableCell>
        <TableCell
          width="300px"
          align="left"
          color={
            create
              ? tmpTax
                ? tmpTax < 0
                  ? "#3DC700"
                  : "#414141"
                : "rgba(64,64,64,0.5)"
              : chargedTax < 0
              ? "#3DC700"
              : "#414141"
          }
          bold
        >
          $
          {chargedTax
            ? chargedTax < 0
              ? Math.abs(chargedTax).toLocaleString(undefined)
              : chargedTax.toLocaleString(undefined)
            : tmpTax
            ? Math.abs(tmpTax).toLocaleString(undefined)
            : "00.00"}
        </TableCell>
      </TableRow>
      <TableRow justify="space-between" align="center" padding="15px 24px">
        <TableCell width="180px" align="left" bold size="24px">
          Total
        </TableCell>
        <TableCell width="300px" align="left" bold></TableCell>
        <TableCell width="600px" align="left" bold></TableCell>
        <TableCell
          width="300px"
          align="left"
          bold
          size="24px"
          color={
            create
              ? realTotal
                ? realTotal < 0
                  ? "#3DC700"
                  : "#414141"
                : "rgba(64,64,64,0.5)"
              : total < 0
              ? "#3DC700"
              : "#414141"
          }
        >
          $
          {total
            ? total < 0
              ? `${Math.abs(total).toLocaleString(undefined)} Credit`
              : total.toLocaleString(undefined)
            : tmpTotal
            ? realTotal < 0
              ? `${Math.abs(realTotal).toLocaleString(undefined)} Credit`
              : realTotal.toLocaleString(undefined)
            : "00.00"}
        </TableCell>
      </TableRow>
    </Fragment>
  );
};

export default DetailsTableContent;
