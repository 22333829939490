import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/pro-light-svg-icons";
import TicketDialer from "./TicketDialer";
import CallRecordMessage from "./CallRecordMessage";
import CallRecordMessageNoAnswer from "./CallRecordMessageNoAnswer";

const CallMessage = ({
  msg,
  token,
  defaultAdmin,
  handleCancel,
  handleMute,
  ticketUser,
  muted,
  record,
  admin,
  duration,
  handleCall,
  handleTagRemove,
  curMsgId,
  callStatus,
  ticketAssignedAdmin,
}) =>
  msg.label === "calling" &&
  (msg.id === curMsgId && callStatus === "CONNECTED" ? (
    <div className="message-tag-outgoing">
      <TicketDialer
        token={token}
        defaultAdmin={defaultAdmin}
        handleCancel={handleCancel}
        handleMute={handleMute}
        ticketUser={ticketUser}
        muted={muted}
      />
    </div>
  ) : (
    msg.callRecord &&
    (record !== null ? (
      <div className="message-tag-outgoing">
        {" "}
        <CallRecordMessage
          record={record}
          admin={admin}
          duration={duration}
          ticketAssignedAdmin={ticketAssignedAdmin}
        />
      </div>
    ) : msg.callRecord.status === "no-answer" ||
      (msg.callRecord.status === "ringing" &&
        msg.callRecord.recording === null) ||
      (msg.callRecord.status === "busy" &&
        msg.callRecord.recording === null) ? (
      <div className="message-tag-outgoing">
        {" "}
        <CallRecordMessageNoAnswer
          admin={admin}
          handleCall={handleCall}
          handleTagRemove={handleTagRemove}
          ticketAssignedAdmin={ticketAssignedAdmin}
        />
      </div>
    ) : msg.callRecord.status === "failed" ? (
      <div className="message-tag-outgoing">Call failed</div>
    ) : (
      <div className="message-tag-outgoing">
        <FontAwesomeIcon className="blue" spin size="1x" icon={faSpinner} />
      </div>
    ))
  ));

export default CallMessage;
