import React from "react";
import { USER_LOGS } from "../queries";
import Loading from "../../../Shared/Loading";
import LogsBody from "./Body";
import { useQuery } from "@apollo/client";

const UserLogs = ({ userId }) => {
  const { data, loading, error } = useQuery(USER_LOGS, {
    variables: { userId },
    fetchPolicy: "network-only",
  });

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return null;
  }

  if (data.userLogs) {
    return (
      <LogsBody logs={data.userLogs} timeZone={data.userProfileData.timeZone} />
    );
  }
};

export default UserLogs;
