import React, { useState } from "react";
import { Fragment } from "react";
import Button from "../../../Shared/Button";
import styles from "../batchActions.module.scss";
import { BATCH_ADD_USER_TAG } from "../queries";
import { useMutation } from "@apollo/client";
import ErrorModal from "../../../Payments/Invoices/ActionPopup/ErrorModal";
import { faTimes } from "@fortawesome/pro-light-svg-icons";
import { faPlus } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Loading from "../../../Shared/Loading";
import SelectAllConfirmModal from "./SelectAllConfirmModal";

const AddTag = ({
  selectedOption,
  selections,
  close,
  clearAllSelections,
  isAllSelected,
  toggleFeedbackModal,
  toggleRefresh,
  citiesFilter,
}) => {
  const [isError, setIsError] = useState();
  const [errorMsg, setErrorMsg] = useState();
  const [isConfirm, setIsConfirm] = useState();
  const [tag, setTag] = useState();
  const [tagsToAdd, setTagsToAdd] = useState([]);

  const toggleConfirmModal = () => {
    setIsConfirm(!isConfirm);
  };

  const triggerTag = (e) => {
    setTag(e.target.value);
  };

  const handleAdd = () => {
    setTagsToAdd((tagsToAdd) => [...tagsToAdd, tag]);
    setTag("");
  };

  const handleRemove = (tag) => {
    let curTags = [...tagsToAdd];
    curTags.includes(tag) && (curTags = curTags.filter((item) => item !== tag));
    setTagsToAdd(curTags);
  };

  const toggleErrorModal = () => {
    setIsError(!isError);
  };

  const [tagAddition, { loading, error }] = useMutation(BATCH_ADD_USER_TAG);
  const addUserTag = () => {
    tagAddition({
      variables: {
        userIds: selections,
        tags: tagsToAdd,
        tableName: "users",
        isAllSelected: isAllSelected,
        citiesToFilter: citiesFilter || [],
      },
    }).then(({ data: { batchAddTag } }) => {
      if (batchAddTag.success) {
        console.log("Add User Tag Succeed!");
        toggleRefresh();
      } else {
        toggleErrorModal();
        setErrorMsg("add user tag not working");
        console.log("Add User Tag error!");
      }
    });
  };

  const addable = tag !== undefined && tag !== "" && !tagsToAdd.includes(tag);
  const applyable = tagsToAdd.length !== 0;

  return loading ? (
    <Loading size="3x" />
  ) : (
    <Fragment>
      <div className={styles.darkText}>{selectedOption.text}</div>
      <div className={styles.contentContainer}>
        <div className={styles.addTagContent}>
          <input
            className={styles.tagInput}
            onChange={triggerTag}
            value={tag}
          />
          <button
            className={
              addable ? "add-button-container" : "invalid-add-button-container"
            }
          >
            <div
              className="add-tag-button"
              onClick={() => {
                addable && handleAdd();
              }}
            >
              <FontAwesomeIcon icon={faPlus} size="md" className="plus-icon" />
              <div style={{ fontWeight: "600" }}>Add</div>
            </div>
          </button>
        </div>

        <div className={styles.ticketTagsContainer}>
          {tagsToAdd.map((tag) => (
            <div className={styles.ticketTagBox}>
              {tag}
              <FontAwesomeIcon
                icon={faTimes}
                size="lg"
                className="tag-times"
                onClick={() => {
                  handleRemove(tag);
                }}
              />
            </div>
          ))}
        </div>
        <div className={styles.notesSaveButton}>
          <Button
            label={"Save"}
            height="39px"
            fullWidth="150px"
            color={applyable ? "#128dff" : "#87CEFA"}
            backColor="#ffffff"
            radius="20px"
            size="16px"
            weight="700"
            border="1px solid #FFFFFF"
            onClick={() => {
              applyable && !isAllSelected && addUserTag();
              applyable && !isAllSelected && clearAllSelections();
              applyable && !isAllSelected && toggleFeedbackModal();
              applyable && !isAllSelected && close();
              applyable && isAllSelected && toggleConfirmModal();
            }}
          />
        </div>
      </div>
      <ErrorModal
        open={isError}
        errorMsg={errorMsg}
        toggleErrorModal={toggleErrorModal}
      />
      <SelectAllConfirmModal
        open={isConfirm}
        rowCount={selections.length}
        batchAction={selectedOption.text}
        batchActionFun={addUserTag}
        toggleConfirmModal={toggleConfirmModal}
        toggleFeedbackModal={toggleFeedbackModal}
        clearAllSelections={clearAllSelections}
        close={close}
      />
    </Fragment>
  );
};

export default AddTag;
