import { useMutation } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { Fragment } from "react";
import { Redirect, useHistory } from "react-router";
import ErrorModal from "../ActionPopup/ErrorModal";
import GeneralPopup from "../ActionPopup/GeneralPopup";
import { CREATE_INVOICE, DELETE_INVOICE } from "../../queries";
import Details from "./Tabs/Details";
import Notes from "./Tabs/Notes";
import Transactions from "./Tabs/Transactions";

const Index = ({
  create,
  bookingId,
  invoiceData,
  createInvoiceCallback,
  setLiveTotal,
  tmpTotal,
  refetch,
  isCreated,
  setIsCreated,
}) => {
  const [activeTab, setActiveTab] = useState("details");
  const [lineItems, setLineItems] = useState([]);
  const [isError, setIsError] = useState();
  const [errorMsg, setErrorMsg] = useState();
  const [doneEditing, setDoneEditing] = useState(true);
  const [curAction, setAction] = useState();
  const [isPopup, setPopup] = useState();

  const invoiceInfo = invoiceData && invoiceData.getInvoice;
  const invoiceDetails = invoiceInfo && invoiceInfo.lineItems;
  const invoiceTransactions = invoiceInfo && invoiceInfo.payments;
  const status = invoiceInfo && invoiceInfo.status;
  const invoiceId = invoiceInfo && invoiceInfo.id;
  const notes = invoiceInfo && invoiceInfo.notes;

  const timeZone = invoiceInfo && invoiceInfo.user.timeZone;

  const togglePopup = () => {
    setPopup(!isPopup);
  };

  const lineItemsCallBack = (childData) => {
    setLineItems(childData);
  };

  const toggleCreateInvoice = (childData) => {
    setDoneEditing(!doneEditing);
  };

  const handleDelete = () => {
    setAction("delete");
    togglePopup();
  };

  const history = useHistory();
  const [createMutation] = useMutation(CREATE_INVOICE);
  const createNewInvoice = () => {
    createMutation({
      variables: {
        bookingId: bookingId,
        lineItems: lineItems,
      },
    }).then(({ data: { createInvoice } }) => {
      if (createInvoice) {
        createInvoiceCallback({ invoiceId: createInvoice.invoice.id });
        setIsCreated(true);
        history.push({
          pathname: `/payments/invoices/${createInvoice.invoice.id}`,
        });
      } else {
        console.log("create error!");
      }
    });
  };

  const pageContent = () => {
    switch (activeTab) {
      case "details":
        return (
          <Details
            invoiceDetails={invoiceDetails}
            invoiceInfo={invoiceInfo}
            create={create}
            lineItemsCallBack={lineItemsCallBack}
            setLiveTotal={setLiveTotal}
            tmpTotal={tmpTotal}
            isCreated={isCreated}
            refetch={refetch}
            lineItems={lineItems}
            toggleCreateInvoice={toggleCreateInvoice}
          />
        );
      case "transactions":
        return (
          <Transactions
            invoiceTransactions={invoiceTransactions}
            timeZone={timeZone}
            create={create}
            status={status}
          />
        );
      case "notes":
        return <Notes notes={notes} timeZone={timeZone} />;
    }
  };

  const toggleErrorModal = () => {
    setIsError(!isError);
  };

  return (
    <Fragment>
      <div className="botContainer">
        <div className="tab-header">
          <div className="tab-container">
            <div
              className={`unselectable detailTab${
                activeTab === "details" ? " selected" : ""
              }`}
              onClick={() => setActiveTab("details")}
            >
              Details
            </div>
            <div
              className={`unselectable detailTab${
                activeTab === "transactions" ? " selected" : ""
              }`}
              onClick={() => setActiveTab("transactions")}
            >
              Transactions
            </div>
            <div
              className={`unselectable detailTab${
                activeTab === "notes" ? " selected" : ""
              }`}
              onClick={() => setActiveTab("notes")}
            >
              Notes
            </div>
          </div>
          {create ? (
            <button
              className={
                doneEditing && lineItems.length !== 0
                  ? "create-invoice-button-active"
                  : "create-invoice-button"
              }
              onClick={() => {
                !isCreated &&
                  doneEditing &&
                  lineItems.length !== 0 &&
                  createNewInvoice();
              }}
            >
              Create this invoice
            </button>
          ) : status === "pending" || status === "unpaid" ? (
            <button
              className="delete-invoice-button-active"
              onClick={handleDelete}
            >
              Delete this invoice
            </button>
          ) : null}
        </div>

        {pageContent()}
      </div>
      <ErrorModal
        open={isError}
        errorMsg={errorMsg}
        toggleErrorModal={toggleErrorModal}
      />
      <GeneralPopup
        open={isPopup}
        action={curAction}
        togglePopup={togglePopup}
        invoiceId={invoiceId}
        createInvoiceCallback={createInvoiceCallback}
      />
    </Fragment>
  );
};

export default Index;
