import { gql } from "@apollo/client";

const GET_SHIFT_AVAILABILITY = gql`
  query userShiftAvailability(
    $vehicleTier: String!
    $startDate: ISO8601Date!
    $endDate: ISO8601Date!
    $shiftStart: Int!
    $shiftEnd: Int!
    $userId: ID!
    $parkingLotId: ID!
  ) {
    userShiftAvailability(
      vehicleTier: $vehicleTier
      startDate: $startDate
      endDate: $endDate
      shiftStart: $shiftStart
      shiftEnd: $shiftEnd
      userId: $userId
      parkingLotId: $parkingLotId
    ) {
      price
      date
      startTime
      endTime
      shiftRates
      availableCount
    }
  }
`;

const USER_CREATE_BOOKING = gql`
  mutation createBooking(
    $startHour: Int!
    $duration: Int!
    $date: ISO8601Date!
    $vehicleClass: String!
    $userId: ID!
    $rate: Float!
    $shiftRates: [Float!]!
    $parkingLotId: ID!
    $insuranceDeductibleReductionSelected: Boolean
  ) {
    createBooking(
      startHour: $startHour
      duration: $duration
      date: $date
      vehicleClass: $vehicleClass
      userId: $userId
      rate: $rate
      shiftRates: $shiftRates
      parkingLotId: $parkingLotId
      insuranceDeductibleReductionSelected: $insuranceDeductibleReductionSelected
    ) {
      booking {
        id
        status
        startTime
        endTime
        car {
          id
          plate
        }
        rate
      }
      error
    }
  }
`;

const USER_BOOKING_CONFIG = gql`
  query UserBookingConfig($userId: ID!) {
    userBookingConfig(userId: $userId) {
      parkingLots {
        id
        name
        availableTiers
      }
      shiftTimes
      shiftFrequency
      possibleShiftLengths
      maxBookingDuration
      minBookingDuration
      timeZone
    }
  }
`;

const CHANGE_BOOKING_SCHEDULE = gql`
  mutation ChangeSchedule(
    $startTime: DateTime!
    $endTime: DateTime!
    $comment: String!
    $bookingId: ID!
  ) {
    changeSchedule(
      startTime: $startTime
      endTime: $endTime
      comment: $comment
      bookingId: $bookingId
    ) {
      booking {
        id
      }
      error
    }
  }
`;

const END_BOOKING = gql`
  mutation EndBooking($bookingId: ID!, $comment: String) {
    endBooking(bookingId: $bookingId, comment: $comment) {
      booking {
        id
      }
      error
    }
  }
`;

const SWITCH_TO_VEHICLE = gql`
  mutation SwitchToVehicle($bookingId: ID!, $carId: ID!, $comment: String) {
    switchToVehicle(bookingId: $bookingId, carId: $carId, comment: $comment) {
      booking {
        id
      }
      error
    }
  }
`;

const AVAILABLE_VEHICLES = gql`
  query AvailableVehicles($bookingId: ID!) {
    availableVehicles(bookingId: $bookingId) {
      id
      model
      plate
      parkingSpot
      imageLink
    }
  }
`;

const AVAILABLE_VEHICLES_CHARGE_LEVELS = gql`
  query AvailableVehiclesChargeLevels($bookingId: ID!) {
    availableVehiclesChargeLevels(bookingId: $bookingId)
  }
`;

const USER_ACTIVE_BOOKING = gql`
  query UserBookingData($userId: ID!) {
    userActiveBooking(userId: $userId) {
      id
      startTime
      endTime
      vehicleClass
      createdAt
      status
      pickup
      dropoff
      lateFeeAmount
      driver {
        id
      }
      parkinglot {
        id
        region
      }
      car {
        id
        make
        plate
        tier
        model
        parkingSpot
        color
        imageLink
      }
      timeZone
    }
  }
`;

const BOOKING_DETAILS = gql`
  query BookingDetails($id: ID!) {
    bookingDetails(id: $id) {
      id
      status
      startTime
      endTime
      vehicleClass
      pickup
      dropoff
      createdAt
      rate
      bookingFee
      additionalFees
      insuranceDeductibleReductionSchedules {
        startTime
        endTime
      }
      driver {
        id
      }
      car {
        plate
        id
        uuid
      }
      parkinglot {
        region
      }
      timeline {
        event
        eventType
        eventId
        actorType
        actorId
        actorName
        actorIcon
        comment
        caption1
        caption2
        caption3
        colorCode
        timestamp
      }
      timeZone
    }
  }
`;

export {
  GET_SHIFT_AVAILABILITY,
  USER_CREATE_BOOKING,
  USER_BOOKING_CONFIG,
  CHANGE_BOOKING_SCHEDULE,
  END_BOOKING,
  SWITCH_TO_VEHICLE,
  AVAILABLE_VEHICLES,
  AVAILABLE_VEHICLES_CHARGE_LEVELS,
  USER_ACTIVE_BOOKING,
  BOOKING_DETAILS,
};
