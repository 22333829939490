import React from "react";
import ActionButtons from "./ActionButtons";
import ChangeStatusButton from "./ChangeStatusButton";
import TicketBodyHeader from "./TicketBodyHeader";

const StatusAction = ({
  ticketData,
  isEdit,
  curCategory,
  categories,
  changeCategory,
  curSubject,
  setCurSubject,
  changeSubject,
  curOptions,
  chosenAgentName,
  defaultAdmin,
  toggleAdmin,
  refreshTicketCallback,
  ableToCreate,
  userId,
  bookingId,
  assignedAdminId,
  adminIdCallback,
  toggleEdit,
  cancelEdit,
}) => {
  return (
    <div className="status-action">
      <TicketBodyHeader
        ticketData={ticketData}
        isEdit={isEdit}
        curCategory={curCategory}
        categories={categories}
        changeCategory={changeCategory}
        curSubject={curSubject}
        setCurSubject={setCurSubject}
        changeSubject={changeSubject}
        curOptions={curOptions}
        chosenAgentName={chosenAgentName}
        defaultAdmin={defaultAdmin}
        toggleAdmin={toggleAdmin}
      />
      <ChangeStatusButton
        ticketData={ticketData}
        refreshTicketCallback={refreshTicketCallback}
        ableToCreate={ableToCreate}
        userId={userId}
        bookingId={bookingId}
        category={curCategory}
        subject={curSubject}
        adminId={assignedAdminId}
        adminName={defaultAdmin.fullName}
        adminIdCallback={adminIdCallback}
        isEdit={isEdit}
        toggleEdit={toggleEdit}
      />
      <ActionButtons
        ticketData={ticketData}
        editTicket={toggleEdit}
        isEdit={isEdit}
        cancelEdit={cancelEdit}
        refreshTicketCallback={refreshTicketCallback}
      />
    </div>
  );
};

export default StatusAction;
