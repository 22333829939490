import React, { useState } from "react";
import { faEllipsisH } from "@fortawesome/pro-regular-svg-icons";
import ToggleButton from "../../../Shared/ToggleButton";
import Menu from "./Menu";
import styles from "./miscSettings.module.scss";
import GeneralPopup from "../../../Payments/Invoices/ActionPopup/GeneralPopup";
import ImportPopup from "./ImportPopup";
import ExportPopup from "./ExportPopup";

const MiscSettings = ({
  startImport,
  isImportModalOpen,
  closeImport,
  startExport,
  isExportModalOpen,
  closeExport,
  tableName,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [type, setType] = useState();
  const toggleOpenState = () => setIsOpen(!isOpen);
  const handleType = (childData) => setType(childData);
  return (
    <div className={styles.miscSettings}>
      <ToggleButton
        isOpen={isOpen}
        toggleOpenState={toggleOpenState}
        faIcon={faEllipsisH}
      />
      <Menu
        isOpen={isOpen}
        startImport={() => {
          startImport();
          toggleOpenState();
        }}
        startExport={() => {
          startExport();
          toggleOpenState();
        }}
        handleType={handleType}
        tableName={tableName}
      />
      <ImportPopup
        open={isImportModalOpen}
        togglePopup={closeImport}
        csvType={type}
      />
      <ExportPopup
        open={isExportModalOpen}
        togglePopup={closeExport}
        tableName={tableName}
      />
    </div>
  );
};

export default MiscSettings;
