import _ from "lodash";
import moment from "moment";
import TextValue from "../CellValues/Base/TextValue";
import UserValue from "../CellValues/Base/UserValue";
import ImageValue from "../CellValues/Base/ImageValue";

import {
  baseTextProcessor,
  baseDateProcessor,
  baseImageProcessor,
} from "./shared";

const rowComponentsBooking = (time_zone) => ({
  bookingId: {
    component: TextValue,
    dataProcessor: baseTextProcessor("bookingId"),
  },
  userFullName: {
    component: TextValue,
    dataProcessor: baseTextProcessor("userFullName"),
  },
  driverId: {
    component: TextValue,
    dataProcessor: baseTextProcessor("driverId"),
  },
  vehiclePlate: {
    component: TextValue,
    dataProcessor: baseTextProcessor("vehiclePlate"),
  },
  parkingLotName: {
    component: TextValue,
    dataProcessor: baseTextProcessor("parkingLotName"),
  },
  driverImageUrl: {
    component: UserValue,
    dataProcessor: baseImageProcessor("userProfileImageId"),
  },
  vehicleImage: {
    component: ImageValue,
    dataProcessor: (data) => {
      const imageLink =
        data["vehicleImageUrl"] && data["vehicleImageUrl"].value;
      const make = data["vehicleMake"] && data["vehicleMake"].value;
      const model = data["vehicleModel"] && data["vehicleModel"].value;
      return {
        imageLink: imageLink,
        subtext: make && model && `${make} ${model}`,
      };
    },
  },
  vehicleTier: {
    component: TextValue,
    dataProcessor: baseTextProcessor("vehicleTier"),
  },
  bookingStart: {
    component: TextValue,
    dataProcessor: baseDateProcessor("bookingStart", time_zone),
  },
  bookingEnd: {
    component: TextValue,
    dataProcessor: baseDateProcessor("bookingEnd", time_zone),
  },
  bookingPickup: {
    component: TextValue,
    dataProcessor: baseDateProcessor("bookingPickup", time_zone),
  },
  bookingDropoff: {
    component: TextValue,
    dataProcessor: baseDateProcessor("bookingDropoff", time_zone),
  },
  bookedAt: {
    component: TextValue,
    dataProcessor: baseDateProcessor("bookedAt", time_zone),
  },
  rate: {
    component: TextValue,
    dataProcessor: baseTextProcessor("rate"),
  },
  duration: {
    component: TextValue,
    dataProcessor: baseTextProcessor("duration"),
  },
});

export { rowComponentsBooking };
