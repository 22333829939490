import { gql } from "@apollo/client";

const UPDATE_USER = gql`
    mutation UpdateUser($userFields: UserAttributes!, $userId: ID!) {
        updateUser(userFields: $userFields, userId: $userId) {
            user {
            firstName
            lastName
            email
            mobile
            accountStatus
            }
        }
    }
`;

const DELETE_USER = gql`
    mutation deleteUser($userId: ID!) {
        deleteUser(userId: $userId) {
            success
            error
        }
    }
`;

const CLOSE_USER = gql`
    mutation closeUserByAdmin($userId: ID!) {
        closeUserByAdmin(userId: $userId) {
            user {
                id
                accountStatus
                accountStatusChangedBy
            }
        }
    }
`;

const REOPEN_USER = gql`
    mutation reopenUserByAdmin($userId: ID!) {
    reopenUserByAdmin(userId: $userId) {
        user {
            id
            accountStatus
            accountStatusChangedBy
        }
        }
    }
`;

export {UPDATE_USER, DELETE_USER, CLOSE_USER, REOPEN_USER}