import React from "react";
import styles from "../userDetails.module.scss";
import { faTimes } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const DocumentsImages = ({
  user,
  toggleProfile,
  profileRejected,
  toggleReject,
  setDocToReject,
  toggleDriver,
  licenseRejected,
}) => {
  const rejectProfileFunctions = () => {
    toggleReject();
    setDocToReject("profile");
  };

  const rejectLicenseFunctions = () => {
    toggleReject();
    setDocToReject("license");
  };
  return (
    <div className={styles.documentImages}>
      <div className={styles.column}>
        <img
          className={styles.documentImageProfile}
          src={user.uberProfileImage}
          onClick={toggleProfile}
        />
        <div className={styles.documentImageName}>Profile Photo</div>
        {profileRejected && (
          <div className={styles.rejectedText}>(Rejected)</div>
        )}
      </div>
      <div
        className={`actionButton${profileRejected ? " redFill" : " redBorder"}`}
        onClick={() => {
          !profileRejected && rejectProfileFunctions();
        }}
      >
        <FontAwesomeIcon icon={faTimes} size="sm" />
      </div>
      <div className={styles.column}>
        <img
          className={styles.documentImageDriver}
          src={user.driversLicenseImage}
          onClick={toggleDriver}
        />
        <div className={styles.documentImageName}>Driver's License</div>
        {licenseRejected && (
          <div className={styles.rejectedText}>(Rejected)</div>
        )}
      </div>
      <div
        className={`actionButton${licenseRejected ? " redFill" : " redBorder"}`}
        onClick={() => {
          !licenseRejected && rejectLicenseFunctions();
        }}
      >
        <FontAwesomeIcon icon={faTimes} />
      </div>
    </div>
  );
};

export default DocumentsImages;
