import VehicleSelection from "./VehicleSelection";
import CommentContainer from "../Shared/CommentContainer";
import ActionContainer from "../Shared/ActionContainer";
import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import { SWITCH_TO_VEHICLE } from "../../../queries";
import Loading from "../../../../../Shared/Loading";

const BaseDisplay = ({ booking, availableVehicles, closeBookingActions }) => {
  const [isChoosingVehicle, setIsChoosingVehicle] = useState(false);
  const [comment, setComment] = useState("");
  const [errors, setErrors] = useState([]);
  const [newCar, setNewCar] = useState(availableVehicles[0]);

  const selectNewCar = (newCar) => {
    setNewCar(newCar);
  };

  const handleSetComment = (newComment) => setComment(newComment.trim());

  const valid = () => comment !== "" && newCar?.id;

  const [switchToVehicle, { loading: isSwitchVehicleLoading }] =
    useMutation(SWITCH_TO_VEHICLE);
  const handleSwitchVehicle = () => {
    switchToVehicle({
      variables: {
        bookingId: booking.id,
        carId: newCar.id,
        comment,
      },
    }).then(({ data: switchToVehicle }) => {
      if (switchToVehicle.errors) {
        setErrors(switchToVehicle.errors);
        return;
      }
      closeBookingActions();
    });
  };
  return (
    <>
      {isSwitchVehicleLoading ? (
        <Loading />
      ) : (
        <>
          <VehicleSelection
            currentCar={booking.car}
            availableVehicles={availableVehicles}
            selectNewCar={selectNewCar}
            newCar={newCar}
            openVehicleList={() => setIsChoosingVehicle(true)}
            isChoosingVehicle={isChoosingVehicle}
            booking={booking}
          />
          <CommentContainer
            changeComment={handleSetComment}
            title={
              isChoosingVehicle
                ? "Reason for not choosing recommended vehicle"
                : "Comment"
            }
          />
          <ActionContainer
            closeContainer={closeBookingActions}
            handleSave={handleSwitchVehicle}
            valid={valid()}
          />
        </>
      )}
    </>
  );
};

export default BaseDisplay;
