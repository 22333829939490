import React from "react";
import styles from "./locationSelect.module.scss";
import Button from "../../Shared/Button";
import { useMutation } from "@apollo/client";
import { CHANGE_CITY } from "./queries";
import { Fragment } from "react";
import { useState } from "react";
import ErrorModal from "../../Payments/Invoices/ActionPopup/ErrorModal";
import { useEffect } from "react";

const LocationSelectButtons = ({
  lotsSelected,
  reset,
  store,
  toggleOpen,
  handleCityCallback,
}) => {
  const [isError, setIsError] = useState();
  const [errorMsg, setErrorMsg] = useState();

  const toggleErrorModal = () => {
    setIsError(!isError);
  };

  let cityIds = [];
  cityIds = lotsSelected.map((item) => item.id);

  const changeCity = () => {
    handleCityCallback(cityIds);
  };

  return (
    <Fragment>
      <div className={styles.buttons}>
        <div className={styles.reset} onClick={reset}>
          <Button
            label={"Reset"}
            height="39px"
            fullWidth="91px"
            color={"#128dff"}
            backColor="#ffffff"
            radius="20px"
            size="16px"
            weight="700"
            border="1px solid #FFFFFF"
            onClick={() => reset()}
          />
        </div>
        <div className={styles.save}>
          <Button
            label={"Save"}
            height="39px"
            fullWidth="91px"
            color={"#128dff"}
            backColor="#ffffff"
            radius="20px"
            size="16px"
            weight="700"
            border="1px solid #FFFFFF"
            onClick={() => {
              changeCity();
              localStorage.setItem(
                "accessibleCities",
                JSON.stringify(lotsSelected)
              );
              // store.changeSelectedLots(lotsSelected);
              toggleOpen();
              // window.location.reload();
            }}
          />
        </div>
      </div>
      <ErrorModal
        open={isError}
        errorMsg={errorMsg}
        toggleErrorModal={toggleErrorModal}
      />
    </Fragment>
  );
};

export default LocationSelectButtons;
