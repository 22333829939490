import React, { Fragment } from "react";
import DisplayVehicle from "./DisplayVehicle";
import styles from "../../bookingActions.module.scss";

const NewVehicle = ({ newCar, openVehicleList }) => {
  return (
    <div className={styles.vehiclePreviewItem}>
      {newCar ? (
        <Fragment>
          <p>New vehicle</p>
          <DisplayVehicle plate={newCar.plate} imageLink={newCar.imageLink} />
          <div className="choose-vehicle-link" onClick={openVehicleList}>
            Choose another vehicle
          </div>
        </Fragment>
      ) : (
        <div className={styles.noVehicles}>No available vehicles</div>
      )}
    </div>
  );
};

export default NewVehicle;
