import React, { useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { ADD_NEW_TEMPLATE, UPDATE_TEMPLATE } from "../../queries";
import closeButton from "../../../../../../images/buttons/close-button.svg";
import Modal from "../../../../../../artzu-ui/src/Modal";
import DiscardModal from "./DiscardModal";
import { useEffect } from "react";
import { Fragment } from "react";

const NewTemplateModal = ({
  open,
  toggleNew,
  toggleTemplate,
  refetch,
  closeNew,
  curTemplate,
  clearCurrentTemplate,
}) => {
  const [curName, setCurName] = useState();
  const [curText, setCurText] = useState();
  const [isDiscard, setIsDiscard] = useState();

  useEffect(() => {
    if (curTemplate) {
      setCurName(curTemplate.name);
      setCurText(curTemplate.text);
    } else {
      setCurName(undefined);
      setCurText(undefined);
    }
  }, [curTemplate]);

  const triggerNameChange = (e) => {
    setCurName(e.target.value);
  };

  const triggerTextChange = (e) => {
    setCurText(e.target.value);
  };

  const toggleDiscard = () => {
    setIsDiscard(!isDiscard);
  };

  const clearInput = () => {
    setCurName(undefined);
    setCurText(undefined);
  };

  const [addTemplateMutation, { loading: isAddLoading }] =
    useMutation(ADD_NEW_TEMPLATE);
  const handleAdd = () => {
    addTemplateMutation({
      variables: {
        name: curName,
        text: curText,
      },
    }).then(({ data: { addTemplate } }) => {
      if (addTemplate.success) {
        refetch();
        toggleNew();
        console.log("Add template Succeed!");
      } else {
        console.log("Add template error!");
      }
    });
  };

  const [updateTemplateMutation, { loading: isEditLoading }] =
    useMutation(UPDATE_TEMPLATE);
  const handleEdit = () => {
    updateTemplateMutation({
      variables: {
        templateId: curTemplate && curTemplate.id,
        name: curName,
        text: curText,
      },
    }).then(({ data: { editTemplate } }) => {
      if (editTemplate.success) {
        refetch();
        toggleNew();
        console.log("Edit template Succeed!");
      } else {
        console.log("Edit template error!");
      }
    });
  };

  const ableToSave = curName && curText;
  return (
    <Fragment>
      <Modal open={open} backgroundColor="none">
        <div className="template-container">
          <div className="template-title">
            New Template
            <img
              src={closeButton}
              onClick={() => {
                toggleNew();
                toggleTemplate();
              }}
              style={{ cursor: "pointer" }}
            ></img>
          </div>
          <div className="subscript">Template name</div>
          <div className="search-and-add">
            <div className="name-input-container">
              <input
                className="name-input-style"
                value={curName}
                onChange={triggerNameChange}
              />
            </div>
          </div>
          <div className="subscript">Text</div>
          <textarea
            className="template-text-box"
            onChange={triggerTextChange}
            value={curText}
          ></textarea>
          <div className="template-buttons">
            <div>
              <button
                className="booking-cancel-button"
                onClick={() => {
                  toggleDiscard();
                }}
              >
                Back
              </button>
            </div>
            <div>
              <button
                className={
                  ableToSave
                    ? "booking-choose-button"
                    : "booking-choose-button-invalid"
                }
                onClick={() => {
                  ableToSave && (curTemplate ? handleEdit() : handleAdd());
                  clearInput();
                  clearCurrentTemplate();
                }}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </Modal>
      <DiscardModal
        open={isDiscard}
        toggleDiscard={toggleDiscard}
        toggleNew={toggleNew}
        closeNew={closeNew}
        clearCurrentTemplate={clearCurrentTemplate}
        clearInput={clearInput}
      />
    </Fragment>
  );
};

export default NewTemplateModal;
