import { capitalize } from "lodash";
import React from "react";
import { Fragment } from "react";
import ActionList from "../ActionList";
import moment from "moment-timezone";

const TopRight = ({ create, invoiceData, financingCallback, refetch }) => {
  const invoiceInfo = invoiceData && invoiceData.getInvoice;
  const financingInfo = invoiceInfo && invoiceInfo.financingBreakdown;
  const invoiceStatus = invoiceInfo && invoiceInfo.status;
  const status = financingInfo && financingInfo.status;
  const upcomingPayments = financingInfo && financingInfo.upcomingInvoices;
  upcomingPayments &&
    upcomingPayments.length !== 0 &&
    upcomingPayments.sort((p1, p2) =>
      p1.issuanceDate > p2.issuanceDate ? 1 : -1
    );
  const upcomingPayDate =
    upcomingPayments &&
    upcomingPayments.length !== 0 &&
    upcomingPayments[0].dueDate;
  const invoices = financingInfo && financingInfo.invoices;
  const installments = invoices && invoices.length;
  const total = invoiceInfo && invoiceInfo.total;
  const payments =
    financingInfo && financingInfo.payments && financingInfo.payments.length;
  const invoiceNum = invoiceInfo && invoiceInfo.invoiceNumber;
  const invoiceId = invoiceInfo && invoiceInfo.id;
  const processor = financingInfo && financingInfo.processedBy;
  const processorName = processor && processor.fullName;
  const denyReason = financingInfo && financingInfo.comments;

  const timeZone = invoiceInfo && invoiceInfo.user.timeZone;

  const normalActions = [
    { name: "Arrange", value: "Arrange" },
    { name: "Deny", value: "Deny" },
  ];
  const arrangedActions = [{ name: "Edit financing", value: "Edit financing" }];

  const getAction = (status) => {
    switch (status) {
      case "financed":
        return arrangedActions;
      case "credit":
        return null;
      case "deleted":
        return null;
      default:
        return normalActions;
        break;
    }
  };

  const getColor = (text) => {
    switch (text) {
      case "null":
        return "#414141";
      case "approved":
        return "#3dc700";
      case "denied":
        return "#ff0000";
      case "requested":
        return "#f2994a";
      default:
        break;
    }
  };

  const actions = getAction(invoiceStatus);
  const clickable = actions && actions.length !== 0;

  return (
    <div className="financing-details">
      {create ? (
        <div className="financing-message">
          Financing can be arranged after issuing the invoice
        </div>
      ) : (
        <div className="financing-content">
          <div className="financing-title">Financing Details</div>
          <div className="details-field">
            <div className="subscript">Financing Status</div>
            {financingInfo === null || status === null ? (
              <div>
                <div
                  className="plain-field"
                  style={{ color: `${getColor(status)}` }}
                >
                  Not requested
                </div>
              </div>
            ) : (
              <Fragment>
                <div
                  className="plain-field"
                  style={{ color: `${getColor(status)}` }}
                >
                  {capitalize(status)}
                </div>
                {status === "approved" && (
                  <div style={{ opacity: "0.5", fontSize: "12px" }}>
                    Approved by {processorName}
                  </div>
                )}
              </Fragment>
            )}
          </div>
          {status === "approved" ? (
            <div>
              <div className="details-field">
                <div className="subscript">Upcoming payment date</div>
                <div className="plain-field">
                  {upcomingPayDate
                    ? moment(upcomingPayDate)
                        .tz(timeZone)
                        .format("MMM D, YYYY h:mm A z")
                    : "-"}
                </div>
              </div>
              <div className="details-field">
                <div className="subscript">Financing installments</div>
                <div className="plain-field">
                  {installments} installments of $
                  {installments !== 0 &&
                    (total / installments).toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                </div>
              </div>
              <div className="details-field">
                <div className="subscript">Progress</div>
                <div className="plain-field">
                  {payments} out of {installments} installments paid
                </div>
              </div>
            </div>
          ) : (
            status === "denied" && (
              <div>
                <div className="details-field">
                  <div className="subscript">Denied by</div>
                  <div className="plain-field">{processorName}</div>
                  {/* <div className="plain-field"></div> */}
                </div>
                <div className="details-field">
                  <div className="subscript">Reason of denial</div>
                  <div className="plain-field">{denyReason}</div>
                </div>
              </div>
            )
          )}
        </div>
      )}

      <div className="threeDot">
        {!create && (
          <ActionList
            actions={actions}
            listData={invoices}
            invoiceNum={invoiceNum}
            total={total}
            invoiceId={invoiceId}
            financingCallback={financingCallback}
            refetch={refetch}
            clickable={clickable}
          />
        )}
      </div>
    </div>
  );
};

export default TopRight;
