import React from "react";
import classNames from "classnames";
import styles from "./baseValue.module.scss";
import { renderColor, titleize } from "../../../../utils";
import _, { capitalize } from "lodash";

const TicketStatusValue = ({ cellData, classes, columnName }) => {
  const { text } = cellData;
  const getColor = (text) => {
    switch (text) {
      case "in_progress":
        return "orange";
      case "resolved":
        return "#3dc700";
      case "deleted":
        return "#414141";
      case "unassigned":
        return "#ff0000";
      case "call_requested":
        return "rgb(151, 18, 255)";
      case "new":
        return "#128dff";
      case "backlog":
        return "#966919";
      default:
        break;
    }
  };

  const getBackColor = (text) => {
    switch (text) {
      case "in_progress":
        return "#ffe2c1";
      case "resolved":
        return "#cbf0bb";
      case "deleted":
        return "#dedede";
      case "unassigned":
        return "#ffbaba";
      case "call_requested":
        return "rgb(240, 208, 255)";
      case "new":
        return "#daedff";
      case "backlog":
        return "rgb(150, 105, 25, 0.4)";
      default:
        break;
    }
  };

  return (
    <div className={classNames(classes)}>
      <div
        className={styles.ticketTag}
        style={{
          color: `${getColor(text ? text : "new")}`,
          backgroundColor: `${getBackColor(text ? text : "new")}`,
          border: `1px ${getBackColor(text ? text : "new")} solid;`,
        }}
      >
        {titleize(text)}
      </div>
    </div>
  );
};

export default TicketStatusValue;
