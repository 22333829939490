import { gql } from "@apollo/client";

const GET_USER_INFO = gql`
  query UserProfile($id: ID!) {
    userProfileData(id: $id) {
      id
      email
      firstName
      lastName
      fullName
      uberProfileImage
      accountStatus
      deviceOs
      appVersion
      createdAt
      mostImmediateBooking {
        id
      }
      parkingLot {
        name
      }
      bookings {
        id
      }
      ridesharePlatforms {
        name
        totalVehicleCount
        ptcActiveVehicleCount
      }
      tickets {
        id
      }
    }
  }
`;

const GET_ADMIN_USER_INFO = gql`
  query adminUserProfile($id: ID, $name: String) {
    adminUserData(id: $id, name: $name) {
      id
      email
      firstName
      lastName
      fullName
      tickets {
        id
      }
    }
  }
`;

const GET_ADMIN_NAME = gql`
  query allAdminName {
    allAdminName {
      id
      firstName
      lastName
    }
  }
`;
const GET_TICKET_TOKEN = gql`
  query getToken {
    getToken
  }
`;

const GET_ALL_TEMPLATES = gql`
  query getAllTemplates {
    getAllTemplates {
      id
      name
      text
    }
  }
`;

const GET_TICKET = gql`
  query getAdminSupportTicket($ticketId: ID!) {
    getAdminSupportTicket(ticketId: $ticketId) {
      id
      status
      category
      subject
      description
      createdAt
      ticketTags {
        id
        name
      }
      allValidTags
      ticketFeedbacks {
        id
        rating
        comments
      }
      callRequests {
        id
        status
        sentAt
      }
      reminderDate
      taggedUsers
      user {
        id
        fullName
        accountStatus
        mobile
        email
        uberProfileImage
        deviceOs
        appVersion
        createdAt
        timeZone
        mostImmediateBooking {
          id
        }
        parkingLot {
          name
        }
        bookings {
          id
        }
        ridesharePlatforms {
          name
          totalVehicleCount
          ptcActiveVehicleCount
        }
        tickets {
          id
          status
        }
      }
      adminUser {
        id
        fullName
      }
      booking {
        id
        startTime
        endTime
        bookingFee
        car {
          id
          plate
          make
          model
        }
        carHistory {
          carId
        }
        timeZone
      }
      messages {
        id
        text
        senderName
        fromAdmin
        sentAt
        direction
        label
        internal
        callRecord {
          adminUser {
            id
            firstName
            lastName
            fullName
          }
          recording
          recordingDuration
          status
        }
        callRequest {
          id
          status
        }
      }
      accidentReport {
        id
        ticket {
          id
          status
          category
          subject
        }
        injuredPeople
        statusDescription
        ridesharePlatform
        towed
        towingCompany
        towingAddress
        accidentDescription
        accidentLocation
        accidentTime
        frontImage
        driverImage
        backImage
        passengerImage
        autzuDamageImages
        thirdPartyIndividuals {
          name
          phone
          email
          individualImages
        }
        thirdPartyVehicles {
          id
          licensePlate
          year
          make
          model
          color
          licensePlateImage
          licenseImage
          insuranceImage
          vehicleDamageImages
        }
      }
    }
  }
`;

const RESOLVE_TICKET = gql`
  mutation resolveSupportTicket($ticketId: ID!) {
    resolveSupportTicket(ticketId: $ticketId) {
      ticket {
        id
        status
      }
      success
      error
    }
  }
`;

const REOPEN_TICKET = gql`
  mutation reopenSupportTicket($ticketId: ID!, $reason: String!) {
    reopenSupportTicket(ticketId: $ticketId, reason: $reason) {
      ticket {
        id
      }
      success
      error
    }
  }
`;

const RECOVER_TICKET = gql`
  mutation recoverSupportTicket($ticketId: ID!, $reason: String!) {
    recoverSupportTicket(ticketId: $ticketId, reason: $reason) {
      success
      error
    }
  }
`;

const DELETE_TICKET = gql`
  mutation deleteSupportTicket($ticketId: ID!, $reason: String!) {
    deleteSupportTicket(ticketId: $ticketId, reason: $reason) {
      success
      error
    }
  }
`;

const UPDATE_TICKET = gql`
  mutation updateSupportTicket(
    $ticketId: ID!
    $adminUserId: ID
    $bookingId: ID
    $category: String
    $subject: String
    $userId: ID!
  ) {
    updateSupportTicket(
      ticketId: $ticketId
      adminUserId: $adminUserId
      bookingId: $bookingId
      category: $category
      subject: $subject
      userId: $userId
    ) {
      success
      error
    }
  }
`;

const ASSIGN_TICKET = gql`
  mutation assignSupportTicket($ticketId: ID!, $adminUserId: ID!) {
    assignSupportTicket(ticketId: $ticketId, adminUserId: $adminUserId) {
      ticket {
        id
        status
        adminUser {
          id
        }
      }
      success
      error
    }
  }
`;

const IN_PROGRESS_TO_BACKLOG = gql`
  mutation backlogSupportTicket($ticketId: ID!) {
    backlogSupportTicket(ticketId: $ticketId) {
      success
      error
    }
  }
`;

const BACKLOG_TO_IN_PROGRESS = gql`
  mutation inProgressSupportTicket($ticketId: ID!) {
    inProgressSupportTicket(ticketId: $ticketId) {
      success
      error
    }
  }
`;

const DEFAULT_ADMIN_USER = gql`
  query assignedAdminUser {
    currentAdminUser {
      id
      fullName
      authenticationToken
      email
    }
  }
`;

const CREATE_TICKET = gql`
  mutation createSupportTicket(
    $bookingId: ID
    $category: String!
    $description: String!
    $subject: String!
    $userId: ID!
    $adminUserId: ID!
  ) {
    createSupportTicket(
      bookingId: $bookingId
      category: $category
      description: $description
      subject: $subject
      userId: $userId
      adminUserId: $adminUserId
    ) {
      ticket {
        id
        status
        category
        subject
        description
        booking {
          id
          startTime
          endTime
        }
        user {
          id
        }
        adminUser {
          id
        }
        messages {
          id
          text
          senderName
          sentAt
        }
      }
      error
    }
  }
`;

const REMOVE_TICKET_TAG = gql`
  mutation removeTag($ticketId: ID!, $tagName: String!) {
    removeTag(ticketId: $ticketId, tagName: $tagName) {
      success
      error
    }
  }
`;

const ADD_TICKET_TAG = gql`
  mutation addTag($ticketId: ID!, $tagName: String!) {
    addTag(ticketId: $ticketId, tagName: $tagName) {
      success
      error
    }
  }
`;

const ANSWER_CALL_REQUEST = gql`
  mutation answerCallRequest($ticketId: ID!, $requestId: ID!) {
    answerCallRequest(ticketId: $ticketId, requestId: $requestId) {
      callRequest {
        id
        status
      }
      success
      error
    }
  }
`;

const TICKET_REMINDER = gql`
  mutation remindAdmin($ticketId: ID!, $adminId: ID, $reminderDate: DateTime!) {
    remindAdmin(
      ticketId: $ticketId
      adminId: $adminId
      reminderDate: $reminderDate
    ) {
      success
      error
    }
  }
`;

const REMOVE_ADMIN_REMINDER = gql`
  mutation removeReminder($ticketId: ID!, $adminId: ID!, $adminName: String!) {
    removeReminder(
      ticketId: $ticketId
      adminId: $adminId
      adminName: $adminName
    ) {
      success
      error
    }
  }
`;

const SEND_TO_FLEET = gql`
  mutation sendToFleet(
    $ticketId: ID!
    $plate: String!
    $subject: String!
    $note: String!
  ) {
    sendToFleet(
      ticketId: $ticketId
      plate: $plate
      subject: $subject
      note: $note
    ) {
      success
      error
    }
  }
`;

const ADD_NEW_TEMPLATE = gql`
  mutation addTemplate($name: String!, $text: String!) {
    addTemplate(name: $name, text: $text) {
      template {
        name
        text
      }
      success
      error
    }
  }
`;

const REMOVE_TEMPLATE = gql`
  mutation removeTemplate($templateId: ID!) {
    removeTemplate(templateId: $templateId) {
      success
      error
    }
  }
`;

const UPDATE_TEMPLATE = gql`
  mutation editTemplate($templateId: ID!, $name: String!, $text: String!) {
    editTemplate(templateId: $templateId, name: $name, text: $text) {
      template {
        id
        name
        text
      }
      success
      error
    }
  }
`;

export {
  GET_USER_INFO,
  GET_TICKET,
  RESOLVE_TICKET,
  REOPEN_TICKET,
  DEFAULT_ADMIN_USER,
  GET_ADMIN_USER_INFO,
  CREATE_TICKET,
  ASSIGN_TICKET,
  UPDATE_TICKET,
  DELETE_TICKET,
  RECOVER_TICKET,
  REMOVE_TICKET_TAG,
  ADD_TICKET_TAG,
  GET_ADMIN_NAME,
  GET_TICKET_TOKEN,
  ANSWER_CALL_REQUEST,
  TICKET_REMINDER,
  REMOVE_ADMIN_REMINDER,
  SEND_TO_FLEET,
  GET_ALL_TEMPLATES,
  ADD_NEW_TEMPLATE,
  REMOVE_TEMPLATE,
  UPDATE_TEMPLATE,
  IN_PROGRESS_TO_BACKLOG,
  BACKLOG_TO_IN_PROGRESS,
};
