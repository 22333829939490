import React from "react";
import CheckBox from "../../../Shared/CheckBox";
import styles from "./rowSelect.module.scss";
import classsNames from "classnames";

const RowSelect = ({ rowId, onClick, isSelected, isAllSelected }) => {
  return (
    <div className={styles.rowSelect}>
      <CheckBox
        onClick={(evt) => {
          // evt.preventDefault();
          onClick();
        }}
        isSelected={isSelected}
        isAllSelected={isAllSelected}
      />
    </div>
  );
};

export default RowSelect;
