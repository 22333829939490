import BMW3SeriesWhite from "../../../../images/BMW3SeriesWhite.png";
import BMW3SeriesBlack from "../../../../images/BMW3SeriesBlack.png";
import BMWX1Black from "../../../../images/BMWX1Black.png";
import BMWX2Black from "../../../../images/BMWX2Black.png";
import TeslaModel3 from "../../../../images/TeslaM3.png";

const vehicleImage = (model, color) => {
  if (/^3.*/.test(model)) {
    if (color.toLowerCase() === "white") {
      return BMW3SeriesWhite;
    } else {
      return BMW3SeriesBlack;
    }
  } else if (/^X1/.test(model)) {
    return BMWX1Black;
  } else if (/^X2/.test(model)) {
    return BMWX2Black;
  } else {
    return TeslaModel3;
  }
};

const statusColor = (status) => {
  switch (status) {
    case "ready":
      return "gold";
    case "active":
      return "#319f00";
    case "late":
      return "#d30000";
    case "ended":
      return "#414141";
    default:
      return "#414141";
  }
};

export { vehicleImage, statusColor };
