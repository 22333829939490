import React, { Component } from "react";
import ActiveDashboard from "./ActiveDashboard";
import SuspendedDashboard from "./SuspendedDashboard";
import { USER_DASHBOARD_DATA } from "./queries";
import { useQuery } from "@apollo/client";
import { GeneralTab } from "./Tabs/GeneralTab";
import Loading from "../../Shared/Loading";

const Dashboard = ({ id }) => {
  const { loading, data } = useQuery(USER_DASHBOARD_DATA, {
    variables: { id: id },
  });

  const userData = data && data.userDashboardData;

  const DashboardData = () => {
    switch (userData.accountStatus) {
      case "in_progress":
      case "signed_up":
      case "active":
        return <ActiveDashboard data={userData} />;
      case "suspended":
        return <SuspendedDashboard data={userData} />;
      case "prospect":
        return <GeneralTab data={userData} />;
      default:
        switch (userData.previousStatus) {
          case "in_progress":
          case "signed_up":
          case "active":
            return <ActiveDashboard data={userData} />;
          case "suspended":
            return <SuspendedDashboard data={userData} />;
          case "prospect":
          default:
            return <GeneralTab data={userData} />;
        }
    }
  };

  return <div>{loading ? <Loading /> : DashboardData()}</div>;
};

export default Dashboard;
