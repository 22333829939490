import React from "react";
import styles from "./locationSelect.module.scss";
import classNames from "classnames";
import _ from "lodash";
import ParkingLot from "./DropdownParkingLots";

const City = ({ lots, selectLots, lotsSelected }) => {
  return (
    <div className={styles.cityContainer}>
      {_.map(lots, (element, key) => {
        return (
          <div className={styles.textContainer} key={key}>
            <ParkingLot
              element={element}
              lotsSelected={lotsSelected}
              selectLots={selectLots}
            />
          </div>
        );
      })}
    </div>
  );
};

export default City;
