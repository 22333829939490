import { useQuery } from "@apollo/client";
import React, { useState } from "react";
import { GET_ADMIN_NAME } from "../../Communication/Ticket/TicketPageContent/queries";
import AddComment from "./Tickets/AddComment";
import AddReminder from "./Tickets/AddReminder";
import AddTag from "./Tickets/AddTag";
import AssignAgent from "./Tickets/AssignAgent";
import RecoverTickets from "./Tickets/RecoverTickets";

const TicketActionContainer = ({
  selectedOption,
  selectedTickets,
  close,
  toggleRefresh,
  clearAllSelections,
}) => {
  const { data, loading } = useQuery(GET_ADMIN_NAME);
  return (
    <div>
      {selectedOption.value === "addComment" && (
        <AddComment
          selections={selectedTickets}
          close={close}
          clearAllSelections={clearAllSelections}
        />
      )}
      {selectedOption.value === "addReminder" && (
        <AddReminder
          selections={selectedTickets}
          close={close}
          clearAllSelections={clearAllSelections}
          adminData={data}
        />
      )}
      {selectedOption.value === "addTag" && (
        <AddTag
          selectedOption={selectedOption}
          selections={selectedTickets}
          close={close}
          clearAllSelections={clearAllSelections}
        />
      )}
      {selectedOption.value === "assignToAgent" && (
        <AssignAgent
          selectedOption={selectedOption}
          selections={selectedTickets}
          close={close}
          clearAllSelections={clearAllSelections}
          adminData={data}
        />
      )}
      {selectedOption.value === "reassignAgent" && (
        <AssignAgent
          selectedOption={selectedOption}
          selections={selectedTickets}
          close={close}
          clearAllSelections={clearAllSelections}
          adminData={data}
          reAssign={true}
        />
      )}
      {selectedOption.value === "recoverTickets" && (
        <RecoverTickets
          selectedOption={selectedOption}
          selections={selectedTickets}
          close={close}
          clearAllSelections={clearAllSelections}
        />
      )}
    </div>
  );
};

export default TicketActionContainer;
