import React, { Fragment, useEffect, useState } from "react";

import { ARRANGE_FINANCING, EDIT_FINANCING } from "../../queries";
import { useMutation } from "@apollo/client";
import moment from "moment";
import { equals } from "lodash/fp";
import ErrorModal from "./ErrorModal";
import _ from "lodash";
import { getDefaultValues } from "@apollo/client/utilities";
import ArrangeEditModal from "./ArrangeEditModal";

const Arrange = ({
  open,
  toggleArrange,
  curAction,
  listData,
  invoiceNum,
  total,
  invoiceId,
  financingCallback,
  isEdit,
  refetch,
  closeList,
}) => {
  let presetDates =
    listData &&
    listData
      .sort(
        (a, b) =>
          moment(a.issuanceDate).toDate() - moment(b.issuanceDate).toDate()
      )
      .map((item) => moment(item.dueDate).toDate());
  let attributes =
    listData && listData.map((data) => _.pick(data, "id", "issuanceDate"));
  attributes && attributes.map((data) => (data.installmentId = data.id));
  attributes && attributes.map((data) => delete data.id);

  const installCount = [
    { name: 1, value: 1 },
    { name: 2, value: 2 },
    { name: 3, value: 3 },
    { name: 4, value: 4 },
    { name: 5, value: 5 },
    { name: 6, value: 6 },
    { name: 7, value: 7 },
    { name: 8, value: 8 },
    { name: 9, value: 9 },
    { name: 10, value: 10 },
    { name: 11, value: 11 },
    { name: 12, value: 12 },
  ];
  const [install, setInstall] = useState();
  const [dates, setDates] = useState(listData ? presetDates : []);
  const [isError, setIsError] = useState();
  const [errorMsg, setErrorMsg] = useState();
  const [installAttributes, setAttributes] = useState(attributes);

  const onInstallChange = (e) => {
    setInstall(e);
  };

  const clearInstall = () => {
    setInstall(undefined);
    setDates([]);
  };

  const toggleErrorModal = () => {
    setIsError(!isError);
  };

  const updateAttributes = (index, date) => {
    const newArr = installAttributes;
    newArr[index].issuanceDate = date;
    setAttributes(newArr);
  };

  const datesCallback = (childData, childIdx, presetInstall) => {
    if (listData) {
      if (childIdx) {
        let newArr = [...dates];
        newArr[childIdx - 1] = childData;
        setDates(newArr);
        updateAttributes(childIdx - 1, childData);
      }
    } else if (childData !== undefined) {
      let newArr = [...dates];
      if (childIdx === undefined) {
        setDates(presetInstall);
      } else {
        newArr[childIdx - 1] = childData;
        setDates(newArr);
      }
    }
  };

  const [arrangeInvoiceMutation] = useMutation(ARRANGE_FINANCING);
  const arrangeFinancing = () => {
    arrangeInvoiceMutation({
      variables: {
        invoiceId: invoiceId,
        financingDates: dates,
      },
    }).then(({ data: { arrangeInvoiceFinancing } }) => {
      if (arrangeInvoiceFinancing.success) {
        toggleArrange();
        // closeList();
        // window.location.reload();
        refetch();
        console.log("arrange financing succeed!");
      } else {
        toggleErrorModal();
        setErrorMsg(arrangeInvoiceFinancing.error);
        console.log("arrange financing error!");
      }
    });
  };

  const [editInvoiceMutation] = useMutation(EDIT_FINANCING);
  const editFinancing = () => {
    editInvoiceMutation({
      variables: {
        financingInstallments: installAttributes,
      },
    }).then(({ data: { editInvoiceFinancing } }) => {
      if (editInvoiceFinancing.success) {
        toggleArrange();
        // closeList();
        // window.location.reload();
        refetch();
        console.log("edit financing succeed!");
      } else {
        toggleErrorModal();
        setErrorMsg(editInvoiceFinancing.error);
        console.log("edit financing error!");
      }
    });
  };

  const toSave = listData ? !equals(presetDates, dates) : true;
  console.log(dates);
  return (
    <Fragment>
      <ArrangeEditModal
        open={open}
        invoiceNum={invoiceNum}
        invoiceId={invoiceId}
        total={total}
        listData={listData}
        installCount={installCount}
        install={install}
        onInstallChange={onInstallChange}
        datesCallback={datesCallback}
        toggleArrange={toggleArrange}
        clearInstall={clearInstall}
        toSave={toSave}
        editFinancing={editFinancing}
        isEdit={isEdit}
        arrangeFinancing={arrangeFinancing}
        financingCallback={financingCallback}
        refetch={refetch}
        closeList={closeList}
      />

      <ErrorModal
        open={isError}
        errorMsg={errorMsg}
        toggleErrorModal={toggleErrorModal}
      />
    </Fragment>
  );
};

export default Arrange;
