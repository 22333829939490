import React, { useState } from "react";
import Search from "./Search";
import AvailableVehicles from "./AvailableVehicles";
import styles from "../../bookingActions.module.scss";
import { AVAILABLE_VEHICLES_CHARGE_LEVELS } from "../../../../queries";
import { useQuery } from "@apollo/client";

const VehicleList = ({
  vehicles,
  selectNewCar,
  newCar,
  setChoosing,
  booking,
}) => {
  const [searchValue, setSearchValue] = useState("");

  const filterVehicles = () => {
    if (searchValue === "") return vehicles;

    const searchValueLowerCase = searchValue.toLowerCase();
    return vehicles.filter(
      (v) =>
        v.plate.toLowerCase().includes(searchValueLowerCase) &&
        v.model.toLowerCase().includes(searchValueLowerCase)
    );
  };

  const { loading, data } = useQuery(AVAILABLE_VEHICLES_CHARGE_LEVELS, {
    variables: {
      bookingId: booking.id,
    },
  });

  return (
    <div className={styles.vehicleList}>
      <div className={styles.title}>Choose another vehicle</div>
      <Search setSearchValue={setSearchValue} searchValue={searchValue} />
      <AvailableVehicles
        selectNewCar={selectNewCar}
        newCar={newCar}
        vehicles={filterVehicles()}
        chargeLevelList={data?.availableVehiclesChargeLevels}
      />
    </div>
  );
};

export default VehicleList;
