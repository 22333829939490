import UsersBlack from "../../../../assets/icons/UsersBlack.svg";
import { renderColor, titleize } from "../../../../utils";
import styles from "../userDashboard.module.scss";
import moment from "moment-timezone";
import StatusMenu from "./Dropdown/StatusMenu";

import {
  Removed,
  dateXDaysAgo,
  CellBlock,
  Inactive,
  formatString,
} from "../utils";
import { Link } from "react-router-dom";

const GeneralTab = ({ data }) => {
  const timeZone = data.timeZone;

  const timeInStatus =
    data.timeInStatus != null
      ? `${data.timeInStatus} Day${data.timeInStatus === 1 ? "" : "s"}`
      : "Unknown";

  const dateOfStatusChange =
    data.timeInStatus != null ? dateXDaysAgo(data.timeInStatus) : "";

  const dateOfAppDownload = data.dateOfAppDownload
    ? moment(data.dateOfAppDownload).tz(timeZone).format("MMM DD, YYYY")
    : "Unknown";

  const previousStatus = data.previousStatus
    ? titleize(data.previousStatus)
    : "Unknown";

  const lastSignInAt =
    data.lastSignInAt != null
        ? moment(data.lastSignInAt).tz(timeZone).format("MMM DD, YYYY")
        : "Unknown";

  const ChangedBy = `${titleize(data.accountStatus)} by`;

  return (
    <div className={styles.categoryTab}>
      <div className={styles.tabHeader}>
        <div className={styles.title}>
          <img src={UsersBlack} />
          General
        </div>
        <StatusMenu userData={data} />
      </div>
      <div className={styles.tabContent}>
        <CellBlock subcontent="Current status">
          <div
            className={renderColor("Account Status", data.accountStatus)}
            style={{ fontWeight: "bold" }}
          >
            {formatString(data.accountStatus)}
          </div>
        </CellBlock>
        <CellBlock subcontent="Time in status">
          {timeInStatus}
          <div className={styles.subscript} style={{ opacity: "0.5" }}>
            {dateOfStatusChange}
          </div>
        </CellBlock>
        <CellBlock subcontent="Account created on">
          {moment(data.createdAt).tz(timeZone).format("MMM DD, YYYY")}
        </CellBlock>
        {data.accountStatus !== "prospect" ||
        (Removed(data.accountStatus) && data.previousStatus !== "prospect") ? (
          <CellBlock subcontent="App version">
            {data.deviceOs || "-"} {data.appVersion || "-"}
          </CellBlock>
        ) : (
          ""
        )}
        {data.accountStatus !== "prospect" ||
        (Removed(data.accountStatus) && data.previousStatus !== "prospect") ? (
          <CellBlock subcontent="Date of app download">
            {dateOfAppDownload}
          </CellBlock>
        ) : (
          ""
        )}
        {data.accountStatus !== "prospect" ||
        (Removed(data.accountStatus) && data.previousStatus !== "prospect") ? (
          <CellBlock subcontent="Last log in">
            {lastSignInAt}
          </CellBlock>
        ) : (
          ""
        )}
        <CellBlock subcontent="Number of open tickets">
          <Link
            to={{ pathname: `/users/${data.id}/communication` }}
            style={{
              textDecoration: "none",
              color: "#128dff",
              fontWeight: "bold",
            }}
          >
            {data.openTicketsCount}/{data.totalTicketsCount}
          </Link>
        </CellBlock>
        <CellBlock subcontent="Pinned comments">
          <Link
            to={{
              pathname: `/users/${data.id}/details`,
              state: { activeTab: "comments" },
            }}
            style={{
              textDecoration: "none",
              color: "#128dff",
              fontWeight: "bold",
            }}
          >
            {data.pinnedCommentsCount}
          </Link>
        </CellBlock>
        {Inactive(data.accountStatus) ||
          (Removed(data.accountStatus) && Inactive(data.previousStatus) && (
            <CellBlock subcontent="Outstanding requirements">
              {data.outstandingRequirements.length}
            </CellBlock>
          ))}
        {Removed(data.accountStatus) && (
          <CellBlock subcontent="Previous status">{previousStatus}</CellBlock>
        )}
        {Removed(data.accountStatus) ? (
          <CellBlock subcontent={ChangedBy}>
            {data.accountStatusChangedBy
              ? data.accountStatusChangedBy
              : "Unknown"}
          </CellBlock>
        ) : (
          ""
        )}
        {data.accountStatus === "suspended" ||
          (Removed(data.accountStatus) &&
            data.previousStatus === "suspended" && (
              <CellBlock subcontent="Reason for suspension">
                {data.outstandingRequirements.map((source) => (
                  <div className={styles.suspensionReason}>
                    {formatString(source)}
                  </div>
                ))}
              </CellBlock>
            ))}
      </div>
    </div>
  );
};

export { GeneralTab };
