import React from "react";
import Dropdown from "../../../../InformationTable/ActionBar/Filters/OptionsModal/Body/Filter/Dropdown";

const TicketBodyHeader = ({
  ticketData,
  isEdit,
  curCategory,
  categories,
  changeCategory,
  curSubject,
  setCurSubject,
  changeSubject,
  curOptions,
  chosenAgentName,
  defaultAdmin,
  toggleAdmin,
}) => {
  return (
    <div className="assign-container">
      <div className="assign-agent">
        Category
        {ticketData && !isEdit ? (
          <div style={{ fontSize: "18px", width: "max-content" }}>
            {ticketData.category}
          </div>
        ) : (
          <Dropdown
            initialValue={
              curCategory
                ? curCategory
                : ticketData
                ? ticketData.category
                : null
            }
            items={categories}
            onChange={changeCategory}
          />
        )}
      </div>
      <div className="assign-agent">
        Subject
        {ticketData && !isEdit ? (
          <div style={{ fontSize: "18px", width: "max-content" }}>
            {ticketData.subject}
          </div>
        ) : curCategory !== "Other" ? (
          <Dropdown
            initialValue={
              curSubject ? curSubject : ticketData ? ticketData.subject : null
            }
            items={curOptions}
            onChange={changeSubject}
            disabled={!curCategory}
          />
        ) : (
          <div className="subject-input">
            Subject
            <input
              className="subject-input-style"
              onChange={(e) => setCurSubject(e.target.value)}
            ></input>
          </div>
        )}
      </div>
      <div className="assign-agent">
        Assigned to
        <div className="assigned-agent">
          {ticketData
            ? isEdit
              ? chosenAgentName
                ? chosenAgentName
                : ticketData.adminUser && ticketData.adminUser.fullName
              : ticketData.adminUser && ticketData.adminUser.fullName
            : chosenAgentName
            ? chosenAgentName
            : defaultAdmin.fullName}
        </div>
        {(!ticketData || isEdit) && (
          <div className="change-agent-button" onClick={toggleAdmin}>
            Change agent
          </div>
        )}
      </div>
    </div>
  );
};

export default TicketBodyHeader;
