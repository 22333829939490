import React from "react";
import Modal from "../../../../artzu-ui/src/Modal";
import styles from "./selections.module.scss";
import ButtonGroup from "../../../Shared/ButtonGroup";
import Button from "../../../Shared/Button";
import classNames from "classnames";
import Loading from "../../../Shared/Loading";

const ConfirmModal = ({
  isOpen,
  closeModal,
  confirmChanges,
  text,
  error,
  loading,
}) => {
  const rightButtonText = error ? null : "Yes";
  const leftButtonText = error ? "Okay" : "No";

  let numberOfButtons = 0;

  if (rightButtonText) {
    numberOfButtons++;
  }

  if (leftButtonText) {
    numberOfButtons++;
  }

  return (
    <Modal padding="none" backgroundColor="none" open={isOpen}>
      <div className={styles.confirmModal}>
        {loading ? (
          <Loading />
        ) : (
          <>
            <div className={styles.text}> {error || text}</div>
            <div
              className={classNames(
                styles.confirmFooter,
                numberOfButtons > 1 ? styles.twoButtons : styles.oneButton
              )}
            >
              <ButtonGroup
                leftButtonFunction={closeModal}
                rightButtonFunction={confirmChanges}
                rightButtonText={rightButtonText}
                leftButtonText={leftButtonText}
              />
            </div>
          </>
        )}
      </div>
    </Modal>
  );
};

export default ConfirmModal;
