import React from "react";
import styles from "../../../../InformationTable/ActionBar/MiscSettings/Menu/menu.module.scss";
import classesNames from "classnames";

const OptionMenu = ({
  isOpen,
  editTicket,
  deleteTicket,
  isEdit,
  cancelEdit,
  fleetReview,
  setCurAction,
  backlogTicket,
  inProgressTicket,
  ticketData,
}) => {
  const ticketOptions = [
    ticketData &&
      ticketData.status === "in_progress" && {
        onClick: backlogTicket,
        name: "Change to Backlog",
      },
    ticketData &&
      ticketData.status === "backlog" && {
        onClick: inProgressTicket,
        name: "Change to In Progress",
      },
    {
      onClick: editTicket,
      name: "Edit",
    },
    {
      onClick: deleteTicket,
      name: "Delete",
    },
    ticketData &&
      (ticketData.category === "Vehicle Issue" ||
        ticketData.category === "Accident Report") &&
      ticketData.booking && {
        onClick: fleetReview,
        name: "Fleet review",
      },
  ];

  const inEditModeOption = [
    {
      onClick: cancelEdit,
      name: "Cancel",
    },
  ];

  const options = isEdit ? inEditModeOption : ticketOptions;

  return (
    <div
      className={classesNames(
        styles.absoluteContainer,
        !isOpen && styles.hidden
      )}
    >
      <div className={styles.menuUp}>
        {options.map((option) => (
          <div
            onClick={() => {
              option.onClick();
              option && setCurAction(option.name);
            }}
            className={styles.menuOption}
          >
            {option && option.name}
          </div>
        ))}
      </div>
    </div>
  );
};

export default OptionMenu;
