import React, { Component } from "react";
import { m } from "../../utils";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { faCalendar } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class SimpleDateInput extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { date, onClick, value, classes } = this.props;
    return (
      <div className={classes.dateInput} onClick={onClick}>
        {date ? `${m(date).format("ddd MMM D, YYYY")}` : "Date"}
        <FontAwesomeIcon icon={faCalendar} style={{ marginLeft: "1em" }} size="sm" />
      </div>
    );
  }
}

SimpleDateInput.propTypes = {
  onClick: PropTypes.func,
  value: PropTypes.string,
};

const style = (theme) => ({
  dateInput: {
    color: "#414141",
    width: "max-content",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    borderRadius: "12px",
    border: "1px solid #DEDEDE",
    alignItems: "center",
    padding: "0 12px 0 12px",
    height: "22px",
    backgroundColor: "#FFFFFF",
    cursor: "pointer"
  },
});

export default withStyles(style)(SimpleDateInput);