import React from 'react'

const SvgGreenCheck = ({ color = '#dedede', ...props }) => (
  <svg
    id="green-check_svg__Layer_1"
    data-name="Layer 1"
    viewBox="0 0 120.37 120.37"
    {...props}>
    <defs>
      <style>
        {`.green-check_svg__cls-1{fill:none;stroke:#dedede;stroke-linecap:round;stroke-linejoin:round;stroke-width:7px}`}
      </style>
    </defs>
    <path
      className="green-check_svg__cls-1"
      d="M88 43.17L53.96 77.2h-.05L32.37 55.67"
    />
    <circle
      className="green-check_svg__cls-1"
      cx={60.19}
      cy={60.19}
      r={56.69}
    />
  </svg>
)

export default SvgGreenCheck
