import Modal from "../../../../../artzu-ui/src/Modal"
import styles from "../../userDashboard.module.scss"
import { titleize } from "../../../../../utils"
import { DELETE_USER, REOPEN_USER, CLOSE_USER, UPDATE_USER} from './mutations';
import { useMutation } from "@apollo/client";

const StatusChangePopup = ({userData,open,changeMessage,togglePopup}) => {
    
    const changeFunc = (message) => {
        switch(message){
            case "close":
                closeUser();
                break;
            case "re-open":
            case "recover":
                reopenUser();
                break;
            case "blacklist":
                updateUser("blacklisted");
                break;
            case "delete":
                deleteUser();
                break;
            case "un-blacklist":
                updateUser(userData.previousStatus) //might need to change
                break;
            default:
                // console.log(`${changeMessage} invalid option!`);
        }
    }

    const [deleteUserMutation, { loading: isDeleteLoading }] = useMutation(
        DELETE_USER
    );

    const [closeUserMutation, { loading: isCloseLoading }] = useMutation(
        CLOSE_USER
    );

    const [reopenUserMutation, { loading: isReopenLoading }] = useMutation(
        REOPEN_USER
    );

    const [updateUserMutation, { loading: isUpdateLoading }] = useMutation(
        UPDATE_USER
    );

    const deleteUser = () => {
        deleteUserMutation({
            variables: {
                userId: userData.id,
            },
        }).then(({ data: { deleteUser } }) => {
            if (deleteUser && deleteUser.success) {
                // console.log("Delete User Succeed!");
            } else {
                // console.log("Delete User error!");
            }
            window.location.reload(false);
        }).catch(error => {
            console.error("An error occurred:", error);
        });
    };

    const closeUser = () => {
        closeUserMutation({
            variables: {
                userId: userData.id,
            },
        }).then(({ data: { closeUser } }) => {
            if (closeUser && closeUser.user) {
                // console.log("Close User Succeed!");
                
            } else {
                // console.log("Close User error!");
            }
            window.location.reload(false);
        }).catch(error => {
            console.error("An error occurred:", error);
        });
    };

    const reopenUser = () => {
        reopenUserMutation({
            variables: {
                userId: userData.id,
            },
        }).then(({ data: { reopenUser } }) => {
            if (reopenUser && reopenUser.user) {
                // console.log("Re-open User Succeed!");
            } else {
                // console.log("Re-open User error!");
            }
            window.location.reload(false);
        }).catch(error => {
            console.error("An error occurred:", error);
        });
    };

    const updateUser = (status) => {
        updateUserMutation({
            variables: {
                userFields :{
                    accountStatus: status
                },
                userId: userData.id,
            },
        }).then(({ data: { updateUser } }) => {
            if (updateUser && updateUser.user) {
                // console.log("Update User Succeed!");
            } else {
                // console.log("Update User error!");
            }
            window.location.reload(false);
        }).catch(error => {
            console.error("An error occurred:", error);
        });
    };

    return (
        <Modal open={open} backgroundColor="none">
            <div className={styles.popupModalContainer}>
                <div className={styles.popupHead}>{titleize(changeMessage)} user</div>
                <div>
                    <div className={styles.popupMessage}>
                        Are you sure you want to {changeMessage} this user?
                    </div>
                </div>
                <div className={styles.buttonsFooter}>
                    <div className={styles.cancelButton}
                        onClick={togglePopup}
                    >No</div>
                    <div className={styles.confirmButton}
                        onClick={() => {
                            changeFunc(changeMessage);
                            togglePopup();
                        }}
                    >Yes</div>
                </div>
            </div>
        </Modal>
    )
}

export default StatusChangePopup;