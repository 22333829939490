import { gql } from "@apollo/client";

const bookingDetailsString = `
  query BookingDetails($id: ID!) {
    bookingDetails(id: $id) {
      id
      status
      startTime
      endTime
      vehicleClass
      pickup
      dropoff
      createdAt
      rate
      bookingFee
      additionalFees
      insuranceDeductibleReductionSchedules {
        startTime
        endTime
      }
      driver {
        id
      }
      car {
        plate
        id
        uuid
      }
      parkinglot {
        region
      }
      timeline {
        event
        eventType
        eventId
        actorType
        actorId
        actorName
        actorIcon
        comment
        caption1
        caption2
        caption3
        colorCode
        timestamp
      }
    }
  }
`;
const prebookingInspectionString = `
  query getPrebookingInspection($prebookingInspectionId:ID!){
    getPrebookingInspection(prebookingInspectionId: $prebookingInspectionId){
      id
      safeToDrive
      safetyImage1
      safetyImage2
      safetyImage3
      safetyImage4
      safetyImage5
      safetyImage6
      cleanlinessImage1
      cleanlinessImage2
      cleanlinessImage3
      cleanlinessImage4
      cleanlinessImage5
      cleanlinessImage6
      frontCenter
      frontLeft
      frontRight
      rearCenter
      rearLeft
      rearRight
      reasonForFailure
      completed
      passed
      issueDescription
      cleanliness
      status
      currentStep
      cleanlinessComment
      car {
        plate
      }
    }
  }
    `;

const carChangeInspectionString = `
query ($swapId:ID!){
  getVehicleSwapInformation(swapId: $swapId){
    booking {
      id
    }
    firstCar {
      id
      plate
      imageLink
      uuid
      fleetProfileUrl
    }
    secondCar {
      id
      plate
      imageLink
      uuid
      fleetProfileUrl
    }
    reasonForSwap
    comment
  }
}

`;

const availableVehiclesString = `
  query AvailableVehicles($bookingId: ID!) {
    availableVehicles(bookingId: $bookingId) {
      id
      plate
      make
      model
      color
      parkingSpot
      imageLink
    }
  }
`;

const userCommentsString = `
  query UserComments($id: ID!) {
    userProfileData(id: $id) {
      comments {
        id
        resourceType
        resourceId
        authorType
        authorId
        body
        pinned
        updatedAt
        namespace
        author {
          fullName
          email
          profileImage
        }
      }
      documentComments {
        resourceType
        resourceId
        authorType
        authorId
        body
        pinned
        updatedAt
        namespace
        author {
          fullName
          email
          profileImage
        }
      }
      
    }
  }
`;

const informationPageString = `
  query informationPages(
    $filters: [[InformationPageFilterAttributes!]!]
    $columnNames: [String!]
    $tableName: String!
    $paginationArguments: PaginationAttributes
    $sortArguments: SortAttributes
    $searchValue: String
    $selectedTab: String
    $parkingLotNames: [String!]
    $idToFilter: ID
  ) {
    getInformationPage(
      filterGroups: $filters
      columnNames: $columnNames
      tableName: $tableName
      paginationArguments: $paginationArguments
      sortArguments: $sortArguments
      searchValue: $searchValue
      selectedTab: $selectedTab
      parkingLotNames: $parkingLotNames
      idToFilter: $idToFilter
    ) {
      table {
        totalRowCount
        rows {
          rowData {
            columnName
            columnData {
              value
              dataName
              dataType
            }
          }
          rowId
        }
        headers {
          name
          displayedName
        }
      }
      filters {
        available {
          categoryName
          categoryUuid
          dataType
          operators {
            displayedName
            uuid
            valueOptions {
              value
              name
            }
            numberOfInputValues
          }
        }
        savedGroups {
          uuid
          filters {
            categoryUuid
            uuid
            values
            isExcluded
          }
        }
        parkingLotNames
      }
      columns {
        available {
          name
          displayedName
        }
        presets {
          name
          columnNames
          uuid
        }
        current {
          name
          displayedName
        }
      }
      sort {
        column
        direction
      }
      tabs {
        currentTab
        currentTabKeys {
          id
          identifier
        }
        counts {
          tabName
          count
        }
      }
    }
  }
`;

const userFiltersString = `
  query UserFilters {
    userFilters {
      uuid
      name
      displayName
      command
      operators
      valueType
      valueOptions {
        label
        value
      }
    }
    statusTabFilter {
      uuid
      name
      displayName
      command
      operators
      valueType
      valueOptions {
        label
        value
        count
      }
    }
    userColumns {
      uuid
      name
      displayName
      command
      valueType
    }
    userTags {
      name
      taggingsCount
    }
    currentAdminUser {
      userColumns
      userPreset {
        id
        name
        columns
      }
    }
  }
`;

const userPresetsString = `
  query UserPresets {
    userPresets {
      id
      name
      columns
      changed
      tableName
    }
  }
`;

const mutualTagsString = `
  query MutualTags($ids: [ID!]!) {
    mutualTags(ids: $ids) 
  }
`;

const userRideshareString = `
  query UserRideshare($id: ID!) {
    userProfileData(id: $id) {
      id
      email
      firstName
      lastName
      operatingCity
      driversLicenseNumber
      status
      accountStatus
      dateOfBirth
      mobile
      hasUberOauth
      parkingLot {
        region
      }
      addresses {
        streetAddress
        postalCode
        province
        city
        updatedAt
      }
      driversLicenseData {
        addressLine1
        addressLine2
        city
        dateOfBirth
        country
        eyeColour
        height
        id
        firstName
        lastName
        licenseClass
        licenseNumber
        postalCode
        province
        sex
        refNumber
        endorsements
        commercialVehicleCodes
      }
      ridesharePlatforms {
        id
        impliedStatus
        rideshareUser{
          givenCountry
          givenCity
          givenState
          givenFirstName
          givenLastName
          givenMobile
          givenEmail
          givenAddress
          givenZipcode
        }
        hasValidCookies
        accountType
        accountRegion
        connectedOn
        lastConnectedAt
        lastCheckedAt
        name
        thirdPartyStatus
      }
    }
  }
`;

const PIN_USER_COMMENT = gql`
  mutation pinComment($id: ID!, $pinned: Boolean!) {
    pinComment(id: $id, pinned: $pinned) {
      comment {
        id
        pinned
        body
      }
      error
    }
  }
`;
const EDIT_USER_COMMENT = gql`
  mutation editComment($id: ID!, $body: String!) {
    editComment(id: $id, body: $body) {
      comment {
        id
        pinned
        body
      }
      error
    }
  }
`;
const DELETE_USER_COMMENT = gql`
  mutation deleteComment($id: ID!) {
    deleteComment(id: $id) {
      success
      error
    }
  }
`;
const REMIND_USER_COMMENT = gql`
  mutation remindComment($id: ID!, $timestamp: DateTime!) {
    remindComment(id: $id, timestamp: $timestamp) {
      comment {
        id
        pinned
        body
      }
      error
    }
  }
`;

export {
  bookingDetailsString,
  availableVehiclesString,
  userCommentsString,
  userFiltersString,
  userPresetsString,
  mutualTagsString,
  userRideshareString,
  prebookingInspectionString,
  carChangeInspectionString,
  informationPageString,
  PIN_USER_COMMENT,
  EDIT_USER_COMMENT,
  DELETE_USER_COMMENT,
  REMIND_USER_COMMENT,
};
