import VehicleList from "./VehicleList";
import VehiclePreview from "./VehiclePreview";
import React from "react";

const VehicleSelection = ({
  currentCar,
  newCar,
  selectNewCar,
  availableVehicles,
  openVehicleList,
  isChoosingVehicle,
  booking,
}) => (
  <>
    {isChoosingVehicle ? (
      <VehicleList
        vehicles={availableVehicles}
        selectNewCar={selectNewCar}
        newCar={newCar}
        booking={booking}
      />
    ) : (
      <VehiclePreview
        currentCar={currentCar}
        newCar={newCar}
        openVehicleList={openVehicleList}
      />
    )}
  </>
);

export default VehicleSelection;
