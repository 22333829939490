import React, { Fragment } from "react";
import { TableCell, TableRow } from "../../../Shared/Table";
import { titleize } from "../../../../utils";
import moment from "moment-timezone";

const OnboardingData = ({ onboardingData, timeZone }) => {
  if (!onboardingData) {
    return <div>No events found</div>;
  }
  return (
    <Fragment>
      <div style={{ backgroundColor: "white", padding: "0 32px" }}>
        <TableRow
          align="center"
          padding="32px 0 16px"
          borderB="1px solid #414141"
        >
          <TableCell width="20%" bold>
            Onboarding time
          </TableCell>
          <TableCell width="20%" bold>
            Time in status
          </TableCell>
          <TableCell width="20%" bold>
            Status
          </TableCell>
          <TableCell width="20%" bold>
            Created at
          </TableCell>
          <TableCell width="20%" bold>
            Updated at
          </TableCell>
        </TableRow>
        {onboardingData.map((log, index) => {
          return (
            <TableRow borderT="1px solid #dedede" padding="16px 0" key={index}>
              <TableCell width="20%">{log.onboardingTime}</TableCell>
              <TableCell width="20%">{log.timeInStatus}</TableCell>
              <TableCell width="20%">{titleize(log.status)}</TableCell>
              <TableCell width="20%">
                {moment(log.createdAt)
                  .tz(timeZone)
                  .format("MMM D, YYYY h:mm:ss A z")}
              </TableCell>
              <TableCell width="20%">
                {moment(log.updatedAt)
                  .tz(timeZone)
                  .format("MMM D, YYYY h:mm:ss A z")}
              </TableCell>
            </TableRow>
          );
        })}
      </div>
    </Fragment>
  );
};

export default OnboardingData;
