import React, { useState } from "react";
import styles from "../bookingActions.module.scss";
import EndBookingPrompt from "./EndBookingPrompt";

import { useMutation } from "@apollo/client";
import { END_BOOKING } from "../../../queries";
import Loading from "../../../../../Shared/Loading";

const EndBooking = ({ booking, closeEditBooking }) => {
  const [endBooking, { loading: isEndBookingLoading }] =
    useMutation(END_BOOKING);

  const [comment, setComment] = useState();

  const handleEndBooking = () => {
    endBooking({
      variables: {
        bookingId: booking.id,
        comment,
      },
    }).then(() => {
      closeEditBooking();
    });
  };

  return (
    <div className={styles.actionContainer}>
      {isEndBookingLoading ? (
        <Loading />
      ) : (
        <EndBookingPrompt
          handleEndBooking={handleEndBooking}
          closeEditBooking={closeEditBooking}
          setComment={setComment}
          comment={comment}
        />
      )}
    </div>
  );
};

export default EndBooking;
