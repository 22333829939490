import React from "react";
import styles from "./menu.module.scss";
import classesNames from "classnames";

const Menu = ({ isOpen, startImport, handleType, tableName, startExport }) => {
  const InvoiceOptions = [
    {
      onClick: startImport,
      name: "Import Invoices",
      type: "invoices",
    },
    {
      onClick: startImport,
      name: "Import 407 Tolls",
      type: "407Tolls",
    },
    {
      onClick: startExport,
      name: "Export Table to CSV",
    },
  ];

  const regularOption = [
    {
      onClick: startExport,
      name: "Export Table to CSV",
    },
  ];

  const options = tableName === "invoices" ? InvoiceOptions : regularOption;

  return (
    <div
      className={classesNames(
        styles.absoluteContainer,
        !isOpen && styles.hidden
      )}
    >
      <div className={styles.menu}>
        {options.map((option) => (
          <div
            onClick={() => {
              option.onClick();
              handleType(option.type);
            }}
            className={styles.menuOption}
          >
            {option.name}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Menu;
