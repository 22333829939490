import { BookingsTab } from "./Tabs/BookingsTab";
import { PaymentsTab } from "./Tabs/PaymentsTab";
import { IDVerificationTab } from "./Tabs/IDVerificationTab";
import { RideshareTab } from "./Tabs/RideShareTab";
import { GeneralTab } from "./Tabs/GeneralTab";



const SuspendedDashboard = ({data}) => {

    return (
        <div>
            <GeneralTab
                data={data}
            />

            {data.outstandingRequirements.includes("verify_identity") ||
            data.outstandingRequirements.includes("drivers_license") ||
            data.outstandingRequirements.includes("profile_image")? 
                <IDVerificationTab
                    data={data}
                />:""
            }

            {data.outstandingRequirements.includes("rideshare_account")? 
                <RideshareTab
                    data={data}
                />:""
            }

            <PaymentsTab
                data={data}
            />
            
            <BookingsTab
                data={data}
            />


        </div>
    )
}

export default SuspendedDashboard;