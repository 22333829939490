import React, { Fragment, useState } from 'react'

const BookingComments = ({ booking }) => {
  const car = booking.car
  return (
    <Fragment>
    </Fragment>
  )
}

export default BookingComments