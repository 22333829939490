import React from "react";
import Details from "./Details";
import Status from "./Status";

const RideshareBody = ({ user, name }) => {
  const rideshareData = user.ridesharePlatforms;
  const rideshareUber = rideshareData.filter((entry) => entry.name === "uber");
  const rideshareLyft = rideshareData.filter((entry) => entry.name === "lyft");
  const rideshareContent = name === "uber" ? rideshareUber : rideshareLyft;
  return (
    <div className="user-page__body">
      <Status user={user} rideshareData={rideshareContent} />
      <Details user={user} rideshareData={rideshareContent} />
    </div>
  );
};

export default RideshareBody;
