import React from "react";
import Modal from "../../../artzu-ui/src/Modal";
import styles from "../../Payments/Invoices/ActionPopup/popup.module.scss";

const FeedbackModal = ({ open, batchAction, toggleFeedbackModal }) => {
  return (
    <Modal open={open} backgroundColor="none">
      <div className={styles.errorModal}>
        <div className={styles.text} style={{ marginBottom: "20px" }}>
          <span>
            We have sent the{" "}
            <span style={{ fontWeight: 600 }}>{batchAction}</span> batch action
            to Server, you will receive the confirmation email shortly.
          </span>
        </div>
        <div className={styles.errorCancel}>
          <button
            className={styles.errorCancelButton}
            onClick={() => {
              toggleFeedbackModal();
            }}
          >
            Okay
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default FeedbackModal;
