import React, { Fragment, useState } from "react";
import { faPlus } from "@fortawesome/pro-regular-svg-icons";
import styles from "./createButton.module.scss";
// import Options from "./Options";
import ToggleButton from "../../../Shared/ToggleButton";
import CreateInvoiceModal from "../../../Payments/Invoices/CreateInvoiceModal";

const CreateButton = () => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleOpenState = () => setIsOpen(!isOpen);
  const closeOptions = () => setIsOpen(false);

  return (
    <Fragment>
      <div className={styles.createButton}>
        <ToggleButton
          toggleOpenState={toggleOpenState}
          isOpen={isOpen}
          faIcon={faPlus}
        />
      </div>
      <CreateInvoiceModal open={isOpen} toggleCreate={toggleOpenState} />
    </Fragment>
  );
};

export default CreateButton;
