import { useMutation } from "@apollo/client";
import React from "react";
import { useState } from "react";
import Modal from "../../../../../artzu-ui/src/Modal";
import {
  DELETE_TICKET,
  RECOVER_TICKET,
  REOPEN_TICKET,
  SEND_TO_FLEET,
} from "../queries";
import { faSpinner } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const TicketActionPopup = ({
  open,
  action,
  togglePopup,
  refreshTicketCallback,
  ticketData,
}) => {
  const [reason, setReason] = useState();

  const changeReason = (e) => {
    setReason(e.target.value);
  };

  const getAction = (action) => {
    switch (action) {
      case "Re-open ticket":
        reopenTicket();
        break;
      case "Recover ticket":
        recoverTicket();
        break;
      case "Delete ticket":
        deleteTicket();
        break;
      case "Fleet review":
        fleetReview();
        break;
      default:
        break;
    }
  };
  const [reopenTicketMutation, { loading: isReopenLoading }] =
    useMutation(REOPEN_TICKET);
  const reopenTicket = () => {
    reopenTicketMutation({
      variables: {
        ticketId: ticketData && ticketData.id,
        reason: reason,
      },
    }).then(({ data: { reopenSupportTicket } }) => {
      if (reopenSupportTicket.ticket) {
        refreshTicketCallback({ ticketId: reopenSupportTicket.ticket.id });
        togglePopup();
        console.log("Reopen Succeed!");
      } else {
        console.log("Reopen error!");
      }
    });
  };

  const [recoverTicketMutation, { loading: isRecoverLoading }] =
    useMutation(RECOVER_TICKET);
  const recoverTicket = () => {
    recoverTicketMutation({
      variables: {
        ticketId: ticketData && ticketData.id,
        reason: reason,
      },
    }).then(({ data: { recoverSupportTicket } }) => {
      if (recoverSupportTicket.success) {
        refreshTicketCallback({ ticketId: ticketData.id });
        togglePopup();
        console.log("Recover Succeed!");
      } else {
        console.log("Recover error!");
      }
    });
  };

  const [deleteTicketMutation, { loading: isDeleteLoading }] =
    useMutation(DELETE_TICKET);
  const deleteTicket = () => {
    deleteTicketMutation({
      variables: {
        ticketId: ticketData && ticketData.id,
        reason: reason,
      },
    }).then(({ data: { deleteSupportTicket } }) => {
      if (deleteSupportTicket.success) {
        refreshTicketCallback({ ticketId: ticketData.id });
        togglePopup();
        console.log("Delete Succeed!");
      } else {
        console.log("Delete error!");
      }
    });
  };

  const [sendToFleetMutation, { loading: isSendLoading }] =
    useMutation(SEND_TO_FLEET);
  const fleetReview = (requestId) => {
    sendToFleetMutation({
      variables: {
        ticketId: ticketData && ticketData.id,
        plate: ticketData && ticketData.booking && ticketData.booking.car.plate,
        subject: ticketData && ticketData.subject,
        note: reason,
      },
    }).then(({ data: { sendToFleet } }) => {
      if (sendToFleet.success) {
        refreshTicketCallback({ ticketId: ticketData.id });
        togglePopup();
        console.log("Send to Fleet Succeed!");
      } else {
        console.log("Send to FLeet error!");
      }
    });
  };

  const isLoading =
    isDeleteLoading || isRecoverLoading || isReopenLoading || isSendLoading;

  return (
    <Modal open={open} backgroundColor="none">
      <div className="popup-container">
        <div className="relate-user-title">{action}</div>
        <div className="reason-input">
          Reason to {action && action.toLowerCase()} (Internal note)
          <textarea className="reason-box" onChange={changeReason}></textarea>
        </div>
        <div className="reason-buttons">
          <div>
            <button
              className="booking-cancel-button"
              onClick={() => {
                togglePopup();
              }}
            >
              Cancel
            </button>
          </div>
          <div>
            <button
              className={
                reason
                  ? "booking-choose-button"
                  : "booking-choose-button-invalid"
              }
              onClick={() => {
                !isLoading && reason && getAction(action);
              }}
            >
              {isLoading ? (
                <FontAwesomeIcon
                  style={{ color: "#128dff" }}
                  spin
                  size={"1x"}
                  icon={faSpinner}
                />
              ) : action === "Re-open ticket" ? (
                "Re-open"
              ) : action === "Recover ticket" ? (
                "Recover"
              ) : action === "Delete ticket" ? (
                "Delete"
              ) : action === "Fleet review" ? (
                "Send"
              ) : (
                "Apply"
              )}
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default TicketActionPopup;
