import React, { useState } from "react";
import Modal from "../../../../../artzu-ui/src/Modal";

const CancelConfirmModal = ({
  open,
  toggleCancel,
  setCurCategory,
  setCurSubject,
  setAdminId,
  ticketData,
  cancelEditCallback,
  toggleEdit,
}) => {
  return (
    <Modal open={open} backgroundColor="none">
      <div className="cancel-container">
        <div className="relate-user-title">Are you sure?</div>
        <div>
          Are you sure you want to cancel? Cancelling will discard all changes.
        </div>
        <div className="reason-buttons">
          <div>
            <button
              className="booking-cancel-button"
              onClick={() => {
                toggleCancel();
              }}
            >
              No
            </button>
          </div>
          <div>
            <button
              className="booking-choose-button"
              onClick={() => {
                cancelEditCallback();
                toggleEdit();
                toggleCancel();
                setCurCategory(ticketData.category);
                setCurSubject(ticketData.subject);
                setAdminId(ticketData.adminUser && ticketData.adminUser.id);
              }}
            >
              Yes
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default CancelConfirmModal;
