import gql from "graphql-tag";

const USER_LOGS = gql`
  query Logs($userId: ID!) {
    userLogs(userId: $userId) {
      timestamp
      description
    }
    userProfileData(id: $userId) {
      id
      timeZone
    }
  }
`;

const USER_NOTIFICATIONS = gql`
  query Notifications($userId: ID!) {
    userNotifications(userId: $userId) {
      pushMessage
      subject
      body
      view
      readAt
      createdAt
    }
    userProfileData(id: $userId) {
      id
      timeZone
    }
  }
`;

const USER_ONBOARDING_DATA = gql`
  query OnboardingData($userId: ID!) {
    userOnboardingData(userId: $userId) {
      onboardingTime
      timeInStatus
      status
      createdAt
      updatedAt
    }
    userProfileData(id: $userId) {
      id
      timeZone
    }
  }
`;

const USER_ANALYTICS = gql`
  query UserSpecificAnalyticMetrics {
    userSpecificAnalyticMetrics {
      name
      unit
    }
    locationData {
      id
      name
      hubs {
        id
        region
        tierData
      }
    }
    invoiceStatuses
  }
`;

export { USER_LOGS, USER_NOTIFICATIONS, USER_ONBOARDING_DATA, USER_ANALYTICS };
