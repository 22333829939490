import React from "react";
import { Link } from "react-router-dom";
import { faChevronRight } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const InvoiceHeader = ({ invoiceData }) => {
  const invoiceNum = invoiceData && invoiceData.getInvoice.invoiceNumber;
  const invoiceId = invoiceData && invoiceData.getInvoice.id;
  return (
    <div className="path-head unselectable">
      <Link
        to="/payments"
        style={{
          color: "#414141",
          textDecoration: "none",
          fontWeight: "400",
        }}
      >
        Payments
      </Link>
      <FontAwesomeIcon
        icon={faChevronRight}
        size="sm"
        style={{ margin: "0 0.5em" }}
      />
      <Link
        to={`/payments`}
        style={{
          color: "#414141",
          textDecoration: "none",
          fontWeight: "400",
        }}
      >
        {" "}
        Invoice
      </Link>
      <FontAwesomeIcon
        icon={faChevronRight}
        size="sm"
        style={{ margin: "0 0.5em" }}
      />
      <Link
        to={`/payments/invoices/${invoiceId}`}
        style={{
          color: "#414141",
          textDecoration: "none",
          fontWeight: "400",
        }}
      >
        {invoiceNum}
      </Link>
    </div>
  );
};

export default InvoiceHeader;
