import React, { Fragment } from "react";
import { TableCell, TableRow } from "../../../Shared/Table";
import Sortable from "./Sortable";
import styles from "./header.module.scss";
import RowSelect from "../../CellValues/RowSelect";
import _ from "lodash";

const Header = ({
  headers,
  changeSort,
  currentSort,
  tabs,
  changeSelectedTab,
  selectedRows,
  selectAllRows,
  isAllSelected,
  fleetInfo,
  tableName,
  popupSpecific,
  togglePassword,
  isFilterApplied,
}) => {
  const selectable = popupSpecific
    ? false
    : tableName === "users"
    ? true
    : tableName === "tickets"
    ? true
    : tableName === "invoices"
    ? false
    : tabs.currentTab === "future" || tabs.currentTab === "cancelled";
  return (
    <TableRow
      padding="0 0"
      align="center"
      minHeight="64px"
      minWidth={"100%"}
      color="#FFFFFF"
      justifyIpad="space-between"
      justify="space-evenly"
      margin="0 0 12px 0"
    >
      {selectable ? (
        tabs.currentTab === "all" ? (
          tableName === "users" ? (
            <TableCell width="64px">
              <RowSelect
                onClick={isAllSelected ? selectAllRows : togglePassword}
                isSelected={isAllSelected}
              />
            </TableCell>
          ) : (
            <TableCell width="64px"></TableCell>
          )
        ) : (
          <TableCell width="64px"></TableCell>
        )
      ) : null}
      {headers.map((header, index) => {
        return (
          <TableCell width="140px" key={index}>
            <Sortable
              displayedName={header.displayedName}
              changeSort={changeSort}
              name={header.name}
              currentSortColumn={currentSort.column}
              currentSortDirection={currentSort.direction}
              fleetInfo={fleetInfo}
            />
          </TableCell>
        );
      })}
    </TableRow>
  );
};

export default Header;
