import React, { useState } from "react";
import _ from "lodash";
import { BULK_IMPORT } from "./queries";
import { useMutation } from "@apollo/client";
import { Fragment } from "react";
import ErrorModal from "../../../Payments/Invoices/ActionPopup/ErrorModal";
import TableContent from "./TableContent";
import ImportMessageBox from "./ImportMessageBox";

const CsvDataTable = ({
  open,
  toggleTableModal,
  csvData,
  csvHeader,
  csvType,
}) => {
  const [selectedRows, setSelectedRows] = useState([]);
  const [isError, setIsError] = useState();
  const [errorMsg, setErrorMsg] = useState();
  const [isMsg, setIsMsg] = useState();
  const [uploadMsg, setUploadMsg] = useState();

  const toggleErrorModal = () => {
    setIsError(!isError);
  };

  const toggleMsgModal = () => {
    setIsMsg(!isMsg);
  };

  const isAllSelected =
    !_.isEmpty(selectedRows) && selectedRows.length === csvData.length;

  const selectAllRows = () => {
    if (isAllSelected) {
      setSelectedRows([]);
    } else {
      let newSelected = [...selectedRows];
      if (selectedRows.length !== 0) {
        newSelected = [];
      }
      for (let index = 0; index < csvData.length; index++) {
        const ele = csvData[index];
        newSelected.push(ele);
      }
      setSelectedRows(newSelected);
    }
  };

  const addRow = (row) => {
    let newSelected = [...selectedRows];
    const included = newSelected.some((data) => _.isEqual(data, row));
    included
      ? (newSelected = newSelected.filter((item) => !_.isEqual(item, row)))
      : newSelected.push(row);
    setSelectedRows(newSelected);
  };

  const [bulkImportMutation] = useMutation(BULK_IMPORT);
  const bulkImport = () => {
    bulkImportMutation({
      variables: {
        data: selectedRows,
        dataType: csvType,
      },
    }).then(({ data: { bulkImportData } }) => {
      if (bulkImportData.invoices) {
        setUploadMsg(bulkImportData.invoices);
        toggleMsgModal();
        console.log("import succeed!");
      } else {
        toggleErrorModal();
        setErrorMsg(bulkImportData.errors);
        console.log("import error!");
      }
    });
  };

  const ableToUpload =
    csvType === "407Tolls"
      ? selectedRows.length !== 0 &&
        selectedRows.every((row) => row[2] !== "not_found")
      : selectedRows.length !== 0 &&
        selectedRows.every((row) => row[1] !== "No Booking Found");
  return (
    <Fragment>
      <TableContent
        open={open}
        selectedRows={selectedRows}
        isAllSelected={isAllSelected}
        csvHeader={csvHeader}
        csvData={csvData}
        addRow={addRow}
        setSelectedRows={setSelectedRows}
        toggleTableModal={toggleTableModal}
        ableToUpload={ableToUpload}
        bulkImport={bulkImport}
        selectAllRows={selectAllRows}
        csvType={csvType}
      />
      <ErrorModal
        open={isError}
        errorMsg={errorMsg}
        toggleErrorModal={toggleErrorModal}
      />
      <ImportMessageBox
        open={isMsg}
        message={uploadMsg}
        toggleMsgModal={toggleMsgModal}
      />
    </Fragment>
  );
};

export default CsvDataTable;
