import React from "react";
import { m, renderColor } from "../../../utils";

const Details = ({ rideshareData, user }) => {
  console.log(user, rideshareData);
  const notConnected = rideshareData[0].impliedStatus === null;
  const userData = !notConnected && rideshareData[0].rideshareUser;
  const connected = !notConnected ? rideshareData[0].lastConnectedAt : null;

  return (
    <div>
      <div className="details-heading">Details</div>
      <div className="middle-container">
        <div className="details-one-third">
          <div className="details-field">
            <div className="subscript">First name</div>
            <div className="detail-field">
              {!notConnected ? userData.givenFirstName : "-"}
            </div>
          </div>
          <div className="details-field">
            <div className="subscript">Last name</div>
            <div className="detail-field">
              {!notConnected ? userData.givenLastName : "-"}
            </div>
          </div>
          <div className="details-field">
            <div className="subscript">Phone number</div>
            <div className="detail-field">
              {!notConnected ? userData.givenMobile : "-"}
            </div>
          </div>
          <div className="details-field">
            <div className="subscript">Email</div>
            <div className="detail-field">
              {!notConnected ? userData.givenEmail : "-"}
            </div>
          </div>
        </div>
        <div className="details-one-third">
          <div className="details-field">
            <div className="subscript">Account type</div>
            <div className="detail-field">
              {(!notConnected && rideshareData[0].accountType) || "-"}
            </div>
          </div>
          <div className="details-field">
            <div className="subscript">Account region</div>
            <div className="detail-field">
              {(!notConnected && rideshareData[0].accountRegion) || "-"}
            </div>
          </div>
          <div className="details-field">
            <div className="subscript">Connected on</div>
            <div className="detail-field">
              {connected ? m(connected).format("h:mm A ddd MMM D, YYYY") : "-"}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Details;
