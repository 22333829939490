import React, { useState } from "react";
import UpsertComment from "../../../../Mutations/UpsertComment";
import moment from "moment-timezone";
import missing from "../../../../images/missing.png";
import styles from "../userDetails.module.scss";

const DocumentsCommentsBody = ({ userId, refetch, docComments }) => {
  const constructComment = () => {
    return {
      namespace: "documents",
      resourceType: "User",
      resourceId: userId,
      body,
    };
  };
  const [body, setBody] = useState("");
  const [loading, setLoading] = useState(false);

  return (
    <div className={styles.commentsContainer}>
      <div className={styles.commentsDisplay}>
        {docComments.map((comment, index) => {
          let dateTimeAgo = moment(comment.updatedAt).fromNow();
          return (
            <div className={styles.commentContainer} key={index}>
              <div className={styles.commentLeft}>
                <div className={styles.commentHeader}>
                  <div className={styles.imageContainer}>
                    <img className={styles.commentUserImage} src={missing} />
                  </div>
                  <div className={styles.mediumFont}>
                    {comment.author && comment.author.fullName}
                  </div>
                </div>

                <div className={styles.commentBody}>{comment.body}</div>
              </div>
              <div className={styles.commentRight}>
                <div>{dateTimeAgo}</div>
              </div>
            </div>
          );
        })}
        <div className={styles.addComments}>
          <textarea
            className={styles.editTextBoxInput}
            rows={4}
            value={body}
            onChange={(e) => setBody(e.target.value)}
            placeholder="Enter comment here..."
          />
        </div>

        <div className={styles.addButton}>
          <UpsertComment
            comment={constructComment()}
            loading={() => setLoading(true)}
            callback={() => {
              refetch();
              setLoading(true);
              setBody("");
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default DocumentsCommentsBody;
