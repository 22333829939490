import { renderColor, titleize } from "../../../../utils";
import moment from "moment";

const AccountDetailsSection = ({ user, licenseData }) => {
  const getUserSex = () => {
    if (licenseData?.sex === "1") {
      return "Male";
    } else if (licenseData?.sex === "2") {
      return "Female";
    } else {
      return "--";
    }
  };

  return (
    <div className="page-section bottom-border">
      <div className="page-section__heading">Account details</div>
      <div className="page-section__canvas">
        <div className="page-section__half">
          <div className="details-field">
            <div className="subscript">First Name</div>
            <div className="detail-field">{user.firstName}</div>
          </div>
          <div className="details-field">
            <div className="subscript">Last Name</div>
            <div className="detail-field">{user.lastName}</div>
          </div>
          <div className="details-field">
            <div className="subscript">Status</div>
            <div className={renderColor("Account Status", user.accountStatus)}>
              {titleize(user.accountStatus.replace(/_/g, " ")) || "-"}
            </div>
          </div>
          <div className="details-field">
            <div className="subscript">User ID</div>
            <div className="detail-field">{user.id}</div>
          </div>
          <div className="details-field">
            <div className="subscript">Operating city</div>
            <div className="detail-field">{titleize(user.operatingCity)}</div>
          </div>
          <div className="details-field">
            <div className="subscript">Hub</div>
            <div className="detail-field">
              {user.parkingLot ? titleize(user.parkingLot.region) : "N/A"}
            </div>
          </div>
          <div className="details-field">
            <div className="subscript">Referral source</div>
            <div className="detail-field">{user.referralSource || "-"}</div>
          </div>
          <div className="details-field">
            <div className="subscript">Mobile OS</div>
            <div className="detail-field">{(user && user.deviceOs) || "-"}</div>
          </div>
        </div>
        <div className="page-section__half left-border">
          <div className="details-field">
            <div className="subscript">Email</div>
            <div className="detail-field">{user.email}</div>
          </div>
          <div className="details-field">
            <div className="subscript">Mobile phone</div>
            <div className="detail-field">{user.mobile}</div>
          </div>
          <div className="details-field">
            <div className="subscript">Date of birth</div>
            <div className="detail-field">
              {licenseData
                ? moment.utc(licenseData.dateOfBirth).format("MMM D, YYYY")
                : "-"}
            </div>
          </div>
          <div className="details-field">
            <div className="subscript">Sex</div>
            <div className="detail-field">{getUserSex() || "-"}</div>
          </div>
          <div className="details-field">
            <div className="subscript">Height</div>
            <div className="detail-field">{licenseData?.height || "-"}</div>
          </div>
          <div className="details-field">
            <div className="subscript">Eye color</div>
            <div className="detail-field">{licenseData?.eyeColour || "-"}</div>
          </div>
          <div className="details-field">
            <div className="subscript">Driver's license number</div>
            <div className="detail-field">
              {licenseData?.licenseNumber || "-"}
            </div>
          </div>
          <div className="details-field">
            <div className="subscript">App version</div>
            <div className="detail-field">
              {(user && user.appVersion) || "-"}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccountDetailsSection;
