const utilString = `
  query UtilizationData($parkingLotId: [Int!]!, $vehicleClass: [String!]!, $startTime: DateTime!, $endTime: DateTime!){
    weeklyUt(parkinglotId: $parkingLotId, vehicleClass: $vehicleClass, startTime: $startTime, endTime: $endTime) {
      cycleStart
      cycleEnd
      maxHours
      bookedHours
      preBooked
      liveBooked
      serviceHours
      unique
    }
  }
`;

const analyticsString = `
  query AnalyticData($analyticParams: [AnalyticAttributes!]!) {
    analyticData(analyticParams: $analyticParams) {
      metric
      unit
      total
      average
      plotFormat
      axis
      colorway
      dataset {
        cycleStart
        cycleEnd
        yValue
        yValues {
          label
          value
        }
      }
    }
  }
`;

const analyticIndexString = `
  query AnalyticMetrics {
    analyticMetrics {
      name
      unit
    }
    locationData {
      id
      name
      hubs {
        id
        region
        tierData
      }
    }
    userAccountStatuses
    invoiceStatuses
    ticketStatuses
    outstandingRequirements
    suspensionReasons
    currentAdminUser {
      analyticParams {
        scope
        metric
        rangeStart
        rangeEnd
        increment
        plotFormat
        unit
        cityIds
        parkingLotIds
        vehicleClass
        accountStatus
        invoiceStatus
        ticketStatus
        outstandingRequirement
        suspensionReason
        axis
        colorway
      }
    }
  }
`;

const userAnalyticIndexString = `
  query UserSpecificAnalyticMetrics {
    userSpecificAnalyticMetrics {
      name
      unit
    }
    locationData {
      id
      name
      hubs {
        id
        region
        tierData
      }
    }
    userAccountStatuses
    invoiceStatuses
    ticketStatuses
    outstandingRequirements
    suspensionReasons
    currentAdminUser {
      analyticParams {
        scope
        metric
        rangeStart
        rangeEnd
        increment
        plotFormat
        unit
        cityIds
        parkingLotIds
        vehicleClass
        accountStatus
        invoiceStatus
        ticketStatus
        outstandingRequirement
        suspensionReason
        axis
        colorway
      }
    }
  }
`;

export {
  utilString,
  analyticIndexString,
  analyticsString,
  userAnalyticIndexString,
};
