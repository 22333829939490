import React from "react";
import DatePicker from "react-datepicker";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-light-svg-icons";
import { faCalendar, faPlus } from "@fortawesome/pro-regular-svg-icons";
import {
  GET_ADMIN_NAME,
  REMOVE_ADMIN_REMINDER,
  TICKET_REMINDER,
} from "../queries";
import { Fragment } from "react";
import { useMutation, useQuery } from "@apollo/client";
import ErrorModal from "../../../../Payments/Invoices/ActionPopup/ErrorModal";
import moment from "moment";
import Dropdown from "../../../../InformationTable/ActionBar/Filters/OptionsModal/Body/Filter/Dropdown";
import "react-datepicker/dist/react-datepicker.css";

const TicketReminder = ({
  ticketData,
  refreshTicketCallback,
  taggedAdmins,
  defaultAdmin,
}) => {
  const existingReminder =
    ticketData && ticketData.reminderDate
      ? moment(ticketData.reminderDate).toDate()
      : undefined;

  const [reminderDate, setReminderDate] = useState(existingReminder);
  const [newAdmin, setNewAdmin] = useState();
  const [isError, setIsError] = useState();
  const [errorMsg, setErrorMsg] = useState();

  const { data, loading } = useQuery(GET_ADMIN_NAME);

  const allAdmins = data
    ? data.allAdminName.map((admin) => {
        return {
          name: admin.firstName + " " + admin.lastName,
          value: admin.firstName + " " + admin.lastName,
        };
      })
    : [];

  const toggleErrorModal = () => {
    setIsError(!isError);
  };

  const triggerAdmin = (childData) => {
    setNewAdmin(childData);
  };

  const [reminderMutation] = useMutation(TICKET_REMINDER);
  const handleRemind = (givenId) => {
    reminderMutation({
      variables: {
        ticketId: ticketData && ticketData.id,
        adminId: data.allAdminName.find(
          (admin) => admin.firstName + " " + admin.lastName === newAdmin
        )
          ? data.allAdminName.find(
              (admin) => admin.firstName + " " + admin.lastName === newAdmin
            ).id
          : null,
        reminderDate: reminderDate,
      },
    }).then(({ data: { remindAdmin } }) => {
      if (remindAdmin.success) {
        setNewAdmin(undefined);
        refreshTicketCallback(ticketData && ticketData.id);
        console.log("add reminder succeed!");
      } else {
        toggleErrorModal();
        setErrorMsg(remindAdmin.error);
        console.log("add reminder error!");
      }
    });
  };

  const [removeReminderMutation] = useMutation(REMOVE_ADMIN_REMINDER);
  const handleRemove = (admin) => {
    removeReminderMutation({
      variables: {
        ticketId: ticketData && ticketData.id,
        adminId: data.allAdminName.find(
          (item) => item.firstName + " " + item.lastName === admin
        )
          ? data.allAdminName.find(
              (item) => item.firstName + " " + item.lastName === admin
            ).id
          : defaultAdmin.id,
        adminName: admin,
      },
    }).then(({ data: { removeReminder } }) => {
      if (removeReminder.success) {
        refreshTicketCallback(ticketData && ticketData.id);
        console.log("remove reminder succeed!");
      } else {
        toggleErrorModal();
        setErrorMsg(removeReminder.error);
        console.log("remove reminder error!");
      }
    });
  };

  const addable = newAdmin !== undefined && reminderDate;

  return (
    <Fragment>
      <div className="reminder-container">
        <div className="reminder-header">
          <h2>Reminder</h2>
        </div>
        <div className="subscript">Date and time</div>
        <div className="datepicker-container">
          <DatePicker
            selected={reminderDate}
            onChange={(date) => {
              setReminderDate(date);
              taggedAdmins && taggedAdmins.length !== 0 && handleRemind();
            }}
            dateFormat="MMM d, yyyy h:mm a"
            todayButton={"Today"}
            placeholderText="Select date"
            popperPlacement="bottom"
            className="date-input"
            showTimeSelect
            disabled={!ticketData}
          />
          <FontAwesomeIcon
            icon={faCalendar}
            className="reminder-calendar"
            size="lg"
          />
        </div>
        <div style={{ fontSize: "20px", marginBottom: "16px" }}>Users:</div>
        <div className="subscript">Add user</div>
        <div className="search-add-container">
          <Dropdown
            initialValue={newAdmin}
            items={allAdmins}
            onChange={triggerAdmin}
            width="250px"
            disabled={!ticketData}
          />
          <button
            className={
              addable ? "add-button-container" : "invalid-add-button-container"
            }
          >
            <div
              className="add-tag-button"
              onClick={() => {
                addable && handleRemind();
              }}
            >
              <FontAwesomeIcon icon={faPlus} className="plus-icon" />
              <div style={{ fontWeight: "600" }}>Add</div>
            </div>
          </button>
        </div>
        <div className="ticket-tags-container">
          {taggedAdmins &&
            taggedAdmins.map((admin) => (
              <div className="related-tag-container">
                {admin}
                <FontAwesomeIcon
                  icon={faTimes}
                  size="lg"
                  className="tag-times"
                  onClick={() => {
                    handleRemove(admin);
                  }}
                />
              </div>
            ))}
        </div>
      </div>
      <ErrorModal
        open={isError}
        errorMsg={errorMsg}
        toggleErrorModal={toggleErrorModal}
      />
    </Fragment>
  );
};

export default TicketReminder;
