import React, { Fragment, useState } from "react";
import { TableCell } from "../../../../Shared/Table";
import styles from "./bookingTimeline.module.scss";
import ActionLink from "./ActionLink";
import FeedbackModal from "./FeedbackModal";
import ParkingModal from "./ParkingModal";
import ActionLinkTwo from "./ActionLinkTwo";

const Action = ({ tl, toggleInspection, setType, curInspecting }) => {
  const [isFeedback, setIsFeedback] = useState(false);
  const [isParking, setIsParking] = useState(false);

  const getEventColor = (colorCode) => {
    switch (colorCode) {
      case "red": {
        return "#FF0000";
      }
      case "green": {
        return "#3DC700";
      }
      case "orange": {
        return "#FF8900";
      }
      default: {
        return "#414141";
      }
    }
  };

  const toggleFeedback = () => {
    setIsFeedback(!isFeedback);
  };

  const toggleParking = () => {
    setIsParking(!isParking);
  };

  const clickable =
    tl.event === "Feedback" ||
    tl.event === "Personal vehicle" ||
    tl.event === "Parking spot";

  const parkingTimeline =
    (tl.event === "Parking spot" || tl.event === "Personal vehicle") && tl;

  const getClickFunction = (event) => {
    if (event === "Feedback") {
      return () => {
        toggleFeedback();
      };
    } else if (event === "Personal vehicle" || event === "Parking spot") {
      return () => {
        toggleParking();
      };
    }
  };

  return (
    <Fragment>
      {" "}
      <TableCell width="20%" align={"left"}>
        <div
          className={styles.timelineEvent}
          style={{
            color: clickable ? "#128dff" : getEventColor(tl?.colorCode),
            cursor: clickable ? "pointer" : "default",
          }}
          onClick={getClickFunction(tl.event)}
        >
          {tl.event}
        </div>
        <div className={styles.timelineCaption}>
          {tl.caption1?.includes("Vehicle plate") ? (
            <span>
              {tl.caption1.substring(0, 15)}
              <ActionLink tl={tl} index1={23} index2={15} index3={22} />
            </span>
          ) : tl.caption1?.includes("From vehicle plate") ? (
            <span>
              {tl.caption1.substring(0, 20)}
              <ActionLink tl={tl} index1={28} index2={20} index3={27} />
            </span>
          ) : tl.event === "Vehicle inspection" ? (
            <ActionLink tl={tl} index1={8} index2={0} index3={7} />
          ) : (
            !clickable && tl.caption1
          )}
          <div style={{ marginLeft: "10px" }}>
            {tl.eventType === "prebooking" && (
              <a
                className="inspection-button"
                onClick={() => {
                  toggleInspection();
                  curInspecting(tl.eventId);
                  setType("prebooking");
                }}
              >
                View inspection
              </a>
            )}
          </div>
        </div>
        <div className={styles.timelineCaption}>
          {tl.caption2 && tl.caption2.includes("To vehicle plate") ? (
            <span>
              {tl.caption2.substring(0, 18)}
              <ActionLinkTwo tl={tl} index1={26} index2={18} index3={25} />
            </span>
          ) : tl.caption2 &&
            (tl.event === "Invoice processed" ||
              tl.event === "Invoice processed (Merged)") ? (
            <a className={styles.linkButton} href={tl.caption2} target="_blank">
              View invoice
            </a>
          ) : tl.caption2 && tl.event === "Booking merged" ? (
            <a className={styles.linkButton} href={tl.caption2} target="_blank">
              View booking
            </a>
          ) : tl.caption2 && tl.event === "Booking absorbed" ? (
            <a className={styles.linkButton} href={tl.caption2} target="_blank">
              View booking
            </a>
          ) : (
            !clickable && tl.caption2
          )}
        </div>
        <div className={styles.timelineCaption}>
          {tl.caption3}
          <div style={{ marginTop: "5px" }}>
            {tl.event === "Vehicle changed" && (
              <a
                className={styles.inspectionButton}
                onClick={() => {
                  toggleInspection();
                  curInspecting(tl.eventId);
                  setType("carChange");
                }}
              >
                View comments
              </a>
            )}
          </div>
        </div>
      </TableCell>
      <FeedbackModal
        isOpen={isFeedback}
        toggleFeedback={toggleFeedback}
        feedback={tl.event === "Feedback" && tl}
      />
      <ParkingModal
        isOpen={isParking}
        toggleParking={toggleParking}
        parking={parkingTimeline && tl}
        isPersonal={tl.event === "Personal vehicle"}
      />
    </Fragment>
  );
};

export default Action;
