import React from "react";
import styles from "./tabs.module.scss";
import _ from "lodash";
import classNames from "classnames";

const Tabs = ({ tabs, changeSelectedTab, userSpecific, isAllSelected }) => {
  const { counts, currentTab } = tabs;
  return (
    <div
      className={
        userSpecific ? styles.tabsContainerSmall : styles.tabsContainer
      }
    >
      {counts.map((tab) => {
        const isSelected = tab.tabName === currentTab;
        return (
          <div
            className={classNames(styles.tab, isSelected && styles.selected)}
            onClick={() =>
              !isSelected && !isAllSelected && changeSelectedTab(tab.tabName)
            }
            style={{ display: "flex", flexDirection: "column" }}
          >
            {_.startCase(tab.tabName)}
            <span style={{ fontWeight: 500 }}>
              ({tab.count.toLocaleString()})
            </span>
          </div>
        );
      })}
    </div>
  );
};

export default Tabs;
