import React from "react";
import classNames from "classnames";
import styles from "./baseValue.module.scss";
import missing from "../../../../images/missing.png";

const UserValue = ({ cellData, classes }) => {
  return (
    <div className={classNames(classes)}>
      <div className={styles.userContainer}>
        <div className={styles.userImageContainer}>
          <img
            className={styles.userImage}
            src={cellData.imageLink ? cellData.imageLink : missing}
          />
        </div>
      </div>
    </div>
  );
};

export default UserValue;
