import React from "react";
import styles from "./bookingTimeline.module.scss";

const ActionLink = ({ tl, index1, index2, index3 }) => {
  return (
    <a
      href={`${
        process.env.REACT_APP_FLEET_CLIENT_URL
      }/resource/vehicle/${tl.caption1.substring(index1)}`}
      target="_blank"
      className={styles.timelineLink}
    >
      {tl.caption1.substring(index2, index3)}
    </a>
  );
};

export default ActionLink;
