import React, { Fragment } from "react";
import { FormControl, Select, MenuItem } from "@material-ui/core";
import BlueCheckbox from "../../Shared/BlueCheckbox";
import { titleize } from "../../../utils";
import { useStyles } from "./styles.js";
import { isSelected, subsetDropdownTitle } from "../utils";

const TicketStatus = ({
  parkingLotIds,
  ticketStatuses,
  ticketStatus,
  setTicketStatus,
}) => {
  const classes = useStyles();

  const changeTicketStatus = (status) => {
    if (ticketStatus?.includes(status)) {
      setTicketStatus([...ticketStatus.filter((v) => v !== status)]);
    } else {
      setTicketStatus([status, ...ticketStatus]);
    }
  };

  return (
    <div className="dataset-options__field">
      <label>Ticket Status</label>
      <FormControl variant="outlined" className={classes.formControl}>
        <Select
          value={isSelected(ticketStatus)}
          renderValue={() =>
            subsetDropdownTitle(ticketStatus, "ticket status", parkingLotIds)
          }
          className={classes.select}
          onChange={(e) => {}}
        >
          <Fragment>
            {ticketStatuses.map((status, index) => {
              return (
                <MenuItem
                  key={index}
                  className={classes.menuItem}
                  onClick={() => changeTicketStatus(status)}
                >
                  <BlueCheckbox
                    size="small"
                    checked={ticketStatus?.includes(status)}
                    onChange={() => changeTicketStatus(status)}
                    inputProps={{ "aria-label": "secondary checkbox" }}
                  />
                  <div>{titleize(status)}</div>
                </MenuItem>
              );
            })}
          </Fragment>
        </Select>
      </FormControl>
    </div>
  );
};

export default TicketStatus;
