import moment from "moment-timezone";
import TextValue from "../CellValues/Base/TextValue";
import ProfileValue from "../CellValues/Base/ProfileValue";
import PaymentStatusValue from "../CellValues/Base/PaymentStatusValue";
import AmountValue from "../CellValues/Base/AmountValue";
import { baseTextProcessor, baseDateProcessor } from "./shared";

const rowComponentsPayment = (time_zone) => ({
  invoiceStatus: {
    component: PaymentStatusValue,
    dataProcessor: baseTextProcessor("invoiceStatus"),
  },
  invoiceNumber: {
    component: TextValue,
    dataProcessor: baseTextProcessor("invoiceNumber"),
  },
  invoiceAmount: {
    component: AmountValue,
    dataProcessor: baseTextProcessor("invoiceAmount"),
  },
  invoiceIssuanceDate: {
    component: TextValue,
    dataProcessor: baseDateProcessor("issuanceDate", time_zone),
  },
  invoiceDue: {
    component: TextValue,
    dataProcessor: baseDateProcessor("dueDate", time_zone),
  },
  invoiceUser: {
    component: ProfileValue,
    dataProcessor: (data) => {
      const imageLink =
        data["userProfileImageUrl"] && data["userProfileImageUrl"].value;
      const userName = data["userFullName"] && data["userFullName"].value;
      return {
        imageLink: imageLink,
        userName: userName,
      };
    },
  },
  appliedByUser: {
    component: ProfileValue,
    dataProcessor: (data) => {
      const imageLink =
        data["appliedByUserProfileImageUrl"] &&
        data["appliedByUserProfileImageUrl"].value;
      const userName =
        data["appliedByUserFullName"] && data["appliedByUserFullName"].value;
      return {
        imageLink: imageLink,
        userName: userName,
      };
    },
  },
  bookingId: {
    component: TextValue,
    dataProcessor: baseTextProcessor("bookingId"),
  },
});

export { rowComponentsPayment };
