import React from "react";
import styles from "../../../Shared/ToggleButton/toggleButton.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/pro-regular-svg-icons";
import { Link } from "react-router-dom";

const CreateTicket = ({ userId }) => {
  const newTo = {
    pathname: "/communications/ticket/new",
    driverId: userId,
  };

  return (
    <Link
      className={styles.toggleButtonContainer}
      style={{ marginRight: "12px" }}
      to={userId ? newTo : "/communications/ticket/new"}
    >
      <FontAwesomeIcon icon={faPlus}
      style={{ color: "#414141" }} />
    </Link>
  );
};

export default CreateTicket;
