import React from "react";
import classNames from "classnames";
import styles from "./checkBox.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquare } from "@fortawesome/pro-regular-svg-icons";
import { faCheckSquare } from "@fortawesome/pro-solid-svg-icons";

const CheckBox = ({ onClick, isSelected, isAllSelected }) => (
  <FontAwesomeIcon
    icon={isSelected ? faCheckSquare : faSquare}
    className={classNames(
      styles.checkBox,
      isSelected && isAllSelected ? styles.allSelected : styles.checkBoxSelected
    )}
    onClick={(evt) => {
      !isAllSelected && onClick();
      evt.preventDefault();
    }}
  />
);

export default CheckBox;
