import React, { Component } from "react";
import { Query } from "@apollo/client/react/components";
import gql from "graphql-tag";
import Analytics from "../Components/Analytics";
import { analyticIndexString } from "./analyticsQueryStrings";
import Loading from "../Components/Shared/Loading";
import { titleToCamel } from "../utils";

class AnalyticIndex extends Component {
  render() {
    return (
      <Query
        query={gql`
          ${analyticIndexString}
        `}
        fetchPolicy="network-only"
      >
        {({ loading, error, data, refetch }) => {
          if (error) {
            return null;
          } else if (loading) {
            return <Loading />;
          }

          if (data.analyticMetrics) {
            console.log(data);
            return (
              <Analytics
                {...this.props}
                metrics={data.analyticMetrics}
                locations={data.locationData}
                accountStatuses={data.userAccountStatuses}
                invoiceStatuses={data.invoiceStatuses}
                ticketStatuses={data.ticketStatuses}
                outstandingRequirements={data.outstandingRequirements}
                suspensionReasons={data.suspensionReasons}
                analyticParams={data.currentAdminUser.analyticParams}
                refetch={refetch}
              />
            );
          } else {
            return null;
          }
        }}
      </Query>
    );
  }
}

export default AnalyticIndex;
