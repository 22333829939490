import Modal from "../../../../../../artzu-ui/src/Modal";
import { useEffect, useState } from "react";
import AllTagsSection from "./AllTagsSection";
import SearchBar from "../../../../../Shared/SearchBar";
import Button from "../../../../../Shared/Button";
import DeleteTagModal from "./DeleteTag";
import CreateTagModal from "./CreateTag";
import styles from "../../../userDetails.module.scss";

const ManageTagsModal = ({
  open,
  toggleManage,
  user,
  allTagsData,
  refetchProfile,
  refetchAllTags,
}) => {
  const [selectedTag, setSelectedTag] = useState({});
  const [searchValue, setSearchValue] = useState("");
  const [isCreate, setCreate] = useState(false);
  const [isDelete, setDelete] = useState(false);
  let allTags = allTagsData && allTagsData.getAllUserTags;

  const toggleCreate = () => {
    setCreate(!isCreate);
  };

  const toggleDelete = () => {
    setDelete(!isDelete);
  };

  return (
    <Modal open={open} backgroundColor="none">
      <div className={styles.manageTagsModalContainer}>
        <div className={styles.editTagsHead}>
          Manage All Tags
          <div className={styles.createButton} onClick={toggleCreate}>
            Create
          </div>
        </div>
        <div className={styles.addTags}>
          <div className={styles.searchBar}>
            <SearchBar
              width="100%"
              onChange={setSearchValue}
              value={searchValue}
              placeholder="Search Tags"
            />
          </div>
        </div>
        <AllTagsSection
          allTags={allTags}
          selectedTag={selectedTag}
          setSelectedTag={setSelectedTag}
          searchValue={searchValue}
          toggleDelete={toggleDelete}
        />
        <div className={styles.rowCenter}>
          <div className={styles.closeManageButton} onClick={toggleManage}>
            Close
          </div>
        </div>
      </div>
      <DeleteTagModal
        open={isDelete}
        toggleDelete={toggleDelete}
        selectedTag={selectedTag}
        refetchProfile={refetchProfile}
        refetchAllTags={refetchAllTags}
      />
      <CreateTagModal
        open={isCreate}
        toggleCreate={toggleCreate}
        refetchAllTags={refetchAllTags}
      />
    </Modal>
  );
};

export default ManageTagsModal;
