import { titleize } from "../../utils";
import GroupedBar from "../../assets/diagrams/Bar grouped.png";
import StackedBar from "../../assets/diagrams/Bar stacked.png";
import Line from "../../assets/diagrams/Line.png";

const isSelected = (metricValue) => {
  if (metricValue?.length > 0) {
    return "Selected";
  } else {
    return "Select";
  }
};

const subsetDropdownTitle = (metricValue, metric, parkingLotIds) => {
  if (metricValue?.length > 0) {
    return (
      <div className="parking-lot-dropdown">
        {metricValue.map((status, i) => {
          return (
            <div className="parking-lot-card" key={i}>
              {titleize(status)}
            </div>
          );
        })}
      </div>
    );
  } else if (parkingLotIds.length > 0) {
    return "Select";
  } else {
    return `Select location for ${metric} options`;
  }
};

const lotSubsetTitle = (parkingLotIds, locations) => {
  if (parkingLotIds.length > 0) {
    return (
      <div className="parking-lot-dropdown">
        {parkingLotIds.map((pid, i) => {
          return (
            <div className="parking-lot-card" key={i}>
              {
                locations
                  .map((l) => l.hubs)
                  .flat()
                  .find((hub) => parseInt(hub.id) === pid).region
              }
            </div>
          );
        })}
      </div>
    );
  } else {
    return "Select";
  }
};

const vehicleClassDropdownTitle = (vehicleClass, parkingLotIds) => {
  if (vehicleClass.length > 0) {
    return (
      <div className="parking-lot-dropdown">
        {vehicleClass.map((v, i) => {
          return (
            <div className="parking-lot-card" key={i}>
              {titleize(v)}
            </div>
          );
        })}
      </div>
    );
  } else if (parkingLotIds.length > 0) {
    return "Select";
  } else {
    return "Select location for vehicle class options";
  }
};

const plotFormats = [
  { format: "line", label: "Line", img: Line },
  { format: "bar", label: "Bar (grouped)", img: GroupedBar },
  { format: "stacked_bar", label: "Bar (stacked)", img: StackedBar },
];

const vehicleClassRequired = [
  "total_bookings",
  "booked_in_advance",
  "booked_during",
  "max_hours",
  "service_hours",
  "cancelled_hours",
  "utilization",
  "total_bookings_v2",
  "booked_in_advance_v2",
  "booked_during_v2",
  "available_hours_v2",
  "max_hours_v2",
  "service_hours_v2",
  "offline_hours_v2",
  "cancelled_hours_v2",
  "utilization_v2",
  "downtime",
  "count_bookings",
  "count_booked_users",
  "count_recently_booked_users",
  "average_hours_booked_per_user",
  "average_booking_count_per_user",
];

const accountStatusRequired = ["user_status_count", "net_user_status_count"];

const ticketStatusRequired = ["ticket_status_count"];

const requirementTypeRequired = ["count_outstanding_requirements"];

const suspensionReasonRequired = ["count_suspension_reasons"];

const invoiceStatusRequired = [
  "invoice_totals",
  "count_invoices",
  "invoice_totals_by_user",
  "count_invoices_by_user",
];

export {
  isSelected,
  subsetDropdownTitle,
  lotSubsetTitle,
  vehicleClassDropdownTitle,
  plotFormats,
  vehicleClassRequired,
  accountStatusRequired,
  ticketStatusRequired,
  requirementTypeRequired,
  suspensionReasonRequired,
  invoiceStatusRequired,
};
