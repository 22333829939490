import React from 'react'

const SvgChevronRight = ({ color = '#373535', ...props }) => (
  <svg data-name="Layer 1" viewBox="0 0 37.73 68.57" {...props}>
    <path
      fill="none"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={7}
      d="M3.5 3.5l30.73 30.73v.11L3.5 65.07"
    />
  </svg>
)

export default SvgChevronRight
