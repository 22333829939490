import React from "react";
import DetailsTableContent from "./DetailsTableContent";
import DetailsTableHeader from "./DetailsTableHeader";

const DetailsTable = ({
  create,
  invoiceDetails,
  invoiceInfo,
  lineItemsCallBack,
  setLiveTotal,
  tmpTotal,
  isCreated,
  refetch,
  lineItems,
  toggleCreateInvoice,
}) => {
  return (
    <div>
      <DetailsTableHeader create={create} />
      <DetailsTableContent
        create={create}
        invoiceDetails={invoiceDetails}
        invoiceInfo={invoiceInfo}
        lineItemsCallBack={lineItemsCallBack}
        setLiveTotal={setLiveTotal}
        tmpTotal={tmpTotal}
        isCreated={isCreated}
        refetch={refetch}
        lineItems={lineItems}
        toggleCreateInvoice={toggleCreateInvoice}
      />
    </div>
  );
};

export default DetailsTable;
