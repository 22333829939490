import React from "react";

const DetailsTableHeader = ({ create }) => {
  return (
    <div className="details-header">
      <div className="details-header-cell" style={{ width: "180px" }}>
        Category
      </div>
      <div className="details-header-cell" style={{ width: "300px" }}>
        {create ? "Line item" : "Item"}{" "}
      </div>
      <div className="details-header-cell" style={{ width: "600px" }}>
        Details
      </div>
      <div className="details-header-cell" style={{ width: "300px" }}>
        Amount
      </div>
    </div>
  );
};

export default DetailsTableHeader;
