import React from "react";
import Modal from "../../../../../artzu-ui/src/Modal";
import styles from "./bookingTimeline.module.scss";
import StarRatings from "react-star-ratings";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-light-svg-icons";

const FeedbackModal = ({ isOpen, toggleFeedback, feedback }) => {
  const rating = feedback?.caption1;
  const comment = feedback?.caption2;
  const fleetClientUrl = process.env.REACT_APP_FLEET_CLIENT_URL;
  return (
    <Modal open={isOpen} backgroundColor="none">
      <div className={styles.feedbackModal}>
        <div className={styles.modalTitle}>
          Feedback{" "}
          <FontAwesomeIcon
            icon={faTimes}
            size="sm"
            onClick={toggleFeedback}
            className={styles.toClick}
          />
        </div>
        <StarRatings
          rating={parseInt(rating)}
          starDimension="32px"
          starSpacing="5px"
          starRatedColor="#414141"
        />{" "}
        <div className={styles.modalBody}>{comment}</div>
        <a
          href={`${fleetClientUrl}/tasks/new`}
          target="_blank"
          style={{ textDecoration: "none" }}
        >
          <button className={styles.createTaskButton}>Create task</button>
        </a>
      </div>
    </Modal>
  );
};

export default FeedbackModal;
