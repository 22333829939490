import React, { useState } from "react";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { m } from "../../../../utils";
import styles from "../userDetails.module.scss";
import { Fragment } from "react";
import ManualEntryModal from "./ManualEntry/ManualEntryModal";
import moment from "moment-timezone";

const DocumentsCarousel = ({ user, refetchProfile }) => {
  const documentStatus = ["Current", "Previous"];
  const [count, setCount] = useState(0);
  const [isManual, setIsManual] = useState();

  const timeZone = user.timeZone;
  const toggleManual = () => {
    setIsManual(!isManual);
  };

  return (
    <Fragment>
      <div className={styles.carousel}>
        <div className={styles.current}>
          <div className={styles.leftNav}>
            <FontAwesomeIcon icon={faChevronLeft} size="xs" color="#dedede" />
          </div>
          <div className={styles.carouselHeader}>
            <div className={styles.headerName}>{documentStatus[count]}</div>
            <div className={styles.headerTime}>
              Last updated:{" "}
              {moment(user.updatedAt)
                .tz(timeZone)
                .format("YYYY-MM-DD h:mm A z") || ""}
            </div>
          </div>
          <div className={styles.rightNav}>
            <FontAwesomeIcon icon={faChevronRight} size="xs" color="#dedede" />
          </div>
        </div>

        <button
          className={styles.manualEntryButton}
          onClick={() => {
            toggleManual();
          }}
        >
          Manual entry
        </button>
      </div>
      <ManualEntryModal
        open={isManual}
        toggleManual={toggleManual}
        userId={user.id}
        refetchProfile={refetchProfile}
      />
    </Fragment>
  );
};

export default DocumentsCarousel;
