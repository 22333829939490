import Drivers from "../../assets/icons/Users.svg";
import DriversGrey from "../../assets/icons/UsersGrey.svg";
import Communications from "../../assets/icons/Communications.svg";
import CommunicationsGrey from "../../assets/icons/CommunicationsGrey.svg";
import RobotWhite from "../../assets/icons/robot-solid-white.svg";
import RobotGrey from "../../assets/icons/robot-solid-grey.svg";

import Bookings from "../../assets/icons/Bookings.svg";
import BookingsGrey from "../../assets/icons/BookingsGrey.svg";
import Payments from "../../assets/icons/Payments.svg";
import PaymentsGrey from "../../assets/icons/PaymentsGrey.svg";
import Team from "../../assets/icons/Team.svg";
import TeamGrey from "../../assets/icons/TeamGrey.svg";
import Utilization from "../../assets/icons/Utilization.svg";
import UtilizationGrey from "../../assets/icons/UtilizationGrey.svg";
import Settings from "../../assets/icons/Settings.svg";
import SettingsGrey from "../../assets/icons/SettingsGrey.svg";
import Location from "../../assets/icons/Location.svg";
import LocationGrey from "../../assets/icons/LocationGrey.svg";
import UserHollow from "../../assets/icons/UserHollow.svg";
import UserHollowGrey from "../../assets/icons/UserHollowGrey.svg";
import PriceMap from "../../assets/icons/PriceMap.svg";
import PriceMapGrey from "../../assets/icons/PriceMapGrey.svg";
// import icon from ""
const isAutzu = process.env.REACT_APP_COMPANY_CONFIG === "autzu";
const taskbarMenu = [
  {
    name: "users",
    icons: [Drivers, DriversGrey],
    link: "/users",
    isShown: true,
  },
  {
    name: "messages",
    icons: [Communications, CommunicationsGrey],
    link: "/communications",
    isShown: true,
  },
  {
    name: "bookings",
    icons: [Bookings, BookingsGrey],
    link: "/bookings",
    isShown: true,
  },
  {
    name: "payments",
    icons: [Payments, PaymentsGrey],
    link: "/payments",
    isShown: true,
  },
  {
    name: "prices",
    icons: [PriceMap, PriceMapGrey],
    link: "/prices",
    isShown: true,
  },
  {
    name: "team",
    icons: [Team, TeamGrey],
    link: "/team",
    isShown: isAutzu,
  },
  {
    name: "analytics",
    icons: [Utilization, UtilizationGrey],
    link: "/analytics",
    isShown: isAutzu,
  },
  {
    name: "autzuChat",
    icons: [RobotWhite, RobotGrey],
    link: "/autzu-chat",
    isShown: true,
  },
];

const taskbarTools = [
  // {
  //   name: "location",
  //   icons: [Location, LocationGrey],
  //   link: "/location"
  // },
  {
    name: "admin",
    icons: [UserHollow, UserHollowGrey],
    link: "/admin",
  },
  {
    name: "settings",
    icons: [Settings, SettingsGrey],
    link: "/settings",
  },
];

export { taskbarMenu, taskbarTools };
