import React from "react";
import styles from "./inspection.module.scss";

const SafetyCheck = ({ inspectionData, safetyList, toggleSafeImg }) => {
  return (
    <div className={styles.inspectionField}>
      <div className={styles.subscript}>Safe to drive</div>{" "}
      {inspectionData?.safeToDrive ? (
        <div className={styles.safetyConditionGood}>Yes</div>
      ) : inspectionData?.safeToDrive !== null ? (
        <div className={styles.badSafety}>
          <div className={styles.safetyConditionBad}>No</div>
          <div className={styles.inspectionField}>
            <div className={styles.subscript}> Not safe reason</div>{" "}
            <div>{inspectionData?.issueDescription}</div>
          </div>
          <div className={styles.inspectionField}>
            <div className={styles.subscript}> Not safe images</div>{" "}
            <div className={styles.imgContainer}>
              {safetyList.length !== 0 &&
                safetyList.map((img) => (
                  <div key={img}>
                    <img
                      className={styles.exteriorImg}
                      style={{ marginRight: "16px" }}
                      src={img}
                      onClick={() => toggleSafeImg(img)}
                    ></img>
                  </div>
                ))}
            </div>
          </div>
        </div>
      ) : (
        <div style={{ opacity: "0.5" }}>N/A</div>
      )}
    </div>
  );
};

export default SafetyCheck;
