import React from "react";

const CheckBox = ({ label, value, onChange }) => {
  return (
    <label style={{ display: "flex", flexDirection: "row" }}>
      <input
        className="checkbox-format"
        type="checkbox"
        checked={value}
        onChange={onChange}
      />
      <div style={{ marginRight: "10px" }}>{label}</div>
    </label>
  );
};

export default CheckBox;
