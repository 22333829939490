import React from "react";
import StarRatings from "react-star-ratings";

const FeedbackMessage = ({ msg }) =>
  !msg.internal &&
  msg.direction === "incoming" && (
    <div className="message-tag-container">
      <div className="message-tag-feedback">
        <div className="rating-content">
          <StarRatings
            rating={parseFloat(msg.text.split(",")[0])}
            starDimension="32px"
            starSpacing="5px"
            starRatedColor="#414141"
          />{" "}
        </div>
        {msg.text.split(",")[1] !== "null" ? msg.text.split(",")[1] : null}
      </div>
    </div>
  );

export default FeedbackMessage;
