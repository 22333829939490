import React from "react";
import { FormControl, Select, MenuItem } from "@material-ui/core";
import { useStyles } from "./styles.js";
import { titleize } from "../../../utils";

const Metric = ({ metrics, metric, setMetric }) => {
  const classes = useStyles();

  const metricDropdownTitle = (metric) => {
    if (metric) {
      return titleize(metric);
    } else {
      return "Select";
    }
  };

  return (
    <div className="dataset-options__field">
      <label>Metric</label>
      <FormControl variant="outlined" className={classes.formControl}>
        <Select
          value={metric || "Select"}
          renderValue={() => metricDropdownTitle(metric)}
          placeholder="select"
          className={classes.select}
          onChange={(e) => setMetric(e.target.value)}
        >
          {metrics.map((m, index) => {
            return (
              <MenuItem value={m.name} key={index}>
                {titleize(m.name)}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </div>
  );
};

export default Metric;
