import React, { Fragment } from "react";
import { FormControl, Select, MenuItem } from "@material-ui/core";
import BlueCheckbox from "../../Shared/BlueCheckbox";
import { titleize } from "../../../utils";
import { useStyles } from "./styles.js";
import { isSelected, subsetDropdownTitle } from "../utils";

const InvoiceStatus = ({
  parkingLotIds,
  invoiceStatuses,
  invoiceStatus,
  setInvoiceStatus,
}) => {
  const classes = useStyles();

  const changeInvoiceStatus = (status) => {
    if (invoiceStatus?.includes(status)) {
      setInvoiceStatus([...invoiceStatus.filter((v) => v !== status)]);
    } else {
      setInvoiceStatus([status, ...invoiceStatus]);
    }
  };

  return (
    <div className="dataset-options__field">
      <label>Invoice Status</label>
      <FormControl variant="outlined" className={classes.formControl}>
        <Select
          value={isSelected(invoiceStatus)}
          renderValue={() =>
            subsetDropdownTitle(invoiceStatus, "invoice status", parkingLotIds)
          }
          className={classes.select}
          onChange={(e) => {}}
        >
          <Fragment>
            {invoiceStatuses.map((status, index) => {
              return (
                <MenuItem
                  key={index}
                  className={classes.menuItem}
                  onClick={() => changeInvoiceStatus(status)}
                >
                  <BlueCheckbox
                    size="small"
                    checked={invoiceStatus.includes(status)}
                    onChange={() => changeInvoiceStatus(status)}
                    inputProps={{ "aria-label": "secondary checkbox" }}
                  />
                  <div>{titleize(status)}</div>
                </MenuItem>
              );
            })}
          </Fragment>
        </Select>
      </FormControl>
    </div>
  );
};

export default InvoiceStatus;
