import React, { Component } from "react";
import DropdownHeader from "./DropdownHeader";
import DropdownOption from "./DropdownOption";

class DropdownField extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      heading: null,
      selectedValue: null,
      exiting: false, // determines OptionsBox open/close animation
    };
  }

  componentDidMount() {
    const { prompt = "Select" } = this.props;
    this.setState({ heading: prompt });
  }

  openOptions = () => this.setState({ isOpen: true });

  /*
		The following block of functions are used to display an open/close animation on the options box,
		to delay the removal of the OptionsBox component from the DOM until the animation has run.
	*/
  resetOptionsBox = () => this.setState({ isOpen: false, exiting: false });

  wait = () => setTimeout(this.resetOptionsBox, 300);

  closeOptions = () => this.setState({ exiting: true }, this.wait);

  returnSelection = () => {
    const { onChange } = this.props;
    const { selectedValue, heading } = this.state;
    onChange(selectedValue, heading);
  };

  newSelection = (heading, selectedValue) =>
    this.setState({ heading, selectedValue }, this.returnSelection);

  render() {
    const {
      children = [undefined], // in the event that a user does not pass any Option components
      primaryColor,
      secondaryColor,
      width,
      height,
      disabled,
      error,
      title = "",
    } = this.props;
    const { heading, selectedValue, isOpen, exiting } = this.state;
    // a single child does not get passed as an array, so it will fail
    const _children = children.length ? children : [children];

    const childrenWithProps = _children.map((child, key) => {
      if (child) {
        return React.cloneElement(child, {
          key,
          index: key,
          length: _children.length,
          newSelection: this.newSelection,
          secondaryColor,
          heading,
          selectedValue,
        });
      }
      return null;
    });

    return (
      <div className="selectionBox">
        <div
          className="contact-select-container"
          onClick={() => {
            if (!disabled) {
              isOpen ? this.closeOptions() : this.openOptions();
            }
          }}
        >
          <DropdownHeader
            heading={heading}
            isOpen={isOpen}
            width={width}
            height={height}
          />
          {isOpen ? (
            <DropdownOption
              exiting={exiting}
              children={childrenWithProps}
              closeOptions={this.closeOptions}
              width={width}
            />
          ) : null}
        </div>
      </div>
    );
  }
}

export default DropdownField;
