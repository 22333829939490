import React, { useState } from "react";
import { Fragment } from "react";
import moment from "moment-timezone";
import _ from "lodash";
import { useEffect } from "react";
import FullImageMessage from "./FullImageMessage";
import AccidentReportModal from "./AccidentReportModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisH } from "@fortawesome/pro-duotone-svg-icons";
import { ANSWER_CALL_REQUEST } from "../queries";
import { useMutation } from "@apollo/client";
import AccidentReportMessage from "./Messages/AccidentReportMessage";
import CallRequestMessage from "./Messages/CallRequestMessage";
import NormalMessage from "./Messages/NormalMessage";
import FeedbackMessage from "./Messages/FeedbackMessage";
import CallMessage from "./Messages/CallMessage";

const Message = ({
  messages,
  historyMsg,
  refreshTicketCallback,
  ticketId,
  defaultAdmin,
  accidentReport,
  token,
  handleCall,
  handleCancel,
  handleMute,
  callStatus,
  ticketData,
  muted,
  assignTicket,
  handleTagRemove,
}) => {
  const [isFull, setIsFull] = useState(false);
  const [curUrl, setCurUrl] = useState();
  const [isAccident, setIsAccident] = useState();
  const [curMsgId, setCurMsgId] = useState();
  const ticketUser = ticketData?.user;
  const ticketStatus = ticketData?.status;
  const ticketAssignedAdmin = ticketData?.adminUser;

  const timeZone = ticketData?.user?.timeZone;

  let sortedHistoryMsg = {};
  let sortedHisMsg = [...historyMsg];
  sortedHisMsg = sortedHisMsg.sort(
    (a, b) => moment(a.sentAt).toDate() - moment(b.sentAt).toDate()
  );
  sortedHisMsg.forEach((msgs) => {
    const date = moment(msgs.sentAt).toDate().toString().slice(4, 15);
    if (sortedHistoryMsg[moment(date).format("MMM D, YYYY")]) {
      sortedHistoryMsg[moment(date).format("MMM D, YYYY")].push(msgs);
    } else {
      sortedHistoryMsg[moment(date).format("MMM D, YYYY")] = [msgs];
    }
  });

  useEffect(() => {
    refreshTicketCallback({ ticketId: ticketId });
  }, [messages]);

  useEffect(() => {
    sortedHisMsg &&
      sortedHisMsg.length > 0 &&
      setCurMsgId(sortedHisMsg[sortedHisMsg.length - 1].id);
  }, [historyMsg]);

  const toggleImg = () => {
    // e.preventDefault();
    setIsFull(!isFull);
    document.addEventListener("click", closeImg);
  };

  const closeImg = () => {
    setIsFull(false);
    document.removeEventListener("click", closeImg);
  };

  const toggleAccident = () => {
    setIsAccident(!isAccident);
  };

  const [answerCallMutation, loading] = useMutation(ANSWER_CALL_REQUEST);
  const handleCallback = (requestId) => {
    answerCallMutation({
      variables: {
        ticketId: ticketId,
        requestId: requestId,
      },
    }).then(({ data: { answerCallRequest } }) => {
      if (answerCallRequest.success) {
        refreshTicketCallback({ ticketId: ticketId });
        console.log("Answer Succeed!");
      } else {
        console.log("Answer error!");
      }
    });
  };

  return (
    <Fragment>
      <div className="message-container">
        {Object.keys(sortedHistoryMsg).map((date) => (
          <div>
            {sortedHistoryMsg[date].some(
              (message) => message.label === "normal"
            ) && (
              <div className="date-line">
                <div className="line"></div>
                <span>{moment(date).format("MMM D, YYYY")} </span>
                <div className="line"></div>
              </div>
            )}
            {sortedHistoryMsg[date].map((msg) => {
              const sentAt = moment(msg.sentAt).tz(timeZone);
              return (
                <div className="message-box">
                  {((msg.label === "notification" && msg.internal) ||
                    msg.label === "accident") && (
                    <div className="date-line">
                      <div className="line"></div>
                      <div className="notification-container">
                        <span style={{ fontSize: "12px" }}>
                          {sentAt.format("h:mmA MMM D, YYYY z")}{" "}
                        </span>
                        <span style={{ margin: "8px 0" }}>{msg.text} </span>
                        <span style={{ fontSize: "12px", opacity: "0.5" }}>
                          By {msg.senderName}{" "}
                        </span>
                      </div>
                      <div className="line"></div>
                    </div>
                  )}
                  <div className="message-content">
                    <AccidentReportMessage
                      msg={msg}
                      accidentReport={accidentReport}
                      toggleAccident={toggleAccident}
                    />
                    <CallRequestMessage
                      msg={msg}
                      ticketStatus={ticketStatus}
                      assignTicket={assignTicket}
                      handleCallback={handleCallback}
                      handleCall={handleCall}
                      handleTagRemove={handleTagRemove}
                    />
                    {msg.label === "normal" || msg.label === "image" ? (
                      (msg.internal ||
                        msg.direction === "outgoing" ||
                        msg.fromAdmin) && <div>{sentAt.format("h:mmA z")}</div>
                    ) : msg.label === "calling" &&
                      callStatus === "CONNECTED" ? (
                      <div>{sentAt.format("h:mmA z")}</div>
                    ) : (
                      msg.callRecord && <div>{sentAt.format("h:mmA z")}</div>
                    )}
                    {msg.label === "normal" || msg.label === "image" ? (
                      <NormalMessage
                        msg={msg}
                        setCurUrl={setCurUrl}
                        toggleImg={toggleImg}
                        defaultAdmin={defaultAdmin}
                      />
                    ) : msg.label === "feedback" ? (
                      <FeedbackMessage msg={msg} />
                    ) : (
                      <CallMessage
                        msg={msg}
                        token={token}
                        defaultAdmin={defaultAdmin}
                        handleCancel={handleCancel}
                        handleMute={handleMute}
                        ticketUser={ticketUser}
                        muted={muted}
                        record={msg.callRecord && msg.callRecord.recording}
                        admin={msg.callRecord && msg.callRecord.adminUser}
                        duration={
                          msg.callRecord && msg.callRecord.recordingDuration
                        }
                        handleCall={handleCall}
                        handleTagRemove={handleTagRemove}
                        curMsgId={curMsgId}
                        callStatus={callStatus}
                        ticketAssignedAdmin={ticketAssignedAdmin}
                      />
                    )}

                    {(((msg.label === "normal" || msg.label === "feedback") &&
                      !msg.internal &&
                      msg.direction === "incoming" &&
                      !msg.fromAdmin) ||
                      msg.status ||
                      msg.label === "accident") && (
                      <div>{sentAt.format("h:mmA z")}</div>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        ))}
        {messages.length !== 0
          ? _.last(messages).messageType === "typing indicator" &&
            _.last(messages).message === "typing" &&
            defaultAdmin.id !== _.last(messages).sender.toString() && (
              <div className="message-box">
                <div className="message-tag" style={{ padding: "6px 8px" }}>
                  <FontAwesomeIcon icon={faEllipsisH} size="2x" />
                </div>
              </div>
            )
          : null}
      </div>
      <FullImageMessage isFull={isFull} imgUrl={curUrl} />
      <AccidentReportModal
        open={isAccident}
        toggleAccident={toggleAccident}
        accidentReport={accidentReport}
      />
    </Fragment>
  );
};

export default Message;
