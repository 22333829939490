import React, { Fragment, useEffect, useState } from "react";
import Bottom from "./Bottom";
import Top from "./Top";

const InvoicePageContent = ({
  driver,
  driverId,
  bookingId,
  invoiceData,
  create,
  refetch,
}) => {
  const createInvoiceCallback = (childData) => {
    refetch(childData);
  };
  const financingCallback = (childData) => {
    refetch(childData);
  };

  const [tmpTotal, setTmpTotal] = useState();
  const [isCreated, setIsCreated] = useState();

  useEffect(() => {
    refetch();
  }, [isCreated]);

  const setLiveTotal = (childData) => {
    setTmpTotal(childData);
  };

  return (
    <Fragment>
      <Top
        invoiceData={invoiceData}
        driver={driver}
        driverId={driverId}
        bookingId={bookingId}
        create={create}
        isCreated={isCreated}
        tmpTotal={tmpTotal}
        setLiveTotal={setLiveTotal}
        refetch={refetch}
        financingCallback={financingCallback}
      />
      <Bottom
        invoiceData={invoiceData}
        create={create}
        bookingId={bookingId}
        createInvoiceCallback={createInvoiceCallback}
        setLiveTotal={setLiveTotal}
        tmpTotal={tmpTotal}
        refetch={refetch}
        isCreated={isCreated}
        setIsCreated={setIsCreated}
      />
    </Fragment>
  );
};

export default InvoicePageContent;
