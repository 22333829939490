import React, { Component } from "react";
import gql from "graphql-tag";
import { Mutation } from "@apollo/client/react/components";
import { ApolloConsumer } from "@apollo/client";
import Button from "../artzu-ui/src/Button";
import Loading from "../Components/Shared/Loading";

class UpdateUserDetails extends Component {
  UPDATE_USER = gql`
    mutation UpdateUser($userFields: UserAttributes!, $userId: ID!) {
      updateUser(userFields: $userFields, userId: $userId) {
        user {
          firstName
          lastName
          email
          mobile
          accountStatus
        }
      }
    }
  `;

  render() {
    const { userFields, userId, toggleEdit, refetch, edited } = this.props;
    // const organization = "Autzu"
    return (
      <ApolloConsumer>
        {(client) => (
          <Mutation
            mutation={this.UPDATE_USER}
            variables={{
              userFields,
              userId,
            }}
            onCompleted={(data) => {
              if (!data.updateUser.errors) {
                refetch();
                // callBack();
              }
            }}
          >
            {(updateUser) => (
              <button
                className="save-button"
                style={{ color: edited ? "#128dff" : "#87CEFA" }}
                onClick={() => {
                  edited && updateUser();
                  edited && toggleEdit();
                }}
              >
                Save
              </button>
            )}
          </Mutation>
        )}
      </ApolloConsumer>
    );
  }
}

export default UpdateUserDetails;
