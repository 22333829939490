import React from "react";
import styles from "../../bookingActions.module.scss";

const VehicleData = ({ title, value }) => (
  <div>
    <div
      className={styles.bold}
      style={{ width: title === "Battery Level" && "max-content" }}
    >
      {title}
    </div>
    <div>{value || "-"}</div>
  </div>
);

export default VehicleData;
