import React from "react";
import styles from "./batchActions.module.scss";
import { faTimes } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "../../Shared/Button";

const Selections = ({
  selectedUsers,
  removeSelection,
  close,
  isStatus,
  isAllSelected,
}) => {
  return (
    <div className={styles.selections}>
      {isAllSelected ? (
        <div className={styles.allSelection}>
          {selectedUsers.length} users selected
        </div>
      ) : (
        <div className={styles.header}>Users: {selectedUsers.length}</div>
      )}
      <div
        className={
          isStatus ? styles.selectionsBodyStatus : styles.selectionsBody
        }
      >
        {!isAllSelected &&
          selectedUsers.map((selection) => (
            <div className={styles.individualSelection}>
              <div className={styles.identifier}>{selection}</div>
              <FontAwesomeIcon
                onClick={(evt) => {
                  evt.stopPropagation();
                  removeSelection(selection);
                }}
                icon={faTimes}
              />
            </div>
          ))}
      </div>
      <div className={styles.selectionsFooter}>
        <Button
          label={"Cancel"}
          height="39px"
          fullWidth="150px"
          color="#414141"
          radius="20px"
          size="16px"
          weight="700"
          border="1px solid #DEDEDE"
          onClick={close}
        />
      </div>
    </div>
  );
};

export default Selections;
