import React from "react";
import "./invoice.css";
import InvoiceHeader from "./InvoiceHeader";
import InvoicePageContent from "./InvoicePageContent";
import { useLazyQuery, useQuery } from "@apollo/client";
import { GET_INVOICE } from "../queries";
import { useLocation, useRouteMatch } from "react-router";
import Loading from "../../Shared/Loading";
import { Helmet } from "react-helmet";
import { Fragment } from "react";

const Invoice = () => {
  const match = useRouteMatch();
  const invoiceId = match.params.id;

  const [getInvoice, { data, loading, refetch, called }] = useLazyQuery(
    GET_INVOICE,
    {
      variables: { invoiceId: invoiceId },
      fetchPolicy: "no-cache",
    }
  );

  const location = useLocation();
  const driver = location && location.driverName;
  const driverId = location && location.driverId;
  const bookingId = location && location.bookingId;
  const create = location && location.create;

  if (invoiceId && !called) {
    getInvoice();
  }

  return (
    <Fragment>
      <Helmet>
        <title>Payments | Autzu Admin</title>
      </Helmet>
      {loading || (
        <div className="invoice-page">
          <InvoiceHeader invoiceData={data} />
          <InvoicePageContent
            invoiceData={data}
            driver={driver}
            driverId={driverId}
            bookingId={bookingId}
            create={create}
            refetch={refetch}
          />
        </div>
      )}
    </Fragment>
  );
};

export default Invoice;
