import SearchBar from "../../../../../../Shared/SearchBar";
import React from "react";
import styles from "../../bookingActions.module.scss";

const Search = ({ searchValue, setSearchValue }) => (
  <div className={styles.searchContainer}>
    <div className={styles.searchBarContainer}>
      <div className={styles.searchBar}>
        <SearchBar
          width="95%"
          placeholder="Search by Plate, Model"
          color="#ffffff"
          onChange={setSearchValue}
          value={searchValue}
          submit={null}
        />
      </div>
    </div>
    <div className={styles.searchNote}>(only showing available vehicles)</div>
  </div>
);

export default Search;
