import React from "react";
import moment from "moment-timezone";

const NoteHeader = ({ note, timeZone }) => {
  return (
    <div className="note-header">
      <div className="notes-user-image">
        <div className="userImageContainer">
          <img src={note.author.profileImage} style={{ width: "100%" }} />
        </div>
        <p className="note-user">
          {note.author.firstName + note.author.lastName}:{" "}
        </p>
      </div>
      <div className="note-date-container">
        <div className="note-date">
          {moment(note.createdAt).tz(timeZone).format("MMM D, YYYY hh:mm A z")}
        </div>
      </div>
    </div>
  );
};

export default NoteHeader;
