import React, { useState, useEffect, useRef } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { GET_DATA, UPSERT_PRESET } from "./misc/gqlQueries";
import gql from "graphql-tag";
import Table from "./Table";
import ActionBar from "./ActionBar";
import NavBar from "./NavBar";
import Tabs from "./Tabs";
import styles from "./informationTable.module.scss";
import Loading from "./Loading";
import Footer from "./Footer";
import _ from "lodash";
import { observer, inject } from "mobx-react";
// import ManageAllTagsModal from "./BatchActions/Actions/AddTag/ManageTags/ManageAll";
import { Fragment } from "react";
import BookingBatchActions from "./BatchActions/Bookings";
import UserBatchActions from "./BatchActions/UserBatchActions";
import CommunicationBatchActions from "./BatchActions/CommunicationBatchActions";
import NewTicketNotification from "../../sounds/notification-sound.wav";
import PasswordModal from "./PasswordModal";

const InformationTable = observer(
  ({
    rowComponentType,
    background,
    tableName,
    navbar,
    startTerms,
    userSpecific,
    id,
    cities,
    popupSpecific,
    popupFunc,
    currentBookingId,
    bookingSpecific,
    timeZone,
  }) => {
    const [paginationArguments, setPaginationArguments] = useState({
      page: 1,
      itemsPerPage: 10,
    });

    const [searchValue, setSearchValue] = useState(null);

    const [selectedRows, setSelectedRows] = useState({});

    const [isEditModeActivated, setIsEditModeActivated] = useState(false);

    const [edits, setEdits] = useState({});

    const [isEditModalOpen, setIsEditModalOpen] = useState(false);

    const [isImportModalOpen, setIsImportModalOpen] = useState(false);

    const [isExportModalOpen, setIsExportModalOpen] = useState(false);

    const [isPassword, setIsPassword] = useState(false);

    const [initialized, setInitialized] = useState(false);

    const updatePageNumberFromLocalStorage = () => {
      if (JSON.parse(window.localStorage.getItem("tableName")) === tableName) {
        setPaginationArguments({
          page: JSON.parse(window.localStorage.getItem("pageNumber")) || 1,
          itemsPerPage: 10,
        });
      }
    };

    const storePageNumberLocally = () => {
      window.localStorage.setItem(
        "pageNumber",
        JSON.stringify(paginationArguments.page)
      );
    };

    const storeTableNameLocally = () => {
      window.localStorage.setItem("tableName", JSON.stringify(tableName));
    };

    useEffect(() => {
      updatePageNumberFromLocalStorage();
    }, []);

    useEffect(() => {
      storePageNumberLocally();
    }, [paginationArguments]);

    useEffect(() => {
      storeTableNameLocally();
    }, [tableName]);

    const [currentAdminUserOnly, setCurrentAdminUserOnly] = useState(false);

    const modifyEdits = (rowId, changedValues) => {
      const editsCopy = _.cloneDeep(edits);
      const newValues = { ...(editsCopy[rowId] || {}), ...changedValues };
      editsCopy[rowId] = newValues;
      setEdits(editsCopy);
    };

    const startImport = () => setIsImportModalOpen(true);
    const closeImport = () => setIsImportModalOpen(false);

    const startExport = () => setIsExportModalOpen(true);
    const closeExport = () => setIsExportModalOpen(false);

    const selectRow = (rowId) => {
      const stateCopy = { ...selectedRows };
      const rowState = stateCopy[rowId];

      const newState = rowState ? false : true;

      stateCopy[rowId] = newState;
      setSelectedRows(stateCopy);
    };

    const baseVariables = {
      tableName: tableName,
      paginationArguments: paginationArguments,
      searchValue: searchValue,
      idToFilter: id ? id : null,
      citiesToFilter: cities ? cities : null,
      currentBookingId: currentBookingId ? currentBookingId : null,
      currentAdminUserOnly: currentAdminUserOnly,
    };
    const { loading, error, data, refetch, startPolling, stopPolling } =
      useQuery(GET_DATA, {
        variables: baseVariables,
        fetchPolicy: "network-only",
        // pollInterval: 1000,
      });
    useEffect(() => {
      startPolling(60000);
      return () => {
        stopPolling();
      };
    }, [startPolling, stopPolling]);

    const reloadPage = (changedVariables) => {
      const newVariables = {
        ...baseVariables,
        ...changedVariables,
      };
      refetch(newVariables);
    };

    useEffect(() => {
      if (initialized) {
        const newVariables = { citiesToFilter: cities };
        reloadPage(newVariables);
      } else {
        setInitialized(true);
      }
    }, [cities]);
    
    //sets page to one after searchvalue is detected to be changes, delete if too aggressive
    useEffect(() => {
      setPaginationArguments({
        page: 1,
        itemsPerPage: 10,
      });
    }, [searchValue]);

    const changeSort = (column, direction) => {
      const newVariables = {
        sortArguments: {
          column: column,
          direction: direction,
        },
      };

      reloadPage(newVariables);
    };

    const changePage = (newPage) => {
      const newPaginationArguments = {
        ...paginationArguments,
        page: newPage,
      };

      const newVariables = {
        paginationArguments: newPaginationArguments,
      };

      setPaginationArguments(newPaginationArguments);
      reloadPage(newVariables);
    };

    const changeSearchValue = (newSearchValue) => {
      const newVariables = {
        searchValue: newSearchValue,
      };

      setSearchValue(newSearchValue);
      reloadPage(newVariables);
    };

    const changeColumnsValue = (newColumnsValue) => {
      const newVariables = {
        columnNames: newColumnsValue,
      };
      reloadPage(newVariables);
    };

    const changeSelectedTab = (newSelectedTab) => {
      const newVariables = {
        selectedTab: newSelectedTab,
      };
      reloadPage(newVariables);
    };

    const changeParkingLotFilter = (newParkingLotNames) => {
      const newVariables = {
        parkingLotNames: newParkingLotNames,
      };
      reloadPage(newVariables);
    };

    const changeFilters = (newFilters) => {
      const newVariables = {
        filters: newFilters,
      };
      reloadPage(newVariables);
    };

    const changeAdminUserOnly = () => {
      const newVariables = {
        currentAdminUserOnly: !currentAdminUserOnly,
      };

      setCurrentAdminUserOnly(!currentAdminUserOnly);
      reloadPage(newVariables);
    };
    console.log(data);
    if (data) {
      
      const { table, filters, columns, sort, tabs } = data.getInformationPage;
      if (userSpecific && filters.savedGroups){
        changeFilters([])
      }
      const { currentTabKeys } = tabs;
      const currentTabIds = currentTabKeys.map((key) => key.id);

      const currentTabKeysObject = currentTabKeys.reduce((h, value) => {
        h[value.id] = value;
        return h;
      }, {});

      const presetSearch = filters.searchValue;

      const isFilterApplied = filters.savedGroups.length !== 0 && !userSpecific;

      const isAllSelected =
        !_.isEmpty(selectedRows) &&
        !_.some(selectedRows, (value, key) => value === false) &&
        Object.keys(selectedRows).length === currentTabIds.length;

      const clearAllSelections = () => {
        const newSelected = currentTabIds.reduce((h, value) => {
          h[String(value)] = false;
          return h;
        }, {});
        setSelectedRows(newSelected);
      };

      const selectAllRows = () => {
        if (isAllSelected) {
          const newSelected = currentTabIds.reduce((h, value) => {
            h[String(value)] = false;
            return h;
          }, {});
          setSelectedRows(newSelected);
        } else {
          const newSelected = _.reduce(
            currentTabIds,
            (h, value, index) => {
              h[String(value)] = true;
              return h;
            },
            {}
          );
          setSelectedRows(newSelected);
        }
      };

      const selectedRowCount = Object.keys(
        _.filter(selectedRows, (value) => value)
      ).length;

      const selectedRowsWithIdentifier = _.reduce(
        selectedRows,
        (result, value, key) => {
          if (value) {
            result.push(currentTabKeysObject[key]);
          }
          return result;
        },
        []
      );

      const togglePassword = () => {
        setIsPassword(!isPassword);
      };

      const removeSelection = (id) => selectRow(id);

      return (
        <Fragment>
          <div
            className={
              userSpecific
                ? styles.containerSmall
                : popupSpecific
                ? styles.containerPopup
                : styles.container
            }
            style={{ marginLeft: userSpecific ? "-32px" : "0" }}
          >
            {!bookingSpecific && !userSpecific && !popupSpecific && (
              <NavBar navbar={navbar} />
            )}
            <ActionBar
              edits={edits}
              rowIdToIdentifierObject={currentTabKeysObject}
              modifyEdits={modifyEdits}
              isEditModeActivated={isEditModeActivated}
              isEditModalOpen={isEditModalOpen}
              startImport={startImport}
              closeImport={closeImport}
              startExport={startExport}
              closeExport={closeExport}
              isImportModalOpen={isImportModalOpen}
              isExportModalOpen={isExportModalOpen}
              filters={filters}
              columns={columns}
              searchValue={searchValue}
              presetSearch={presetSearch}
              changeSearchValue={changeSearchValue}
              changeColumnsValue={changeColumnsValue}
              changeFilters={changeFilters}
              clearAllSelections={clearAllSelections}
              selectedRowsCount={selectedRowCount}
              background={background}
              startTerms={startTerms}
              userSpecific={userSpecific}
              popupSpecific={popupSpecific}
              tableName={tableName}
              userId={id}
              currentAdminUserOnly={currentAdminUserOnly}
              changeAdminUserOnly={changeAdminUserOnly}
              reloadPage={reloadPage}
              bookingSpecific={bookingSpecific}
              timeZone={timeZone}
            />
            <Tabs
              tabs={tabs}
              changeSelectedTab={changeSelectedTab}
              userSpecific={userSpecific}
              isAllSelected={isAllSelected}
            />
            <Table
              edits={edits}
              isEditModeActivated={isEditModeActivated}
              modifyEdits={modifyEdits}
              isAllSelected={isAllSelected}
              selectAllRows={selectAllRows}
              selectRow={selectRow}
              selectedRows={selectedRows}
              tabs={tabs}
              tableData={table}
              changeSort={changeSort}
              currentSort={sort}
              paginationArguments={paginationArguments}
              changeSelectedTab={changeSelectedTab}
              rowComponentType={rowComponentType}
              refetch={refetch}
              baseVariables={baseVariables}
              userSpecific={userSpecific}
              popupSpecific={popupSpecific}
              tableName={tableName}
              popupFunc={popupFunc}
              togglePassword={togglePassword}
              isFilterApplied={isFilterApplied}
            />
            <Footer
              totalRowCount={table.totalRowCount}
              paginationArguments={paginationArguments}
              changePage={changePage}
              userSpecific={userSpecific}
            />
          </div>
          {tableName === "bookings" && (
            <BookingBatchActions
              isOpen={selectedRowCount > 0}
              selectedRows={selectedRows}
              clearAllSelections={clearAllSelections}
              refetch={refetch}
              tabs={tabs}
            />
          )}
          {tableName === "users" && (
            <UserBatchActions
              isOpen={selectedRowCount > 0}
              removeSelection={removeSelection}
              selectedRows={selectedRows}
              clearAllSelections={clearAllSelections}
              tabs={tabs}
              refetch={refetch}
              isAllSelected={isAllSelected}
              citiesFilter={cities}
            />
          )}
          {tableName === "tickets" && (
            <CommunicationBatchActions
              isOpen={selectedRowCount > 0}
              selectedRows={selectedRows}
              clearAllSelections={clearAllSelections}
              tabs={tabs}
              refetch={refetch}
            />
          )}
          <PasswordModal
            open={isPassword}
            togglePassword={togglePassword}
            selectAllRows={selectAllRows}
          />
        </Fragment>
      );
    } else if (loading) {
      return (
        <div className={styles.container}>
          <Loading />
        </div>
      );
    }
  }
);

export default InformationTable;
