import React, { Fragment, useState } from "react";
import UserDocumentsCommentsData from "../../../../Queries/UserDocumentsCommentsData";
import DocumentsCarousel from "./DocumentsCarousel";
import DocumentsContent from "./DocumentsContent";
import styles from "../userDetails.module.scss";

const DocumentsBody = ({ user, refetch, refetchProfile, id }) => {
  return (
    <Fragment>
      <div className={styles.documentsPageBody}>
        <DocumentsCarousel user={user} refetchProfile={refetchProfile} />
        <div className={styles.documentsCanvas}>
          <DocumentsContent
            user={user}
            refetch={refetch}
            refetchProfile={refetchProfile}
          />
          <div>
            <UserDocumentsCommentsData id={id} />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default DocumentsBody;
