import React from "react";
import styles from "../../userDetails.module.scss";

const TextInput = ({ text, isHalf, triggerChange, manualInputs }) => {
  const changeInputValue = (e) => {
    text === "First name" &&
      triggerChange({ ...manualInputs, firstName: e.target.value });
    text === "Last name" &&
      triggerChange({ ...manualInputs, lastName: e.target.value });
    text === "Address" &&
      triggerChange({ ...manualInputs, address: e.target.value });
    text === "Driver's license number" &&
      triggerChange({ ...manualInputs, licenseNumber: e.target.value });
    text === "Reference" &&
      triggerChange({ ...manualInputs, refNumber: e.target.value });
    text === "Height" &&
      triggerChange({ ...manualInputs, height: e.target.value });
    text === "Class" &&
      triggerChange({ ...manualInputs, licenseClass: e.target.value });
  };
  return (
    <div className={styles.field}>
      <div className={styles.subscript}>{text}</div>
      <input
        className={styles.inputField}
        style={{ width: isHalf && "216px" }}
        onChange={(e) => changeInputValue(e)}
      ></input>
    </div>
  );
};

export default TextInput;
