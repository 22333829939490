import React from "react";
import classNames from "classnames";
import styles from "./baseValue.module.scss";
import { capitalize } from "lodash";

const PaymentStatusValue = ({ cellData, classes }) => {
  const { text } = cellData;
  const getColor = (text) => {
    switch (text) {
      case "pending":
        return "#9B51E0";
      case "paid":
        return "#3dc700";
      case "unpaid":
        return "#ff0000";
      case "financed":
        return "#f2994a";
      case "deleted":
        return "rgba(65, 65, 65, 0.5)";
      case "credit":
        return "#3dc700";
      default:
        break;
    }
  };
  return (
    <div className={classNames(classes)}>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div
          className={styles.textValue}
          style={{ color: `${getColor(text)}` }}
        >
          {capitalize(text)}
        </div>
      </div>
    </div>
  );
};

export default PaymentStatusValue;
