import { gql } from "@apollo/client";

const CHANGE_CITY = gql`
  mutation changeCityFilters($cityIds: [ID!]!) {
    changeCityFilters(cityIds: $cityIds) {
      success
      errors
    }
  }
`;

export { CHANGE_CITY };
