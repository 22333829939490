import React, { Component } from "react";
import styles from "./dropdown.module.scss";

class DropdownOption extends Component {
  componentDidMount() {
    document.addEventListener("click", this.props.closeOptions);
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.props.closeOptions);
  }

  render() {
    const { children, width, ...props } = this.props;
    return (
      <div className={styles.dropdownOption} style={{ width: `${width}` }}>
        {children}
      </div>
    );
  }
}

export default DropdownOption;
