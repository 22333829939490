import React, { Component } from "react";
import Loading from "../../Shared/Loading";
import { useQuery } from "@apollo/client";
import TicketPage from "./TicketPage";
import {
  DEFAULT_ADMIN_USER,
  GET_TICKET_TOKEN,
} from "./TicketPageContent/queries";
import { useLocation } from "react-router";

const Index = (props) => {
  const { data: adminUserData, loading } = useQuery(DEFAULT_ADMIN_USER, {
    fetchPolicy: "network-only",
  });
  const { data: tokenData } = useQuery(GET_TICKET_TOKEN);
  const location = useLocation();
  const driverId = location && location.driverId;

  if (loading) {
    return (
      <div
        style={{
          width: "100%",
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Loading />
      </div>
    );
  }
  if (adminUserData.currentAdminUser) {
    return (
      <TicketPage
        defaultAdmin={adminUserData.currentAdminUser}
        token={tokenData?.getToken}
        presetDriver={driverId}
      />
    );
  }
};

export default Index;
