import React from "react";
import { useMutation } from "@apollo/client";
import {
  RESOLVE_TICKET,
  REOPEN_TICKET,
  CREATE_TICKET,
  UPDATE_TICKET,
} from "../queries";
import { useHistory } from "react-router";
import { Fragment } from "react";
import AssignTicketModal from "./AssignTicketModal";
import { useState } from "react";
import TicketActionPopup from "./TicketActionPopup";
import { faSpinner } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ChangeStatusButton = ({
  ticketData,
  refreshTicketCallback,
  ableToCreate,
  userId,
  bookingId,
  category,
  subject,
  adminId,
  adminName,
  adminIdCallback,
  isEdit,
  toggleEdit,
}) => {
  const history = useHistory();
  const [isAssign, setIsAssign] = useState();
  const [isPopup, setIsPopup] = useState();

  const toggleAssign = () => {
    setIsAssign(!isAssign);
  };

  const togglePopup = () => {
    setIsPopup(!isPopup);
  };

  const getLabel = (status) => {
    switch (status) {
      case "unassigned":
        return "Assign ticket";
      case "call_requested":
        return "Assign ticket";
      case "in_progress":
        return "Resolve ticket";
      case "resolved":
        return "Re-open ticket";
      case "deleted":
        return "Recover ticket";
      case "new":
        return "Create ticket";
      case "save":
        return "Save";
      case "backlog":
        return "Resolve ticket";
      default:
        break;
    }
  };

  const getAction = (status) => {
    switch (status) {
      case "in_progress":
        resolveTicket();
        break;
      case "resolved":
        togglePopup();
        break;
      case "unassigned":
        toggleAssign();
        break;
      case "deleted":
        togglePopup();
        break;
      case "new":
        createTicket();
        break;
      case "save":
        updateTicket();
        break;
      case "backlog":
        resolveTicket();
        break;
      default:
        break;
    }
  };

  const [resolveTicketMutation, { loading: isResolveLoading }] =
    useMutation(RESOLVE_TICKET);
  const resolveTicket = () => {
    resolveTicketMutation({
      variables: {
        ticketId: ticketData.id,
      },
    }).then(({ data: { resolveSupportTicket } }) => {
      if (resolveSupportTicket.ticket) {
        refreshTicketCallback({ ticketId: ticketData.id });
        console.log("Resolve Succeed!");
      } else {
        console.log("Resolve error!");
      }
    });
  };
  const [updateTicketMutation, { loading: isUpdateLoading }] =
    useMutation(UPDATE_TICKET);
  const updateTicket = () => {
    updateTicketMutation({
      variables: {
        ticketId: ticketData.id,
        adminUserId: adminId ? adminId : null,
        bookingId: bookingId ? bookingId : null,
        category: category ? category : null,
        subject: subject ? subject : null,
        userId: ticketData.user.id,
      },
    }).then(({ data: { updateSupportTicket } }) => {
      if (updateSupportTicket.success) {
        refreshTicketCallback({ ticketId: ticketData.id });
        toggleEdit();
        console.log("Update Succeed!");
      } else {
        console.log("Update error!");
      }
    });
  };
  const [createTicketMutation, { loading: isCreateLoading }] =
    useMutation(CREATE_TICKET);
  const createTicket = () => {
    createTicketMutation({
      variables: {
        bookingId: bookingId ? bookingId : null,
        category: category,
        description: "Ticket created",
        subject: subject,
        userId: userId,
        adminUserId: adminId,
      },
    }).then(({ data: { createSupportTicket } }) => {
      if (createSupportTicket.ticket) {
        refreshTicketCallback({ ticketId: createSupportTicket.ticket.id });
        console.log("Create Succeed!");
        history.push({
          pathname: `/communications/tickets/${createSupportTicket.ticket.id}`,
        });
      } else {
        console.log("Create error!");
      }
    });
  };

  const ableToAct = ticketData && ticketData.status ? true : ableToCreate;
  const isLoading = isCreateLoading || isUpdateLoading || isResolveLoading;

  return (
    <Fragment>
      <div className="change-status-button">
        <button
          className="multi-action-button"
          style={{ backgroundColor: ableToAct ? "#128dff" : "#87cefa" }}
          onClick={() =>
            !isLoading &&
            ableToAct &&
            getAction(isEdit ? "save" : ticketData ? ticketData.status : "new")
          }
        >
          {isLoading ? (
            <FontAwesomeIcon
              style={{ color: "#ffffff" }}
              spin
              size={"1x"}
              icon={faSpinner}
            />
          ) : (
            getLabel(isEdit ? "save" : ticketData ? ticketData.status : "new")
          )}
        </button>
      </div>
      <AssignTicketModal
        open={isAssign}
        adminIdCallback={adminIdCallback}
        ticketData={ticketData}
        refreshTicketCallback={refreshTicketCallback}
        toggleAssign={toggleAssign}
      />
      <TicketActionPopup
        open={isPopup}
        action={getLabel(ticketData ? ticketData.status : "new")}
        refreshTicketCallback={refreshTicketCallback}
        togglePopup={togglePopup}
        ticketData={ticketData}
      />
    </Fragment>
  );
};

export default ChangeStatusButton;
