import { gql } from "@apollo/client";

const PARSE_CSV = gql`
  mutation parseCsv($file: Upload!, $csvType: String!) {
    parseCsv(file: $file, csvType: $csvType) {
      csvData
      errors
      headers
    }
  }
`;

const BULK_IMPORT = gql`
  mutation bulkImportData($data: JSON!, $dataType: String!) {
    bulkImportData(data: $data, dataType: $dataType) {
      invoices {
        id
        total
      }
    }
  }
`;

const DOWNLOAD_CSV = gql`
  mutation downloadCsv(
    $tableName: String!
    $password: String!
    $reason: String!
  ) {
    downloadCsv(tableName: $tableName, password: $password, reason: $reason) {
      success
      errors
    }
  }
`;

export { PARSE_CSV, BULK_IMPORT, DOWNLOAD_CSV };
