import React, { Component } from "react";
import { Query } from "@apollo/client/react/components";
import gql from "graphql-tag";
import UserAnalytics from "../Components/Analytics/UserAnalytics";
import { userAnalyticIndexString } from "./analyticsQueryStrings";
import Loading from "../Components/Shared/Loading";
import { titleToCamel } from "../utils";

class UserAnalyticIndex extends Component {
  render() {
    return (
      <Query
        query={gql`
          ${userAnalyticIndexString}
        `}
        fetchPolicy="network-only"
      >
        {({ loading, error, data, refetch }) => {
          if (error) {
            return null;
          } else if (loading) {
            return <Loading />;
          }

          if (data.userSpecificAnalyticMetrics) {
            return (
              <UserAnalytics
                {...this.props}
                metrics={data.userSpecificAnalyticMetrics}
                locations={data.locationData}
                accountStatuses={data.accountStatuses}
                invoiceStatuses={data.invoiceStatuses}
                analyticParams={data.currentAdminUser.analyticParams}
                refetch={refetch}
              />
            );
          } else {
            return null;
          }
        }}
      </Query>
    );
  }
}

export default UserAnalyticIndex;
