import React from "react";
import { Link } from "react-router-dom";
import moment from "moment-timezone";
import RelatedBookingModal from "./RelatedBookingModal";
import { Fragment } from "react";
import { useState } from "react";
import { useQuery } from "@apollo/client";
import { BOOKING_DETAILS } from "../../../../../Payments/queries";
import { useEffect } from "react";

const RelatedBooking = ({
  bookingData,
  userId,
  bookingIdCallback,
  isEdit,
  ticketData,
  isCancel,
  refreshTicketCallback,
  userData,
  relatedUserData
}) => {
  const [isBooking, setIsBooking] = useState();
  const [selectedBookingId, setSelectedBookingId] = useState();

  const bookingId = bookingData && bookingData.id;
  const fee = bookingData && bookingData.bookingFee;
  const start = bookingData && bookingData.startTime;
  const finish = bookingData && bookingData.endTime;
  const car = bookingData && bookingData.car;
  const plate = car && car.plate;
  const vehicle = car && car.make + car.model;
  const swapped =
    bookingData && bookingData.carHistory && bookingData.carHistory.length > 1;

  //replace this with booking specific hub
  const hub = userData
  ? userData.parkingLot && userData.parkingLot.name
  : relatedUserData &&
    relatedUserData.parkingLot &&
    relatedUserData.parkingLot.name;

  const toggleBooking = () => {
    setIsBooking(!isBooking);
  };

  const ChosenBooking = (childData) => {
    setSelectedBookingId(childData);
    bookingIdCallback(childData);
  };

  const { data, loading } = useQuery(BOOKING_DETAILS, {
    variables: { id: selectedBookingId && selectedBookingId },
  });

  const newBookingData = data && data.bookingDetails;
  const newBookingId = newBookingData && newBookingData.id;
  const newFee = newBookingData && newBookingData.bookingFee;
  const newStart = newBookingData && newBookingData.startTime;
  const newFinish = newBookingData && newBookingData.endTime;
  const newCar = newBookingData && newBookingData.car;
  const newPlate = newCar && newCar.plate;
  const newVehicle = newCar && newCar.make + " " + newCar.model;
  const newSwapped =
    newBookingData &&
    newBookingData.carHistory &&
    newBookingData.carHistory.length > 1;

  const timeZone = bookingData?.timeZone || newBookingData?.timeZone;

  useEffect(() => {
    ChosenBooking(ticketData && ticketData.booking && ticketData.booking.id);
    refreshTicketCallback(ticketData && ticketData.id);
  }, [isCancel]);

  return (
    <Fragment>
      {bookingData ? (
        <div className="booking-info-container">
          <div className="booking-info-header">
            <h2>Related booking</h2>
            <div
              className="choose-a-booking"
              onClick={() => toggleBooking()}
              style={{ cursor: "pointer" }}
            >
              {isEdit ? "Change a booking" : null}
            </div>
          </div>

          <div className="details-container">
            <div className="booking-details-field">
              <div className="subscript">Booking ID</div>
              <Link
                to={`/users/${ticketData.user.id}/bookings/${
                  isEdit ? newBookingId : bookingId
                }`}
                target="_blank"
                style={{ textDecoration: "none" }}
              >
                <div className="detail-field" style={{ color: "#128dff" }}>
                  {isEdit ? newBookingId : bookingId}
                </div>
              </Link>
            </div>
            <div className="booking-details-field">
              <div className="subscript">Hub</div>
              <div className="detail-field">{hub}</div>
            </div>
            <div className="booking-details-field">
              <div className="subscript">Booking fee</div>
              <div className="detail-field">${isEdit ? newFee : fee}</div>
            </div>
            <div className="booking-details-field">
              <div className="subscript">Start</div>
              <div className="detail-field">
                {isEdit
                  ? newStart &&
                    moment(newStart).tz(timeZone).format("h:mmA ddd")
                  : start && moment(start).tz(timeZone).format("h:mmA ddd")}
              </div>
              <div className="detail-field" style={{ opacity: "0.5" }}>
                {isEdit
                  ? newStart &&
                    moment(newStart).tz(timeZone).format("MMM D, YYYY z")
                  : start && moment(start).tz(timeZone).format("MMM D, YYYY z")}
              </div>
            </div>
            <div className="booking-details-field">
              <div className="subscript">Finish</div>
              <div className="detail-field">
                {isEdit
                  ? newFinish &&
                    moment(newFinish).tz(timeZone).format("h:mmA ddd")
                  : finish && moment(finish).tz(timeZone).format("h:mmA ddd")}
              </div>
              <div className="detail-field" style={{ opacity: "0.5" }}>
                {isEdit
                  ? newFinish &&
                    moment(newFinish).tz(timeZone).format("MMM D, YYYY z")
                  : finish &&
                    moment(finish).tz(timeZone).format("MMM D, YYYY z")}
              </div>
            </div>
            <div className="booking-details-field">
              <div className="subscript">Plate</div>
              <div className="detail-field">
                {isEdit ? newPlate : plate} {swapped ? "(swapped)" : ""}
              </div>
            </div>
            <div className="booking-details-field">
              <div className="subscript">Vehicle</div>
              <div className="detail-field">
                {isEdit ? newVehicle : vehicle}
              </div>
            </div>
          </div>
        </div>
      ) : newBookingData ? (
        <div className="booking-info-container">
          <div className="booking-info-header">
            <h2>Related booking</h2>
            <div className="choose-a-booking" onClick={toggleBooking}>
              {newBookingData ? "Change a booking" : "Choose a booking"}
            </div>
          </div>
          <div className="details-container">
            <div className="booking-details-field">
              <div className="subscript">Booking ID</div>
              <Link
                to={`/users/${
                  userId ? userId : ticketData.user.id
                }/bookings/${newBookingId}`}
                target="_blank"
                style={{ textDecoration: "none" }}
              >
                <div className="detail-field" style={{ color: "#128dff" }}>
                  {newBookingId}
                </div>
              </Link>
            </div>
            <div className="booking-details-field">
              <div className="subscript">Booking fee</div>
              <div className="detail-field">${newFee}</div>
            </div>
            <div className="booking-details-field">
              <div className="subscript">Start</div>
              <div className="detail-field">
                {newStart && moment(newStart).tz(timeZone).format("h:mmA ddd")}
              </div>
              <div className="detail-field" style={{ opacity: "0.5" }}>
                {newStart &&
                  moment(newStart).tz(timeZone).format("MMM D, YYYY z")}
              </div>
            </div>
            <div className="booking-details-field">
              <div className="subscript">Finish</div>
              <div className="detail-field">
                {newFinish &&
                  moment(newFinish).tz(timeZone).format("h:mmA ddd")}
              </div>
              <div className="detail-field" style={{ opacity: "0.5" }}>
                {newFinish &&
                  moment(newFinish).tz(timeZone).format("MMM D, YYYY z")}
              </div>
            </div>
            <div className="booking-details-field">
              <div className="subscript">Plate</div>
              <div className="detail-field">
                {newPlate} {newSwapped ? "(swapped)" : ""}
              </div>
            </div>
            <div className="booking-details-field">
              <div className="subscript">Vehicle</div>
              <div className="detail-field">{newVehicle}</div>
            </div>
          </div>
        </div>
      ) : (
        <div className="booking-info-container">
          <div className="booking-info-header">
            <h2>Related booking</h2>
            <div
              className="choose-a-booking"
              onClick={() =>
                isEdit
                  ? toggleBooking()
                  : ticketData
                  ? null
                  : userId && toggleBooking()
              }
              style={{
                opacity: isEdit
                  ? "1"
                  : ticketData
                  ? "0.5"
                  : userId
                  ? "1"
                  : "0.5",
                cursor: isEdit
                  ? "pointer"
                  : ticketData
                  ? "default"
                  : userId
                  ? "pointer"
                  : "default",
              }}
            >
              {bookingData === null
                ? "Choose a booking"
                : newBookingData
                ? "Change a booking"
                : "Choose a booking"}
            </div>
          </div>
          <div className="no-driver">No related booking</div>
        </div>
      )}
      <RelatedBookingModal
        open={isBooking}
        toggleBooking={toggleBooking}
        ChosenBooking={ChosenBooking}
        userId={userId}
        ticketData={ticketData}
      />
    </Fragment>
  );
};

export default RelatedBooking;
