import React, { useState } from "react";
import Modal from "../../../../../artzu-ui/src/Modal";
import { faSearch } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-light-svg-icons";
import { GET_ADMIN_NAME, GET_ADMIN_USER_INFO } from "../queries";
import { useQuery } from "@apollo/client";
import { faSpinner } from "@fortawesome/pro-light-svg-icons";
import Dropdown from "../../../../InformationTable/ActionBar/Filters/OptionsModal/Body/Filter/Dropdown";
import { useEffect } from "react";

const AssignAdminModal = ({ open, toggleAdmin, adminIdCallback }) => {
  const [selectedAdminId, setSelectedAdminId] = useState();
  const [newAdmin, setNewAdmin] = useState();

  const { data, loading } = useQuery(GET_ADMIN_NAME);

  const allAdmins = data
    ? data.allAdminName.map((admin) => {
        return {
          name: admin.firstName + " " + admin.lastName,
          value: admin.firstName + " " + admin.lastName,
        };
      })
    : [];

  const triggerAdmin = (childData) => {
    setNewAdmin(childData);
  };

  useEffect(() => {
    data &&
      setSelectedAdminId(
        data.allAdminName.find(
          (item) => item.firstName + " " + item.lastName === newAdmin
        )
          ? data.allAdminName.find(
              (item) => item.firstName + " " + item.lastName === newAdmin
            ).id
          : null
      );
  }, [newAdmin]);

  const ableToApply = newAdmin;

  return (
    <Modal open={open} backgroundColor="none">
      <div className="related-user-container">
        <div className="relate-user-title">Change agent</div>
        <div className="user-input">
          Assign to
          <div className="search-add-container">
            <Dropdown
              initialValue={newAdmin}
              items={allAdmins}
              onChange={triggerAdmin}
              width="250px"
            />
          </div>
        </div>
        <div className="relate-user-buttons" style={{ marginTop: "36px" }}>
          <div>
            <button
              className="booking-cancel-button"
              onClick={() => {
                toggleAdmin();
              }}
            >
              Cancel
            </button>
          </div>
          <div>
            <button
              className={
                ableToApply
                  ? "booking-choose-button"
                  : "booking-choose-button-invalid"
              }
              onClick={() => {
                ableToApply && adminIdCallback(selectedAdminId);
                ableToApply && toggleAdmin();
              }}
            >
              Apply
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default AssignAdminModal;
