import React from "react";
import "./ticket.css";
import { Fragment } from "react";
import TicketHeader from "./TicketHeader";
import { Helmet } from "react-helmet";
import Loading from "../../Shared/Loading";
import TicketPageContent from "./TicketPageContent";
import { useQuery } from "@apollo/client";
import { GET_TICKET } from "./TicketPageContent/queries";
import { useRouteMatch } from "react-router";
import { useEffect } from "react";

const TicketPage = ({ defaultAdmin, token, presetDriver }) => {
  const match = useRouteMatch();
  const params = match.params;

  const { loading, error, data, refetch, startPolling, stopPolling } = useQuery(
    GET_TICKET,
    {
      variables: { ticketId: params.id },
    }
  );

  useEffect(() => {
    startPolling(120000);
    return () => {
      stopPolling();
    };
  }, [startPolling, stopPolling]);

  const ticketData = data && data.getAdminSupportTicket;

  const refreshTicketCallback = (newVariables) => {
    refetch(newVariables);
  };

  return (
    <Fragment>
      <Helmet>
        <title>Communications | Autzu Admin</title>
      </Helmet>
      <div className="ticket-page">
        <TicketHeader ticketData={ticketData} />
        {loading ? (
          <Loading />
        ) : (
          <TicketPageContent
            ticketData={ticketData}
            params={params}
            refreshTicketCallback={refreshTicketCallback}
            defaultAdmin={defaultAdmin}
            token={token}
            presetDriver={presetDriver}
          />
        )}
      </div>
    </Fragment>
  );
};

export default TicketPage;
