import { capitalize } from "lodash";
import React from "react";
import { titleize } from "../../../../../utils";

const TicketTop = ({ ticketData, refreshTicketCallback }) => {
  const getColor = (text) => {
    switch (text) {
      case "in_progress":
        return "orange";
      case "resolved":
        return "#3dc700";
      case "deleted":
        return "#414141";
      case "unassigned":
        return "#ff0000";
      case "new":
        return "#128dff";
      case "backlog":
        return "#966919";
      default:
        break;
    }
  };

  const getBackColor = (text) => {
    switch (text) {
      case "in_progress":
        return "#ffe2c1";
      case "resolved":
        return "#cbf0bb";
      case "deleted":
        return "#dedede";
      case "unassigned":
        return "#ffbaba";
      case "new":
        return "#daedff";
      case "backlog":
        return "rgb(150, 105, 25, 0.4)";
      default:
        break;
    }
  };
  return (
    <div className="ticket-title">
      <div className="ticket-status">
        {ticketData ? (
          String(ticketData.id).padStart(6, "0")
        ) : (
          <div style={{ opacity: "0.5", fontWeight: "700" }}>Not issued</div>
        )}
        <div
          className="ticket-tag"
          style={{
            color: `${getColor(ticketData ? ticketData.status : "new")}`,
            backgroundColor: `${getBackColor(
              ticketData ? ticketData.status : "new"
            )}`,
          }}
        >
          {ticketData ? titleize(ticketData.status) : "New ticket"}
        </div>
      </div>
    </div>
  );
};

export default TicketTop;
