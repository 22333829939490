import React, { Component } from 'react'
import SelectHeader from './SelectHeader'
import OptionsBox from './OptionsBox'
import ErrorText from "./ErrorText"
import styled from 'styled-components'

const StyledContainer = styled.div`
	height: 2.5em;
	width: ${({ width }) => width};
	display: inline-flex;
`

class SelectField extends Component {
	constructor(props) {
		super(props)
		this.state = {
			isOpen: false,
			heading: null,
			selectedValue: null,
			exiting: false // determines OptionsBox open/close animation
		}
	}

	componentDidMount() {
		const { prompt = 'Select' } = this.props
		this.setState({ heading: prompt })
	}

	openOptions = () => this.setState({ isOpen: true })

	/*
		The following block of functions are used to display an open/close animation on the options box,
		to delay the removal of the OptionsBox component from the DOM until the animation has run.
	*/
	resetOptionsBox = () => this.setState({ isOpen: false, exiting: false })

	wait = () => setTimeout(this.resetOptionsBox, 300)

	closeOptions = () => this.setState({ exiting: true }, this.wait)

	returnSelection = () => {
		const { onChange } = this.props
		const { selectedValue, heading } = this.state
		onChange(selectedValue, heading)
	}

	newSelection = (heading, selectedValue) =>
		this.setState({ heading, selectedValue }, this.returnSelection)

	render() {
		const {
			children = [undefined], // in the event that a user does not pass any Option components
			primaryColor,
			secondaryColor,
			width = '12.5em',
			disabled,
			error,
			title = "",
			...props
		} = this.props
		const { heading, selectedValue, isOpen, exiting } = this.state
		// a single child does not get passed as an array, so it will fail
		const _children = children.length ? children : [children]

		const childrenWithProps = _children.map((child, key) => {
			if (child) {
				return React.cloneElement(child, {
					key,
					index: key,
					length: _children.length,
					newSelection: this.newSelection,
					secondaryColor,
					heading,
					selectedValue
				})
			}
			return null
		})

		const StyledTextHeading = styled.p`
			font-weight: 600;
			margin: 0.5em auto;
			font-size: 115%;
			color: ${({ theme, headingColor = theme.darkGrey }) => headingColor};
		`

		return (
			<div style={{ position:"relative", width:"100%" }}>
				<StyledTextHeading {...props}>{title}</StyledTextHeading>
				<StyledContainer
					width={width}
					onClick={() => {
						if (!disabled) {
							isOpen ? this.closeOptions() : this.openOptions()
						}
					}}>
					{!isOpen ? (
						<SelectHeader
							heading={heading}
							primaryColor={primaryColor}
							width={width}
							disabled={disabled}
						/>
					) : (
						<OptionsBox
							{...props}
							exiting={exiting}
							primaryColor={primaryColor}
							width={width}
							children={childrenWithProps}
							closeOptions={this.closeOptions}
						/>
					)}
				</StyledContainer>
				{error && <ErrorText error={error} />}
			</div>
		)
	}
}

export default SelectField
