import React from "react";
import MessageContent from "./MessageContent";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhoneAlt } from "@fortawesome/pro-solid-svg-icons";

const InputContainer = ({
  activeTab,
  handleSend,
  ticketData,
  refreshTicketCallback,
  curSubs,
  handleCall,
  assignTicket,
  setActiveTab,
  setIsInternal,
  handleTagRemove,
  userData,
}) => {
  return (
    <div className="input-container">
      <div className="tabs">
        <div className="two-input-options">
          <div
            className={`unselectable detail-tab${
              activeTab === "public" ? " ticket-tab-selected" : ""
            }`}
            onClick={() => {
              setActiveTab("public");
              setIsInternal(false);
            }}
          >
            Public reply
          </div>
          <div
            className={`unselectable detail-tab${
              activeTab === "internal" ? " ticket-tab-selected" : ""
            }`}
            onClick={() => {
              setActiveTab("internal");
              setIsInternal(true);
            }}
          >
            Internal note
          </div>
        </div>
        {ticketData ? (
          <div
            className="call-tab"
            onClick={() => {
              ticketData &&
                ticketData.status === "unassigned" &&
                assignTicket();
              handleSend("Answered request and calling back", true);
              handleCall();
              handleTagRemove("Call requested");
            }}
          >
            <FontAwesomeIcon
              icon={faPhoneAlt}
              style={{ marginRight: "12px" }}
            />
            Call
          </div>
        ) : (
          <div className="call-tab-invalid">
            <FontAwesomeIcon
              icon={faPhoneAlt}
              style={{ marginRight: "12px" }}
            />
            Call
          </div>
        )}
      </div>

      <MessageContent
        handleSend={handleSend}
        tab={activeTab}
        ticketData={ticketData}
        refreshTicketCallback={refreshTicketCallback}
        curSubs={curSubs}
        assignTicket={assignTicket}
        userData={userData}
      />
    </div>
  );
};

export default InputContainer;
