import React from "react";
import styles from "./footer.module.scss";
import PreviousPage from "./PreviousPage";
import NextPage from "./NextPage";
import PageNumbers from "./PageNumbers";
import FirstPage from "./FirstPage";
import LastPage from "./LastPage";
import ItemsViewing from "./ItemsViewing";

const Footer = ({
  totalRowCount,
  paginationArguments,
  changePage,
  item,
  userSpecific,
}) => {
  const { page, itemsPerPage } = paginationArguments;
  const lastPage = Math.ceil(totalRowCount / itemsPerPage);

  return (
    <div
      className={styles.footer}
      style={{ marginLeft: userSpecific ? "32px" : "0" }}
    >
      <div className={styles.paginationContainer}>
        <FirstPage changePage={changePage} currentPage={page} />
        <PreviousPage changePage={changePage} currentPage={page} />
        <PageNumbers
          totalRowCount={totalRowCount}
          currentPage={page}
          itemsPerPage={itemsPerPage}
          changePage={changePage}
        />
        <NextPage
          changePage={changePage}
          currentPage={page}
          lastPage={lastPage}
        />
        <LastPage
          changePage={changePage}
          currentPage={page}
          lastPage={lastPage}
        />
      </div>
      <ItemsViewing
        page={page}
        itemsPerPage={itemsPerPage}
        totalRowCount={totalRowCount}
        item={item}
      />
    </div>
  );
};

export default Footer;

// <div className="pagination-container">
//   <div className={`prev-page${currentPage > 1 ? "" : " disabled"}`} onClick={currentPage > 1 ? handlePrevPage : null}>
//     <FontAwesomeIcon icon={faChevronLeft} size="sm" style={{ padding: "0 6px" }} />
//   </div>
//   {pageNumbers.map((pn, index) => {
//     return (
//         <div
//             key={index}
//             className={`page-number${currentPage === pn ? " selected" : ""}`}
//             onClick={() => handlePagination((pn - 1) * limit)}
//         >
//           {pn}
//         </div>
//     )
//   })}
//   <div className={`next-page${currentPage < lastIndex - 1 ? "" : " disabled"}`} onClick={currentPage < lastIndex - 1 ? handleNextPage : null}>
//     <FontAwesomeIcon icon={faChevronRight} size="sm" style={{ padding: "0 6px" }} />
//   </div>
// </div>
// <div className="page-display">
//   Viewing {Math.min(offset + 1, userIds.length)} - {Math.min(offset + 10, userIds.length)} of {userIds.length} users
// </div>
