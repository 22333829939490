import React, { Component } from "react";
import { Query } from "@apollo/client/react/components";
import { Redirect } from "react-router-dom";
import gql from "graphql-tag";
import Loading from "../Components/Shared/Loading";
import BookingPage from "../Components/User/Bookings/BookingPage";
import {
  carChangeInspectionString,
  prebookingInspectionString,
} from "./queryStrings";
import BookingInspection from "../Components/User/Bookings/BookingPage/BookingTimeline/BookingInspection";
import CarChangeInspection from "../Components/User/Bookings/BookingPage/BookingTimeline/CarChangeInspection";
// import { nextShift } from "../utils";

class VehicleChangeData extends Component {
  render() {
    const { eventId, isInspection, toggleInspection } = this.props;
    return (
      <Query
        query={gql`
          ${carChangeInspectionString}
        `}
        variables={{ swapId: eventId }}
      >
        {({ loading, error, data, refetch }) => {
          console.log(data);
          if (error) {
            return null;
          } else if (loading) {
            return (
              <div
                className="user-page__container"
                style={{ display: "flex", alignItems: "center" }}
              >
                <Loading />
              </div>
            );
          }

          if (data.getVehicleSwapInformation) {
            return (
              <CarChangeInspection
                inspectionData={data.getVehicleSwapInformation}
                isInspection={isInspection}
                toggleInspection={toggleInspection}
                refetch={refetch}
              />
            );
          }
        }}
      </Query>
    );
  }
}

export default VehicleChangeData;
