import React from "react";
import DisplayVehicle from "./DisplayVehicle";
import styles from "../../bookingActions.module.scss";

const CurrentVehicle = ({ car }) => (
  <div className={styles.vehiclePreviewItem}>
    <p>Previous vehicle</p>
    {car ? (
      <DisplayVehicle plate={car.plate} imageLink={car.imageLink} />
    ) : (
      "Not assigned"
    )}
  </div>
);

export default CurrentVehicle;
