const categories = [
  { name: "Accident Report", value: "Accident Report" },
  { name: "Account", value: "Account" },
  { name: "Vehicle Issue", value: "Vehicle Issue" },
];

const accidentSub = [
  {
    name: "Accident Report",
    value: "Accident Report",
  },
];

const accountSub = [
  {
    name: "Account suspended",
    value: "Account suspended",
  },
  {
    name: "Signing up",
    value: "Signing up",
  },
  {
    name: "Not receiving trip requests",
    value: "Not receiving trip requests",
  },
  {
    name: "Cancellation",
    value: "Cancellation",
  },
  {
    name: "Request payment plan",
    value: "Request payment plan",
  },
  {
    name: "Booking",
    value: "Booking",
  },
  {
    name: "Other",
    value: "Other",
  },
];

const vehicleSub = [
  { name: "Vehicle is dirty", value: "Vehicle is dirty" },
  {
    name: "Low battery level",
    value: "Low battery level",
  },
  {
    name: "Supercharger parking receipt",
    value: "Supercharger parking receipt",
  },
  {
    name: "Low tire pressure/flat tire",
    value: "Low tire pressure/flat tire",
  },
  { name: "Vehicle is damaged", value: "Vehicle is damaged" },
  {
    name: "Warning/error messasge",
    value: "Warning/error messasge",
  },
  {
    name: "Vehicle is not here",
    value: "Vehicle is not here",
  },
  {
    name: "Vehicle in wrong spot",
    value: "Vehicle in wrong spot",
  },
  {
    name: "Washer fluid receipt",
    value: "Washer fluid receipt",
  },
  {
    name: "Traffic ticket/infraction",
    value: "Traffic ticket/infraction",
  },
  {
    name: "Other",
    value: "Other",
  },
];

export { categories, accidentSub, accountSub, vehicleSub };
