import React from 'react'

const SvgClose = ({ color = '#373535', ...props }) => (
  <svg
    id="close_svg__Layer_1"
    data-name="Layer 1"
    viewBox="0 0 43.42 43.42"
    {...props}>
    <defs>
      <style>
        {`.close_svg__cls-1{fill:none;stroke:${color};stroke-linecap:round;stroke-miterlimit:10;stroke-width:7px}`}
      </style>
    </defs>
    <path
      className="close_svg__cls-1"
      d="M39.92 3.5L3.5 39.92M39.92 39.92L3.5 3.5"
    />
  </svg>
)

export default SvgClose
