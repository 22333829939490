import React, { useEffect } from "react";
import { Fragment } from "react";
import ReferralStats from "./ReferralStats";
import ReferralDisplay from "./ReferralDisplay";
import { useLocation } from 'react-router-dom';


const Referral = ({ resourceId, Id, refetch, referralsData, referralCode }) => {
  const totalReferrals = referralsData && referralsData.length;
  console.log(referralsData);
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0,0);
  }, [location]);

  const countCredit = () => {
    let count = 0;
    referralsData.map((ref) =>
      ref.referralStatus === "redeemed"
        ? ref.referral2Status === "redeemed"
          ? (count = count + 150)
          : (count = count + 50)
        : (count = count)
    );
    return count;
  };

  return (
    <Fragment>
      <ReferralStats
        referralCode={referralCode}
        totalReferrals={totalReferrals}
        totalCredits={countCredit()}
      />
      <ReferralDisplay referrals={referralsData} />
    </Fragment>
  );
};

export default Referral;
