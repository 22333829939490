import React from "react";
import Modal from "../../../../artzu-ui/src/Modal";
import Dropdown from "../../../Shared/Dropdown";
import styles from "./popup.module.scss";
import PickDate from "./PickDate";
import { useEffect } from "react";

const ArrangeEditModal = ({
  invoiceNum,
  invoiceId,
  total,
  listData,
  installCount,
  install,
  onInstallChange,
  datesCallback,
  toggleArrange,
  clearInstall,
  toSave,
  editFinancing,
  isEdit,
  arrangeFinancing,
  financingCallback,
  open,
  closeList,
}) => {
  var today = new Date();
  var tomorrow = new Date(today);
  tomorrow.setDate(today.getDate() + 1);

  var presetInstallDates = [];
  presetInstallDates.push(tomorrow);

  for (let i = 1; i <= install - 1; i++) {
    var newDate = new Date(tomorrow);
    install && newDate.setDate(tomorrow.getDate() + 7 * i);
    presetInstallDates.push(newDate);
  }

  return (
    <Modal open={open} backgroundColor="none">
      <div className={styles.arrangePopup}>
        <div className={styles.arrangeTitle}>Arrange financing</div>
        <div className={styles.financeInfo}>
          <div>
            The total amount due for{" "}
            <span className={styles.bold}>{invoiceNum}</span> is{" "}
            <span className={styles.bold}>
              $
              {total &&
                total.toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
            </span>
          </div>
          <div className={styles.dropdownText}>
            The invoice is split into
            <div style={{ marginLeft: "10px", marginRight: "10px" }}>
              {listData && listData.length !== 0 ? (
                listData.length
              ) : (
                <Dropdown
                  initialValue={1}
                  items={installCount}
                  onChange={onInstallChange}
                  width="36px"
                  height="16px"
                />
              )}
            </div>
            installments
          </div>
        </div>
        <div>
          Each installment is{" "}
          {listData ? (
            <span style={{ fontWeight: "600" }}>
              $
              {(total / listData.length).toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </span>
          ) : install ? (
            <span style={{ fontWeight: "600" }}>
              $
              {(total / install).toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </span>
          ) : (
            <span style={{ fontWeight: "600" }}>
              $
              {total &&
                total.toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
            </span>
          )}
        </div>
        {listData && listData.length !== 0 ? (
          <div className={styles.installmentContainer}>
            {listData.map((e, i) => (
              <PickDate
                index={i}
                date={e.dueDate}
                listData={listData}
                datesCallback={datesCallback}
              />
            ))}
          </div>
        ) : (
          <div className={styles.installmentContainer}>
            {presetInstallDates.map((e, i) => (
              <PickDate
                index={i}
                date={e}
                datesCallback={datesCallback}
                presetInstallDates={presetInstallDates}
              />
            ))}
          </div>
        )}
        <div className={styles.actionButtons}>
          <div>
            <button
              className={styles.cancelButton}
              onClick={() => {
                toggleArrange();
                clearInstall();
                closeList();
              }}
            >
              Cancel
            </button>
          </div>
          <div>
            <button
              className={toSave ? styles.saveButton : styles.notSaveButton}
              onClick={() => {
                isEdit && toSave && editFinancing();
                !isEdit && toSave && arrangeFinancing();
                financingCallback({ invoiceId: invoiceId });
                // closeList();
                // window.location.reload();
              }}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ArrangeEditModal;
