import React, { Fragment } from "react";
import { FormControl, Select, MenuItem } from "@material-ui/core";
import BlueCheckbox from "../../Shared/BlueCheckbox";
import { titleize } from "../../../utils";
import { useStyles } from "./styles.js";
import { isSelected, subsetDropdownTitle } from "../utils";

const AccountStatus = ({
  parkingLotIds,
  accountStatuses,
  accountStatus,
  setAccountStatus,
}) => {
  const classes = useStyles();

  const changeAccountStatus = (status) => {
    if (accountStatus?.includes(status)) {
      setAccountStatus([...accountStatus.filter((v) => v !== status)]);
    } else {
      setAccountStatus([status, ...accountStatus]);
    }
  };

  return (
    <div className="dataset-options__field">
      <label>Account Status</label>
      <FormControl variant="outlined" className={classes.formControl}>
        <Select
          value={isSelected(accountStatus)}
          renderValue={() =>
            subsetDropdownTitle(accountStatus, "account status", parkingLotIds)
          }
          className={classes.select}
          onChange={(e) => {}}
        >
          <Fragment>
            {accountStatuses.map((status, index) => {
              return (
                <MenuItem
                  key={index}
                  className={classes.menuItem}
                  onClick={() => changeAccountStatus(status)}
                >
                  <BlueCheckbox
                    size="small"
                    checked={accountStatus.includes(status)}
                    onChange={() => changeAccountStatus(status)}
                    inputProps={{ "aria-label": "secondary checkbox" }}
                  />
                  <div>{titleize(status)}</div>
                </MenuItem>
              );
            })}
          </Fragment>
        </Select>
      </FormControl>
    </div>
  );
};

export default AccountStatus;
