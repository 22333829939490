import { useMutation } from "@apollo/client";
import React, { useState } from "react";
import Dropdown from "../../ActionBar/Filters/OptionsModal/Body/Filter/Dropdown";
import styles from "../batchActions.module.scss";
import { BULK_ADD_TICKET_TAG } from "../queries";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-light-svg-icons";
import { faPlus } from "@fortawesome/pro-regular-svg-icons";

const AddTag = ({ selections, close, clearAllSelections }) => {
  const [newTag, setNewTag] = useState();
  const [tagsToAdd, setTagsToAdd] = useState([]);

  const allValidTags = [
    "Urgent",
    "Waiting for Approval",
    "Accident",
    "Software bug",
    "Refund",
    "Keycard",
    "In Progress -> Active",
    "In Progress -> Closed",
    "Suspended -> Active",
    "Suspended -> Closed",
    "No Answer -> Closed",
    "Driver Intro",
    "Reimbursed",
    "Incentive",
    "Pre-booking Inspection",
    "No Answer -> Active",
    "Onboarding",
    "Lost & Found",
    "Lyft",
    "In Progress -> Contacted",
    "No Answer -> Contacted",
    "Suspended -> Contacted",
    "Collections",
    "Referral",
    "Fleet Supplies",
    "Onboarded -> Onboarding",
    "Onboarded -> Active",
    "Signed-up -> Contacted",
    "Signed-up -> Closed",
    "Signed-up -> Active",
    "Prospect -> Signed up",
    "Prospect -> In Progress",
    "Active -> Contacted",
    "Active -> Closed",
    "Pending",
    "Feedback",
    "Cleaning Credit",
  ];
  let arrayToSort = [...allValidTags];
  const allTagList = arrayToSort.sort().map((tag) => {
    return {
      name: tag,
      value: tag,
    };
  });

  const triggerTag = (childData) => {
    setNewTag(childData);
  };

  const handleAddTag = () => {
    setTagsToAdd((tagsToAdd) => [...tagsToAdd, newTag]);
    setNewTag("");
  };

  const handleRemoveTag = (tag) => {
    let curTags = [...tagsToAdd];
    curTags.includes(tag) && (curTags = curTags.filter((item) => item !== tag));
    setTagsToAdd(curTags);
  };

  const [addTicketTagMutation] = useMutation(BULK_ADD_TICKET_TAG);
  const handleBulkAddTags = () => {
    addTicketTagMutation({
      variables: {
        ticketIds: selections,
        tags: tagsToAdd,
      },
    }).then(({ data: { bulkAddTag } }) => {
      if (bulkAddTag.success) {
        clearAllSelections();
        close();
        console.log("Add Tags Succeed!");
      } else {
        console.log("Add Tags error!");
      }
    });
  };

  const addable =
    newTag !== undefined && newTag !== "" && !tagsToAdd.includes(newTag);
  const applyable = tagsToAdd.length !== 0;

  return (
    <div className={styles.ticketActionContainer} style={{ height: "280px" }}>
      <div className={styles.actionHeader}>Add tag</div>
      <span className="subscript">Add tag</span>
      <div style={{ height: "150px" }}>
        <div className={styles.searchAddContainer}>
          <Dropdown
            initialValue={newTag}
            items={allTagList}
            onChange={triggerTag}
            width="200px"
            height="30px"
          />
          <div className={styles.addAgentButton}>
            <button
              className={
                addable
                  ? "add-button-container"
                  : "invalid-add-button-container"
              }
            >
              <div
                className="add-tag-button"
                onClick={() => {
                  addable && handleAddTag();
                }}
              >
                <FontAwesomeIcon
                  icon={faPlus}
                  size="md"
                  className="plus-icon"
                />
                <div style={{ fontWeight: "600" }}>Add</div>
              </div>
            </button>
          </div>
        </div>
        <div className={styles.ticketTagsContainer}>
          {tagsToAdd.map((tag) => (
            <div className={styles.ticketTagBox}>
              {tag}
              <FontAwesomeIcon
                icon={faTimes}
                size="lg"
                className="tag-times"
                onClick={() => {
                  handleRemoveTag(tag);
                }}
              />
            </div>
          ))}
        </div>
      </div>

      <div className={styles.ticketModalButtons} style={{ marginTop: "18px" }}>
        <div>
          <button
            className={styles.ticketModalCancelButton}
            onClick={() => {
              close();
            }}
          >
            Cancel
          </button>
        </div>
        <div>
          <button
            className={styles.ticketModalApplyButton}
            style={{ color: applyable ? "#128dff" : "#87caef" }}
            onClick={() => {
              applyable && handleBulkAddTags();
            }}
          >
            Apply
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddTag;
