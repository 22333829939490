import { useMutation } from "@apollo/client";
import React, { useState } from "react";
import { Fragment } from "react";
import Modal from "../../../../artzu-ui/src/Modal";
import ErrorModal from "../../../Payments/Invoices/ActionPopup/ErrorModal";
import styles from "./importPopup.module.scss";
import { DOWNLOAD_CSV } from "./queries";

const ExportPopup = ({ open, togglePopup, tableName }) => {
  const [password, setPassword] = useState();
  const [reason, setReason] = useState();
  const [isError, setIsError] = useState();
  const [errorMsg, setErrorMsg] = useState();

  const handlePassword = (e) => {
    setPassword(e.target.value);
  };

  const handleReason = (e) => {
    setReason(e.target.value);
  };

  const toggleErrorModal = () => {
    setIsError(!isError);
  };

  const clearInput = () => {
    setPassword();
    setReason();
  };

  const [downloadMutation] = useMutation(DOWNLOAD_CSV);
  const DownloadCSVFile = () => {
    downloadMutation({
      variables: {
        tableName: tableName,
        password: password,
        reason: reason,
      },
    }).then(({ data: { downloadCsv } }) => {
      if (downloadCsv.success) {
        clearInput();
        console.log("download succeed!");
      } else {
        toggleErrorModal();
        setErrorMsg(downloadCsv.errors);
        console.log("download error!");
      }
    });
  };

  const ableToDownload = password && reason;
  return (
    <Fragment>
      <Modal open={open} backgroundColor="none">
        <div className={styles.importPopup}>
          <div className={styles.uploadContainer}>
            <span className={styles.importTitle}>
              Export to CSV
            </span>
            <span className={styles.importSubtitle}>
              Please enter your account password and reason to download.{" "}
            </span>
            <div className={styles.password}>
              Password{" "}
              <input 
                className={styles.passwordContent} 
                type="password" 
                onChange={handlePassword}
              ></input>
            </div>
            <div className={styles.reason}>
              Reason{" "}
              <textarea
                className={styles.reasonContent}
                onChange={handleReason}
              ></textarea>
            </div>
          </div>
          <div className={styles.actionButtons}>
            <div>
              <button
                className={styles.cancelButton}
                onClick={() => {
                  togglePopup();
                }}
              >
                Cancel
              </button>
            </div>
            <div>
              <button
                className={
                  ableToDownload ? styles.saveButton : styles.notSaveButton
                }
                onClick={() => {
                  ableToDownload && togglePopup();
                  ableToDownload && DownloadCSVFile();
                }}
              >
                Export
              </button>
            </div>
          </div>
        </div>
      </Modal>
      <ErrorModal
        open={isError}
        errorMsg={errorMsg}
        toggleErrorModal={toggleErrorModal}
      />
    </Fragment>
  );
};

export default ExportPopup;
