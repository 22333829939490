const formatLotName = lot => {
  switch (lot) {
    case "Downtown":
      return "DT";
    case "Square One":
      return "SQO";
    case "Langhorne, PA":
      return "LPA";
    case "Weekly Lease":
      return "WL";
    case "Secaucus, NJ":
      return "SNJ";
    case "LIC Centre Blvd":
      return "LIC";
    case "Skyport":
      return "SKY";
    case "Skyport (Outdoor)":
      return "SKYO";
    case "Multiple Lots":
      return "MULT";
  }
};

export default formatLotName;
