import gql from "graphql-tag";

const userProfileString = gql`
  query UserProfile($id: ID!) {
    userProfileData(id: $id) {
      id
      email
      firstName
      lastName
      uberProfileImage
      accountStatus
      mobile
      referralSource
      referralCode
      referrals {
        referrerId
        referrerName
        referredUserId
        referredUserName
        referralStatus
        referral2Status
        hoursDriven
        referredUserSignedUpDate
        referrerSignedUpDate
      }
    }
  }
`;

const GET_TEXT_MESSAGES = gql`
  query GetTextMessages($id: ID!) {
    userProfileData(id: $id) {
      id
      recentTextMessages {
        id
        adminUser {
          fullName
        }
        user {
          fullName
        }
        fromSystem
        fromDriver
        sentAt
        message
      }
      timeZone
    }
  }
`;

export { userProfileString, GET_TEXT_MESSAGES };
