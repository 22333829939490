import React, { useState } from "react";
import Modal from "../../../../../../artzu-ui/src/Modal";
import { faSearch } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-light-svg-icons";
import { GET_USER_INFO } from "../../queries";
import { useQuery } from "@apollo/client";
import { faSpinner } from "@fortawesome/pro-light-svg-icons";

const RelateUserModal = ({ open, toggleUser, userIdCallback }) => {
  const [selectedUser, setSelectedUser] = useState();
  const [userToSearch, setUserToSearch] = useState();
  const changeSelectedUser = (e) => {
    setSelectedUser(e.target.value);
  };

  const onKeyPress = (evt) => {
    if (evt.key === "Enter") {
      searchUser();
    }
  };

  const clearSearch = () => {
    setSelectedUser("");
    setUserToSearch(null);
  };

  const searchUser = () => {
    setUserToSearch(selectedUser);
  };

  const { data, loading } = useQuery(GET_USER_INFO, {
    variables: {
      id: userToSearch && userToSearch,
    },
  });

  const searchedUser = data && data.userProfileData;
  const userFullName = searchedUser && searchedUser.fullName;
  const userId = searchedUser && searchedUser.id;

  const ableToApply = userToSearch && userId;

  return (
    <Modal open={open} backgroundColor="none">
      <div className="related-user-container" style={{ height: "240px" }}>
        <div className="relate-user-title">Choose a user</div>
        <div className="user-input">
          User
          <div className="input-search">
            <div className="user-input-container">
              <div className="search-icon">
                <FontAwesomeIcon className="" icon={faSearch} color="#A2A2A2" />
              </div>
              <input
                className="input-style"
                placeholder={"Search by user ID"}
                onChange={changeSelectedUser}
                value={selectedUser}
                onKeyPress={onKeyPress}
              />
              {selectedUser && (
                <FontAwesomeIcon
                  icon={faTimes}
                  style={{ fontSize: "20px" }}
                  onClick={clearSearch}
                />
              )}
            </div>
            <button className="search-button" onClick={searchUser}>
              {loading ? (
                <FontAwesomeIcon
                  style={{ color: "#ffffff" }}
                  spin
                  size={"1x"}
                  icon={faSpinner}
                />
              ) : (
                "Search"
              )}
            </button>
          </div>
          <div className="admin-details">
            {userFullName?
            <div className="driver-name">
              User name: <div className="booking-info-text">{userFullName}</div>
            </div>:""}
          </div>
        </div>
        <div className="relate-user-buttons">
          <div>
            <button
              className="booking-cancel-button"
              onClick={() => {
                toggleUser();
                clearSearch();
              }}
            >
              Cancel
            </button>
          </div>
          <div>
            <button
              className={
                ableToApply
                  ? "booking-choose-button"
                  : "booking-choose-button-invalid"
              }
              onClick={() => {
                ableToApply && userIdCallback(userId);
                ableToApply && toggleUser();
                clearSearch();
              }}
            >
              Apply
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default RelateUserModal;
