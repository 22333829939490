import { useQuery } from "@apollo/client";
import { USER_COMMENTS } from "../queries";
import Loading from "../../../Shared/Loading";
import CommentsBody from "./CommentsBody";

const Comments = ({ id }) => {
  const { data, refetch, errors, loading } = useQuery(USER_COMMENTS, {
    variables: { id },
    fetchPolicy: "network-only",
  });

  if (loading) return <Loading />;

  if (errors) return <p>Error</p>;

  if (data) {
    const pinnedComments = data.userProfileData.comments.filter(
      (c) => c.pinned
    );

    const unpinnedComments = data.userProfileData.comments.filter(
      (c) => !c.pinned
    );

    return (
      <CommentsBody
        resourceId={id}
        pinnedComments={pinnedComments}
        unpinnedComments={unpinnedComments}
        refetch={refetch}
        timeZone={data.userProfileData.timeZone}
      />
    );
  }
};

export default Comments;
