import React, { useState } from "react";
import { Fragment } from "react";
import { Link } from "react-router-dom";
import { capitalize, renderColor } from "../../../../../../utils";
import RelateUserModal from "./RelateUserModal";
import moment from "moment";

const RelatedUser = ({ userData, userIdCallback, relatedUserData }) => {
  const [isUser, setIsUser] = useState();

  const toggleUser = () => {
    setIsUser(!isUser);
  };

  const userId = userData ? userData.id : relatedUserData && relatedUserData.id;
  const userName = userData
    ? userData.fullName
    : relatedUserData && relatedUserData.fullName;
  const avatar = userData
    ? userData.uberProfileImage
    : relatedUserData && relatedUserData.uberProfileImage;
  const status = userData
    ? userData.accountStatus
    : relatedUserData && relatedUserData.accountStatus;
  const lastBooking = userData
    ? userData.mostImmediateBooking && userData.mostImmediateBooking.id
    : relatedUserData &&
      relatedUserData.mostImmediateBooking &&
      relatedUserData.mostImmediateBooking.id;
  // const hub = userData
  //   ? userData.parkingLot && userData.parkingLot.name
  //   : relatedUserData &&
  //     relatedUserData.parkingLot &&
  //     relatedUserData.parkingLot.name;
  const ticketCount = userData
    ? userData.tickets.filter((item) => item.status !== "deleted").length
    : relatedUserData &&
      relatedUserData.tickets.filter((item) => item.status !== "deleted")
        .length;
  const active = userData
    ? userData.ridesharePlatforms.filter((data) => data.name === "uber")[0]
        .ptcActiveVehicleCount
    : relatedUserData &&
      relatedUserData.ridesharePlatforms.filter(
        (data) => data.name === "uber"
      )[0].ptcActiveVehicleCount;
  const total = userData
    ? userData.ridesharePlatforms.filter((data) => data.name === "uber")[0]
        .totalVehicleCount
    : relatedUserData &&
      relatedUserData.ridesharePlatforms.filter(
        (data) => data.name === "uber"
      )[0].totalVehicleCount;
  const appVersion = userData
    ? userData.deviceOs + userData.appVersion
    : relatedUserData && relatedUserData.deviceOs + relatedUserData.appVersion;
  const driverSince = userData
    ? userData.createdAt
    : relatedUserData && relatedUserData.createdAt;

  return (
    <Fragment>
      {userData || relatedUserData ? (
        <div className="user-info-container">
          <div className="booking-info-header">
            <h2>User</h2>
            <div className="choose-a-booking" onClick={toggleUser}>
              {userData
                ? null
                : relatedUserData
                ? "Change a user"
                : "Choose a user"}
            </div>
          </div>
          <div className="user-identity">
            <div className="image-name">
              <div className="user-image-container-large">
                <Link
                  to={`/users/${userId}`}
                  target="_blank"
                  style={{ textDecoration: "none" }}
                >
                  <img
                    src={avatar}
                    style={{ width: "68px", height: "68px" }}
                  ></img>
                </Link>
              </div>
              <Link
                to={`/users/${userId}`}
                target="_blank"
                style={{ textDecoration: "none" }}
              >
                <div className="related-user-name" style={{ color: "#128dff" }}>
                  {userName}
                </div>
              </Link>
            </div>
          </div>
          <div className="details-container">
            <div className="booking-details-field">
              <div className="subscript">Status</div>
              <div className="detail-field">
                <div
                  className={renderColor("Account Status", status && status)}
                >
                  {capitalize(status)}
                </div>
              </div>
            </div>
            <div className="booking-details-field">
              <div className="subscript">Last booking</div>
              <Link
                to={`/users/${userId}/bookings/${lastBooking}`}
                target="_blank"
                style={{ textDecoration: "none" }}
              >
                <div className="detail-field" style={{ color: "#128dff" }}>
                  {lastBooking}
                </div>
              </Link>
            </div>
            <div className="booking-details-field">
              <div className="subscript">Number of open tickets</div>
              <div className="detail-field">{ticketCount}</div>
            </div>
            <div className="booking-details-field">
              <div className="subscript">Number of uploaded</div>
              <div className="detail-field">
                {`${active ? active : 0} / ${total ? total : 0}`}
              </div>
            </div>
            <div className="booking-details-field">
              <div className="subscript">App version</div>
              <div className="detail-field">{appVersion || "-"}</div>
            </div>
            <div className="booking-details-field">
              <div className="subscript">Driver since</div>
              <div className="detail-field">
                {driverSince && moment(driverSince).format("MMM D, YYYY")}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="user-info-container">
          <div className="booking-info-header">
            <h2>User</h2>
            <div className="choose-a-booking" onClick={toggleUser}>
              {userData
                ? null
                : relatedUserData
                ? "Change a user"
                : "Choose a user"}
            </div>
          </div>
          <div className="no-driver">No driver selected</div>
        </div>
      )}

      <RelateUserModal
        open={isUser}
        toggleUser={toggleUser}
        userIdCallback={userIdCallback}
      />
    </Fragment>
  );
};

export default RelatedUser;
