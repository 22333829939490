import React, { Component } from "react";
import SelectHeader from "./SelectHeader";
import OptionsBox from "./OptionsBox";
import ErrorText from "./ErrorText";
import styled from "styled-components";

const StyledContainer = styled.div`
  position: relative;
  height: ${({ height }) => height || "100%"};
  width: ${({ width }) => width};
  display: inline-flex;
`;

class SelectField extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      heading: null,
      selectedValue: null,
      exiting: false // determines OptionsBox open/close animation
    };
  }

  componentDidMount() {
    const { prompt = "Select", initialValue } = this.props;
    this.setState({ heading: prompt, selectedValue: initialValue });
  }

  // temporary solution for handling dropdown heading on reset
  componentDidUpdate(prevProps) {
    if (this.props.prompt !== prevProps.prompt && this.props.prompt !== "Err") {
      this.setState({ heading: this.props.prompt });
    }
  }

  openOptions = () => this.setState({ isOpen: true });

  /*
    The following block of functions are used to display an open/close animation on the options box,
    to delay the removal of the OptionsBox component from the DOM until the animation has run.
  */
  resetOptionsBox = () => this.setState({ isOpen: false, exiting: false });

  wait = () => setTimeout(this.resetOptionsBox, 0);

  closeOptions = () => this.setState({ exiting: true }, this.wait);

  returnSelection = () => {
    const { onChange } = this.props;
    const { selectedValue, heading } = this.state;
    onChange(selectedValue, heading);
  };

  newSelection = (heading, selectedValue) =>
    this.setState({ heading, selectedValue }, this.returnSelection);

  render() {
    const {
      children = [undefined], // in the event that a user does not pass any Option components
      primaryColor,
      secondaryColor,
      width,
      optionWidth,
      disabled,
      borderRadius,
      weight,
      text,
      height,
      opacity,
      color,
      padding,
      error,
      title = "",
      fontSize,
      fontWeight,
      border,
      margin,
      containerHeight,
      ...props
    } = this.props;
    const { heading, selectedValue, isOpen, exiting } = this.state;
    // a single child does not get passed as an array, so it will fail
    const _children = children.length ? children : [children];

    const childrenWithProps = _children.map((child, key) => {
      if (child) {
        return React.cloneElement(child, {
          key,
          index: key,
          length: _children.length,
          newSelection: this.newSelection,
          secondaryColor,
          heading,
          selectedValue
        });
      }
      return null;
    });

    const StyledTextHeading = styled.div`
      font-weight: 600;
      color: ${({ theme, headingColor = theme.darkGrey }) => headingColor};
    `;

    return (
      <div
        style={{
          position: "relative",
          width: "100%",
          height: containerHeight || ""
        }}
      >
        {title ? (
          <StyledTextHeading {...props}>{title}</StyledTextHeading>
        ) : null}
        <StyledContainer
          width={width}
          borderRadius={borderRadius}
          onClick={() => {
            if (!disabled) {
              isOpen ? this.closeOptions() : this.openOptions();
            }
          }}
        >
          <>
            <SelectHeader
              heading={heading}
              primaryColor={primaryColor}
              width={width}
              disabled={disabled}
              borderRadius={borderRadius}
              height={height}
              weight={weight}
              opacity={opacity}
              color={color}
              border={border}
              padding={padding}
              fontSize={fontSize}
              fontWeight={fontWeight}
              margin={margin}
              error={error}
            />
            {isOpen && (
              <OptionsBox
                {...props}
                exiting={exiting}
                primaryColor={primaryColor}
                width={optionWidth}
                height={height}
                borderRadius={borderRadius}
                children={childrenWithProps}
                closeOptions={this.closeOptions}
              />
            )}
          </>
        </StyledContainer>
      </div>
    );
  }
}

export default SelectField;
