import React from "react";
import Modal from "../../../../../artzu-ui/src/Modal";
import DatePicker from "react-datepicker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar } from "@fortawesome/pro-regular-svg-icons";

const ReminderModal = ({
  open,
  timestamp,
  setTimestamp,
  toggleReminder,
  existingComment,
  setExistingComment,
  handleRemind,
  curId,
}) => {
  const ableToSet = timestamp;
  return (
    <Modal open={open} backgroundColor="none">
      <div className="comment-reminder-container">
        <div className="reminder-title">Edit Reminder</div>
        <div className="choose-date-container">
          <div>Remind on:</div>
          <div className="datepicker-container" style={{ marginBottom: "0" }}>
            <DatePicker
              selected={timestamp}
              onChange={(date) => {
                setTimestamp(date);
              }}
              dateFormat="MMM d, yyyy h:mm a"
              todayButton={"Today"}
              placeholderText="Select date"
              popperPlacement="bottom"
              className="comment-date-input"
              showTimeSelect
              disabled={false}
            />
            <FontAwesomeIcon
              icon={faCalendar}
              className="calendar-icon"
              size="lg"
            />
          </div>
        </div>
        <div className="relate-user-buttons" style={{ marginTop: "36px" }}>
          <div>
            <button
              className="booking-cancel-button"
              style={{ width: "130px" }}
              onClick={() => {
                toggleReminder();
                setTimestamp(undefined);
              }}
            >
              Cancel
            </button>
          </div>
          <div>
            <button
              className={
                ableToSet
                  ? "booking-choose-button"
                  : "booking-choose-button-invalid"
              }
              style={{ width: "130px" }}
              onClick={() => {
                ableToSet && existingComment && handleRemind(curId, timestamp);
                ableToSet && existingComment && setTimestamp(undefined);
                ableToSet && existingComment && setExistingComment(false);
                ableToSet && toggleReminder();
              }}
            >
              Set Reminder
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ReminderModal;
