import React from "react";
import TicketActionContainer from "./TicketActionContainer";
import Modal from "../../../artzu-ui/src/Modal";
import _ from "lodash";

const TicketActionModal = ({
  isOpen,
  selectedTickets,
  selectedBatchAction,
  batchOptionsHash,
  closeModal,
  toggleRefresh,
  clearAllSelections,
}) => {
  const selectedOption = batchOptionsHash[selectedBatchAction];
  return (
    <Modal open={isOpen} padding="none" backgroundColor={"none"}>
      {selectedOption && (
        <TicketActionContainer
          selectedOption={selectedOption}
          selectedTickets={selectedTickets}
          close={closeModal}
          open={isOpen}
          toggleRefresh={toggleRefresh}
          clearAllSelections={clearAllSelections}
        />
      )}
    </Modal>
  );
};

export default TicketActionModal;
