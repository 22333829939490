import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import { ThemeProvider } from "styled-components";
import { MuiThemeProvider } from "@material-ui/core/styles";
import {
  ApolloProvider,
  ApolloClient,
  InMemoryCache,
  ApolloLink,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { createUploadLink } from "apollo-upload-client";
import Root from "./Containers/Root";
import * as serviceWorker from "./serviceWorker";
import theme from "./theme";
import { loadState, saveState } from "./utils/localStorage";
import { Provider } from "mobx-react";
import store from "./utils/gpsStore";
import { Helmet } from "react-helmet";

const options = {
  uri: `${process.env.REACT_APP_API_URL}/graphql`,
};

const httpLink = createUploadLink(options);
const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  if (localStorage.state) {
    const localState = JSON.parse(localStorage.state);
    return {
      headers: {
        ...headers,
        "X-AdminUser-Email": localState.auth.email,
        "X-AdminUser-Token": localState.auth.token,
      },
    };
  }
  // return the headers to the context so httpLink can read them
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache({ addTypename: false }),
});

const artzuUiTheme = {
  main: "#128DFF",
  lightGrey: "#DEDEDE",
  darkGrey: "#414141",
};

ReactDOM.render(
  <ApolloProvider client={client}>
    <Provider store={store}>
      <ThemeProvider theme={artzuUiTheme}>
        <MuiThemeProvider theme={theme}>
          <Helmet>
            <title>Autzu Admin</title>
          </Helmet>
          <Root />
        </MuiThemeProvider>
      </ThemeProvider>
    </Provider>
  </ApolloProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
