import React, { useState, useEffect } from "react";
import Modal from "../../../../../../artzu-ui/src/Modal";
import { faTimes } from "@fortawesome/pro-light-svg-icons";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "./inspection.module.scss";

const FullImage = ({ isFull, imageList, toggleImg, curImgIndex }) => {
  const imgCount = imageList.length - 1;
  const [curIdx, setCurIdx] = useState(curImgIndex);
  useEffect(() => {
    setCurIdx(curImgIndex);
  }, [curImgIndex]);

  const nextImg = () => {
    if (curIdx !== imgCount) {
      setCurIdx(curIdx + 1);
    } else {
      setCurIdx(0);
    }
  };

  const prevImg = () => {
    if (curIdx !== 0) {
      setCurIdx(curIdx - 1);
    } else {
      setCurIdx(imgCount);
    }
  };

  return (
    <Modal open={isFull} backgroundColor="none">
      {imageList[curIdx] !== "" && imageList[curIdx] !== null && (
        <div className={styles.fullImageContainer}>
          <img
            src={imageList[curIdx]}
            className={styles.fullImageDisplay}
          ></img>
          <div className={styles.prevButton} onClick={prevImg}>
            <FontAwesomeIcon icon={faChevronLeft} size="2x" />
          </div>
          <div className={styles.nextButton} onClick={nextImg}>
            <FontAwesomeIcon icon={faChevronRight} size="2x" />
          </div>
          <div className={styles.closeImg} onClick={toggleImg}>
            <FontAwesomeIcon icon={faTimes} size="1x" />
          </div>
        </div>
      )}
    </Modal>
  );
};

export default FullImage;
