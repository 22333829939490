import React from "react";
import styles from "../../bookingActions.module.scss";

const VehicleImage = ({ imageLink }) => (
  <div>
    <div
      className={styles.vehicleImage}
      style={{ height: "44px", width: "108px" }}
    >
      <img src={imageLink} />
    </div>
  </div>
);

export default VehicleImage;
