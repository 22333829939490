import moment from "moment-timezone";

const baseTextProcessor = (accessor) => {
  return (data) => {
    return {
      text: data[accessor].value,
    };
  };
};

const baseDateProcessor = (accessor, time_zone = null) => {
  return (data) => {
    const value = data[accessor].value;
    return {
      text: value
        ? moment
            .utc(value)
            .tz(time_zone || "America/Toronto")
            .format("MMM D, YYYY")
        : "",
      subtext: value
        ? moment
            .utc(value)
            .tz(time_zone || "America/Toronto")
            .format("h:mm:ss A z")
        : "",
    };
  };
};

const baseImageProcessor = (accessor) => {
  return (data) => {
    const value = data[accessor].value;
    return {
      imageLink: value,
    };
  };
};

export { baseDateProcessor, baseTextProcessor, baseImageProcessor };
