import React, { useState } from "react";
import { faAngleDown, faAngleUp } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import styles from "../../table.module.scss";

const chooseIcon = (direction) =>
  direction == "desc" ? faAngleDown : faAngleUp;

const Sortable = ({
  name,
  displayedName,
  currentSortColumn,
  currentSortDirection,
  changeSort,
  fleetInfo,
}) => {
  const isSortedColumn = currentSortColumn == name;
  // console.log(currentSortColumn);

  return (
    <div
      className={classNames("vehicle-data", styles.sortable)}
      onClick={() => {
        const newDirection =
          isSortedColumn && currentSortDirection == "asc" ? "desc" : "asc";
        changeSort(name, newDirection);
      }}
      style={isSortedColumn ? { color: "#128DFF" } : null}
    >
      <div className={styles.columnName}>
        {" "}
        {displayedName === "Image" ? "Model" : displayedName}{" "}
      </div>
      {isSortedColumn ? (
        <FontAwesomeIcon
          className={styles.sortableIcon}
          icon={chooseIcon(currentSortDirection)}
        />
      ) : null}
    </div>
  );
};
export default Sortable;
