import { useQuery } from "@apollo/client";
import React, { useState } from "react";
import Modal from "../../../artzu-ui/src/Modal";
import { BOOKING_DETAILS } from "../queries";
import { m } from "../../../utils";
import { Link } from "react-router-dom";

const CreateInvoiceModal = ({ open, toggleCreate }) => {
  const [curNumber, setNumber] = useState();
  const [searchNum, setSearch] = useState();

  const setCurNum = (e) => {
    const { value } = e.target;
    setNumber(value);
  };

  const setSearchNum = () => {
    setSearch(curNumber);
  };

  const { data, loading } = useQuery(BOOKING_DETAILS, {
    variables: { id: searchNum },
  });

  const id = data && data.bookingDetails.id;
  const driverName = data && data.bookingDetails.driver.fullName;
  const driverId = data && data.bookingDetails.driver.id;
  const start = data ? data.bookingDetails.startTime : null;
  const end = data ? data.bookingDetails.endTime : null;
  const date =
    start === null || end === null
      ? ""
      : `${m(start).format("MMM DD, YYYY h:mmA")} to ${m(end).format("h:mmA")}`;

  const newTo = {
    pathname: "/payments/invoice/new",
    driverName: driverName,
    driverId: driverId,
    bookingId: id,
    create: true,
  };

  const readyToCreate = searchNum !== null && searchNum !== undefined;
  return (
    <Modal open={open} backgroundColor="none">
      <div className="create-invoice">
        <div className="create-invoice-title">Create an invoice</div>
        <div className="number-search">
          Booking number
          <input
            className="booking-search-input"
            type="text"
            value={curNumber}
            onChange={setCurNum}
          ></input>
          <button className="booking-search-button" onClick={setSearchNum}>
            Search
          </button>
        </div>
        <div className="booking-info">
          <div className="driver-name">
            Driver name: <div className="booking-info-text">{driverName}</div>
          </div>
          <div className="driver-name">
            Driver id: <div className="booking-info-text">{driverId}</div>
          </div>
          <div className="booking-date">
            Booking date: <div className="booking-info-text">{date}</div>
          </div>
        </div>
        <div className="action-buttons">
          <div>
            <button className="cancel-button" onClick={toggleCreate}>
              Cancel
            </button>
          </div>
          <div>
            {readyToCreate ? (
              <Link to={newTo} style={{ textDecoration: "none" }}>
                <button className="create-button" onClick={toggleCreate}>
                  Create
                </button>
              </Link>
            ) : (
              <button className="create-button-inactive">Create</button>
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default CreateInvoiceModal;
