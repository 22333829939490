import React from "react";
import classNames from "classnames";
import styles from "../../table.module.scss";
import missing from "../../../../images/autzu-robot.jpg";

const ProfileValue = ({ cellData, classes }) => {
  const { imageLink, userName } = cellData;
  return (
    <div className={classNames(classes)}>
      <div className={styles.imageValue}>
        <img
          className={styles.userImageContainer}
          src={imageLink ? imageLink : missing}
        />
        {userName && <div className={styles.profileText}>{userName}</div>}
      </div>
    </div>
  );
};

export default ProfileValue;
