import React from "react";
import styles from "./textInput.module.scss";
import classNames from "classnames";

const TextInput = ({ value, onChange, error }) => {
  return (
    <input
      onChange={(evt) => onChange(evt.target.value)}
      className={classNames(
        styles.editBox,
        value && styles.markEdited,
        error && styles.markError
      )}
      value={value}
    />
  );
};

export default TextInput;
