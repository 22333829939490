import React, { Component } from "react";
import gql from "graphql-tag";
import UserProfile from "./UserProfile";
import { userProfileString } from "./queryStrings";
import { useRouteMatch } from "react-router";
import Loading from "../Shared/Loading";
import { useQuery } from "@apollo/client";
import "./index.css";

const Index = () => {
  const match = useRouteMatch();
  const id = match.params.id;
  const resourceId = match.params.resourceId && match.params.resourceId;
  const resource = match.params.resource && match.params.resource;

  const { data, loading, error, refetch } = useQuery(userProfileString, {
    variables: { id },
    fetchPolicy: "network-only",
  });

  if (loading) return <Loading />;

  if (error) return null;

  if (data.userProfileData) {
    return (
      <UserProfile
        userId={id}
        resource={resource}
        resourceId={resourceId}
        userProfileData={data.userProfileData}
        refetch={refetch}
      />
    );
  }
};

export default Index;
