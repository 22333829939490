import { useMutation } from "@apollo/client";
import React from "react";
import { useState } from "react";
import Modal from "../../../../../artzu-ui/src/Modal";
import ButtonGroup from "../../../../Shared/ButtonGroup";
import Loading from "../../../../Shared/Loading";
import { MANUAL_DRIVERS_LICENSE_UPLOAD } from "../../queries";
import styles from "../../userDetails.module.scss";
import DateInput from "./DateInput";
import DropdownInput from "./DropdownInput";
import ImageInput from "./ImageInput";
import TextInput from "./TextInput";
import { manualInputsInitialState } from "../utils";

const ManualEntryModal = ({ open, toggleManual, userId, refetchProfile }) => {
  const [manualInputs, setManualInputs] = useState(manualInputsInitialState);

  const allGenders = [
    { name: "Male", value: "1" },
    { name: "Female", value: "2" },
  ];

  const triggerSexChange = (child) => {
    setManualInputs({ ...manualInputs, sex: child });
  };

  const clearInputs = () => {
    setManualInputs({ ...manualInputsInitialState });
  };

  const [manualEntryMutation, { loading: isManualLoading }] = useMutation(
    MANUAL_DRIVERS_LICENSE_UPLOAD
  );
  const manualUploadDriversLicense = () => {
    manualEntryMutation({
      variables: {
        userId: userId,
        driverLicenseFields: {
          firstName: manualInputs.firstName,
          lastName: manualInputs.lastName,
          addressLine1: manualInputs.address,
          licenseNumber: manualInputs.licenseNumber,
          dateOfBirth: manualInputs.dateOfBirth,
          issuanceDate: manualInputs.issueDate,
          expiryDate: manualInputs.expiryDate,
          refNumber: manualInputs.refNumber,
          height: manualInputs.height,
          sex: manualInputs.sex,
          licenseClass: manualInputs.licenseClass,
          frontPhoto: manualInputs.frontImage,
          backPhoto: manualInputs.backImage,
          profilePhoto: manualInputs.profileImage,
        },
      },
    }).then(({ data: { driverLicenseManualEntry } }) => {
      if (driverLicenseManualEntry.driverLicenseData) {
        refetchProfile();
        clearInputs();
        toggleManual();
        console.log("Manual Upload Succeed!");
      } else {
        console.log("Manual upload error!");
      }
    });
  };

  return (
    <Modal open={open} backgroundColor="none">
      {isManualLoading ? (
        <Loading />
      ) : (
        <div className={styles.manualEntryContainer}>
          <div className={styles.title}>Manual entry</div>
          <div className={styles.subTitle}>Driver's license</div>
          <TextInput
            text={"First name"}
            manualInputs={manualInputs}
            triggerChange={setManualInputs}
          />
          <TextInput
            text={"Last name"}
            manualInputs={manualInputs}
            triggerChange={setManualInputs}
          />
          <TextInput
            text={"Address"}
            manualInputs={manualInputs}
            triggerChange={setManualInputs}
          />
          <TextInput
            text={"Driver's license number"}
            manualInputs={manualInputs}
            triggerChange={setManualInputs}
          />
          <DateInput
            text={"Date of birth"}
            dateValue={manualInputs.dateOfBirth}
            manualInputs={manualInputs}
            setDateFunc={setManualInputs}
            isHalf={false}
          />
          <div className={styles.twoInOneRow}>
            <DateInput
              text={"Issue date"}
              dateValue={manualInputs.issueDate}
              manualInputs={manualInputs}
              setDateFunc={setManualInputs}
              isHalf={true}
            />
            <DateInput
              text={"Expiry date"}
              dateValue={manualInputs.expiryDate}
              manualInputs={manualInputs}
              setDateFunc={setManualInputs}
              isHalf={true}
            />
          </div>
          <div className={styles.twoInOneRow}>
            <TextInput
              text={"Reference"}
              isHalf={true}
              manualInputs={manualInputs}
              triggerChange={setManualInputs}
            />
            <TextInput
              text={"Height"}
              isHalf={true}
              manualInputs={manualInputs}
              triggerChange={setManualInputs}
            />
          </div>
          <div className={styles.twoInOneRow}>
            <DropdownInput
              text={"Sex"}
              sex={manualInputs.sex}
              genders={allGenders}
              triggerSexChange={triggerSexChange}
            />
            <TextInput
              text={"Class"}
              isHalf={true}
              manualInputs={manualInputs}
              triggerChange={setManualInputs}
            />
          </div>
          <div className={styles.twoInOneRow}>
            <ImageInput
              text={"Picture of the front"}
              file={manualInputs.frontImage}
              manualInputs={manualInputs}
              setFile={setManualInputs}
            />
            <ImageInput
              text={"Picture of the back"}
              file={manualInputs.backImage}
              manualInputs={manualInputs}
              setFile={setManualInputs}
            />
          </div>
          <div className={styles.subTitle}>Profile photo</div>
          <ImageInput
            text={"Profile photo"}
            file={manualInputs.profileImage}
            manualInputs={manualInputs}
            setFile={setManualInputs}
          />
          <div className={styles.buttonsFooter}>
            <ButtonGroup
              leftButtonText="Cancel"
              leftButtonFunction={() => {
                clearInputs();
                toggleManual();
              }}
              rightButtonText={"Save"}
              rightButtonFunction={manualUploadDriversLicense}
            />
          </div>
        </div>
      )}
    </Modal>
  );
};

export default ManualEntryModal;
