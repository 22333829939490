import { gql } from "@apollo/client";

const GET_INVOICE = gql`
  query getInvoice($invoiceId: ID!) {
    getInvoice(invoiceId: $invoiceId) {
      id
      invoiceNumber
      dueDate
      status
      issuanceDate
      subtotal
      chargedTax
      total
      taxRate
      appliedBy
      absorbedBookingId
      user {
        id
        fullName
        timeZone
      }
      balance
      lineItems {
        id
        category
        name
        description
        amount
      }
      payments {
        id
        amount
        referenceNumber
        stripePaymentFailureMessage
        details
        status
        paid
        paymentTime
        paymentMethod {
          id
          last4
        }
      }
      booking {
        id
      }
      notes {
        author {
          firstName
          lastName
          profileImage
        }
        body
        pinned
        createdAt
      }
      financingBreakdown {
        comments
        requestedAt
        status
        invoices {
          id
          dueDate
          issuanceDate
          subtotal
          chargedTax
          total
          balance
        }
        upcomingInvoices {
          id
          dueDate
          issuanceDate
          subtotal
          chargedTax
          total
          balance
        }
        payments {
          id
          paid
          paymentTime
          paymentMethod {
            id
            last4
          }
        }
        paidTotal
        remainingTotal
        processedBy {
          id
          fullName
        }
      }
    }
  }
`;

const BOOKING_DETAILS = gql`
  query BookingDetails($id: ID!) {
    bookingDetails(id: $id) {
      id
      status
      startTime
      endTime
      date
      driver {
        id
        fullName
      }
      duration
      car {
        model
        make
        plate
      }
      bookingFee
      carHistory {
        carId
      }
      timeZone
    }
  }
`;

const CREATE_INVOICE = gql`
  mutation createInvoice($bookingId: ID!, $lineItems: [LineItemAttributes!]!) {
    createInvoice(bookingId: $bookingId, lineItems: $lineItems) {
      invoice {
        id
        invoiceNumber
        status
        issuanceDate
        dueDate
        subtotal
        total
        chargedTax
        lineItems {
          id
          name
          category
          amount
        }
        payments {
          id
        }
        booking {
          id
        }
        user {
          id
          fullName
        }
        financingBreakdown {
          requestedAt
          status
          invoices {
            id
            dueDate
            issuanceDate
            subtotal
            chargedTax
            total
            balance
          }
          upcomingInvoices {
            id
            dueDate
            issuanceDate
            subtotal
            chargedTax
            total
            balance
          }
          payments {
            id
            paid
            paymentTime
            paymentMethod {
              id
              last4
            }
          }
        }
      }
      error
    }
  }
`;

const ARRANGE_FINANCING = gql`
  mutation arrangeInvoiceFinancing(
    $invoiceId: ID!
    $financingDates: [DateTime!]!
  ) {
    arrangeInvoiceFinancing(
      invoiceId: $invoiceId
      financingDates: $financingDates
    ) {
      success
      error
    }
  }
`;

const EDIT_FINANCING = gql`
  mutation editInvoiceFinancing(
    $financingInstallments: [FinancingInstallmentAttributes!]!
  ) {
    editInvoiceFinancing(financingInstallments: $financingInstallments) {
      success
      error
    }
  }
`;

const DELETE_INVOICE = gql`
  mutation deleteInvoice($invoiceId: ID!, $comments: String!) {
    deleteInvoice(invoiceId: $invoiceId, comments: $comments) {
      success
      error
    }
  }
`;

const PROCESS_INVOICE = gql`
  mutation processInvoice($invoiceId: ID!, $comments: String!) {
    processInvoice(invoiceId: $invoiceId, comments: $comments) {
      success
      error
    }
  }
`;

const MARK_INVOICE = gql`
  mutation markInvoiceAs(
    $invoiceId: ID!
    $newStatus: String!
    $comments: String!
  ) {
    markInvoiceAs(
      invoiceId: $invoiceId
      newStatus: $newStatus
      comments: $comments
    ) {
      success
      error
    }
  }
`;

const DENY_FINANCING = gql`
  mutation denyInvoiceFinancing($invoiceId: ID!, $comments: String) {
    denyInvoiceFinancing(invoiceId: $invoiceId, comments: $comments) {
      success
      error
    }
  }
`;

export {
  GET_INVOICE,
  BOOKING_DETAILS,
  CREATE_INVOICE,
  ARRANGE_FINANCING,
  DELETE_INVOICE,
  DENY_FINANCING,
  EDIT_FINANCING,
  PROCESS_INVOICE,
  MARK_INVOICE,
};
