import React, { Component, Fragment, useState } from "react";
import InformationTable from "../InformationTable";
import { Helmet } from "react-helmet";

const Booking = ({selectedCities}) => {
  return (
    <Fragment>
      <Helmet>
        <title>Bookings | Autzu Admin</title>
      </Helmet>
      <InformationTable
        rowComponentType="booking"
        tableName="bookings"
        navbar="Bookings"
        startTerms="Search By BookingID, Name and Plate"
        cities={selectedCities}
      />
    </Fragment>
  );
};

export default Booking;
