import { gql } from "@apollo/client";

const USER_PAYMENT = gql`
  query UserPayments($id: ID!) {
    userProfileData(id: $id) {
      id
      email
      firstName
      lastName
      operatingCity
      driversLicenseNumber
      status
      accountStatus
      availableStatuses
      dateOfBirth
      mobile
      outstandingBalance
      outstandingBalanceUpdatedAt
      parkingLot {
        region
      }
      paymentMethods {
        status
        card {
          last4
          expYear
          expMonth
          brand
        }
        created
      }
      addresses {
        streetAddress
        postalCode
        province
        city
        updatedAt
      }
      timeZone
    }
  }
`;

export { USER_PAYMENT };
