import React from "react";
import Modal from "../../../../../artzu-ui/src/Modal";

const DeleteModal = ({ open, toggleDelete, curId, handleDelete }) => {
  return (
    <Modal open={open} backgroundColor="none">
      <div className="comment-reminder-container" style={{ height: "86px" }}>
        <div className="choose-date-container" style={{ margin: "0" }}>
          Are you sure you want to delete this comment?
        </div>
        <div className="relate-user-buttons" style={{ marginTop: "36px" }}>
          <div>
            <button
              className="booking-cancel-button"
              style={{ width: "130px" }}
              onClick={() => {
                toggleDelete();
              }}
            >
              No
            </button>
          </div>
          <div>
            <button
              className="booking-choose-button"
              style={{ width: "130px" }}
              onClick={() => {
                handleDelete(curId);
                toggleDelete();
              }}
            >
              Yes
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default DeleteModal;
