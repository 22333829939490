import React from "react";
import { TableCell, TableRow } from "../../../../../Shared/Table";
import { m } from "../../../../../../utils";
import { Fragment } from "react";
import moment from "moment-timezone";

const TransactionsTableContent = ({
  invoiceTransactions,
  create,
  status,
  timeZone,
}) => {
  return (
    <Fragment>
      {create || status === "pending" ? (
        <div className="financing-message">
          This Invoice will be processed automatically 30 minutes after issuance
        </div>
      ) : (
        invoiceTransactions &&
        invoiceTransactions.map((item) => (
          <TableRow
            justify="space-between"
            align="center"
            padding="15px 24px"
            borderB="1px solid #dedede"
          >
            <TableCell width="200px" align="left">
              {moment(item.paymentTime)
                .tz(timeZone)
                .format("MMM DD, YYYY hh:mm A z")}
            </TableCell>
            <TableCell width="180px" align="left">
              ${item.amount}
            </TableCell>
            <TableCell width="300px" align="left">
              <div style={{ display: "flex", flexDirection: "column" }}>
                <div
                  style={{
                    color:
                      item.status === "successful"
                        ? "#3DC700"
                        : item.status === "failed"
                        ? "#FF0000"
                        : null,
                  }}
                >
                  Payment {item.status}
                </div>
                <div>{item.details}</div>
                <div>{item.stripePaymentFailureMessage}</div>
              </div>
            </TableCell>
            <TableCell width="200px" align="left">
              {item.referenceNumber}
            </TableCell>
          </TableRow>
        ))
      )}
    </Fragment>
  );
};

export default TransactionsTableContent;
