import React from "react";
import classNames from "classnames";

import styles from "../bookingActions.module.scss";

const ActionContainer = ({
  handleSave,
  valid,
  closeContainer,
  saveButtonName = "Save",
  cancelButtonName = "Cancel",
}) => (
  <div className={styles.relateUserButtons}>
    <div>
      <button
        className={classNames(styles.button, styles.cancel)}
        onClick={closeContainer}
      >
        {cancelButtonName}
      </button>
    </div>
    <div>
      <button
        className={classNames(
          styles.button,
          styles.save,
          !valid && styles.disabled
        )}
        onClick={handleSave}
        disabled={!valid}
      >
        {saveButtonName}
      </button>
    </div>
  </div>
);

export default ActionContainer;
