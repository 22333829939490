import React from "react";
import styles from "../../userDetails.module.scss";
import fileUpload from "../../../../../images/icons/image-upload.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-regular-svg-icons";

const FrontImageInput = ({ text, file, manualInputs, setFile }) => {
  const setFileHelper = (output) => {
    text === "Picture of the front" &&
      setFile({ ...manualInputs, frontImage: output });
    text === "Picture of the back" &&
      setFile({ ...manualInputs, backImage: output });
    text === "Profile photo" &&
      setFile({ ...manualInputs, profileImage: output });
  };

  const imageUploader = () => {
    var output = document.getElementById(`file-input-${text}`).files[0];
    setFileHelper(output);
    output.src = URL.createObjectURL(output);
    output.onload = function () {
      URL.revokeObjectURL(output.src);
    };
  };

  return (
    <div className={styles.field} style={{ width: "230px" }}>
      <div className={styles.subscript}>{text}</div>
      <div className="image-upload">
        <label for={`file-input-${text}`}>
          <img
            src={file ? file.src : fileUpload}
            className={styles.imageUploader}
          ></img>
        </label>
        <input
          id={`file-input-${text}`}
          type="file"
          onChange={imageUploader}
          onClick={(e) => (e.target.value = null)}
        />
        {file && (
          <div className={styles.timesIcon}>
            <FontAwesomeIcon
              icon={faTimes}
              onClick={() => setFileHelper(undefined)}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default FrontImageInput;
