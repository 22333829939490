import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "./locationSelect.module.scss";
import classNames from "classnames";
import CheckBox from "./../../Shared/CheckBox";

const ParkingLot = ({ element, lotsSelected, selectLots }) => {
  return (
    <div>
      {" "}
      {element.map((lot) => (
        <div
          className={classNames(
            styles.city,
            styles.hover,
            lotsSelected.some((parkinglot) => parkinglot.name === lot.name) &&
              styles.selected
          )}
          key={lot.id}
          onClick={() => selectLots(lot)}
        >
          <CheckBox isSelected={lotsSelected.includes(lot)}/>
          {" "}
          {lot.name}
        </div>
      ))}
    </div>
  );
};

export default ParkingLot;
