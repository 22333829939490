import React from 'react'

const SvgBackArrow = ({ color = '#373535', ...props }) => (
  <svg data-name="Layer 1" viewBox="0 0 72.98 58.51" {...props}>
    <path
      strokeMiterlimit={10}
      fill="none"
      stroke={color}
      strokeLinecap="round"
      strokeWidth={7}
      d="M69.48 29.29H3.5"
    />
    <path
      strokeLinejoin="round"
      fill="none"
      stroke={color}
      strokeLinecap="round"
      strokeWidth={7}
      d="M29.23 55.01L3.5 29.29v-.06L29.23 3.5"
    />
  </svg>
)

export default SvgBackArrow
