import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenSquare } from "@fortawesome/pro-light-svg-icons";

const AccidentReportMessage = ({ msg, accidentReport, toggleAccident }) =>
  msg.label === "accident" &&
  accidentReport && (
    <div className="accident-report-message">
      <div className="ac-title">
        <FontAwesomeIcon
          icon={faPenSquare}
          onClick={toggleAccident}
          size="2x"
        />
        Accident report
      </div>
      <button className="view-report-button" onClick={toggleAccident}>
        View report
      </button>
    </div>
  );

export default AccidentReportMessage;
