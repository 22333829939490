import React from "react";
import { useState } from "react";
import { Fragment } from "react";
import UserHollow from "../../assets/icons/UserHollow.svg";
import UserHollowGrey from "../../assets/icons/UserHollowGrey.svg";
import DestroyAdminSession from "../../Mutations/DestroyAdminSession";

const Logout = () => {
  const [open, setOpen] = useState(false);

  const toggleErrorModal = () => {
    setOpen(!open);
  };
  return (
    <Fragment>
      <img
        src={open ? UserHollow : UserHollowGrey}
        onClick={toggleErrorModal}
        className="lower-icon"
      />
      <DestroyAdminSession open={open} />
    </Fragment>
  );
};

export default Logout;
