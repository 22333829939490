import { useMutation } from "@apollo/client";
import React, { useState } from "react";
import styles from "../batchActions.module.scss";
import { TICKET_BULK_ADD_COMMENT } from "../queries";

const AddComment = ({ selections, close, clearAllSelections }) => {
  const [curComment, setCurComment] = useState();

  const changeComment = (e) => {
    setCurComment(e.target.value);
  };
  const [addCommentMutation] = useMutation(TICKET_BULK_ADD_COMMENT);
  const handelAddComment = () => {
    addCommentMutation({
      variables: {
        ticketIds: selections,
        comment: curComment,
      },
    }).then(({ data: { bulkAddComment } }) => {
      if (bulkAddComment.success) {
        clearAllSelections();
        close();
        console.log("Add Comment Succeed!");
      } else {
        console.log("Add Comment error!");
      }
    });
  };
  return (
    <div className={styles.ticketActionContainer}>
      <div className={styles.actionHeader}>Add comment</div>
      <span className="subscript">Comment</span>
      <textarea className={styles.ticketTextBox} onChange={changeComment} />
      <div className={styles.ticketModalButtons}>
        <div>
          <button
            className={styles.ticketModalCancelButton}
            onClick={() => {
              close();
            }}
          >
            Cancel
          </button>
        </div>
        <div>
          <button
            className={styles.ticketModalApplyButton}
            onClick={() => {
              handelAddComment();
            }}
          >
            Apply
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddComment;
