import styles from "../userDashboard.module.scss";
import RideshareBlack from "../../../../assets/icons/RideshareBlack.svg";
import { titleize } from "../../../../utils";
import moment from "moment-timezone";
import { Link } from "react-router-dom";
import { formatString, statusColor } from "../utils";

const RideshareTab = ({ data }) => {
  const timeZone = data.timeZone;
  return (
    <div className={styles.categoryTab}>
      <div className={styles.tabHeader}>
        <div className={styles.title}>
          <img src={RideshareBlack} />
          <div className={styles.textComponent}>
            Rideshare
            {/* DO THIS BETTER, look at all rideshare platforms, maybe helper func*/}
            {data.ridesharePlatforms[0].lastCheckedAt && (
              <div className={styles.datescript}>
                Last Updated:{" "}
                {moment(data.ridesharePlatforms[0].lastCheckedAt)
                  .tz(timeZone)
                  .format("MMM DD, YYYY h:mmA z")}
              </div>
            )}
          </div>
        </div>
        <Link
          to={{ pathname: `/users/${data.id}/rideshare` }}
          style={{ textDecoration: "none", color: "#128dff" }}
        >
          See more
        </Link>
      </div>
      <div className={styles.tabContent}>
        {data.ridesharePlatforms.map((source) => (
          <div className={styles.contentBlock}>
            <div className={styles.statCell} style={statusColor(source)}>
              {formatString(source.impliedStatus)}
            </div>
            {source.connectedOn ? (
              <div className={styles.subscript} style={{ opacity: "0.5" }}>
                Connected on:{" "}
                {moment(source.connectedOn).format("MMM DD, YYYY")}
              </div>
            ) : (
              ""
            )}
            <div className={styles.subscript} style={{ marginTop: "16px" }}>
              {titleize(source.name)}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export { RideshareTab };
