import React from "react";
import BlueRadio from "../../Shared/BlueRadio";
import Y1 from "../../../assets/diagrams/Y1.png";
import Y2 from "../../../assets/diagrams/Y2.png";

const Axis = ({ invalidAxis, axis, setAxis }) => {
  return (
    <div className="dataset-options__field" style={{ border: "none" }}>
      <label>Axis</label>
      <div className="plot-options">
        <div className={`plot-axis${invalidAxis(1) ? " translucent" : ""}`}>
          <img src={Y1} />
          <div style={{ display: "flex", alignItems: "center" }}>
            <BlueRadio 
              disabled={invalidAxis(1)} 
              value="yAxis1" 
              size="small" 
              checked={axis === "yAxis1"} 
              onChange={e => setAxis(e.target.value)} 
            />
            <div>Y-axis 1</div>
          </div>
        </div>
        <div className={`plot-axis${invalidAxis(2) ? " translucent" : ""}`}>
          <img src={Y2} />
          <div style={{ display: "flex", alignItems: "center" }}>
            <BlueRadio 
              disabled={invalidAxis(2)} 
              value="yAxis2" 
              size="small" 
              checked={axis === "yAxis2"} 
              onChange={e => setAxis(e.target.value)} 
            />
            <div>Y-axis 2</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Axis;
