import React, { useState } from "react";
import styles from "./batchActions.module.scss";
import {
  faComments,
  faCommentAlt,
  faUser,
  faTag,
} from "@fortawesome/pro-light-svg-icons";
import ActionButton from "./ActionButton";
import ActionModal from "./ActionModal";
import _ from "lodash";
import FeedbackModal from "../../Shared/Modal/FeedbackModal";
import { Fragment } from "react";
import { titleize } from "../../../utils";

const UserBatchActions = ({
  isOpen,
  selectedRows,
  removeSelection,
  refetch,
  clearAllSelections,
  isAllSelected,
  citiesFilter,
}) => {
  const [selectedBatchAction, setSelectedBatchAction] = useState();
  const [selectedBatchActionText, setSelectedBatchActionText] = useState();
  const [isFeedback, setIsFeedback] = useState();
  const icons = [
    {
      value: "changeUserStatus",
      text: "Change user status",
      icon: faUser,
    },
    {
      value: "addComment",
      text: "Add comment",
      icon: faCommentAlt,
    },
    {
      value: "addTag",
      text: "Add Tag",
      icon: faTag,
    },
    {
      value: "communication",
      text: "Communication",
      icon: faComments,
    },
  ];

  const batchOptionsHash = _.reduce(
    icons,
    (h, icon) => {
      h[icon.value] = icon;
      return h;
    },
    {}
  );

  const toggleFeedbackModal = () => {
    setIsFeedback(!isFeedback);
  };

  const closeModal = () => setSelectedBatchAction(null);
  return (
    <Fragment>
      {isOpen && (
        <div className={styles.batchActions}>
          <div className={styles.batchActionButtons}>
            {icons.map((icon) => (
              <ActionButton
                icon={icon.icon}
                text={icon.text}
                onClick={() => {
                  setSelectedBatchAction(icon.value);
                  setSelectedBatchActionText(icon.text);
                }}
              />
            ))}
          </div>
          <ActionModal
            isOpen={selectedBatchAction}
            selectedBatchAction={selectedBatchAction}
            selectedRows={selectedRows}
            removeSelection={removeSelection}
            batchOptionsHash={batchOptionsHash}
            closeModal={closeModal}
            toggleRefresh={refetch}
            clearAllSelections={clearAllSelections}
            isAllSelected={isAllSelected}
            toggleFeedbackModal={toggleFeedbackModal}
            citiesFilter={citiesFilter}
          />
        </div>
      )}
      <FeedbackModal
        open={isFeedback}
        batchAction={selectedBatchActionText}
        toggleFeedbackModal={toggleFeedbackModal}
      />
    </Fragment>
  );
};

export default UserBatchActions;
