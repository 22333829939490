import React, { Component, Fragment } from "react";
import ParameterPanel from "./ParameterPanel";
import DatasetPanel from "./DatasetPanel";
import Divider from "../../artzu-ui/src/Divider";
import { m } from "../../utils";
import PlotHook from "../../Queries/AnalyticData";
import "./index.css";
import { Helmet } from "react-helmet";

class UserAnalytics extends Component {
  constructor(props) {
    super(props);
    const y1 = this.props.analyticParams.find((d) => d.axis === "yAxis1");
    const y2 = this.props.analyticParams.find((d) => d.axis === "yAxis2");
    let savedStart,
      savedEnd,
      savedIncrement = null;
    if (this.props.analyticParams.length > 0) {
      savedStart = this.props.analyticParams[0].rangeStart;
      savedEnd = this.props.analyticParams[0].rangeEnd;
      savedIncrement = this.props.analyticParams[0].increment;
    }
    this.state = {
      rangeStart: savedStart || m("2018-01-22"),
      rangeEnd: savedEnd || m(),
      increment: savedIncrement || "1 WEEK",
      yAxis1: y1 ? y1.unit : null,
      yAxis2: y2 ? y2.unit : null,
      datasets: this.props.analyticParams.map((d) => {
        return {
          metric: d.metric,
          plotFormat: d.plotFormat,
          unit: d.unit,
          parkingLotIds: d.parkingLotIds,
          vehicleClass: d.vehicleClass,
          axis: d.axis,
          colorway: d.colorway,
          accountStatus: d.accountStatus,
          invoiceStatus: d.invoiceStatus
        };
      }),
      analyticParams: this.props.analyticParams,
      palette: [
        "#128dff",
        "#ffab4a",
        "#00a896",
        "#fb4d3d",
        "#682d63",
        "#ef9cda",
        "#232c33",
      ],
      csvData: []
    };
  }

  handleXChange = (params) => {
    const { rangeStart, rangeEnd, increment } = params;
    let palette = this.state.palette;
    let datasets = this.state.datasets;
    let yAxis1, yAxis2;
    const { metrics } = this.props;
    let plotData = datasets.map((d, i) => {
      let nd = Object.assign({}, d);
      nd.rangeStart = rangeStart;
      nd.rangeEnd = rangeEnd;
      nd.increment = increment;
      nd.parkingLotIds = nd.parkingLotIds.map((pid) => parseInt(pid));
      nd.scope = "parking_lot";
      nd.colorway = d.colorway;
      nd.userId = this.props.userId;
      let metric = metrics.find((m) => m.name === nd.metric)
      if (metric != null){
        nd.unit = metric.unit
      }
      // nd.unit = metrics.find((m) => m.name === nd.metric).unit; // maybe refactor this into DatasetOptions prior to calling this function
      if (nd.axis === "yAxis1") {
        yAxis1 = nd.unit;
      } else if (nd.axis === "yAxis2") {
        yAxis2 = nd.unit;
      }
      return nd;
    });
    this.setState({
      rangeStart,
      rangeEnd,
      increment,
      analyticParams: plotData,
      yAxis1,
      yAxis2,
    });
  };

  addDataset = (dataset) => {
    const { rangeStart, rangeEnd, increment, palette } = this.state;
    let datasets = this.state.datasets;
    dataset.colorway =
      palette.filter((p) => !datasets.map((d) => d.colorway).includes(p))[0] ||
      `#${Math.floor(Math.random() * 16777215).toString(16)}`;
    datasets.push(dataset);
    let yAxis1, yAxis2;
    const { metrics } = this.props;
    let plotData = datasets.map((d, i) => {
      d.rangeStart = rangeStart;
      d.rangeEnd = rangeEnd;
      d.increment = increment;
      d.parkingLotIds = d.parkingLotIds.map((pid) => parseInt(pid));
      d.scope = "parking_lot";
      d.colorway = d.colorway;
      d.userId = this.props.userId;
      let metric = metrics.find((m) => m.name === d.metric)
      if (metric != null){
        d.unit = metric.unit
      }
      if (d.axis === "yAxis1") {
        yAxis1 = d.unit;
      } else if (d.axis === "yAxis2") {
        yAxis2 = d.unit;
      }
      return d;
    });

    this.setState({ datasets, analyticParams: plotData, yAxis1, yAxis2 });
  };

  updateDataset = (index, dataset) => {
    const { rangeStart, rangeEnd, increment, palette } = this.state;
    let datasets = this.state.datasets;
    datasets[index] = dataset;
    let yAxis1, yAxis2;
    const { metrics } = this.props;
    let plotData = datasets.map((d, i) => {
      d.rangeStart = rangeStart;
      d.rangeEnd = rangeEnd;
      d.increment = increment;
      d.parkingLotIds = d.parkingLotIds.map((pid) => parseInt(pid));
      d.scope = "parking_lot";
      d.colorway = d.colorway;
      d.userId = this.props.userId;
      let metric = metrics.find((m) => m.name === d.metric)
      if (metric != null){
        d.unit = metric.unit
      }
      if (d.axis === "yAxis1") {
        yAxis1 = d.unit;
      } else if (d.axis === "yAxis2") {
        yAxis2 = d.unit;
      }
      return d;
    });
    this.setState({ datasets, analyticParams: plotData, yAxis1, yAxis2 });
  };

  removeDataset = (index) => {
    const { rangeStart, rangeEnd, increment, palette } = this.state;
    let datasets = this.state.datasets;
    datasets.splice(index, 1);
    let yAxis1, yAxis2;
    const { metrics } = this.props;
    let plotData = datasets.map((d, i) => {
      d.rangeStart = rangeStart;
      d.rangeEnd = rangeEnd;
      d.increment = increment;
      d.parkingLotIds = d.parkingLotIds.map((pid) => parseInt(pid));
      d.scope = "parking_lot";
      d.colorway = d.colorway;
      d.userId = this.props.userId;
      let metric = metrics.find((m) => m.name === d.metric)
      if (metric != null){
        d.unit = metric.unit
      }
      if (d.axis === "yAxis1") {
        yAxis1 = d.unit;
      } else if (d.axis === "yAxis2") {
        yAxis2 = d.unit;
      }
      return d;
    });
    this.setState({ datasets, analyticParams: plotData, yAxis1, yAxis2 });
  };

  plot = () => {
    const { rangeStart, rangeEnd, increment } = this.state;
    const { metrics } = this.props;
    let plotData = this.state.datasets.map((d) => {
      d.rangeStart = rangeStart;
      d.rangeEnd = rangeEnd;
      d.increment = increment;
      d.parkingLotIds = d.parkingLotIds.map((pid) => parseInt(pid));
      d.scope = "parking_lot";
      let metric = metrics.find((m) => m.name === d.metric)
      if (metric != null){
        d.unit = metric.unit
      }
      delete d.axis;
      return d;
    });
    this.setState({ analyticParams: plotData });
  };

  construct = (params) => {
    const { metric, rangeStart, rangeEnd, increment } = params;
    let unit = this.props.metrics.find((m) => m.name === metric).unit;
    let analyticParams = [];
    analyticParams.push({
      scope: "parking_lot",
      metric,
      rangeStart,
      rangeEnd,
      increment,
      unit,
    });
    this.setState({ analyticParams, yAxis1: unit });
  };

  setCsvData = (data) => {
    this.setState({ csvData: data })
  }

  render() {
    const {
      rangeStart,
      rangeEnd,
      increment,
      analyticParams,
      yAxis1,
      yAxis2,
      metric,
      datasets,
      csvData
    } = this.state;
    const { metrics, locations, accountStatuses, invoiceStatuses } = this.props;
    return (
      <Fragment>
        <Helmet>
          <title>Analytics | Autzu Admin</title>
        </Helmet>
        <div className="analytics-body">
          <ParameterPanel
            handleXChange={this.handleXChange}
            rangeStart={rangeStart}
            rangeEnd={rangeEnd}
            increment={increment}
            csvData={csvData}
          />
          <DatasetPanel
            addDataset={this.addDataset}
            updateDataset={this.updateDataset}
            removeDataset={this.removeDataset}
            datasets={datasets}
            locations={locations}
            accountStatuses={accountStatuses}
            invoiceStatuses={invoiceStatuses}
            metrics={metrics}
            yAxis1={yAxis1}
            yAxis2={yAxis2}
          />
          <PlotHook
            offsetWidth={588}
            analyticParams={analyticParams}
            yAxis1Label={yAxis1}
            yAxis2Label={yAxis2}
            xAxisLabel={increment.toLowerCase().slice(2) + "s"}
            setCsvData={this.setCsvData}
          />
        </div>
      </Fragment>
    );
  }
}

export default UserAnalytics;
