import { useMutation } from "@apollo/client";
import React, { useState } from "react";
import styles from "../batchActions.module.scss";
import { BULK_RECOVER_TICKETS } from "../queries";

const RecoverTickets = ({ selections, close, clearAllSelections }) => {
  const [recoverTicketsMutation] = useMutation(BULK_RECOVER_TICKETS);
  const handleRecover = () => {
    recoverTicketsMutation({
      variables: {
        ticketIds: selections,
      },
    }).then(({ data: { bulkRecover } }) => {
      if (bulkRecover.success) {
        clearAllSelections();
        close();
        console.log("Recover Tickets Succeed!");
      } else {
        console.log("Recover Tickets error!");
      }
    });
  };
  return (
    <div className={styles.ticketActionContainerSmall}>
      <div className={styles.actionHeader}>Recover tickets</div>
      <span style={{ marginBottom: "24px" }}>
        Are you sure you want to recover these tickets?
      </span>
      <div className={styles.ticketModalButtons}>
        <div>
          <button
            className={styles.ticketModalCancelButton}
            onClick={() => {
              close();
            }}
          >
            No
          </button>
        </div>
        <div>
          <button
            className={styles.ticketModalApplyButton}
            onClick={() => {
              handleRecover();
            }}
          >
            Yes
          </button>
        </div>
      </div>
    </div>
  );
};

export default RecoverTickets;
