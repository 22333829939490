import React from "react";
import ActionList from "../ActionList";
import { Link } from "react-router-dom";
import { capitalize } from "lodash";
import moment from "moment-timezone";

const TopLeft = ({
  driver,
  bookingId,
  invoiceData,
  tmpTotal,
  driverId,
  create,
  refetch,
}) => {
  const invoiceInfo = invoiceData && invoiceData.getInvoice;
  const invoiceId = invoiceInfo && invoiceInfo.id;
  const invoiceNum = invoiceInfo && invoiceInfo.invoiceNumber;
  const totalAmount = invoiceInfo && invoiceInfo.total;
  const status = invoiceInfo && invoiceInfo.status;
  const driverName = invoiceInfo && invoiceInfo.user.fullName;
  const driId = invoiceInfo && invoiceInfo.user.id;
  const bookingRef =
    invoiceInfo && invoiceInfo.booking ? invoiceInfo.booking.id : null;
  const absorbedBookingRef =
    invoiceInfo && invoiceInfo.absorbedBookingId? invoiceInfo.absorbedBookingId : bookingRef;
  const issueDate = invoiceInfo && invoiceInfo.issuanceDate;
  const issuer = invoiceInfo && invoiceInfo.appliedBy;
  const lastTran =
    invoiceInfo && invoiceInfo.payments.length !== 0
      ? invoiceInfo.payments[invoiceInfo.payments.length - 1].paymentTime
      : null;
  const left = true;

  const timeZone = invoiceInfo && invoiceInfo.user.timeZone;

  const unpaidAction = [
    { name: "Mark as Paid", value: "Mark as Paid" },
    { name: "Process Invoice", value: "Process Invoice" },
  ];

  const paidAction = [{ name: "Mark as Unpaid", value: "Mark as Unpaid" }];

  const pendingAction = [
    { name: "Process Invoice", value: "Process Invoice" },
    { name: "Mark as Paid", value: "Mark as Paid" },
  ];

  const getAction = (status) => {
    switch (status) {
      case "pending":
        return pendingAction;
      case "paid":
        return paidAction;
      case "unpaid":
        return unpaidAction;
      default:
        return null;
        break;
    }
  };

  const taxRate = 0.13;
  const realTotal = tmpTotal * taxRate + tmpTotal;

  var today = new Date();

  const getColor = (text) => {
    switch (text) {
      case "pending":
        return "#9B51E0";
      case "paid":
        return "#3dc700";
      case "unpaid":
        return "#ff0000";
      case "financed":
        return "#f2994a";
      case "deleted":
        return "rgba(65, 65, 65, 0.5)";
      case "credit":
        return "#3dc700";
      default:
        break;
    }
  };

  function getInvoiceForLink() {
    const userId = driId ? driId: driverId;
    const bookingLink = absorbedBookingRef ? absorbedBookingRef : bookingId;
    return `/users/${userId}/bookings/${bookingLink}`;
  }

  const actions = getAction(status);
  const clickable = actions && actions.length !== 0;
  return (
    <div className="invoice-info">
      <div className="info-left">
        <div className="details-field">
          <div className="subscript">Invoice number</div>
          <div className={invoiceNum ? "plain-field" : "plain-field-grey"}>
            {invoiceNum || "Not Issued"}
          </div>
        </div>
        <div className="details-field">
          <div className="subscript">Total amount</div>
          <div
            className={totalAmount ? "plain-field" : "plain-field-grey"}
            style={{
              fontSize: "40px",
              color: `${
                realTotal < 0 || totalAmount < 0 ? "#3DC700" : "#414141"
              }`,
            }}
          >
            ${" "}
            {totalAmount
              ? totalAmount < 0
                ? `${Math.abs(totalAmount).toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                    roundingMode: "floor",
                  })} Credit`
                : totalAmount.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                    roundingMode: "floor",
                  })
              : tmpTotal
              ? realTotal < 0
                ? `${Math.abs(realTotal).toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                    roundingMode: "floor",
                  })} Credit`
                : realTotal.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                    roundingMode: "floor",
                  })
              : "00.00"}
          </div>
        </div>
        <div className="details-field">
          <div className="subscript">Status of invoice</div>
          <div
            className={status ? "plain-field" : "plain-field-grey"}
            style={{ color: `${getColor(status)}` }}
          >
            {capitalize(status) || "Not Issued"}
          </div>
        </div>
        {!create && (
          <div className="details-field">
            <div className="subscript">Last transaction</div>
            <div className="plain-field">
              {lastTran
                ? moment(lastTran).tz(timeZone).format("MMM DD, YYYY h:mm A z")
                : "-"}
            </div>
          </div>
        )}
      </div>
      <div className="info-right">
        <div className="details-field">
          <div className="subscript">Driver name</div>
          <Link
            to={`/users/${driId ? driId : driverId}`}
            target="_blank"
            style={{ textDecoration: "none" }}
          >
            <div className="plain-field-blue">
              {driverName ? driverName : driver}
            </div>
          </Link>
        </div>
        <div className="details-field">
          <div className="subscript">Booking reference</div>
          <Link
            to={`/users/${driId ? driId : driverId}/bookings/${
              bookingRef ? bookingRef : bookingId
            }`}
            target="_blank"
            style={{ textDecoration: "none" }}
          >
            <div className="plain-field-blue">
              {bookingRef ? bookingRef : bookingId}
            </div>
          </Link>
        </div>
        <div className="details-field">
          <div className="subscript">Issue date</div>
          <div className="plain-field">
            {create
              ? moment(today).format("MMM DD, YYYY h:mm A z")
              : issueDate
              ? moment(issueDate).tz(timeZone).format("MMM DD, YYYY h:mm A z")
              : ""}
          </div>
          {issueDate && (
            <div style={{ opacity: "0.5", fontSize: "12px" }}>
              Issued by {issuer}
            </div>
          )}
        </div>
        <div className="details-field">
          <div className="subscript">Invoice For Booking</div>
          <Link
            to={getInvoiceForLink()}
            target="_blank"
            style={{ textDecoration: "none" }}
          >
            <div className="plain-field-blue">
              {absorbedBookingRef ? absorbedBookingRef : bookingId}
            </div>
          </Link>
        </div>
      </div>
      {!create && (
        <div className="threeDot">
          <ActionList
            actions={actions}
            clickable={clickable}
            left={left}
            invoiceId={invoiceId}
            refetch={refetch}
          />
        </div>
      )}
    </div>
  );
};

export default TopLeft;
