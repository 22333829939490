import moment from "moment";
import React from "react";
import { m, titleize, renderColor } from "../../../utils";
import StarRatings from "react-star-ratings";
import { rgb } from "d3-color";

const Status = ({ rideshareData }) => {
  const notConnected = rideshareData[0].impliedStatus === "not_connected";
  var now = moment();
  const isExpired = !notConnected
    ? m(rideshareData[0].cookiesExpiryDate).isBefore(m(now))
    : null;
  const setColor = notConnected
    ? rgb(65, 65, 65, 0.5)
    : isExpired
    ? "red"
    : "#3dc700";

  return (
    <div className="top-container">
      <div className="status-container">
        {"Autzu Displayed Uber Status"}
        <div
          className={renderColor(
            "Account Status",
            !notConnected
              ? rideshareData[0].impliedStatus.toLowerCase()
              : undefined
          )}
          style={{ fontSize: "40px", marginTop: "5px", fontWeight: "500" }}
        >
          {!notConnected
            ? titleize(rideshareData[0].impliedStatus)
            : "Not connected"}
          {!notConnected && rideshareData[0].lastCheckedAt && (
            <div style={{ opacity: "0.5", color: "#414141", fontSize: "15px" }}>
              Last checked:{" "}
              {m(rideshareData[0].lastCheckedAt).format("h:mm A MMM D, YYYY")}
            </div>
          )}
        </div>
      </div>
      <div className="status-container">
        {"Real Uber Status"}
        <div
          className={renderColor(
            "Account Status",
            !notConnected
              ? rideshareData[0].impliedStatus.toLowerCase()
              : undefined
          )}
          style={{ fontSize: "40px", marginTop: "5px", fontWeight: "500" }}
        >
          {!notConnected
            ? titleize(rideshareData[0].thirdPartyStatus)
            : "Not connected"}
          {!notConnected && rideshareData[0].lastCheckedAt && (
            <div style={{ opacity: "0.5", color: "#414141", fontSize: "15px" }}>
              Last checked:{" "}
              {m(rideshareData[0].lastCheckedAt).format("h:mm A MMM D, YYYY")}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Status;
