import React from "react";
import ListPopup from "./ListPopup";
import styles from "./listPopup.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisH } from "@fortawesome/pro-regular-svg-icons";

const ActionList = ({
  actions,
  listData,
  invoiceNum,
  total,
  invoiceId,
  financingCallback,
  clickable,
  left,
  refetch,
}) => {
  const ButtonComponent = ({ onClick }) => (
    <FontAwesomeIcon
      icon={faEllipsisH}
      onClick={onClick}
      color={
        left
          ? clickable
            ? "#128dff"
            : "rgba(65,65,65,0.5)"
          : clickable
          ? "#128dff"
          : "rgba(65,65,65,0.5)"
      }
    />
  );

  const SelectedButtonComponent = ({ onClick }) => (
    <FontAwesomeIcon
      className={styles.selected}
      icon={faEllipsisH}
      onClick={onClick}
    />
  );

  return (
    <div>
      <ListPopup
        listItems={actions}
        buttonComponent={ButtonComponent}
        selectedButtonComponent={SelectedButtonComponent}
        listData={listData}
        invoiceNum={invoiceNum}
        total={total}
        invoiceId={invoiceId}
        financingCallback={financingCallback}
        clickable={clickable}
        left={left}
        refetch={refetch}
      />
    </div>
  );
};

export default ActionList;
