import React, { useState } from "react";
import { TableRow } from "../../Shared/Table";
import { rowComponentsBooking } from "../rowData/rowDataBooking";
import { processTableData } from "../misc/helperFuncs";
import styles from "../informationTable.module.scss";
import RowSelect from "../CellValues/RowSelect";
import { Link, useHistory } from "react-router-dom";
import TableCell from "./TableCell";
import { rowComponentsPayment } from "../rowData/rowDataPayment";
import { rowComponentsUser } from "../rowData/rowDataUser";
import { rowComponentsTicket } from "../rowData/rowDataTicket";

const Body = ({
  rows,
  selectedRows,
  selectRow,
  isEditModeActivated,
  modifyEdits,
  edits,
  tabs,
  rowComponentType,
  tableName,
  popupSpecific,
  popupFunc,
  isAllSelected,
}) => {
  const parsedTableData = processTableData(rows);
  const [expandedColumn, setExpandedColumn] = useState(null);
  const [selectedRow, setSelectedRow] = useState();
  const selectable = popupSpecific
    ? false
    : tableName === "users"
    ? true
    : tableName === "tickets"
    ? true
    : tabs.currentTab === "future" || tabs.currentTab === "cancelled";

  const getComponentType = (component, time_zone) => {
    switch (component) {
      case "booking":
        return rowComponentsBooking(time_zone);
      case "payment":
        return rowComponentsPayment(time_zone);
      case "user":
        return rowComponentsUser(time_zone);
      case "ticket":
        return rowComponentsTicket(time_zone);
      default:
        return null;
    }
  };

  const history = useHistory();

  return parsedTableData.map(({ rowData, rowId, rowLink, rowTimeZone }) => {
    const rowComponents = getComponentType(rowComponentType, rowTimeZone);

    const isRowExpanded = expandedColumn && expandedColumn.rowId == rowId;
    return !popupSpecific ? (
      <Link
        key={rowId}
        className={styles.link}
        to={{
          pathname: `${rowLink}`,
          state: { id: rowLink },
        }}
      >
        <TableRow
          padding="0.8em 0"
          align="center"
          height={isRowExpanded ? "" : "fit-content"}
          minWidth={"100%"}
          color="#FFFFFF"
          justifyIpad="space-between"
          justify="space-evenly"
          margin="0 0 12px 0"
          hoverColor="#dedede"
          hover
          key={rowId}
          // onClick={() => history.push(`${rowLink}`)}
          pointer
        >
          {selectable && (
            <TableCell width="64px" onClick={(evt) => evt.stopPropagation()}>
              <RowSelect
                rowId={rowId}
                onClick={() => selectRow(rowId)}
                isSelected={selectedRows[rowId]}
                isAllSelected={isAllSelected}
              />
            </TableCell>
          )}

          {rowData.map((column) => {
            let cellData;
            const rowInfo = rowComponents[column.columnName];
            if (rowInfo) {
              const ColumnComponent = rowInfo.component;
              if (rowInfo.dataProcessor) {
                cellData = rowInfo.dataProcessor(column.columnData);
              }

              const rowExpansionHandlers = {
                onMouseEnter: () =>
                  setExpandedColumn({
                    rowId,
                    columnName: column.columnName,
                  }),
                onMouseLeave: () => setExpandedColumn(null),
              };

              const editedRowValues = edits[rowId];
              const editedValue =
                editedRowValues &&
                editedRowValues[column.columnName] &&
                editedRowValues[column.columnName].newValue;

              const isColumnExtended =
                isRowExpanded &&
                expandedColumn &&
                expandedColumn.columnName == column.columnName;
              return (
                <TableCell width={"140px"} display={"flex"}>
                  <ColumnComponent
                    isExpanded={isColumnExtended}
                    rowExpansionHandlers={rowExpansionHandlers}
                    classes={styles.tableCellFlex}
                    cellData={cellData}
                    columnName={column.columnName}
                    rowId={rowId}
                    isEditModeActivated={isEditModeActivated}
                    modifyEdits={modifyEdits}
                    editedValue={editedValue}
                  />
                </TableCell>
              );
            } else {
              return <TableCell width={"140px"}>NULL</TableCell>;
            }
          })}
        </TableRow>
      </Link>
    ) : (
      <TableRow
        padding="0.8em 0"
        align="center"
        height={isRowExpanded ? "" : "fit-content"}
        minWidth={"100%"}
        color={selectedRow === rowId ? "rgba(0, 0, 0, 0.1)" : "#FFFFFF"}
        justifyIpad="space-between"
        justify="space-evenly"
        margin="0 0 12px 0"
        hoverColor="#dedede"
        hover
        key={rowId}
        onClick={() => {
          setSelectedRow(rowId);
          popupFunc(rowId);
        }}
        pointer
      >
        {selectable && (
          <TableCell width="64px" onClick={(evt) => evt.stopPropagation()}>
            <RowSelect
              rowId={rowId}
              onClick={() => selectRow(rowId)}
              isSelected={selectedRows[rowId]}
              isAllSelected={isAllSelected}
            />
          </TableCell>
        )}

        {rowData.map((column) => {
          let cellData;
          const rowInfo = getComponentType(rowComponentType)[column.columnName];
          if (rowInfo) {
            const ColumnComponent = rowInfo.component;
            if (rowInfo.dataProcessor) {
              cellData = rowInfo.dataProcessor(column.columnData);
            }

            const rowExpansionHandlers = {
              onMouseEnter: () =>
                setExpandedColumn({
                  rowId,
                  columnName: column.columnName,
                }),
              onMouseLeave: () => setExpandedColumn(null),
            };

            const editedRowValues = edits[rowId];
            const editedValue =
              editedRowValues &&
              editedRowValues[column.columnName] &&
              editedRowValues[column.columnName].newValue;

            const isColumnExtended =
              isRowExpanded &&
              expandedColumn &&
              expandedColumn.columnName == column.columnName;
            return (
              <TableCell width={"140px"} display={"flex"}>
                <ColumnComponent
                  isExpanded={isColumnExtended}
                  rowExpansionHandlers={rowExpansionHandlers}
                  classes={styles.tableCellFlex}
                  cellData={cellData}
                  columnName={column.columnName}
                  rowId={rowId}
                  isEditModeActivated={isEditModeActivated}
                  modifyEdits={modifyEdits}
                  editedValue={editedValue}
                />
              </TableCell>
            );
          } else {
            return <TableCell width={"140px"}>NULL</TableCell>;
          }
        })}
      </TableRow>
    );
  });
};

export default Body;
