import React, { useState } from "react";
import { Fragment } from "react";
import ErrorModal from "../../../../../Payments/Invoices/ActionPopup/ErrorModal";
import EditContainer from "./EditContainer";

const ChangeBookingSchedule = ({ booking, closeEditBooking, userId }) => {
  const [isError, setIsError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  const toggleErrorModal = () => {
    setIsError(!isError);
  };

  const showErrorMessage = (error) => {
    toggleErrorModal();
    setErrorMsg(error);
  };

  return (
    <Fragment>
      <EditContainer
        booking={booking}
        close={closeEditBooking}
        userId={userId}
        showErrorMessage={showErrorMessage}
      />
      <ErrorModal
        open={isError}
        errorMsg={errorMsg}
        toggleErrorModal={toggleErrorModal}
      />
    </Fragment>
  );
};

export default ChangeBookingSchedule;
