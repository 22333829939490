import React from "react";
import gql from "graphql-tag";
import { Query } from "@apollo/client/react/components";
import { Redirect, Route } from "react-router-dom";

import Loading from "../Components/Shared/Loading";

const AuthRoute = (props) => (
  <Query
    query={gql`
      query AdminAuth {
        currentAdminUser {
          authenticationToken
          email
        }
      }
    `}
    pollInterval={1800000}
    fetchPolicy={"no-cache"}
  >
    {({ loading, error, data, refetch }) => {
      if (loading) return <Loading />;
      if (error) {
        return <Redirect to="/404" />;
      }
      const Component = props.component;
      return data &&
        data.currentAdminUser &&
        data.currentAdminUser.authenticationToken ? (
        <Route
          refetch={refetch}
          exact
          path={props.path}
          component={() => <Component {...props} />}
          // {...props}
        />
      ) : (
        <Redirect
          to={{
            pathname: "/login",
            state: { from: window.location.pathname },
          }}
        />
      );
    }}
  </Query>
);

export default AuthRoute;
