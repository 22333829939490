import { useMutation } from "@apollo/client";
import React, { useState } from "react";
import { Fragment } from "react";
import Modal from "../../../../artzu-ui/src/Modal";
import { DELETE_INVOICE, PROCESS_INVOICE, MARK_INVOICE } from "../../queries";
import ErrorModal from "./ErrorModal";
import styles from "./popup.module.scss";

const GeneralPopup = ({
  open,
  action,
  togglePopup,
  invoiceId,
  createInvoiceCallback,
  refetch,
  closeList,
}) => {
  const [curComment, setCurComment] = useState();
  const [isError, setIsError] = useState();
  const [errorMsg, setErrorMsg] = useState();
  const newStatus = action === "mark as unpaid" ? "unpaid" : "paid";

  const setComment = (e) => {
    setCurComment(e.target.value);
  };

  const toggleErrorModal = () => {
    setIsError(!isError);
  };
  const [markMutation] = useMutation(MARK_INVOICE);
  const markInvoice = () => {
    markMutation({
      variables: {
        invoiceId: invoiceId,
        newStatus: newStatus,
        comments: curComment,
      },
    }).then(({ data: { markInvoiceAs } }) => {
      if (markInvoiceAs.success) {
        console.log("mark succeed!");
        refetch();
        togglePopup();
        // closeList();
      } else {
        toggleErrorModal();
        setErrorMsg(markInvoiceAs.error);
        console.log("mark error!");
      }
    });
  };

  const [processMutation] = useMutation(PROCESS_INVOICE);
  const processInvoice = () => {
    processMutation({
      variables: {
        invoiceId: invoiceId,
        comments: curComment,
      },
    }).then(({ data: { processInvoice } }) => {
      if (processInvoice.success) {
        console.log("process succeed!");
        refetch();
        togglePopup();
        // closeList();
      } else {
        toggleErrorModal();
        setErrorMsg(processInvoice.error);
        console.log("process error!");
      }
    });
  };

  const [deleteMutation] = useMutation(DELETE_INVOICE);
  const deleteNewInvoice = () => {
    deleteMutation({
      variables: {
        invoiceId: invoiceId,
        comments: curComment,
      },
    }).then(({ data: { deleteInvoice } }) => {
      if (deleteInvoice.success) {
        createInvoiceCallback({ invoiceId: invoiceId });
        console.log("delete succeed!");
        togglePopup();
      } else {
        toggleErrorModal();
        setErrorMsg(deleteInvoice.error);
        console.log("delete error!");
      }
    });
  };

  const ableToSave = curComment !== "" && curComment !== undefined;
  return (
    <Fragment>
      <Modal open={open} backgroundColor="none">
        <div className={styles.denyPopup}>
          Please enter the reason for {action && action.toLowerCase()} of this
          invoice.
          <textarea className={styles.denyReason} onChange={setComment}>
            {curComment}
          </textarea>
          <div className={styles.actionButtons}>
            <div>
              <button
                className={styles.cancelButton}
                onClick={() => {
                  togglePopup();
                  action !== "delete" && closeList();
                }}
              >
                Cancel
              </button>
            </div>
            <div>
              <button
                className={
                  ableToSave ? styles.saveButton : styles.saveButtonDisabled
                }
                onClick={() => {
                  action === "delete" && ableToSave && deleteNewInvoice();
                  action === "process" && ableToSave && processInvoice();
                  (action === "mark as unpaid" || action === "mark as paid") &&
                    ableToSave &&
                    markInvoice();

                  action !== "delete" && ableToSave && closeList();
                }}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </Modal>
      <ErrorModal
        open={isError}
        errorMsg={errorMsg}
        toggleErrorModal={toggleErrorModal}
      />
    </Fragment>
  );
};

export default GeneralPopup;
