import React, { Fragment } from "react";
import styles from "./inspection.module.scss";
import StarRatings from "react-star-ratings";

const CleanlinessCheck = ({
  inspectionData,
  notClean,
  cleanList,
  toggleCleanImg,
}) => {
  return (
    <Fragment>
      <div className={styles.inspectionField}>
        <div className={styles.subscript}>Cleanliness</div>
        {inspectionData?.cleanliness ? (
          <StarRatings
            rating={inspectionData?.cleanliness}
            starDimension="24px"
            starSpacing="5px"
          />
        ) : (
          <div style={{ opacity: "0.5" }}>N/A</div>
        )}
      </div>
      {notClean && (
        <div className={styles.inspectionField}>
          <div className={styles.subscript}> Not clean comment</div>{" "}
          {inspectionData?.cleanlinessComment}
        </div>
      )}
      {notClean && (
        <div className={styles.inspectionField}>
          <div className={styles.subscript}> Not clean images</div>{" "}
          <div className={styles.imgContaier}>
            {cleanList.length !== 0 &&
              cleanList.map((img) => (
                <div key={img}>
                  <img
                    className={styles.exteriorImg}
                    style={{ marginRight: "16px" }}
                    src={img}
                    onClick={() => toggleCleanImg(img)}
                  ></img>
                </div>
              ))}
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default CleanlinessCheck;
