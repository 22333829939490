import React, { Fragment, useState } from "react";
import ChangeVehicle from "./ChangeVehicle";
import Modal from "../../../../../artzu-ui/src/Modal";
import EndBooking from "./EndBooking";
import ChangeBookingSchedule from "./ChangeBookingSchedule";

const BookingActions = ({
  booking,
  action,
  open,
  closeBookingActions,
  refetch,
  userId,
}) => {
  const closeEditBooking = () => {
    refetch();
    closeBookingActions();
  };

  const body = () => {
    switch (action) {
      case "edit":
        return (
          <ChangeBookingSchedule
            userId={userId}
            booking={booking}
            closeEditBooking={closeEditBooking}
          />
        );
      case "swap":
        return (
          <ChangeVehicle
            booking={booking}
            closeBookingActions={closeEditBooking}
            refetch={refetch}
          />
        );
      case "end":
        return (
          <EndBooking booking={booking} closeEditBooking={closeEditBooking} />
        );
    }
  };
  return (
    <Modal open={open} backgroundColor="none">
      {body()}
    </Modal>
  );
};
export default BookingActions;
