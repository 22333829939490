import React, { useState, useEffect } from "react";
import _ from "lodash";
import LocationSelectContent from "./LocationSelectContent";
import { observer, inject } from "mobx-react";

const LocationSelect = observer(
  inject("store")(({ currentUser, store, handleCityCallback }) => {
    const [open, setOpen] = useState(false);
    const [lots, setLots] = useState({});
    const [lotsSelected, setLotsSelected] = useState(
      JSON.parse(localStorage.getItem("accessibleCities")) || []
    );
    const [lotsSelectedName, setLotsSelectedName] = useState(
      lotsSelected.length > 0
        ? lotsSelected.length === 1
          ? lotsSelected[0].name
          : lotsSelected.length < 3
          ? "Multiple Cities"
          : "All Cities"
        : ""
    );
    // console.log(lotsSelectedName);

    useEffect(() => {
      setLots(
        _.groupBy(
          currentUser && currentUser.accessibleCities,
          (lot) => lot.name
        )
      );
    }, [currentUser]);

    const toggleOpen = () => {
      setOpen(!open);
    };

    const selectLots = (lot) => {
      let newLots = [...lotsSelected];
      const included = _.includes(JSON.stringify(newLots), JSON.stringify(lot));
      included
        ? _.remove(newLots, (item) => _.isEqual(item, lot))
        : newLots.push(lot);
      setLotsSelected(newLots);
      let lotsName = "";
      if (newLots.length === 1) {
        lotsName = newLots[0].name;
      } else if (newLots.length > 1 && newLots.length !== 3) {
        lotsName = "Multiple Cities";
      } else if (newLots.length === 3) {
        lotsName = "All Cities";
      }
      setLotsSelectedName(lotsName);
    };

    const reset = () => {
      setLotsSelected([]);
      setLotsSelectedName("");
      localStorage.removeItem("accessibleCities");
    };
    localStorage.setItem("accessibleCities", JSON.stringify(lotsSelected));

    return (
      <LocationSelectContent
        store={store}
        open={open}
        toggleOpen={toggleOpen}
        lotsSelectedName={lotsSelectedName}
        lots={lots}
        lotsSelected={lotsSelected}
        selectLots={selectLots}
        reset={reset}
        close={() => setOpen(false)}
        handleCityCallback={handleCityCallback}
      />
    );
  })
);

export default LocationSelect;
