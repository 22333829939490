import React, { Component, useState, useEffect } from "react";
import AccountDetails from "./AccountDetails";
import Documents from "./Documents";
import Comments from "./Comments";
import { faPencil } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useLocation } from "react-router-dom";

const Details = ({ id, refetch }) => {
  const [activeTab, setActiveTab] = useState("details");
  const [isEdit, setIsEdit] = useState(false);
  const location = useLocation();

  useEffect(() => {
    if (location.state && location.state.activeTab) {
      setActiveTab(location.state.activeTab);
      
    }
    window.scrollTo(0,0);
  }, [location]);

  const toggleEdit = () => {
    setIsEdit(!isEdit);
  };

  const Tab = (value, name) => {
    const className = `unselectable detail-tab${
      activeTab === value ? " selected" : ""
    }`;

    return (
      <div className={className} onClick={() => setActiveTab(value)}>
        {name}
      </div>
    );
  };

  const pageContent = () => {
    switch (activeTab) {
      case "details":
        return (
          <AccountDetails id={id} isEdit={isEdit} toggleEdit={toggleEdit} />
        );
      case "documents":
        return <Documents id={id} refetchProfile={refetch} />;
      case "comments":
        return <Comments id={id} />;
    }
  };

  return (
    <div className="user-details-container">
      <div className="tab-container">
        {Tab("details", "Details")}
        {Tab("documents", "Documents")}
        {Tab("comments", "Comments")}
        {activeTab === "details"
          ? !isEdit && (
              <button className="edit-profile" onClick={toggleEdit}>
                <div className="edit-profile-content">
                  <FontAwesomeIcon icon={faPencil} size="sm" />
                  Edit Profile
                </div>
              </button>
            )
          : null}
      </div>
      {pageContent()}
    </div>
  );
};

export default Details;
