import React, { Fragment } from "react";
import { FormControl, Select, MenuItem } from "@material-ui/core";
import BlueCheckbox from "../../Shared/BlueCheckbox";
import { titleize } from "../../../utils";
import { useStyles } from "./styles.js";
import { isSelected, subsetDropdownTitle } from "../utils";

const OutstandingRequirement = ({
  parkingLotIds,
  outstandingRequirements,
  requirement,
  setRequirement,
}) => {
  const classes = useStyles();

  const changeRequirement = (status) => {
    if (requirement?.includes(status)) {
      setRequirement([...requirement.filter((v) => v !== status)]);
    } else {
      setRequirement([status, ...requirement]);
    }
  };

  return (
    <div className="dataset-options__field">
      <label>Outstanding Requirement</label>
      <FormControl variant="outlined" className={classes.formControl}>
        <Select
          value={isSelected(requirement)}
          renderValue={() =>
            subsetDropdownTitle(
              requirement,
              "outstanding requirement",
              parkingLotIds
            )
          }
          className={classes.select}
          onChange={(e) => {}}
        >
          <Fragment>
            {outstandingRequirements &&
              outstandingRequirements.map((status, index) => {
                return (
                  <MenuItem
                    key={index}
                    className={classes.menuItem}
                    onClick={() => changeRequirement(status)}
                  >
                    <BlueCheckbox
                      size="small"
                      checked={requirement?.includes(status)}
                      onChange={() => changeRequirement(status)}
                      inputProps={{ "aria-label": "secondary checkbox" }}
                    />
                    <div>{titleize(status)}</div>
                  </MenuItem>
                );
              })}
          </Fragment>
        </Select>
      </FormControl>
    </div>
  );
};

export default OutstandingRequirement;
