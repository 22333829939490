import React from 'react'

const SvgPhone = ({ color = '#fff', ...props }) => (
  <svg data-name="Layer 1" viewBox="0 0 90 89.98" {...props}>
    <path
      d="M89.08 75.61C84 87.83 72 89.99 68.12 89.99c-1.15 0-19 .95-44.69-23.41C2.77 46.99.24 25.88 0 21.88S.9 8.19 14.36.88c1.67-.9 5-1.3 5.83-.45.38.38 11.68 19 12 19.59a4.16 4.16 0 0 1 .45 1.82q0 1.27-1.82 3.18a31 31 0 0 1-3.95 3.5 38.45 38.45 0 0 0-4 3.38q-1.82 1.78-1.82 2.93c.08 2 1.71 9.09 14.09 20s18.4 13.95 18.97 14.16a4.31 4.31 0 0 0 1.43.32q1.15 0 2.93-1.81c1.19-1.21 5.18-6.69 6.46-7.9s2.34-1.81 3.19-1.81a4.15 4.15 0 0 1 1.81.44c.62.3 19.23 11.19 19.59 11.56 1 1 .28 4.08-.45 5.83"
      fill={color}
    />
  </svg>
)

export default SvgPhone
