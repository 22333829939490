import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhoneAlt } from "@fortawesome/pro-solid-svg-icons";

const TicketDialer = ({ handleCancel, handleMute, ticketUser, muted }) => {
  return (
    <div>
      <div className="calling-box">
        <div className="calling-title">
          <FontAwesomeIcon icon={faPhoneAlt} style={{ marginRight: "12px" }} />
          Calling {ticketUser.fullName}
        </div>
        <div className="mute-button-container">
          <button
            onClick={handleMute}
            className={muted ? "unmute-call-button" : "mute-call-button"}
          >
            {muted ? "Unmute" : "Mute"}
          </button>
        </div>
        <button onClick={handleCancel} className="end-call-button">
          End
        </button>
      </div>
    </div>
  );
};

export default TicketDialer;
