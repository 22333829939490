import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

const StyledCell = styled.div`
  font-weight: ${({ bold }) => (bold ? "bold" : "normal")};
  cursor: ${({ pointer }) => (pointer ? "pointer" : "default")};
  width: ${({ width }) => width};
  color: ${({ color }) => color};
  font-size: ${({ size }) => size};
  background-color: ${({ backgroundColor }) => backgroundColor};
  margin: ${({ margin }) => margin};
  padding: ${({ padding }) => padding};
  display: ${({ display }) => display};
  justify-content: ${({ justify }) => justify || "space-around"};
  text-align: ${({ align }) => align};
  overflow-wrap: ${({ wrap }) => wrap};
  word-break: ${({ wordWrap }) => wordWrap || "break-word"};
  height: ${({ height }) => height};

  align-items: ${({ alignItems }) => alignItems};

  @media (max-width: 1024px) {
    width: ${({ widthIpad }) => widthIpad};
  }
`;

export const _tableCell = ({
  data,
  children,
  width,
  pointer,
  bold,
  color,
  margin,
  padding,
  size,
  backgroundColor,
  display,
  justify,
  align,
  height,
  alignItems,
  widthIpad,
  onClick,
  wrap,
}) => (
  <StyledCell
    width={width}
    pointer={pointer}
    bold={bold}
    color={color}
    size={size}
    backgroundColor={backgroundColor}
    onClick={onClick}
    padding={padding}
    margin={margin}
    display={display}
    justify={justify}
    align={align}
    height={height}
    alignItems={alignItems}
    widthIpad={widthIpad}
    wrap={wrap}
  >
    {data}
    {children}
  </StyledCell>
);

export default _tableCell;

_tableCell.defaultProps = {
  pointer: false,
  bold: false,
  color: "inherit",
  size: "inherit",
  onClick: null,
};

_tableCell.propTypes = {
  data: PropTypes.string,
  children: PropTypes.any,
  pointer: PropTypes.bool,
  bold: PropTypes.bool,
  width: PropTypes.string.isRequired,
  color: PropTypes.string,
  size: PropTypes.string,
  onClick: PropTypes.func,
};
