import React, { Fragment } from "react";

import NoteHeader from "./NoteContent/NoteHeader";
import NoteDetails from "./NoteContent/NoteDetails";

const NotesDisplay = ({ pinnedNotes, otherNotes, all, timeZone }) => {
  const displayNotes = otherNotes ? otherNotes : pinnedNotes;
  return displayNotes.map((note, index) => {
    if (all || index < 5) {
      return (
        <div
          key={index}
          className={otherNotes ? "notesContainer" : "pinnedNotesContainer"}
        >
          <div className="note">
            <NoteHeader note={note} timeZone={timeZone} />

            <div className="note-details-container">
              <NoteDetails note={note} />
            </div>
          </div>
        </div>
      );
    } else return null;
  });
};

export default NotesDisplay;
