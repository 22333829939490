import React, { useState, useEffect, useRef } from "react";
import styles from "./listPopup.module.scss";
import ListBox from "./ListBox";

const ListPopup = ({
  listItems,
  buttonComponent,
  selectedButtonComponent,
  listData,
  invoiceNum,
  total,
  invoiceId,
  financingCallback,
  clickable,
  left,
  refetch,
}) => {
  const [isListOpen, setListOpen] = useState(false);
  const toggleListOpenState = () => {
    setListOpen(!isListOpen);
  };

  const closeList = () => {
    setListOpen(false);
  };

  const ref = useRef();

  const handleClickOutside = (e) => {
    if (!ref.current.contains(e.target)) {
      setListOpen(false);
    }
  };

  const useEvent = (event, handler, passive = false) => {
    useEffect(() => {
      // initiate the event handler
      window.addEventListener(event, handler, passive);

      // this will clean up the event every time the component is re-rendered
      return function cleanup() {
        window.removeEventListener(event, handler);
      };
    });
  };

  const ButtonComponent = isListOpen
    ? selectedButtonComponent
    : buttonComponent;

  useEvent("mousedown", handleClickOutside);

  return (
    <div className={styles.threeDotList}>
      <div
        className={
          isListOpen
            ? styles.buttonContainerOpen
            : left
            ? clickable
              ? styles.buttonContainer
              : styles.invalidButtonContainer
            : clickable
            ? styles.buttonContainer
            : styles.invalidButtonContainer
        }
      >
        <ButtonComponent
          onClick={
            left
              ? clickable && toggleListOpenState
              : clickable && toggleListOpenState
          }
        />
      </div>
      <div ref={ref} className={styles.listContainer}>
        {isListOpen && (
          <ListBox
            listItems={listItems}
            closeList={closeList}
            listData={listData}
            invoiceNum={invoiceNum}
            total={total}
            invoiceId={invoiceId}
            financingCallback={financingCallback}
            refetch={refetch}
          />
        )}
      </div>
    </div>
  );
};

export default ListPopup;
