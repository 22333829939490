import React from "react";

const ActionContainer = ({
  handleCreate,
  clearSearch,
  ableToAdd,
  toggleCreate,
}) => (
  <div className="relate-user-buttons" style={{ marginTop: "36px" }}>
    <div>
      <button
        className="booking-cancel-button"
        onClick={() => {
          clearSearch();
          toggleCreate();
        }}
      >
        Cancel
      </button>
    </div>
    <div>
      <button
        className={
          ableToAdd ? "booking-choose-button" : "booking-choose-button-invalid"
        }
        onClick={handleCreate}
      >
        Add
      </button>
    </div>
  </div>
);

export default ActionContainer;
