import React, { useState } from "react";
import { useQuery } from "@apollo/client";
import { WEEKLY_PRICE_MAP } from "./queries";
import moment from "moment-timezone";
import { Fragment } from "react";
import Loading from "../InformationTable/Loading";
import styles from "./vehicles_available.module.scss";

const NewAvailabilityTable = ({
  criteria,
  fleetTier,
  parkingLotId,
  startDate,
  timeZone,
}) => {
  const { loading, data } = useQuery(WEEKLY_PRICE_MAP, {
    fetchPolicy: "no-cache",
    variables: {
      vehicleClass: fleetTier || "ev",
      parkingLotId: parseInt(parkingLotId),
      startDate: startDate,
      stretch: 7,
    },
  });

  const times = [
    "1AM - 3AM",
    "3AM - 5AM",
    "5AM - 7AM",
    "7AM - 9AM",
    "9AM - 11AM",
    "11AM - 1PM",
    "1PM - 3PM",
    "3PM - 5PM",
    "5PM - 7PM",
    "7PM - 9PM",
    "9PM - 11PM",
    "11PM - 1AM",
  ];

  const getPriceBackColor = (rangeMin, rangeMax, price) => {
    if (price == 0) {
      return;
    }

    // I want perc between 0.25 and 1
    const perc = (price - rangeMin) / (rangeMax - rangeMin);

    return `rgba(18, 141, 255, ${0.75 * perc + 0.25})`;
  };

  if (loading) {
    return (
      <div
        style={{
          width: "100%",
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Loading size="5x" />
      </div>
    );
  }

  return (
    <Fragment>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <div className={styles.timesContainer}>
          <div className={styles.timeZone}>
            {moment(startDate).tz(timeZone).format("z")}
          </div>
          {times.map((ts, i) => (
            <div className={styles.timeSlot}>
              <div style={{ fontSize: "12px" }}>{times[i]}</div>
            </div>
          ))}
        </div>

        <div className={styles.dayContainer}>
          {data &&
            data.priceMap.map((day, i) => (
              <div key={`${day.date}-${1}`} className={styles.singleDay}>
                {moment().isSame(day.date, "day") ? (
                  <div className={styles.dateToday}>
                    <div style={{ fontSize: "24px", fontWeight: "700" }}>
                      {moment(day.date).format("D")}
                    </div>
                    <div>{moment(day.date).format("ddd")}</div>
                  </div>
                ) : (
                  <div className={styles.date}>
                    <div style={{ fontSize: "24px", fontWeight: "700" }}>
                      {moment(day.date).format("D")}
                    </div>
                    <div>{moment(day.date).format("ddd")}</div>
                  </div>
                )}
                {day.priceMap.map((shift) => {
                  const isHourly = criteria == "hourlyPrice";
                  const utilization = shift.utilization;
                  let backColour;
                  let rate;

                  if (isHourly) {
                    rate = shift.hourlyRate;
                    backColour = getPriceBackColor(7, 9, rate);
                  } else {
                    rate = shift.dailyRate;
                    backColour = getPriceBackColor(5, 9, rate);
                  }

                  return (
                    <div>
                      <div
                        key={`${day.date}-${shift.shift}`}
                        className={styles.singleCell}
                      >
                        <div
                          className={styles.carCount}
                          style={{
                            color: "#ffffff",
                            backgroundColor:
                              utilization < 1 ? backColour : "#dedede",
                          }}
                        >
                          <div>
                            <span
                              style={{ fontSize: "24px", fontWeight: "700" }}
                            >
                              {utilization < 1
                                ? `$${(Math.round(rate * 100) / 100).toFixed(
                                    2
                                  )}`
                                : "FULL"}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            ))}
        </div>
      </div>
    </Fragment>
  );
};

export default NewAvailabilityTable;
