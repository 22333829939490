import styles from "../../../userDetails.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-light-svg-icons";

const TagCellList = ({ tags, searchValue, parentCallback }) => {
  return (
    <div className={styles.existingTagsFrame}>
      <div className={styles.userTagsContainer}>
        {tags.map((tag) => (
          <div
            className={styles.tagCell}
            style={tag == searchValue ? { border: "1px #ff0000 solid" } : {}}
          >
            {tag}
            <FontAwesomeIcon
              icon={faTimes}
              size="lg"
              className="tag-times" //lifted from old code in AccountDetails
              onClick={() => {
                parentCallback(tag);
              }}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default TagCellList;
