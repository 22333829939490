import React, { Fragment, useEffect, useRef, useState } from "react";
import styles from "./csAi.module.scss";
import Loading from "../Shared/Loading";
import _ from "lodash";
import classNames from "classnames";
import { useMutation } from "@apollo/client";
import { SUBMIT_AI_CHAT_MUTATION } from "./queries";
import { faSpinner } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { createConsumer } from "@rails/actioncable";

const ChatContainer = ({ chat, initialMessages, refetch }) => {
  const [question, setQuestion] = useState("");
  const [messages, setMessages] = useState(initialMessages || []);
  const [currentInternalMessage, setCurrentInternalMessage] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [currentSubscription, setCurrentSubscription] = useState();

  const handleChange = (change) => setQuestion(change.target.value);

  const [submitMessage, { loading, error, data }] = useMutation(
    SUBMIT_AI_CHAT_MUTATION
  );

  const addMessage = (newMessage) => {
    setMessages((prevMessages) => [...prevMessages, newMessage]);
  };

  const handleSubmit = () => {
    setIsLoading(true);
    setQuestion("");
    console.log("HERE");
    addMessage({ text: question, source: "user" });
    submitMessage({ variables: { chatId: chat.id, message: question } });
  };

  const cable = useRef();

  useEffect(() => {
    if (!cable.current) {
      cable.current = createConsumer(`${process.env.REACT_APP_WEBSOCKET_URL}`);
    }
    const paramsToSend = {
      channel: "AutzuAiChatChannel",
      chatId: chat.id,
    };
    const handlers = {
      connected: () => {
        console.log("connected");
      },
      disconnected: () => {
        console.log("disconnected");
        cable.current = null;
      },
      received: (data) => {
        receiveMessage(data);
      },
    };

    const subscription = cable.current.subscriptions.create(
      paramsToSend,
      handlers
    );

    setCurrentSubscription(subscription);

    return function cleanup() {
      cable.current = null;
      subscription.unsubscribe();
    };
  }, []);

  const canSendQuestion = !(loading || question == "");

  const receiveMessage = (data) => {
    const message = { text: data.text, source: "assistant" };
    if (data.message_complete) {
      setCurrentInternalMessage(null);
      addMessage(message);
    } else {
      setIsLoading(false);
      setCurrentInternalMessage(message);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter" && !event.shiftKey && canSendQuestion) {
      event.preventDefault();
      handleSubmit();
    }
  };

  return (
    <div className={styles.container}>
      <Fragment>
        <div className={styles.chatBox}>
          {_.compact([...messages, currentInternalMessage]).map((message) => {
            return (
              <div
                className={classNames(
                  message.source == "user"
                    ? styles.messageContainerUser
                    : styles.messageContainerAi
                )}
              >
                <div
                  className={classNames(
                    styles.message,
                    message.source == "user" ? styles.fromUser : styles.fromAi
                  )}
                >
                  {message.text}
                </div>
              </div>
            );
          })}
          {isLoading && (
            <div className={styles.messageContainerAi}>
              <div
                className={classNames(
                  styles.message,
                  styles.fromAi,
                  styles.loadingMessage
                )}
              >
                <FontAwesomeIcon
                  className="blue"
                  spin
                  size="2x"
                  icon={faSpinner}
                />
              </div>
            </div>
          )}
        </div>

        <textarea
          className={styles.inputBox}
          onChange={handleChange}
          placeholder={"Question here..."}
          value={question}
          onKeyDown={handleKeyDown}
        />
        <button
          className={classNames(
            styles.sendButton,
            canSendQuestion ? styles.buttonEnabled : styles.buttonDisabled
          )}
          onClick={() => handleSubmit()}
          disabled={!canSendQuestion}
        >
          Send
        </button>
      </Fragment>
    </div>
  );
};

export default ChatContainer;
