import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "./locationSelect.module.scss";
import _ from "lodash";
import formatCityName from "./FormatCityName";
import formatLotName from "./FormatLotName";
import { faMapMarkerAlt } from "@fortawesome/pro-solid-svg-icons";
import { observer } from "mobx-react";
import LocationSelectDropdown from "./LocationSelectDropdown";
import { Fragment } from "react";

const LocationSelectContent = observer(
  ({
    open,
    toggleOpen,
    lotsSelectedName,
    lots,
    lotsSelected,
    selectLots,
    reset,
    store,
    handleCityCallback,
  }) => {
    return (
      <Fragment>
        <div
          className={
            open ? styles.dropdownContainerOpen : styles.dropdownContainer
          }
        >
          <div className={styles.navbarLink} onClick={toggleOpen}>
            <FontAwesomeIcon
              icon={faMapMarkerAlt}
              style={{ fontSize: "x-large" }}
            />
            <div>{_.startCase(formatCityName(lotsSelectedName) || "ALL")}</div>
          </div>
        </div>
        {open && (
          <div>
            <div className={styles.triangle} />
            <LocationSelectDropdown
              lots={lots}
              lotsSelected={lotsSelected}
              selectLots={selectLots}
              reset={reset}
              store={store}
              toggleOpen={toggleOpen}
              handleCityCallback={handleCityCallback}
            />
          </div>
        )}
      </Fragment>
    );
  }
);

export default LocationSelectContent;
