import React, { useState } from "react";
import { faLineColumns } from "@fortawesome/pro-regular-svg-icons";
import styles from "./columnSettings.module.scss";
import Options from "./Options";
import ToggleButton from "../../../Shared/ToggleButton";

const ColumnSettings = ({ columns, changeColumnsValue }) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleOpenState = () => setIsOpen(!isOpen);
  const closeOptions = () => setIsOpen(false);

  return (
    <div className={styles.columnSettings}>
      <ToggleButton
        toggleOpenState={toggleOpenState}
        isOpen={isOpen}
        faIcon={faLineColumns}
      />
      <Options
        isOpen={isOpen}
        closeOptions={closeOptions}
        columns={columns}
        changeColumnsValue={changeColumnsValue}
      />
    </div>
  );
};

export default ColumnSettings;
