import moment from "moment-timezone";
import _ from "lodash";

const m = (x) => moment(x).tz("America/Toronto");

const capitalize = (string) =>
  string && string.charAt(0).toUpperCase() + string.slice(1);

const titleToCamel = (string) =>
  string && (string[0].toLowerCase() + string.slice(1)).replace(/\s/g, "");

const titleToSnake = (string) =>
  string && string.replace(/\s/g, "_").toLowerCase();

const titleize = (string) =>
  string &&
  string
    .split("_")
    .map((s) => (s.length > 0 ? s[0].toUpperCase() + s.slice(1) : ""))
    .join(" ");

const addCommas = (number) =>
  number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

const formatNumber = (num, sdigit) =>
  num && sdigit === 2
    ? formatCurrency(
        num.toLocaleString(undefined, {
          minimumFractionDigits: sdigit,
          maximumFractionDigits: sdigit,
        })
      )
    : num.toLocaleString(undefined, {
        minimumFractionDigits: sdigit,
        maximumFractionDigits: sdigit,
      });

const formatCurrency = (string) =>
  /\-/.test(string)
    ? string.replace(/\-/, "($").concat(")")
    : "$".concat(string);

const renderColor = (columnName, value) => {
  if (!colorColumns.includes(columnName)) {
    return "";
  }
  if (parseInt(value)) {
    value = parseInt(value) > 0 ? false : true;
  }
  let color = "";
  if (greenLabels.includes(value)) {
    color = " green";
  } else if (orangeLabels.includes(value)) {
    color = " orange";
  } else if (redLabels.includes(value)) {
    color = " red";
  } else if (greyLabels.includes(value)) {
    color = " translucent";
  }
  return color;
};

const colorColumns = [
  "Account Status",
  "Cookies Verified",
  "Documents Verified",
  "Unread Text Count",
  "Uber Status",
  "Has Uber Oauth",
];

const greenLabels = ["active", "verified", true, "onboarded"];

const orangeLabels = ["in_progress", "resubmitted", "signed_up"];

const redLabels = ["rejected", "connection_expired", "suspended", false];

const greyLabels = ["unverified", undefined];
const formatOdometer = (num) => Math.round(num).toLocaleString();

const parsePredictionData = (daysUntil, mileageUntil, unit) => {
  if (daysUntil >= 0) {
    return `In ${daysUntil} days ${
      mileageUntil ? `/ ${addCommas(mileageUntil)}${unit}` : ""
    }`;
  } else if (daysUntil === null) {
    return `${mileageUntil ? `/ ${addCommas(mileageUntil)}${unit}` : ""}`;
  } else {
    return `Overdue by ${daysUntil ? -1 * daysUntil : "--"} days ${
      mileageUntil ? `/ ${addCommas(-1 * mileageUntil)}${unit}` : ""
    }`;
  }
};

const reorderAllProj = (allProj, order) => {
  let orderPending = _.filter(allProj, function (allProj) {
    return order.some((selection) => {
      return selection === allProj.name;
    });
  });

  return orderPending;
};

const reorderNotes = (notes) => {
  let partitionNotes = _.partition(notes, { pinned: true });
  return partitionNotes[0].concat(partitionNotes[1]);
};

const vehicleStatusToStyle = (status) => {
  switch (status) {
    case "Service":
      return "black";
    case "Available":
      return "2px solid #128DFF";
    case "Deactivated":
      return "2px solid #FF0000";
    case "Unauthorized":
    case "Late Driver":
      return "#FF0000";
    case "With Driver":
    case "User":
      return "#128DFF";
    case "Offline":
      return "2px solid #414141";
    default:
      return "";
  }
};

const getVehicleTags = (tags) => {
  let tagNames = [];
  if (tags.length > 0) {
    tags.forEach((tag) => {
      tagNames.push(tag.name);
    });
  }
  return tagNames;
};

export {
  m,
  capitalize,
  titleToCamel,
  titleToSnake,
  titleize,
  formatNumber,
  formatCurrency,
  renderColor,
  formatOdometer,
  parsePredictionData,
  reorderAllProj,
  vehicleStatusToStyle,
  getVehicleTags,
  reorderNotes,
};
