import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhoneAlt } from "@fortawesome/pro-light-svg-icons";

const CallRecordMessageNoAnswer = ({
  admin,
  handleCall,
  handleTagRemove,
  ticketAssignedAdmin,
}) => {
  const callerName = admin?.fullName || ticketAssignedAdmin?.fullName;
  return (
    <div className="callrecord-box" style={{ justifyContent: "space-between" }}>
      <div className="callrecord-title" style={{ borderBottom: "none" }}>
        <FontAwesomeIcon
          icon={faPhoneAlt}
          style={{ rotate: "135deg" }}
          size="2x"
        />
        <div className="callrecord-info">
          Call not answered
          <div className="subscript" style={{ marginTop: "6px" }}>
            By: {callerName}
          </div>
        </div>
      </div>
      <button
        onClick={() => {
          handleCall();
          handleTagRemove("Call requested");
        }}
        className="call-again-button"
      >
        Call again
      </button>
    </div>
  );
};

export default CallRecordMessageNoAnswer;
