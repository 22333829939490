import { GeneralTab } from "./Tabs/GeneralTab";
import { BookingsTab } from "./Tabs/BookingsTab";
import { PaymentsTab } from "./Tabs/PaymentsTab";
import { Inactive } from "./utils";
import { IDVerificationTab } from "./Tabs/IDVerificationTab";
import { RideshareTab } from "./Tabs/RideShareTab";

const ActiveDashboard = ({ data }) => {
  return (
    <div>
      <GeneralTab data={data} />

      {Inactive(data.accountStatus) ? <IDVerificationTab data={data} /> : ""}

      {Inactive(data.accountStatus) ? <RideshareTab data={data} /> : ""}

      {!Inactive(data.accountStatus) ? <PaymentsTab data={data} /> : ""}

      {!Inactive(data.accountStatus) ? <BookingsTab data={data} /> : ""}
    </div>
  );
};

export default ActiveDashboard;
