import moment from "moment-timezone";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAlarmClock } from "@fortawesome/pro-light-svg-icons";
import {
  faPencil,
  faThumbtack,
  faTrashAlt,
} from "@fortawesome/pro-solid-svg-icons";
import React from "react";

const SingleComment = ({
  comment,
  index,
  timeZone,
  handlePin,
  setExistingComment,
  toggleReminder,
  setCurId,
  setChangedBody,
  toggleEdit,
  toggleDelete,
  isPinned,
}) => (
  <div className="page-section__comment" key={index}>
    <div className="page-section__left">
      <div>{(comment.author && comment.author.email) || "-"}</div>
      <div>{comment.body}</div>
    </div>
    <div className="page-section__right">
      <div>
        {moment(comment.updatedAt).tz(timeZone).format("MMM D, YYYY h:mm A z")}
      </div>
      <div className="comment-actions">
        <div
          className="action-button"
          onClick={() => {
            setExistingComment(true);
            setCurId(comment.id);
            toggleReminder();
          }}
          style={{ cursor: "pointer" }}
        >
          <FontAwesomeIcon icon={faAlarmClock} />
        </div>
        <div
          className="action-button"
          onClick={() => {
            setChangedBody(comment.body);
            setCurId(comment.id);
            toggleEdit();
          }}
          style={{ cursor: "pointer" }}
        >
          <FontAwesomeIcon icon={faPencil} />
        </div>
        <div
          className={`action-button`}
          onClick={() => {
            handlePin(comment.id, !comment.pinned);
          }}
          style={{
            cursor: "pointer",
            backgroundColor: comment.pinned ? "#414141" : "#ffffff",
          }}
        >
          <FontAwesomeIcon
            icon={faThumbtack}
            style={{ color: comment.pinned ? "white" : "#414141" }}
          />
        </div>
        <div
          className="action-button"
          style={{ borderColor: "red", cursor: "pointer" }}
          onClick={() => {
            setCurId(comment.id);
            toggleDelete();
          }}
        >
          <FontAwesomeIcon icon={faTrashAlt} style={{ color: "red" }} />
        </div>
      </div>
    </div>
  </div>
);

export default SingleComment;
