import React from "react";
import styles from "../filterRow.module.scss";
import moment from "moment";

const InputBox = ({ date, onClick, timeZone}) => {
  const timezone = timeZone ? timeZone : "America/Toronto";
  const text = date ? moment(date).tz(timezone).format("MM/DD/YY,kk:mm z") : "Select one";  

  return (
    <div className={styles.dateInput} onClick={onClick}>
      {text}
    </div>
  );
};

export default InputBox;
