import React, { useState } from "react";
import styles from "./listPopup.module.scss";
import { Fragment } from "react";
import Deny from "./ActionPopup/Deny";
import Arrange from "./ActionPopup/Arrange";
import GeneralPopup from "./ActionPopup/GeneralPopup";

const ListBox = ({
  listItems,
  closeList,
  listData,
  invoiceNum,
  total,
  invoiceId,
  financingCallback,
  refetch,
}) => {
  const [isArrange, setIsArrange] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isDeny, setIsDeny] = useState(false);
  const [curAction, setAction] = useState();
  const [isPopup, setPopup] = useState();

  const toggleDeny = () => {
    setIsDeny(!isDeny);
  };

  const toggleArrange = () => {
    setIsArrange(!isArrange);
  };

  const togglePopup = () => {
    setPopup(!isPopup);
  };

  const onItemClick = (child) => {
    if (child === "Deny") {
      setIsDeny(!isDeny);
      setAction("deny");
    } else if (child === "Arrange") {
      setIsArrange(!isArrange);
      setAction("arrange");
    } else if (child === "Edit financing") {
      setIsArrange(!isArrange);
      setIsEdit(!isEdit);
      setAction("arrange");
    } else if (child === "Process Invoice") {
      setPopup(!isPopup);
      setAction("process");
    } else if (child === "Mark as Paid") {
      setPopup(!isPopup);
      setAction("mark as paid");
    } else if (child === "Mark as Unpaid") {
      setPopup(!isPopup);
      setAction("mark as unpaid");
    }
    // closeList();
  };
  return (
    <Fragment>
      <div className={styles.options}>
        <div className={styles.optionsBox}>
          <div className={styles.optionsContainer}>
            {listItems.map((item) => {
              // const isSelectedStatus = curValue === item.name;
              return (
                <div
                  className={styles.optionsItem}
                  onClick={() => onItemClick(item.value)}
                >
                  {/* <div
                  className={classNames(
                    isSelectedStatus && styles.selectedText
                  )}
                > */}
                  {item.name}
                  {/* </div> */}
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <Deny
        open={isDeny}
        toggleDeny={toggleDeny}
        invoiceId={invoiceId}
        curAction={curAction}
        refetch={refetch}
        closeList={closeList}
      />
      <Arrange
        open={isArrange}
        toggleArrange={toggleArrange}
        listData={listData}
        invoiceNum={invoiceNum}
        total={total}
        invoiceId={invoiceId}
        financingCallback={financingCallback}
        isEdit={isEdit}
        refetch={refetch}
        closeList={closeList}
      />
      <GeneralPopup
        open={isPopup}
        action={curAction}
        togglePopup={togglePopup}
        invoiceId={invoiceId}
        refetch={refetch}
        closeList={closeList}
      />
    </Fragment>
  );
};

export default ListBox;
