import React, { useEffect } from "react";
import DetailsTable from "./DetailsTable";
import "../invoiceTabs.css";

const Details = ({
  create,
  invoiceDetails,
  invoiceInfo,
  lineItemsCallBack,
  setLiveTotal,
  tmpTotal,
  isCreated,
  refetch,
  lineItems,
  toggleCreateInvoice,
}) => {
  return (
    <div className="canvas">
      <DetailsTable
        create={create}
        invoiceDetails={invoiceDetails}
        invoiceInfo={invoiceInfo}
        lineItemsCallBack={lineItemsCallBack}
        setLiveTotal={setLiveTotal}
        tmpTotal={tmpTotal}
        isCreated={isCreated}
        refetch={refetch}
        lineItems={lineItems}
        toggleCreateInvoice={toggleCreateInvoice}
      />
    </div>
  );
};

export default Details;
