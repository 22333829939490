import React from "react";
import styles from "./referral.module.scss";

const ReferralStats = ({ referralCode, totalReferrals, totalCredits }) => {
  return (
    <div className={styles.statsContainer}>
      <div className={styles.title}>Stats</div>
      <div className={styles.contentContainer}>
        <div className={styles.statBox}>
          <div className={styles.stat}>{referralCode}</div>
          <div>Referral code</div>
        </div>
        <div className={styles.statBox}>
          <div className={styles.stat}>{totalReferrals}</div>
          <div>Total referrals</div>
        </div>
        <div className={styles.statBox}>
          <div className={styles.stat}>${totalCredits}</div>
          <div>Total credit from referrals</div>
        </div>
      </div>
    </div>
  );
};

export default ReferralStats;
