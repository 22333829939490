import React from "react";
import styles from "./loadingShared.module.scss";
import { faSpinner } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Loading = ({ size = "2x" }) => (
  <div className={styles.loading}>
    <FontAwesomeIcon className="blue" spin size={size} icon={faSpinner} />
  </div>
);

export default Loading;
