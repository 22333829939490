import React from 'react';
import { withStyles } from "@material-ui/core/styles"
import Radio from '@material-ui/core/Radio';

const BlueRadio = withStyles({
  root: {
    color: "#128dff",
    '&$checked': {
      color: "#128dff",
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);

export default BlueRadio
