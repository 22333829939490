import { gql } from "@apollo/client";

const ACTIVE_PARKING_LOTS = gql`
  query cityOperationalParkingLots($cityIds: [ID!]) {
    cityOperationalParkingLots(cityIds: $cityIds) {
      id
      availableTiers
      name
      timeZoneData {
        name
        offset
      }
    }
  }
`;

const WEEKLY_PRICE_MAP = gql`
  query priceMap(
    $vehicleClass: String!
    $startDate: DateTime!
    $stretch: Int!
    $parkingLotId: Int!
  ) {
    priceMap(
      vehicleClass: $vehicleClass
      startDate: $startDate
      stretch: $stretch
      parkingLotId: $parkingLotId
    ) {
      date
      priceMap {
        hourlyRate
        dailyRate
        shift
        utilization
      }
    }
  }
`;

export { ACTIVE_PARKING_LOTS, WEEKLY_PRICE_MAP };
