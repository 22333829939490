import React from "react";
import styles from "../batchActions.module.scss";

import ActionButton from "../ActionButton";

const ActionsContainer = ({
  isActionButtonsShown,
  availableBatchActions,
  setSelectedAction,
}) => (
  <>
    {isActionButtonsShown && (
      <div className={styles.batchActions}>
        <div className={styles.batchActionButtons}>
          {availableBatchActions.map((batchAction) => (
            <ActionButton
              icon={batchAction.icon}
              text={batchAction.text}
              onClick={() => setSelectedAction(batchAction)}
            />
          ))}
        </div>
      </div>
    )}
  </>
);

export default ActionsContainer;
