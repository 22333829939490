import React, { Fragment } from "react";
import { FormControl, Select, MenuItem } from "@material-ui/core";
import BlueCheckbox from "../../Shared/BlueCheckbox";
import { titleize } from "../../../utils";
import { useStyles } from "./styles.js";
import { isSelected, vehicleClassDropdownTitle } from "../utils";

const VehicleClass = ({
  parkingLotIds,
  locations,
  vehicleClass,
  setVehicleClass,
}) => {
  const classes = useStyles();

  const isChecked =
    locations
      .map((l) => l.hubs)
      .flat()
      .filter((h) => parkingLotIds.includes(parseInt(h.id)))
      .map((h) => h.tierData)
      .flat()
      .filter((v, i, a) => a.indexOf(v) === i)
      .filter((vc) => !vehicleClass.includes(vc)).length === 0;

  const changeVehicleClassAll = () => {
    if (isChecked) {
      setVehicleClass([]);
    } else {
      setVehicleClass(
        locations
          .map((l) => l.hubs)
          .flat()
          .filter((h) => parkingLotIds.includes(parseInt(h.id)))
          .map((h) => h.tierData)
          .flat()
          .filter((v, i, a) => a.indexOf(v) === i)
      );
    }
  };

  const changeVehicleClassSingle = (tier) => {
    if (vehicleClass.includes(tier)) {
      setVehicleClass([...vehicleClass.filter((v) => v !== tier)]);
    } else {
      setVehicleClass([tier, ...vehicleClass]);
    }
  };

  return (
    <div className="dataset-options__field">
      <label>Vehicle Class</label>
      <FormControl variant="outlined" className={classes.formControl}>
        <Select
          value={isSelected(vehicleClass)}
          renderValue={() =>
            vehicleClassDropdownTitle(vehicleClass, parkingLotIds)
          }
          className={classes.select}
          onChange={(e) => {}}
        >
          <Fragment>
            {parkingLotIds.length > 0 ? (
              <MenuItem
                className={classes.menuItem}
                onClick={() => changeVehicleClassAll()}
              >
                <BlueCheckbox
                  size="small"
                  checked={isChecked}
                  onChange={() => changeVehicleClassAll()}
                  inputProps={{ "aria-label": "secondary checkbox" }}
                />
                <div>All</div>
              </MenuItem>
            ) : null}
            {parkingLotIds.length > 0 ? (
              locations
                .map((l) => l.hubs)
                .flat()
                .filter((h) => parkingLotIds.includes(parseInt(h.id)))
                .map((h) => h.tierData)
                .flat()
                .filter((v, i, a) => a.indexOf(v) === i)
                .map((tier, index) => {
                  return (
                    <MenuItem
                      key={index}
                      className={classes.menuItem}
                      onClick={() => changeVehicleClassSingle(tier)}
                    >
                      <BlueCheckbox
                        size="small"
                        checked={vehicleClass.includes(tier)}
                        onChange={() => changeVehicleClassSingle(tier)}
                        inputProps={{ "aria-label": "secondary checkbox" }}
                      />
                      <div>{titleize(tier)}</div>
                    </MenuItem>
                  );
                })
            ) : (
              <MenuItem>Select location for vehicle class options</MenuItem>
            )}
          </Fragment>
        </Select>
      </FormControl>
    </div>
  );
};

export default VehicleClass;
