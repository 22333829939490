import React, { useState, useEffect } from "react";
import { Fragment } from "react";
import Dropdown from "../../../../../Shared/Dropdown";
import { TableCell, TableRow } from "../../../../../Shared/Table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle, faCheckCircle } from "@fortawesome/pro-light-svg-icons";
import { faCircle } from "@fortawesome/pro-light-svg-icons";
import {
  faCircle as faCircleSolid,
  faPen,
} from "@fortawesome/pro-solid-svg-icons";
import { faTrashAlt } from "@fortawesome/pro-regular-svg-icons";
import _, { capitalize } from "lodash";

const EditableRow = ({
  lineItemsCallBack,
  setLiveTotal,
  isCreated,
  setLiveCredit,
  lineItems,
  toggleCreateInvoice,
}) => {
  const cats = [
    { name: "Booking Fee", value: "booking fee" },
    { name: "Deduction", value: "deduction" },
    { name: "Credit", value: "credit" },
  ];

  const feeItems = [{ name: "Booking Fee", value: "Booking Fee" }];

  const deductionItems = [
    { name: "Cleaning Fee", value: "Cleaning Fee" },
    { name: "Traffic Violation Fee", value: "Traffic Violation Fee" },
    { name: "Towing Fee", value: "Towing Fee" },
    { name: "Damage Fee", value: "Damage Fee" },
    { name: "Insurance Deductible Fee", value: "Insurance Deductible Fee" },
    { name: "Miscellaneous Fee", value: "Miscellaneous Fee" },
    {
      name: "Tesla Supercharger Idle Fee",
      value: "Tesla Supercharger Idle Fee",
    },
    {
      name: "Tesla Supercharger Fee",
      value: "Tesla Supercharger Fee",
    },
  ];

  const creditItems = [
    { name: "Booking Fee Credit", value: "Booking Fee Credit" },
    { name: "Cleaning Credit", value: "Cleaning Credit" },
    { name: "EV Parking Entrance Credit", value: "EV Parking Entrance Credit" },
    { name: "Inconvenience Credit", value: "Inconvenience Credit" },
    { name: "Miscellaneous Credit", value: "Miscellaneous Credit" },
  ];

  const [curCategory, setCategory] = useState();
  const [addedRows, setRows] = useState(lineItems);
  const [curItem, setItem] = useState();
  const [curAmount, setAmount] = useState();
  const [curDetail, setDetail] = useState();
  const [adding, setAdding] = useState(true);
  const [isEdit, setEdit] = useState();
  const [isRowEditing, setRowEditing] = useState();
  const [curRow, setCurRow] = useState();
  const [curOptions, setCurOptions] = useState([]);

  const ableToAdd = curCategory && curItem && curAmount && curDetail;
  const addDetailRow = () => {
    setRows([
      ...addedRows,
      {
        category: curCategory,
        name: curItem,
        amount: +curAmount,
        description: curDetail,
      },
    ]);
  };

  const toggleEdit = () => {
    setEdit(!isEdit);
    toggleCreateInvoice();
  };

  const toggleRowEdit = () => {
    setRowEditing(!isRowEditing);
    toggleCreateInvoice();
  };

  const setDetailContent = (e) => {
    const { value } = e.target;
    setDetail(value);
  };

  const setDetailAmount = (e) => {
    const { value } = e.target;
    setAmount(value);
  };

  const onCatChange = (e) => {
    setCategory(e);
    if (e === "deduction") {
      setCurOptions(deductionItems);
    } else if (e === "credit") {
      setCurOptions(creditItems);
    } else {
      setCurOptions(feeItems);
    }
  };

  const onItemChange = (e) => {
    setItem(e);
  };

  const toggleAddButton = () => {
    setAdding(!adding);
  };

  const clearLineItem = () => {
    setItem(null);
    setAmount(null);
    setCategory(null);
    setDetail(null);
    // setCurOptions([]);
  };

  useEffect(() => {
    lineItemsCallBack(addedRows);
  }, [addedRows]);

  const tmpTotal =
    addedRows.length !== 0 &&
    addedRows.filter((rowData) => rowData.category !== "credit").length !== 0 &&
    addedRows
      .filter((rowData) => rowData.category !== "credit")
      .map((rowData) => rowData.amount)
      .reduce((prev, next) => prev + next);

  const totalCredit =
    addedRows.length !== 0 &&
    addedRows.filter((rowData) => rowData.category === "credit").length !== 0 &&
    addedRows
      .filter((rowData) => rowData.category === "credit")
      .map((rowData) => rowData.amount)
      .reduce((prev, next) => prev + next);

  const subTotal = tmpTotal - totalCredit;

  useEffect(() => {
    setLiveTotal(subTotal);
  }, [subTotal]);

  return (
    <Fragment>
      <Fragment>
        {addedRows.map((row, i) => (
          <TableRow
            justify="space-between"
            align="center"
            padding="15px 24px"
            borderB="1px solid #dedede"
            className={!isCreated ? "hoverRow" : null}
          >
            {isRowEditing && i === curRow ? (
              <TableCell width="180px" align="left">
                <Dropdown
                  initialValue={row.category}
                  items={cats}
                  onChange={onCatChange}
                  width="124px"
                  height="21px"
                />
              </TableCell>
            ) : (
              <TableCell width="180px" align="left">
                {_.startCase(row.category)}
              </TableCell>
            )}
            {isRowEditing && i === curRow ? (
              <TableCell width="300px" align="left">
                <Dropdown
                  initialValue={row.name}
                  items={curOptions}
                  onChange={onItemChange}
                  width={"212px"}
                  height="21px"
                />
              </TableCell>
            ) : (
              <TableCell width="300px" align="left" wrap="break-word">
                {row.name}
              </TableCell>
            )}
            {isRowEditing && i === curRow ? (
              <TableCell width="600px" align="left">
                <input
                  className="details-detail-input"
                  type="text"
                  value={
                    curDetail
                      ? curDetail
                      : curDetail !== ""
                      ? row.description
                      : curDetail
                  }
                  placeholder={row.description}
                  onChange={setDetailContent}
                ></input>
              </TableCell>
            ) : (
              <TableCell width="600px" align="left" wrap="break-word">
                {row.description}
              </TableCell>
            )}
            {isRowEditing && i === curRow ? (
              <TableCell width="300px" align="left">
                <div className="amount-container">
                  <div style={{ marginRight: "3px" }}>$</div>
                  <input
                    className="details-amount-input"
                    type="number"
                    value={curAmount}
                    placeholder={row.amount}
                    onChange={setDetailAmount}
                  ></input>
                  <FontAwesomeIcon
                    className="detail-add"
                    icon={faCheckCircle}
                    color="#128dff"
                    size="2x"
                    onClick={() => {
                      setRows((existingItems) => {
                        return [
                          ...existingItems.slice(0, i),
                          {
                            category: curCategory ? curCategory : row.category,
                            name: curItem ? curItem : row.name,
                            amount: curAmount ? +curAmount : row.amount,
                            description: curDetail
                              ? curDetail
                              : row.description,
                          },
                          ...existingItems.slice(i + 1),
                        ];
                      });

                      toggleRowEdit();
                      clearLineItem();
                    }}
                  />
                </div>
              </TableCell>
            ) : (
              <TableCell
                width="300px"
                align="left"
                color={row.category === "credit" ? "#3DC700" : "#414141"}
              >
                ${" "}
                {row.amount.toLocaleString(undefined)}
              </TableCell>
            )}
            {!isCreated && (
              <div className="rowActionButtons">
                <span className="fa-layers fa-fw">
                  <FontAwesomeIcon
                    icon={faCircleSolid}
                    className="editCircle"
                    size="lg"
                  />
                  <FontAwesomeIcon
                    icon={faPen}
                    className="editIcon"
                    size="xs"
                    onClick={() => {
                      toggleRowEdit();
                      setCurRow(i);
                      isRowEditing ? setAmount(null) : setAmount(row.amount);
                    }}
                  />
                </span>
                <span className="fa-layers fa-fw">
                  <FontAwesomeIcon
                    icon={faCircle}
                    className="deleteCircle"
                    size="lg"
                  />
                  <FontAwesomeIcon
                    icon={faTrashAlt}
                    className="deleteIcon"
                    size="xs"
                    onClick={() => {
                      setRows((existingItems) => {
                        return [
                          ...existingItems.slice(0, i),
                          ...existingItems.slice(i + 1),
                        ];
                      });
                      setCurRow(addedRows.length - 1);
                      setRowEditing(false);
                      clearLineItem();
                    }}
                  />
                </span>
              </div>
            )}
          </TableRow>
        ))}
        {!isCreated && adding && (
          <TableRow
            justify="space-between"
            align="center"
            padding="15px 24px"
            borderB="1px solid #dedede"
          >
            <TableCell width="180px" align="left">
              <div className="addButtonRow">
                <div className="addButton">
                  <div className="plusButton">
                    <FontAwesomeIcon
                      icon={faPlusCircle}
                      color="#128dff"
                      size="1x"
                      onClick={() => {
                        toggleEdit();
                        toggleAddButton();
                      }}
                    />
                  </div>
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      toggleEdit();
                      toggleAddButton();
                    }}
                  >
                    Add
                  </div>
                </div>
              </div>
            </TableCell>
          </TableRow>
        )}
      </Fragment>

      {isEdit && (
        <TableRow
          justify="space-between"
          align="center"
          padding="15px 24px"
          borderB="1px solid #dedede"
        >
          <TableCell width="180px" align="left">
            <Dropdown
              initialValue={curCategory}
              items={cats}
              onChange={onCatChange}
              width="111px"
              height="21px"
            />
          </TableCell>
          <TableCell width="300px" align="left">
            <Dropdown
              initialValue={curItem}
              items={curOptions}
              onChange={onItemChange}
              width={"212px"}
              height="21px"
              disabled={!curCategory}
            />
          </TableCell>
          <TableCell width="600px" align="left">
            <input
              className="details-detail-input"
              type="text"
              value={curDetail}
              onChange={setDetailContent}
            ></input>
          </TableCell>
          <TableCell width="300px" align="left">
            <div className="amount-container">
              <div style={{ marginRight: "3px" }}>$</div>
              <input
                className="details-amount-input"
                type="number"
                value={curAmount}
                onChange={setDetailAmount}
              ></input>
              <FontAwesomeIcon
                className="detail-add"
                icon={faPlusCircle}
                color="#128dff"
                size="2x"
                onClick={() => {
                  ableToAdd && addDetailRow();
                  ableToAdd && toggleEdit();
                  ableToAdd && toggleAddButton();
                  ableToAdd && clearLineItem();
                }}
              />
            </div>
          </TableCell>
          <div className="rowActionButtons">
            <span className="fa-layers fa-fw">
              <FontAwesomeIcon
                icon={faCircle}
                className="deleteCircle"
                size="lg"
              />
              <FontAwesomeIcon
                icon={faTrashAlt}
                className="deleteIcon"
                size="xs"
                onClick={() => {
                  setCurRow(addedRows.length !== 0 && addedRows.length - 1);
                  toggleEdit();
                  toggleAddButton();
                  clearLineItem();
                }}
              />
            </span>
          </div>
        </TableRow>
      )}
    </Fragment>
  );
};

export default EditableRow;
