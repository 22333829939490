import React from 'react'

const SvgDownArrow = ({ color = '#373535', ...props }) => (
  <svg data-name="Layer 1" viewBox="0 0 39.74 23.34" {...props}>
    <path
      fill="none"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={7}
      d="M36.24 3.5L19.9 19.84h-.06L3.5 3.5"
    />
  </svg>
)

export default SvgDownArrow
