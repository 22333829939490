import React from "react";
import { useQuery } from "@apollo/client";
import { USER_DETAILS } from "../queries";
import Loading from "../../../Shared/Loading";
import DetailsBody from "./DetailsBody";

const AccountDetails = ({ id, isEdit, toggleEdit }) => {
  const { data, loading, error, refetch } = useQuery(USER_DETAILS, {
    variables: { id },
    fetchPolicy: "network-only",
  });

  if (loading) return <Loading />;
  if (error) return null;

  if (data) {
    return (
      <DetailsBody
        user={data.userProfileData}
        isEdit={isEdit}
        refetch={refetch}
        toggleEdit={toggleEdit}
      />
    );
  }
};

export default AccountDetails;
