import React, { useState } from "react";
import Modal from "../../../../../../artzu-ui/src/Modal";
import InformationTable from "../../../../../InformationTable";

const RelatedBookingModal = ({
  open,
  toggleBooking,
  ChosenBooking,
  userId,
  ticketData,
}) => {
  const [selectedBooking, setSelectedBooking] = useState();

  const ChooseBooking = (childData) => {
    setSelectedBooking(childData);
  };
  return (
    <Modal open={open} backgroundColor="none">
      <div className="related-booking-table">
        <div className="relate-booking-title">Choose a booking</div>
        <InformationTable
          rowComponentType="booking"
          tableName="bookings"
          navbar="Bookings"
          startTerms="Search By BookingID, Name and Plate"
          popupSpecific={true}
          popupFunc={ChooseBooking}
          id={userId ? userId : ticketData && ticketData.user.id}
        />
        <div className="relate-booking-buttons">
          <div>
            <button
              className="booking-cancel-button"
              onClick={() => {
                toggleBooking();
              }}
            >
              Cancel
            </button>
          </div>
          <div>
            <button
              className="booking-choose-button"
              onClick={() => {
                ChosenBooking(selectedBooking);
                toggleBooking();
              }}
            >
              Choose
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default RelatedBookingModal;
