import React from "react";
import classNames from "classnames";
import styles from "./baseValue.module.scss";

const TimeValue = ({ cellData, classes }) => {
  const { text } = cellData;
  return (
    <div className={classNames(classes)}>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div className={styles.textValue}>
          {text
            ? parseFloat(text).toLocaleString(undefined, {
                minimumFractionDigits: 1,
                maximumFractionDigits: 1,
              })
            : null}
        </div>
      </div>
    </div>
  );
};

export default TimeValue;
