import React, { useState } from "react";
import { Fragment } from "react";
import Button from "../../../Shared/Button";
import styles from "../batchActions.module.scss";
import classsNames from "classnames";
import { BATCH_ADD_COMMENT } from "../queries";
import { useMutation } from "@apollo/client";
import ErrorModal from "../../../Payments/Invoices/ActionPopup/ErrorModal";
import DatePicker from "react-datepicker";
import { faAlarmClock, faThumbtack } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DateInput from "../../../Shared/DateInput";
import { m } from "../../../../utils";
import Loading from "../../../Shared/Loading";
import SelectAllConfirmModal from "./SelectAllConfirmModal";
import "react-datepicker/dist/react-datepicker.css";

const AddComment = ({
  selectedOption,
  selections,
  close,
  clearAllSelections,
  isAllSelected,
  toggleFeedbackModal,
  toggleRefresh,
  citiesFilter,
}) => {
  const [timestamp, setTimestamp] = useState();
  const [remind, setRemind] = useState();
  const [pin, setPin] = useState();
  const [comment, setComment] = useState("");
  const [isError, setIsError] = useState();
  const [errorMsg, setErrorMsg] = useState();
  const [isConfirm, setIsConfirm] = useState();

  const toggleConfirmModal = () => {
    setIsConfirm(!isConfirm);
  };

  const handleCommentInput = (e) => {
    setComment(e.target.value);
  };

  const toggleRemind = () => {
    setRemind(!remind);
  };

  const togglePin = () => {
    setPin(!pin);
  };

  const toggleErrorModal = () => {
    setIsError(!isError);
  };

  const [commentAddition, { loading, error }] = useMutation(BATCH_ADD_COMMENT);
  const addUserComment = () => {
    commentAddition({
      variables: {
        userIds: selections,
        comment: comment,
        timestamp: timestamp,
        pinned: pin,
        tableName: "users",
        isAllSelected: isAllSelected,
        citiesToFilter: citiesFilter || [],
      },
    }).then(({ data: { batchComment } }) => {
      if (batchComment.success) {
        console.log("Add Comment Succeed!");
        toggleRefresh();
      } else {
        toggleErrorModal();
        setErrorMsg("add comment not working");
        console.log("Add Comment error!");
      }
    });
  };

  const addable = comment;

  return loading ? (
    <Loading size="3x" />
  ) : (
    <Fragment>
      <div className={styles.darkText}>{selectedOption.text}</div>
      <div className={styles.contentContainer}>
        <div className={styles.comment} style={{ marginTop: 0 }}>
          <h4>Comment</h4>
          <textarea
            className={classsNames(styles.textBox, styles.commentBox)}
            onChange={handleCommentInput}
          ></textarea>
        </div>
        <div className="batch-form__field">
          <label>Remind on: </label>
          <DatePicker
            customInput={<DateInput date={timestamp} />}
            selected={timestamp && m(timestamp).toDate()}
            onChange={(val) => {
              setTimestamp(val);
              setRemind(true);
            }}
            className={styles.dateInput}
            dateFormat="MMM d, yyyy"
            todayButton={"Today"}
            popperPlacement="top"
            showTimeSelect
            timeFormat="HH:mm"
            timeIntervals={15}
          />
          <div
            style={{ marginLeft: "auto" }}
            className={`action-button${remind ? " selected" : ""}`}
            onClick={toggleRemind}
          >
            <FontAwesomeIcon icon={faAlarmClock} size="sm" />
          </div>
          <div
            className={`action-button${pin ? " selected" : ""}`}
            onClick={togglePin}
          >
            <FontAwesomeIcon icon={faThumbtack} size="sm" />
          </div>
        </div>
        <div className={styles.notesSaveButton}>
          <Button
            label={"Save"}
            height="39px"
            fullWidth="150px"
            color={addable ? "#128dff" : "#87CEFA"}
            backColor="#ffffff"
            radius="20px"
            size="16px"
            weight="700"
            border="1px solid #FFFFFF"
            onClick={() => {
              addable && !isAllSelected && addUserComment();
              addable && !isAllSelected && clearAllSelections();
              addable && !isAllSelected && toggleFeedbackModal();
              addable && !isAllSelected && close();
              addable && isAllSelected && toggleConfirmModal();
            }}
          />
        </div>
      </div>
      <ErrorModal
        open={isError}
        errorMsg={errorMsg}
        toggleErrorModal={toggleErrorModal}
      />
      <SelectAllConfirmModal
        open={isConfirm}
        rowCount={selections.length}
        batchAction={selectedOption.text}
        batchActionFun={addUserComment}
        toggleConfirmModal={toggleConfirmModal}
        toggleFeedbackModal={toggleFeedbackModal}
        clearAllSelections={clearAllSelections}
        close={close}
      />
    </Fragment>
  );
};

export default AddComment;
