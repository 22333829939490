import React from "react";
import { useHistory } from "react-router";
import LocationSelect from "./LocationSelect/index.js";
import Logout from "./Logout.js";
import { taskbarMenu, taskbarTools } from "./taskbarItems.js";
import SettingsGrey from "../../assets/icons/SettingsGrey.svg";
import { Link } from "react-router-dom";

const LayoutContent = ({ handleCityCallback, props, data, selectedCities }) => {
  const history = useHistory();

  return (
    <div className="layout-container">
      <div className="taskbar">
        <div className="taskbar__section">
          {taskbarMenu
            .filter((item) => item.isShown)
            .map((item, index) => {
              return (
                <Link to={`${item.link}`} style={{ textDecoration: "none" }} >
                  <div
                    className={`taskbar__item ${
                      window.location.href.includes(
                        `${window.location.host}${item.link}`
                      )
                        ? "taskbar__item--selected"
                        : ""
                    }`}
                    key={index}
                    // onClick={() => history.push(item.link)}
                  >
                    <img
                      src={
                        window.location.href.includes(
                          `${window.location.host}${item.link}`
                        )
                          ? item.icons[0]
                          : item.icons[1]
                      }
                      className="icon"
                      onMouseOver={(e) =>
                        window.location.href.includes(
                          `${window.location.host}${item.link}`
                        )
                          ? null
                          : (e.currentTarget.src = item.icons[0])
                      }
                      onMouseOut={(e) =>
                        window.location.href.includes(
                          `${window.location.host}${item.link}`
                        )
                          ? null
                          : (e.currentTarget.src = item.icons[1])
                      }
                    />
                  </div>
                </Link>
              );
            })}
        </div>
        {/* <div className="taskbar__section"></div> */}
        <div className="taskbar__section">
          <div
            className="taskbar__item"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <LocationSelect
              currentUser={data && data.currentAdminUser}
              handleCityCallback={handleCityCallback}
            />
          </div>
          <div className="taskbar__item">
            <Logout />
          </div>
          <div className="taskbar__item">
            <img src={SettingsGrey} className="lower-icon" />
          </div>
        </div>
      </div>

      <div className="content-body">
        {React.Children.map(props.children, (child) =>
          React.cloneElement(child, 
            { 
              selectedCities,
              timeZone: data && data.currentAdminUser.timeZone,
            })
        )}
      </div>
    </div>
  );
};

export default LayoutContent;
