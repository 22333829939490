import React, { useState } from "react";
import styles from "./batchActions.module.scss";
import AddComment from "./Users/AddComment";
import AddTag from "./Users/AddTag";
import ChangeUserStatus from "./Users/ChangeUserStatus";
import Communication from "./Users/Communication";

const ActionContainer = ({
  selectedOption,
  selectedUsers,
  close,
  toggleRefresh,
  clearAllSelections,
  isAllSelected,
  toggleFeedbackModal,
  citiesFilter,
}) => (
  <div className={styles.actionContainer}>
    <div className={styles.rightHeader}>
      {selectedOption.value === "changeUserStatus" && (
        <ChangeUserStatus
          selectedOption={selectedOption}
          selections={selectedUsers}
          close={close}
          clearAllSelections={clearAllSelections}
          toggleRefresh={toggleRefresh}
          isAllSelected={isAllSelected}
          toggleFeedbackModal={toggleFeedbackModal}
          citiesFilter={citiesFilter}
        />
      )}
      {selectedOption.value === "addComment" && (
        <AddComment
          selectedOption={selectedOption}
          selections={selectedUsers}
          toggleRefresh={toggleRefresh}
          close={close}
          clearAllSelections={clearAllSelections}
          isAllSelected={isAllSelected}
          toggleFeedbackModal={toggleFeedbackModal}
          citiesFilter={citiesFilter}
        />
      )}
      {selectedOption.value === "addTag" && (
        <AddTag
          selectedOption={selectedOption}
          selections={selectedUsers}
          close={close}
          clearAllSelections={clearAllSelections}
          isAllSelected={isAllSelected}
          toggleFeedbackModal={toggleFeedbackModal}
          toggleRefresh={toggleRefresh}
          citiesFilter={citiesFilter}
        />
      )}
      {selectedOption.value === "communication" && (
        <Communication
          selectedOption={selectedOption}
          selections={selectedUsers}
          close={close}
          toggleRefresh={toggleRefresh}
          clearAllSelections={clearAllSelections}
          isAllSelected={isAllSelected}
          toggleFeedbackModal={toggleFeedbackModal}
          citiesFilter={citiesFilter}
        />
      )}
    </div>
  </div>
);

export default ActionContainer;
