import moment from "moment";
import ExpandableList from "../CellValues/Base/ExpandableList";
import BubbleTextValue from "../CellValues/Base/BubbleTextValue";
import TextValue from "../CellValues/Base/TextValue";
import TicketStatusValue from "../CellValues/Base/TicketStatusValue";
import UserValue from "../CellValues/Base/UserValue";
import {
  baseDateProcessor,
  baseImageProcessor,
  baseTextProcessor,
} from "./shared";
import UnreadValue from "../CellValues/Base/UnreadValue";

const baseTagProcessor = (accessor) => {
  return (data) => {
    const listData = data[accessor].value;
    if (listData) {
      const mappedListData = listData.map((data) => ({
        text: data.name,
      }));
      return {
        listComponent: BubbleTextValue,
        listData: mappedListData,
      };
    } else {
      return {
        listComponent: BubbleTextValue,
        listData: [],
      };
    }
  };
};

const rowComponentsTicket = (time_zone) => ({
  ticketStatus: {
    component: TicketStatusValue,
    dataProcessor: baseTextProcessor("ticketStatus"),
  },
  referenceNumber: {
    component: TextValue,
    dataProcessor: baseTextProcessor("referenceNumber"),
  },
  profileImage: {
    component: UserValue,
    dataProcessor: baseImageProcessor("userProfileImageId"),
  },
  userName: {
    component: TextValue,
    dataProcessor: baseTextProcessor("userName"),
  },
  accountStatus: {
    component: TextValue,
    dataProcessor: baseTextProcessor("accountStatus"),
  },
  ticketCategory: {
    component: TextValue,
    dataProcessor: baseTextProcessor("category"),
  },
  ticketSubject: {
    component: TextValue,
    dataProcessor: baseTextProcessor("ticketSubject"),
  },
  issuedAt: {
    component: TextValue,
    dataProcessor: baseDateProcessor("createdAt", time_zone),
  },
  updatedAt: {
    component: TextValue,
    dataProcessor: baseDateProcessor("updatedAt", time_zone),
  },
  tags: {
    component: ExpandableList,
    dataProcessor: baseTagProcessor("tags"),
  },
  adminUserName: {
    component: TextValue,
    dataProcessor: baseTextProcessor("assignedAdminUserFullName"),
  },
  plate: {
    component: TextValue,
    dataProcessor: baseTextProcessor("plate"),
  },
  userAppVersion: {
    component: TextValue,
    dataProcessor: (data) => {
      const os = data["userDeviceOs"] && data["userDeviceOs"].value;
      const version = data["userAppVersion"] && data["userAppVersion"].value;

      return {
        text: os,
        subtext: version,
      };
    },
  },
  ticketRating: {
    component: TextValue,
    dataProcessor: baseTextProcessor("ticketRating"),
  },
  unreadMessages: {
    component: UnreadValue,
    dataProcessor: baseTextProcessor("unreadMessages"),
  },
  activeBooking: {
    component: TextValue,
    dataProcessor: baseTextProcessor("activeBooking"),
  },
});

export { rowComponentsTicket };
