import React, { Component } from "react";
import gql from "graphql-tag";
import { Mutation } from "@apollo/client/react/components";
import { ApolloConsumer } from "@apollo/client";

class DeleteUser extends Component {
  DELETE_USER = gql`
    mutation deleteUser($userId: ID!) {
      deleteUser(userId: $userId) {
        success
        error
      }
    }
  `;

  render() {
    const { userId, toggleDelete, toggleEdit, refetch } = this.props;
    // const organization = "Autzu"
    return (
      <ApolloConsumer>
        {(client) => (
          <Mutation
            mutation={this.DELETE_USER}
            variables={{
              userId,
            }}
            onCompleted={(data) => {
              if (data.deleteUser.success) {
                console.log("Succeed");
                refetch();
                toggleEdit();
              }
            }}
          >
            {(deleteUser) => (
              <button
                className="delete-yes"
                onClick={() => {
                  deleteUser();
                  toggleDelete();
                }}
              >
                Yes
              </button>
            )}
          </Mutation>
        )}
      </ApolloConsumer>
    );
  }
}

export default DeleteUser;
