import React from "react";
import InformationTable from "../InformationTable";
import { Helmet } from "react-helmet";
import { Fragment } from "react";
import { useLocation } from "react-router";

const IndexPage = ({ selectedCities, timeZone }) => {
  return (
    <Fragment>
      <Helmet>
        <title>Users | Autzu Admin</title>
      </Helmet>
      <InformationTable
        rowComponentType="user"
        tableName="users"
        navbar="Users"
        startTerms="Search By Name, User Id, Mobile, Email"
        cities={selectedCities}
        timeZone={timeZone}
      />
    </Fragment>
  );
};

export default IndexPage;
