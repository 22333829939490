import React from 'react'
import styled from 'styled-components'

const StyledDivider = styled.div`
  border: 1px solid #dedede;
  width: ${({ width }) => width || 'auto'};
  margin: ${({ margin }) => margin || '0'};
`

const Divider = () => <StyledDivider />

export default Divider
