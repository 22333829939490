import React from "react";
import Button from "../Button";
import styles from "./buttonGroup.module.scss";

const BaseButton = ({ onClick, label, additionalProps }) => (
  <Button
    fullWidth="152px"
    label={label}
    className={styles.button}
    disabled={!onClick}
    border="1px solid rgb(222,222,222)"
    weight="700"
    onClick={onClick || (() => {})}
    {...additionalProps}
  />
);

export default BaseButton;
