import React from "react";
import styles from "../actionBar.module.scss";
import { useState } from "react";
import toggleOn from "../../../../images/buttons/toggle-on.svg";
import toggleOff from "../../../../images/buttons/toggle-off.svg";

const TicketToggleButton = ({ currentAdminUserOnly ,changeAdminUserOnly }) => {

  return (
    <div className={styles.toggleTicketsButtonContainer}>
      <div>All tickets</div>
      <img
        src={currentAdminUserOnly ? toggleOn : toggleOff}
        onClick={() => {
          changeAdminUserOnly();
        }}
        style={{ cursor: "pointer", marginLeft: "8px", marginRight: "8px" }}
      ></img>
      <div>My tickets</div>
    </div>
  );
};

export default TicketToggleButton;
