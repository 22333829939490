import { DELETE_TAG } from "../../../queries";
import Modal from "../../../../../../artzu-ui/src/Modal";
import styles from "../../../userDetails.module.scss";
import { useMutation } from "@apollo/client";
import ButtonGroup from "../../../../../Shared/ButtonGroup";

const DeleteTagModal = ({
  open,
  toggleDelete,
  selectedTag,
  refetchProfile,
  refetchAllTags,
}) => {
  const [deleteTagMutation, { loading: isDeleteLoading }] =
    useMutation(DELETE_TAG);

  const deleteTag = () => {
    deleteTagMutation({
      variables: {
        tagName: selectedTag.name,
      },
    }).then(({ data: { deleteTag } }) => {
      if (deleteTag.success) {
        refetchAllTags();
        refetchProfile();
        console.log("Delete Tag Succeed!");
      } else {
        console.log("Delete Tag error!");
      }
    });
  };

  return (
    <Modal open={open} backgroundColor="none">
      <div className={styles.popupModalContainer}>
        <div className={styles.editTagsHead}>Delete Tag</div>
        <div>
          <div className={styles.deleteTagsMessage}>
            Are you sure you want to delete{" "}
            <span style={{ fontWeight: "bold" }}>{selectedTag.name}</span>?
          </div>
          <div className={styles.deleteTagsMessage}>
            It is currently attached to{" "}
            <span style={{ fontWeight: "bold" }}>
              {selectedTag.attachedToCount} user
              {selectedTag.attachedToCount == 1 ? "" : "s"}.
            </span>
          </div>
        </div>
        <div className={styles.buttonsFooter}>
          <div className={styles.cancelButton} onClick={toggleDelete}>
            Cancel
          </div>
          <div
            className={styles.confirmDeleteButton}
            onClick={() => {
              deleteTag();
              refetchAllTags();
              toggleDelete();
            }}
          >
            Delete
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default DeleteTagModal;
