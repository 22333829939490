import React, { useEffect } from "react";
import { useMutation } from "@apollo/client";
import { START_AI_CHAT_MUTATION } from "./queries";
import { useHistory } from "react-router-dom";
import Loading from "../Shared/Loading";

const CreateChat = ({}) => {
  const [createChat, { loading, error, data }] = useMutation(
    START_AI_CHAT_MUTATION
  );

  useEffect(() => {
    createChat();
  }, []);

  const history = useHistory();

  useEffect(() => {
    if (data) {
      // Redirect to the new page once the mutation has completed successfully
      history.push(`/autzu-chat/${data.startAiChat.chat.id}`);
    }
    if (error) {
      console.error(error);
    }
  }, [data, error, history]);

  return <Loading />;
};

export default CreateChat;
