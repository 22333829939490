import React, { Component } from "react";
import gql from "graphql-tag";
import { useMutation } from "@apollo/client";
import { useHistory } from "react-router";
import "../Components/Layout/layout.css";

const DestroyAdminSession = ({ open }) => {
  const DESTROY_ADMIN_SESSION = gql`
    mutation DestroyAdminSession {
      destroyAdminSession {
        success
        error
      }
    }
  `;

  const history = useHistory();

  const [destroyAdminMutation] = useMutation(DESTROY_ADMIN_SESSION);
  const destroy = () => {
    destroyAdminMutation().then(({ data: { destroyAdminSession } }) => {
      if (!destroyAdminSession.error) {
        history.push("/login");
        console.log("destroy succeed!");
      } else {
        console.log("destroy error!");
      }
    });
  };

  return (
    <div
      className={`dropdown ${open ? "visible" : "invisible"}`}
      onClick={destroy}
    >
      <p>Sign out</p>
    </div>
  );
};

export default DestroyAdminSession;
