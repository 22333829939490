import React, { Component, Fragment } from "react";
import { m, titleize } from "../../utils";
import { FormControl, Select, MenuItem, InputBase } from "@material-ui/core";
import DatePicker from "react-datepicker";
import Truncate from "react-truncate";
import Button from "../../artzu-ui/src/Button";
import SimpleDateInput from "../Shared/SimpleDateInput";
import { CSVLink } from "react-csv";
import { withStyles } from "@material-ui/core/styles";
import "./index.scss";
import "react-datepicker/dist/react-datepicker.css";

class ParameterPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rangeStart: this.props.rangeStart,
      rangeEnd: this.props.rangeEnd,
      increment: this.props.increment,
      incrementOptions: {
        Month: "1 MONTH",
        Week: "1 WEEK",
        Day: "1 DAY",
      },
      showApply: false,
    };
  }

  render() {
    const { rangeStart, rangeEnd, increment, incrementOptions, showApply } =
      this.state;
    const { handleXChange, csvData } = this.props;
    return (
      <div className="parameter-panel">
        <div className="param-cell">
          <label>From</label>
          <FormControl
            variant="outlined"
            className={this.props.classes.formControl}
          >
            <DatePicker
              customInput={<SimpleDateInput date={rangeStart} />}
              selected={rangeStart && m(rangeStart).toDate()}
              onChange={(val) =>
                this.setState({ rangeStart: val, showApply: true })
              }
              dateFormat="L"
              filterDate={(e) => m(e).isBefore(rangeEnd)}
              todayButton={"Today"}
              popperPlacement="bottom-start"
              popperModifiers={{
                flip: {
                  enabled: false,
                },
                preventOverflow: {
                  enabled: true,
                  escapeWithReference: false,
                },
              }}
            />
          </FormControl>
        </div>
        <div className="param-cell">
          <label>To</label>
          <FormControl
            variant="outlined"
            className={this.props.classes.formControl}
          >
            <DatePicker
              customInput={<SimpleDateInput date={rangeEnd} />}
              selected={rangeEnd && m(rangeEnd).toDate()}
              onChange={(val) =>
                this.setState({ rangeEnd: val, showApply: true })
              }
              dateFormat="L"
              filterDate={(e) => m(e).isAfter(rangeStart)}
              todayButton={"Today"}
              popperPlacement="bottom-start"
              popperModifiers={{
                flip: {
                  enabled: false,
                },
                preventOverflow: {
                  enabled: true,
                  escapeWithReference: false,
                },
              }}
            />
          </FormControl>
        </div>
        <div className="param-cell">
          <label>Interval</label>
          <FormControl
            variant="outlined"
            className={this.props.classes.formControl}
          >
            <Select
              value={increment}
              className={this.props.classes.select}
              onChange={(e) =>
                this.setState({ increment: e.target.value, showApply: true })
              }
            >
              {Object.keys(incrementOptions).map((io, index) => {
                return (
                  <MenuItem value={incrementOptions[io]} key={index}>
                    {io}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </div>
        <div className="analytic-input-buttons">
          <Button
            label="Apply"
            padding="0"
            primary
            style={{ height: "34px", marginTop: "auto", marginRight: "12px" }}
            onClick={() => {
              handleXChange({ rangeStart, rangeEnd, increment });
              this.setState({ showApply: false });
            }}
            disabled={!showApply}
          />
          <CSVLink
            data={csvData}
            filename={`autzu_analytics_${m().format()}`}
            className="param-button"
          >
            <Button
              label="Download"
              padding="0"
              primary
              style={{ height: "34px", marginTop: "auto", marginLeft: "auto" }}
              disabled={csvData.length === 0}
            />
          </CSVLink>
        </div>
      </div>
    );
  }
}

const styles = (theme) => ({
  formControl: {
    width: "100%",
    borderRadius: "12px",
    backgroundColor: "#fff",
    height: "24px",
    marginBottom: "5px",
    "&:focus": {
      borderColor: "#128dff",
      color: "#128dff",
    },
  },
  select: {
    borderRadius: "12px",
    height: "24px",
    fontSize: "16px",
    backgroundColor: "#fff",
    "&:focus": {
      borderColor: "#128dff",
      color: "#128dff",
    },
  },
});

export default withStyles(styles)(ParameterPanel);
