import React, { useState } from "react";
import { useBatchActions } from "./useBatchActions";
import _ from "lodash";
import ActionsContainer from "./ActionsContainer";
import ConfirmModal from "../../ActionBar/Selections/ConfirmModal";

const BookingBatchActions = ({
  isOpen,
  selectedRows,
  clearAllSelections,
  tabs,
  refetch,
}) => {
  const [selectedAction, setSelectedAction] = useState(null);
  const [error, setError] = useState(null);

  const { getBatchActionsForTab, loading } = useBatchActions();

  const selectedBookingIds = Object.keys(_.pickBy(selectedRows));

  const modalText = `Are you sure you want to ${selectedAction?.actionName} selected booking(s)`;

  const resetBatchActions = () => {
    clearAllSelections();
    refetch();
    setSelectedAction(null);
  };
  const successCallback = () => {
    resetBatchActions();
  };

  const errorCallback = (error) => {
    resetBatchActions();
    setError(error);
  };

  const availableBatchActions = getBatchActionsForTab(
    tabs.currentTab,
    successCallback,
    errorCallback
  );

  return (
    <>
      <ActionsContainer
        isActionButtonsShown={isOpen}
        availableBatchActions={availableBatchActions}
        setSelectedAction={setSelectedAction}
      />
      <ConfirmModal
        isOpen={!!selectedAction || error}
        confirmChanges={() => selectedAction?.action(selectedBookingIds)}
        text={modalText}
        loading={loading}
        closeModal={() => setError(null)}
        error={error}
      />
    </>
  );
};
export default BookingBatchActions;
