import React from "react";
import styled from "styled-components";
import {
  getSelectedBorder,
  isSelected,
} from "../../../artzu-ui/src/SelectField/selectHelpers";

const StyledOption = styled.div`
  width: 100%;
  cursor: pointer;
  font-weight: 400;
  display: flex;
  align-items: center;
  padding: 5px 7px;
  ${({
    selectedValue,
    value,
    primaryColor,
    theme,
    secondaryColor = theme.darkGrey,
    index,
    length,
  }) =>
    getSelectedBorder(
      isSelected(selectedValue, value),
      primaryColor,
      secondaryColor,
      index,
      length
    )}
`;

const OptionStyle = ({ label = "", value = null, newSelection, ...props }) => (
  <StyledOption
    {...props}
    value={value}
    onClick={() => newSelection(label, value)}
  >
    {label}
  </StyledOption>
);

export default OptionStyle;
