import React, { useState } from "react";
import Modal from "../../../../../artzu-ui/src/Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/pro-regular-svg-icons";
import styles from "./bookingTimeline.module.scss";

const CarChangeInspection = ({
  inspectionData,
  isInspection,
  toggleInspection,
}) => {
  console.log(inspectionData);
  const oldCar = inspectionData.firstCar;
  const newCar = inspectionData.secondCar;

  const goUrl = (car) => {
    window.open(car.fleetProfileUrl, "_blank");
  };
  return (
    <Modal open={isInspection} backgroundColor="none">
      <div className={styles.carChangeContainer}>
        <h2>Changed vehicle</h2>
        <div className={styles.vehicleImageContainer}>
          <div className={styles.previousContainer}>
            <div style={{ textAlign: "center" }}>Previous vehicle</div>
            <img src={oldCar.imageLink} className={styles.carChangeImg} />
            <div className={styles.carPlate} onClick={() => goUrl(oldCar)}>
              {oldCar.plate}
            </div>
          </div>
          <div className={styles.changeArrow}>
            <FontAwesomeIcon icon={faArrowRight} size="2x" />
          </div>
          <div className={styles.newContainer}>
            <div style={{ textAlign: "center" }}>New vehicle</div>
            <img src={newCar.imageLink} className={styles.carChangeImg} />
            <div className={styles.carPlate} onClick={() => goUrl(newCar)}>
              {newCar.plate}
            </div>
          </div>
        </div>
        <div>
          <h4 style={{ fontWeight: "700" }}>Reason for changing the Vehicle</h4>
          <div>{inspectionData.reasonForSwap}</div>
        </div>
        <div>
          <h4 style={{ fontWeight: "700" }}>Comment</h4>
          <div>{inspectionData.comment}</div>
        </div>
        <div className={styles.inspectionButton}>
          <button className={styles.closeButton} onClick={toggleInspection}>
            Close
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default CarChangeInspection;
