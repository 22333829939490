import React from "react";
import styles from "../userDetails.module.scss";

const DetailField = ({ text, value }) => (
  <div className={styles.detailsField}>
    <div className={styles.subscript}>{text}</div>
    <div className={styles.detailField}>{value}</div>
  </div>
);

export default DetailField;
