import React, { Component } from "react";
import { Query } from "@apollo/client/react/components";
import gql from "graphql-tag";
import Loading from "../Components/Shared/Loading";
import RideshareBody from "../Components/User/Rideshare/RideshareBody";
import { userRideshareString } from "./queryStrings";
// import { nextShift } from "../utils";

class UserRideshareData extends Component {
  render() {
    const { id, name } = this.props;
    return (
      <Query
        query={gql`
          ${userRideshareString}
        `}
        variables={{ id }}
        pollInterval={600000}
      >
        {({ loading, error, data, refetch }) => {
          // console.log(data);
          if (error) {
            return null;
          } else if (loading) {
            return (
              <div
                className="user-page__body"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <Loading />
              </div>
            );
          }

          if (data.userProfileData) {
            return (
              <RideshareBody
                user={data.userProfileData}
                refetch={refetch}
                name={name}
              />
            );
          } else {
            return null;
          }
        }}
      </Query>
    );
  }
}

export default UserRideshareData;
