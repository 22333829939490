import React, { useEffect } from "react";
import { Fragment } from "react";
import InformationTable from "../../InformationTable";
import PaymentHeader from "./PaymentHeader";
import { useLocation } from "react-router-dom";

const Payments = ({ id }) => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <Fragment>
      <PaymentHeader userId={id} />
      <InformationTable
        rowComponentType="payment"
        tableName="invoices"
        navbar={"Payments"}
        startTerms="Search By Invoice# and Booking ID"
        userSpecific={true}
        id={id}
      />
    </Fragment>
  );
};

export default Payments;
