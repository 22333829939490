import React, { Component, useState, useEffect } from "react";
import UserAnalytics from "../../../Queries/UserAnalyticIndex";
import Logs from "./Logs";
import Notifications from "./Notifications";
import Onboarding from "./Onboarding";
import Messages from "./Messages";
import { useLocation } from 'react-router-dom';


const Activity = ({ id }) => {
  const [activeTab, setActiveTab] = useState("analytics");
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0,0);
  }, [location]);

  const pageContent = () => {
    switch (activeTab) {
      case "analytics":
        return <UserAnalytics userId={parseInt(id)} />;
      case "logs":
        return <Logs userId={parseInt(id)} />;
      case "notifications":
        return <Notifications userId={parseInt(id)} />;
      case "onboarding data":
        return <Onboarding userId={parseInt(id)} />;
      case "messages":
        return <Messages userId={parseInt(id)} />;
    }
  };
  return (
    <div className="user-details-container">
      <div className="tab-container">
        <div
          className={`unselectable detail-tab${
            activeTab === "analytics" ? " selected" : ""
          }`}
          onClick={() => setActiveTab("analytics")}
        >
          Analytics
        </div>
        <div
          className={`unselectable detail-tab${
            activeTab === "logs" ? " selected" : ""
          }`}
          onClick={() => setActiveTab("logs")}
        >
          Logs
        </div>
        <div
          className={`unselectable detail-tab${
            activeTab === "notifications" ? " selected" : ""
          }`}
          onClick={() => setActiveTab("notifications")}
        >
          Notifications
        </div>
        <div
          className={`unselectable detail-tab${
            activeTab === "onboarding data" ? " selected" : ""
          }`}
          onClick={() => setActiveTab("onboarding data")}
        >
          Onboarding data
        </div>
        <div
          className={`unselectable detail-tab${
            activeTab === "messages" ? " selected" : ""
          }`}
          onClick={() => setActiveTab("messages")}
        >
          Texts
        </div>
      </div>
      {pageContent()}
    </div>
  );
};

export default Activity;
