import React, { useState, Fragment } from "react";
import Modal from "../../../../../artzu-ui/src/Modal";
import { faTimes } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import FullImageMessage from "./FullImageMessage";

const AccidentReportModal = ({ open, toggleAccident, accidentReport }) => {
  const [isFull, setIsFull] = useState(false);
  const [curUrl, setCurUrl] = useState();

  const vehicleTowed = accidentReport && accidentReport.towed ? "Yes" : "No";
  const isInjured =
    accidentReport && accidentReport.injuredPeople.length > 0 ? "Yes" : "No";

  const carInvolved =
    accidentReport && accidentReport.thirdPartyVehicles.length > 0;
  const peopleInvolved =
    accidentReport && accidentReport.thirdPartyIndividuals.length > 0;

  const toggleImg = () => {
    setIsFull(!isFull);
    document.addEventListener("click", closeImg);
  };

  const closeImg = () => {
    setIsFull(false);
    document.removeEventListener("click", closeImg);
  };

  return (
    <Fragment>
      <Modal open={open} backgroundColor="none">
        <div className="accident-report-container">
          <div className="main-title">
            Accident report
            <div className="close-report">
              <FontAwesomeIcon
                icon={faTimes}
                size="sm"
                onClick={toggleAccident}
              />
            </div>
          </div>
          <div className="accident-detail-block">
            <div className="sub-title">Questionnaire</div>
            <div className="accident-details-field">
              <div className="subscript">Is anyone injured?</div>
              <div className="detail-field">{isInjured}</div>
            </div>
            <div className="accident-details-field">
              <div className="subscript">Who is injured?</div>
              <div className="detail-field">
                {isInjured === "Yes"
                  ? accidentReport &&
                    accidentReport.injuredPeople.map((ppl) => (
                      <li style={{ fontWeight: 500 }}>{ppl}</li>
                    ))
                  : "N/A"}
              </div>
            </div>
            <div className="accident-details-field">
              <div className="subscript">Were you online?</div>
              <div className="detail-field">
                {accidentReport && accidentReport.statusDescription}
              </div>
            </div>
            <div className="accident-details-field">
              <div className="subscript">
                Which rideshare platform were you online at the time of the
                accident?
              </div>
              <div className="detail-field">
                {accidentReport && accidentReport.ridesharePlatform}
              </div>
            </div>
            <div className="accident-details-field">
              <div className="subscript">Was the vehicle towed?</div>
              <div className="detail-field">{vehicleTowed}</div>
            </div>
            {accidentReport && accidentReport.towed && (
              <div className="accident-details-field">
                <div className="subscript">Who towed the vehicle?</div>
                <div className="detail-field">
                  {accidentReport && accidentReport.towingCompany}
                </div>
              </div>
            )}
            {accidentReport && accidentReport.towed && (
              <div className="accident-details-field">
                <div className="subscript">Where was it towed?</div>
                <div className="detail-field">
                  {accidentReport && accidentReport.towingAddress}
                </div>
              </div>
            )}
          </div>
          <div className="accident-detail-block">
            <div className="sub-title">Accident details</div>
            <div className="accident-details-field">
              <div className="subscript">Date of accident</div>
              <div className="detail-field">
                {accidentReport &&
                  accidentReport.accidentTime &&
                  moment(accidentReport.accidentTime).format("MMM D, YYYY")}
              </div>
            </div>
            <div className="accident-details-field">
              <div className="subscript">Time of accident</div>
              <div className="detail-field">
                {accidentReport &&
                  accidentReport.accidentTime &&
                  moment(accidentReport.accidentTime).format("h:mm A")}
              </div>
            </div>
            <div className="accident-details-field">
              <div className="subscript">Location of accident</div>
              <div className="detail-field">
                {" "}
                {accidentReport && accidentReport.accidentLocation}
              </div>
            </div>
            <div className="accident-details-field">
              <div className="subscript">What happened</div>
              <div className="detail-field">
                {" "}
                {accidentReport && accidentReport.accidentDescription}
              </div>
            </div>
          </div>
          <div
            className="accident-detail-block"
            style={{ borderBottom: !carInvolved && !peopleInvolved && "none" }}
          >
            <div className="sub-title">Autzu vehicle damage</div>
            <div className="damage-images">
              {accidentReport &&
                accidentReport.autzuDamageImages.map((dmg) => (
                  <img
                    className="accident-image-container"
                    src={dmg}
                    onClick={() => {
                      setCurUrl(dmg);
                      toggleImg();
                    }}
                  ></img>
                ))}
            </div>
            {accidentReport &&
              (accidentReport.frontImage ||
                accidentReport.backImage ||
                accidentReport.driverImage ||
                accidentReport.passengerImage) && (
                <div className="damage-images">
                  {accidentReport && accidentReport.frontImage && (
                    <div className="angle-image-container">
                      <div className="subscript">Front side</div>
                      <img
                        className="accident-image-container"
                        src={accidentReport.frontImage}
                        onClick={() => {
                          setCurUrl(accidentReport.frontImage);
                          toggleImg();
                        }}
                      ></img>
                    </div>
                  )}
                  {accidentReport && accidentReport.backImage && (
                    <div className="angle-image-container">
                      <div className="subscript">Back side</div>
                      <img
                        className="accident-image-container"
                        src={accidentReport.backImage}
                        onClick={() => {
                          setCurUrl(accidentReport.backImage);
                          toggleImg();
                        }}
                      ></img>
                    </div>
                  )}
                  {accidentReport && accidentReport.driverImage && (
                    <div className="angle-image-container">
                      <div className="subscript">Driver side</div>
                      <img
                        className="accident-image-container"
                        src={accidentReport.driverImage}
                        onClick={() => {
                          setCurUrl(accidentReport.driverImage);
                          toggleImg();
                        }}
                      ></img>
                    </div>
                  )}
                  {accidentReport && accidentReport.passengerImage && (
                    <div className="angle-image-container">
                      <div className="subscript">Passenger side</div>
                      <img
                        className="accident-image-container"
                        src={accidentReport.passengerImage}
                        onClick={() => {
                          setCurUrl(accidentReport.passengerImage);
                          toggleImg();
                        }}
                      ></img>
                    </div>
                  )}
                </div>
              )}
          </div>
          {peopleInvolved && (
            <div className="accident-detail-block">
              <div className="sub-title">Third party details (Individual)</div>
              {accidentReport &&
                accidentReport.thirdPartyIndividuals.map((person) => (
                  <div>
                    {person.name && (
                      <div className="accident-details-field">
                        <div className="subscript">Name</div>
                        <div className="detail-field">{person.name}</div>
                      </div>
                    )}
                    {person.phone && (
                      <div className="accident-details-field">
                        <div className="subscript">Phone number</div>
                        <div className="detail-field">{person.phone}</div>
                      </div>
                    )}
                    {person.email && (
                      <div className="accident-details-field">
                        <div className="subscript">Email</div>
                        <div className="detail-field">{person.email}</div>
                      </div>
                    )}
                    {person.individualImages && (
                      <div className="accident-details-field">
                        <div className="subscript">Images</div>
                        <div className="damage-images">
                          {person.individualImages.map((img) => (
                            <img
                              className="accident-image-container"
                              src={img}
                              onClick={() => {
                                setCurUrl(img);
                                toggleImg();
                              }}
                            ></img>
                          ))}
                        </div>
                      </div>
                    )}
                    {person.notes && (
                      <div className="accident-details-field">
                        <div className="subscript">Notes</div>
                        <div className="detail-field">{person.notes}</div>
                      </div>
                    )}
                  </div>
                ))}
            </div>
          )}
          {carInvolved && (
            <div
              className="accident-detail-block"
              style={{ borderBottom: "none" }}
            >
              <div className="sub-title">Third party details (Vehicle)</div>
              {accidentReport &&
                accidentReport.thirdPartyVehicles.map((car) => (
                  <div>
                    {car.year && (
                      <div className="accident-details-field">
                        <div className="subscript">Year</div>
                        <div className="detail-field">{car.year}</div>
                      </div>
                    )}
                    {car.make && (
                      <div className="accident-details-field">
                        <div className="subscript">Make</div>
                        <div className="detail-field">{car.make}</div>
                      </div>
                    )}
                    {car.model && (
                      <div className="accident-details-field">
                        <div className="subscript">Model</div>
                        <div className="detail-field">{car.model}</div>
                      </div>
                    )}
                    {car.color && (
                      <div className="accident-details-field">
                        <div className="subscript">Color</div>
                        <div className="detail-field">{car.color}</div>
                      </div>
                    )}
                    {car.licensePlateImage && (
                      <div className="damage-images">
                        <div className="angle-image-container">
                          <div className="subscript" style={{ width: "100px" }}>
                            License plate
                          </div>
                          <div>{car.licensePlate}</div>
                          <img
                            className="accident-image-container"
                            src={car.licensePlateImage}
                            onClick={() => {
                              setCurUrl(car.licensePlateImage);
                              toggleImg();
                            }}
                          ></img>
                        </div>

                        {car.licenseImage && (
                          <div className="angle-image-container">
                            <div
                              className="subscript"
                              style={{ width: "100px" }}
                            >
                              Driver license
                            </div>
                            <img
                              className="accident-image-container"
                              src={car.licenseImage}
                              onClick={() => {
                                setCurUrl(car.licenseImage);
                                toggleImg();
                              }}
                            ></img>
                          </div>
                        )}
                        {car.insuranceImage && (
                          <div className="angle-image-container">
                            <div
                              className="subscript"
                              style={{ width: "100px" }}
                            >
                              Insurance slip
                            </div>
                            <img
                              className="accident-image-container"
                              src={car.insuranceImage}
                              onClick={() => {
                                setCurUrl(car.insuranceImage);
                                toggleImg();
                              }}
                            ></img>
                          </div>
                        )}
                      </div>
                    )}
                    {car.vehicleDamageImages && (
                      <div className="accident-details-field">
                        <div className="subscript">
                          Third party vehicle damage
                        </div>
                        <div className="damage-images">
                          {car.vehicleDamageImages.map((img) => (
                            <img
                              className="accident-image-container"
                              src={img}
                              onClick={() => {
                                setCurUrl(img);
                                toggleImg();
                              }}
                            ></img>
                          ))}
                        </div>
                      </div>
                    )}
                  </div>
                ))}
            </div>
          )}
        </div>
      </Modal>
      <FullImageMessage isFull={isFull} imgUrl={curUrl} />
    </Fragment>
  );
};

export default AccidentReportModal;
