import React from "react";
import { titleize, renderColor } from "../../utils";

const SidePanel = ({
  userProfileData,
  activeSection,
  sections,
  selectSection,
}) => (
  <div className="user-side-panel">
    <div className="user-side-panel__header">
      <img
        className="user-side-panel__header--image"
        src={userProfileData.uberProfileImage}
      />
      <div className="user-side-panel__header--label">
        {userProfileData.firstName} {userProfileData.lastName}
      </div>
      <div className="subscript translucent">{userProfileData.id}</div>
    </div>
    <div className="user-side-panel__element">
      <div className="subscript translucent">Status</div>
      <div
        className={renderColor("Account Status", userProfileData.accountStatus)}
      >
        {titleize(userProfileData.accountStatus)}
      </div>
    </div>
    <div className="user-side-panel__element">
      <div className="subscript translucent">Mobile</div>
      <div className="">{userProfileData.mobile}</div>
    </div>
    <div className="user-side-panel__element">
      <div className="subscript translucent">Email</div>
      <div style={{ width: "140px", overflowWrap: "break-word" }}>
        {userProfileData.email}
      </div>
    </div>
    <div className="user-side-panel__element">
      <div className="subscript translucent">Driver rating</div>
      <div className="rating">N/A</div>
    </div>
    <div className="user-side-panel__divider"></div>
    {sections.map((section, index) => {
      return (
        <div
          className={`unselectable pointer user-side-panel__element${
            activeSection === section ? " selected" : " translucent"
          }`}
          onClick={() => selectSection(section)}
          key={index}
        >
          {titleize(section)}
        </div>
      );
    })}
  </div>
);

export default SidePanel;
