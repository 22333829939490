import React, { useState } from "react";
import styles from "./options.module.scss";
import Header from "./Header";
import Body from "./Body";
import Footer from "./Footer";
import _ from "lodash";
import classNames from "classnames";

import { useMutation } from "@apollo/client";
import { UPSERT_PRESET, DELETE_PRESET } from "../../../misc/gqlQueries";

const Options = ({ closeOptions, columns, changeColumnsValue, isOpen }) => {
  const [upsertPresetMutation, { loading: isUpsertPresetLoading }] =
    useMutation(UPSERT_PRESET);

  const [deletePresetMutation] = useMutation(DELETE_PRESET);

  //Used in place of updating cache or reloading page, both which have issues.
  const [newPresets, setNewPresets] = useState([]);
  const [deletedPresets, setDeletedPresets] = useState([]);

  const [tempPresetValue, setTempPresetValue] = useState();
  const { presets, available, current } = columns;

  const currentColumnNames = current.map((column) => column.name);

  const [selectedPreset, setSelectedPreset] = useState();
  const [selectedColumnNames, setSelectedColumnNames] =
    useState(currentColumnNames);

  const [presetMode, setPresetMode] = useState("default");

  const beginEditPreset = () => {
    setPresetMode("edit");
    setTempPresetValue(selectedPreset.name);
  };

  const beginCreatePreset = () => setPresetMode("create");

  const columnList = available.map((column) => {
    const isIn = _.includes(selectedColumnNames, column.name);
    return {
      ...column,
      isSelected: isIn,
    };
  });

  const submitChanges = () => {
    changeColumnsValue(selectedColumnNames);
    closeOptions();
  };

  const reset = () => {
    setSelectedColumnNames(currentColumnNames);
    setPresetMode("default");
    setSelectedPreset(null);
    setTempPresetValue(null);
  };

  const selectPreset = (preset) => {
    if (!selectedPreset || preset.uuid !== selectedPreset.uuid) {
      setSelectedPreset(preset);
      setSelectedColumnNames(preset.columnNames);
    } else {
      setSelectedPreset(null);
    }
  };

  const updatePreset = () => {
    upsertPreset({
      uuid: selectedPreset.uuid,
      name: tempPresetValue,
      columnNames: selectedColumnNames,
    });
  };

  const createPreset = () => {
    upsertPreset({
      name: tempPresetValue,
      columnNames: selectedColumnNames,
    });
  };

  const deletePreset = (preset) => {
    deletePresetMutation({
      variables: {
        uuid: preset.uuid,
      },
    }).then(() => {
      setDeletedPresets([...deletedPresets, preset]);
      reset();
    });
  };

  const upsertPreset = (columnPreset) => {
    upsertPresetMutation({
      variables: {
        tableName: "users",
        columnPreset: columnPreset,
      },
    }).then(({ data: { upsertColumnPreset } }) => {
      if (upsertColumnPreset.preset) {
        setNewPresets([...newPresets, upsertColumnPreset.preset]);
        setPresetMode("default");
        setSelectedPreset(upsertColumnPreset.preset);
        console.log("upsert succeed!");
      } else {
        console.log("upsert error!");
      }
    });
  };

  const changeSelectedColumnNames = (newNames) => {
    if (presetMode === "default") {
      setSelectedPreset(null);
    }
    setSelectedColumnNames(newNames);
  };
  const presetValues = () => {
    const deletedSummary = _.differenceBy(
      presets,
      deletedPresets,
      (preset) => preset.uuid
    );
    return _.uniqBy(
      [...deletedSummary, ...newPresets],
      (preset) => preset.uuid
    );
  };

  const rightButtonText = () => {
    switch (presetMode) {
      case "edit":
        return "Save";
      case "create":
        return "Save";
      case "default":
        return "Apply";
      default:
        return "Apply";
    }
  };

  const rightButtonFunction = () => {
    switch (presetMode) {
      case "edit":
        return updatePreset;
      case "create":
        return createPreset;
      case "default":
        return submitChanges;
      default:
        return submitChanges;
    }
  };

  const leftButtonFunction = () => {
    switch (presetMode) {
      case "edit":
      case "create":
        return reset;
      case "default":
        return closeOptions;
      default:
        return closeOptions;
    }
  };

  return (
    <div
      className={classNames(styles.absoluteContainer, !isOpen && styles.hidden)}
    >
      <div className={styles.optionsBox}>
        <Header closeOptions={closeOptions} />
        <Body
          presets={presetValues()}
          columnList={columnList}
          selectPreset={selectPreset}
          selectedPreset={selectedPreset}
          columnListNames={selectedColumnNames}
          setSelectedColumnNames={changeSelectedColumnNames}
          beginEditPreset={beginEditPreset}
          beginCreatePreset={beginCreatePreset}
          presetMode={presetMode}
          setTempPresetValue={setTempPresetValue}
          tempPresetValue={tempPresetValue}
          deletePreset={deletePreset}
        />
        <Footer
          leftButtonText={"Cancel"}
          leftButtonFunction={leftButtonFunction()}
          rightButtonText={rightButtonText()}
          rightButtonFunction={rightButtonFunction()}
        />
      </div>
    </div>
  );
};

export default Options;
