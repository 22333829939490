import React from "react";
import DetailField from "./DetailField";
import styles from "../userDetails.module.scss";
import { dateFormatter } from "./utils";
import moment from "moment-timezone";
import { titleize } from "../../../../utils";
const LicenseDetailContent = ({ user }) => {
  const licenseData = user && user.driversLicenseData;
  const timeZone = user && user.timeZone;
  const userSex = () => {
    if (licenseData?.sex === "1") {
      return "M";
    } else if (licenseData?.sex === "2") {
      return "F";
    } else return "-";
  };

  const isValidated = () => {
    if (licenseData?.validated) {
      return "Yes";
    } else return "No";
  };
  const driverName =
    licenseData?.firstName + " " + licenseData?.lastName || "-";
  const driverDateOfBirth = dateFormatter(licenseData?.dateOfBirth);
  const driverIssueDate = dateFormatter(licenseData?.issuanceDate);
  const driverExpiryDate = dateFormatter(licenseData?.expiryDate);
  const driverCreatedDate =
    moment(user.createdAt).tz(timeZone).format("MMM D, YYYY h:mm A z") || "-";

  return (
    <div className={styles.documentDetailContainer}>
      <div className={styles.detailOneThird}>
        <DetailField text={"Legal Name"} value={driverName} />
        <DetailField text={"Date of birth"} value={driverDateOfBirth} />
        <DetailField
          text={"Address line 1"}
          value={licenseData?.addressLine1}
        />
        <DetailField
          text={"Address line 2"}
          value={
            licenseData?.addressLine2 ||
            "" + " " + licenseData?.city ||
            "" + " " + licenseData?.province ||
            "" + " " + licenseData?.postalCode ||
            ""
          }
        />
        <DetailField text={"Height"} value={licenseData?.height} />
        <DetailField text={"Sex"} value={userSex()} />
        <DetailField
          text={"Eye color"}
          value={titleize(licenseData?.eyeColour)}
        />
      </div>
      <div className={styles.detailOneThird}>
        <DetailField
          text={"Driver's license number"}
          value={licenseData?.licenseNumber}
        />
        <DetailField text={"Inssuance date"} value={driverIssueDate} />
        <DetailField text={"Expiry date"} value={driverExpiryDate} />
        <DetailField text={"Class"} value={licenseData?.licenseClass} />
        <DetailField text={"Country"} value={licenseData?.country} />
        <DetailField text={"ID"} value={licenseData?.id} />
        <DetailField text={"Created at"} value={driverCreatedDate} />
      </div>
      <div className={styles.detailOneThird}>
        <DetailField text={"Endorsements"} value={licenseData?.endorsements} />
        <DetailField text={"Restrictions"} value={licenseData?.restrictions} />
        <DetailField
          text={"Inventory control number"}
          value={licenseData?.inventoryControlNumber}
        />
        <DetailField
          text={"Commercial vehicle codes"}
          value={licenseData?.commercialVehicleCodes}
        />
        <DetailField text={"Reference number"} value={licenseData?.refNumber} />
        <DetailField text={"Validated"} value={isValidated()} />
      </div>
    </div>
  );
};

export default LicenseDetailContent;
