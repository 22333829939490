import React, { useState } from "react";
import { useEffect } from "react";
import AutocompleteContent from "./AutocompleteContent";

const Autocomplete = ({ options, parentCallback, newTag, addable }) => {
  const [activeOption, setActiveOption] = useState(0);
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [showOptions, setShowOptions] = useState(false);
  const [userInput, setUserInput] = useState();

  const onChange = (e) => {
    let currentInput = e.target.value;
    setUserInput(currentInput);
    setFilteredOptions(
      options.filter((optionName) =>
        optionName
          .toLowerCase()
          .startsWith(currentInput && currentInput.toLowerCase())
      )
    );
    setActiveOption(0);
    setShowOptions(true);
    parentCallback(currentInput);
    e.preventDefault();
  };

  const onClick = (e) => {
    e.preventDefault();
    setActiveOption(0);
    setFilteredOptions([]);
    setShowOptions(false);
    setUserInput(e.currentTarget.innerText);
    parentCallback(e.currentTarget.innerText);
    e.preventDefault();
  };

  const enterKey = () => {
    setActiveOption(0);
    setShowOptions(false);
    setUserInput(filteredOptions[activeOption]);
  };

  const upKey = () => {
    if (activeOption === 0) {
      return;
    }
    setActiveOption(activeOption - 1);
    setUserInput(filteredOptions[activeOption - 1]);
  };

  const downKey = () => {
    if (activeOption + 1 === filteredOptions.length) {
      setActiveOption(filteredOptions.length - 1);
      setShowOptions(true);
      setUserInput(filteredOptions[filteredOptions.length - 1]);
    } else {
      setActiveOption(activeOption + 1);
      setUserInput(filteredOptions[activeOption + 1]);
    }
  };
  //responsive change on addable check
  useEffect(() => {
    parentCallback(userInput);
  }, [userInput]);

  const onKeyAction = (e) => {
    if (e.keyCode === 13) {
      enterKey();
    } else if (e.keyCode === 38) {
      upKey();
    } else if (e.keyCode === 40) {
      downKey();
    }
    parentCallback(e.target.value);
  };

  const enterToAdd = () => {
    parentCallback(userInput);
  };

  useEffect(() => {
    if (newTag === "") {
      setUserInput("");
    }
  }, [newTag]);

  //determine the style of the match/unmatch tag options
  const renderOption = (option) => {
    let input = userInput;
    let index = option.toLowerCase().indexOf(input.toLowerCase());
    if (index !== -1) {
      let length = input.length;
      let match = option.substring(index, index + length);
      let suffix = option.substring(index + length);
      return (
        <span>
          <span className="autocomplete-match">{match}</span>
          {suffix}
        </span>
      );
    }
    return <span>{option}</span>;
  };

  return (
    <div>
      <AutocompleteContent
        activeOption={activeOption}
        filteredOptions={filteredOptions}
        showOptions={showOptions}
        userInput={userInput}
        onChange={onChange}
        onClick={onClick}
        onKeyAction={onKeyAction}
        enterToAdd={enterToAdd}
        newTag={newTag}
        renderOption={renderOption}
        addable={addable}
      />
    </div>
  );
};

export default Autocomplete;
