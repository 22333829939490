import React, { Component } from "react";
import { ValidatorForm } from "react-form-validator-core";
import TextField from "../../artzu-ui/src/TextField";
import TextInput from "./TextInput";
import CreateAdminSession from "../../Mutations/CreateAdminSession";
import AutzuLogoLayout from "./AutzuLogoLayout";
import "./login.css";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      error: "",
      loggedIn: false,
      loading: false,
    };
  }

  displayError = (error) => {
    this.setState({ error });
  };

  handleChange = ({ target }) => {
    const name = target.name;
    const val = target.value;

    this.setState({ [name]: val });
  };

  handleAuthAttempt = (success) => {
    if (success) {
      // redirect
      this.props.location.state
        ? window.location.assign(this.props.location.state.from)
        : this.props.history.push("/");
    } else {
      this.setState({ loading: false, error: "Invalid credentials" });
    }
  };

  render() {
    const { email, password, error } = this.state;
    return (
      <AutzuLogoLayout>
        <ValidatorForm instantValidate={false} onSubmit={() => console.log("")}>
          <div className="sign-in-field">
            <TextInput
              name="email"
              value={email}
              handleChange={this.handleChange}
            />
            <TextInput
              name="password"
              type="password"
              value={password}
              handleChange={this.handleChange}
            />
            <p className="login-error">{error}</p>
            <CreateAdminSession
              email={email}
              password={password}
              callback={this.handleAuthAttempt}
              loading={() => this.setState({ loading: true })}
              displayError={this.displayError}
            />
          </div>
        </ValidatorForm>
      </AutzuLogoLayout>
    );
  }
}
export default Login;
