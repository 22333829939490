import React from 'react'
import styled from 'styled-components'
import { getSelectedBorder, isSelected } from '../SelectField/selectHelpers'

const StyledOption = styled.div`
	width: 100%;
	cursor: pointer;
	padding: 0.5em 1em;
	font-weight: 500;
	display: flex;
	justify-content: space-between;
	align-items: center;
	${({
		selectedValue,
		value,
		primaryColor,
		theme,
		secondaryColor = theme.darkGrey,
		index,
		length
	}) =>
		getSelectedBorder(
			isSelected(selectedValue, value),
			primaryColor,
			secondaryColor,
			index,
			length
		)}
`

const Option = ({ label = '', value = null, newSelection, ...props }) => (
	<StyledOption
		{...props}
		value={value}
		onClick={() => newSelection(label, value)}>
		{label}
	</StyledOption>
)

export default Option
