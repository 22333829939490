import React from "react";
import Modal from "../../../../artzu-ui/src/Modal";
import styles from "../../table.module.scss";

const SelectAllConfirmModal = ({
  open,
  rowCount,
  batchAction,
  toggleConfirmModal,
  batchActionFun,
  toggleFeedbackModal,
  clearAllSelections,
  close,
}) => {
  return (
    <Modal open={open} backgroundColor="none">
      <div className={styles.confirmCheck}>
        <div style={{ marginBottom: "10px" }}>
          Please confirm that you are using select all to select{" "}
          <span style={{ fontWeight: 600 }}>{rowCount}</span> users for this{" "}
          <span style={{ fontWeight: 600 }}>{batchAction}</span> batch action.
        </div>
        <div className={styles.actionButtons}>
          <div>
            <button
              className={styles.cancelButton}
              onClick={() => {
                toggleConfirmModal();
              }}
            >
              No
            </button>
          </div>
          <div>
            <button
              className={styles.saveButton}
              onClick={() => {
                batchActionFun();
                clearAllSelections();
                toggleConfirmModal();
                toggleFeedbackModal();
                close();
              }}
            >
              Yes
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default SelectAllConfirmModal;
