import Dropdown from "../../../InformationTable/ActionBar/Filters/OptionsModal/Body/Filter/Dropdown";
import DatePicker from "react-datepicker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import setHours from "date-fns/setHours";
import setMinutes from "date-fns/setMinutes";
import { faCalendar } from "@fortawesome/pro-regular-svg-icons";
import React, { Fragment } from "react";
import _ from "lodash";
import "react-datepicker/dist/react-datepicker.css";

const BookingSearchArguments = ({
  bookingConfiguration,
  handleSearch,
  changeVehicleClass,
  vehicleClass,
  changeDuration,
  duration,
  changeParkingLot,
  parkingLot,
  changeSelectedDate,
  selectedDate,
  changeInsuranceDeduction,
  deductibleReductionSelected,
}) => {
  const allParkingLots = bookingConfiguration.parkingLots.map((lot) => ({
    name: lot.name,
    value: lot.id,
  }));

  const selectedParkingLot = bookingConfiguration.parkingLots.find(
    (lot) => lot.id === parkingLot
  );

  const allTiers = selectedParkingLot
    ? selectedParkingLot.availableTiers.map((tier) => ({
        name: _.startCase(tier),
        value: tier,
      }))
    : [];

  const allDurations = bookingConfiguration.possibleShiftLengths.map(
    (duration) => ({ name: duration, value: duration })
  );

  const possibleShiftTimes = bookingConfiguration.shiftTimes.map((hour) => {
    return setHours(setMinutes(new Date(), 0), hour);
  });

  const insuranceDeductionOptions = [
    {
      name: "Yes",
      value: true,
    },
    {
      name: "No",
      value: false,
    },
  ];

  const ableToSearch =
    vehicleClass &&
    selectedDate &&
    duration &&
    parkingLot &&
    _.includes(
      possibleShiftTimes.map((time) => time.getHours()),
      selectedDate.getHours()
    );

  return (
    <Fragment>
      <div style={{ marginBottom: "12px" }}>
        <div className="subscript" style={{ marginBottom: "-5px" }}>
          Parking Lot
        </div>
        <Dropdown
          initialValue={parkingLot}
          items={allParkingLots}
          onChange={changeParkingLot}
          width="210px"
          height="32px"
        />
      </div>
      <div style={{ marginBottom: "12px" }}>
        <div className="subscript" style={{ marginBottom: "-5px" }}>
          Tier
        </div>
        <Dropdown
          disabled={!selectedParkingLot}
          initialValue={vehicleClass}
          items={allTiers}
          onChange={changeVehicleClass}
          width="210px"
          height="32px"
        />
      </div>
      <div style={{ marginBottom: "12px" }}>
        <div className="subscript">Start date and time</div>
        <div className="booking-datepicker-container">
          <DatePicker
            selected={selectedDate}
            onChange={(date) => {
              changeSelectedDate(date);
            }}
            dateFormat="MMM d, yyyy h:mm a"
            todayButton={"Today"}
            placeholderText="Select date"
            popperPlacement="bottom"
            className="booking-date-input"
            showTimeSelect
            includeTimes={possibleShiftTimes}
            timeIntervals={60}
            timeFormat="h a"
          />
          <FontAwesomeIcon
            icon={faCalendar}
            className="booking-calendar-icon"
            size="lg"
          />
        </div>
      </div>
      <div style={{ marginBottom: "12px" }}>
        <div className="subscript" style={{ marginBottom: "-5px" }}>
          Duration
        </div>
        <Dropdown
          initialValue={duration}
          items={allDurations}
          onChange={changeDuration}
          width="210px"
          height="32px"
        />
      </div>
      <div style={{ marginBottom: "12px" }}>
        <div className="subscript" style={{ marginBottom: "-5px" }}>
          Deductible Reduction
        </div>
        <Dropdown
          initialValue={deductibleReductionSelected}
          items={insuranceDeductionOptions}
          onChange={changeInsuranceDeduction}
          width="210px"
          height="32px"
        />
      </div>
      <button
        className="car-search-button"
        onClick={() => {
          ableToSearch && handleSearch();
        }}
        style={{ color: ableToSearch ? "#128dff" : "#87cefa" }}
      >
        Search
      </button>
    </Fragment>
  );
};

export default BookingSearchArguments;
