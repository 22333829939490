import { useMutation } from "@apollo/client";
import React, { useState } from "react";
import { Fragment } from "react";
import Modal from "../../../../artzu-ui/src/Modal";
import ErrorModal from "../../../Payments/Invoices/ActionPopup/ErrorModal";
import styles from "./importPopup.module.scss";
import { equals } from "lodash/fp";
import { PARSE_CSV } from "./queries";
import CsvDataTable from "./CsvDataTable";

const ImportPopup = ({ open, togglePopup, csvType }) => {
  const [files, setFiles] = useState([]);
  const [file, setFile] = useState();
  const [isError, setIsError] = useState();
  const [errorMsg, setErrorMsg] = useState();
  const [isTable, setIsTable] = useState();
  const [curData, setData] = useState();
  const [curHeader, setHeader] = useState();

  const handleFileChange = (e) => {
    // let newFiles = [...files];
    // let duplicate = {};
    // if (newFiles.length > 0) {
    //   duplicate = files.find((doc) => doc.name === e.target.files[0].name);
    // }
    // if (equals(duplicate, {}) || duplicate === undefined) {
    //   newFiles.push(e.target.files[0]);
    // }
    // setFiles(newFiles);
    setFile(e.target.files[0]);
  };

  const toggleErrorModal = () => {
    setIsError(!isError);
  };

  const toggleTableModal = () => {
    setIsTable(!isTable);
  };
  const [parseMutation] = useMutation(PARSE_CSV);
  const parseCSVFile = () => {
    parseMutation({
      variables: {
        file: file,
        csvType: csvType,
      },
    }).then(({ data: { parseCsv } }) => {
      if (parseCsv.csvData) {
        setHeader(parseCsv.headers);
        setData(parseCsv.csvData);
        toggleTableModal();
        console.log("parse succeed!");
      } else {
        toggleErrorModal();
        setErrorMsg(parseCsv.errors);
        console.log("parse error!");
      }
    });
  };

  return (
    <Fragment>
      <Modal open={open} backgroundColor="none">
        <div className={styles.importPopup}>
          <div className={styles.uploadContainer}>
            <span className={styles.importTitle}>Upload Your File </span>
            <input type="file" multiple onChange={handleFileChange} />
            <div className={styles.importFileList}>
              Chosen file:
              {/* <ul>
                {files.map((doc) => (
                  <li style={{ marginBottom: "5px" }}>{doc.name}</li>
                ))}
              </ul> */}
            </div>
            <span>{file && file.name}</span>
          </div>
          <div className={styles.actionButtons}>
            <div>
              <button
                className={styles.cancelButton}
                onClick={() => {
                  togglePopup();
                  setFile();
                }}
              >
                Cancel
              </button>
            </div>
            <div>
              <button
                className={styles.saveButton}
                onClick={() => {
                  togglePopup();
                  parseCSVFile();
                  // toggleTableModal();
                  setFile();
                }}
              >
                Upload
              </button>
            </div>
          </div>
        </div>
      </Modal>
      <ErrorModal
        open={isError}
        errorMsg={errorMsg}
        toggleErrorModal={toggleErrorModal}
      />
      <CsvDataTable
        open={isTable}
        toggleTableModal={toggleTableModal}
        csvData={curData}
        csvHeader={curHeader}
        csvType={csvType}
      />
    </Fragment>
  );
};

export default ImportPopup;
