import React from "react";
import { useQuery } from "@apollo/client";
import { BOOKING_DETAILS } from "../queries";
import Loading from "../../../Shared/Loading";
import { Redirect } from "react-router-dom";
import BookingBody from "./BookingBody";

const BookingPage = ({ id, userId }) => {
  const { data, loading, error } = useQuery(BOOKING_DETAILS, {
    variables: { id: id },
    fetchPolicy: "network-only",
  });

  if (loading) return <Loading />;
  if (error) return null;

  console.log("data", data);
  if (
    data.bookingDetails &&
    parseInt(data.bookingDetails.driver.id) === parseInt(userId)
  ) {
    return <BookingBody booking={data.bookingDetails} />;
  } else {
    return <Redirect to={`/users/${userId}/bookings`} />;
  }
};

export default BookingPage;
