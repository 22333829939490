import React from 'react'
import styled from 'styled-components'
import { getColor, getSize, isDisabled } from './buttonHelpers.js'

const StyledButton = styled.button`
	${({ primary, theme, color = theme.main }) => getColor(primary, color)}
	${({ size = 'reg' }) => getSize(size)}
	${({ disabled }) => isDisabled(disabled)}
	border-radius: ${({ radius }) => radius || '50px'};
	margin: ${({ margin }) => margin || '0'};
	padding: ${({ padding }) => padding || '0.36em 1em'};
	font-weight: 500;
	width: ${({ fullWidth }) => (fullWidth ? '100%' : '8.5em')};
	transition: 0.25s;
	&:focus {
		outline: none;
	}
`

const Button = ({ label = '', ...props }) => (
	<StyledButton {...props}>{label}</StyledButton>
)

export default Button
