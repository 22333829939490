import React, { useState } from "react";
import styles from "./batchActions.module.scss";
import {
  faCommentAltLines,
  faAlarmClock,
  faTag,
  faRedo,
  faUsers,
} from "@fortawesome/pro-solid-svg-icons";
import ActionButton from "./ActionButton";
import _ from "lodash";
import TicketActionModal from "./TicketActionModal";

const CommunicationBatchActions = ({
  isOpen,
  selectedRows,
  clearAllSelections,
  tabs,
  refetch,
}) => {
  const [selectedBatchAction, setSelectedBatchAction] = useState();

  const allIcon = [
    {
      value: "addComment",
      text: "Add comment",
      icon: faCommentAltLines,
    },
    {
      value: "addReminder",
      text: "Add reminder",
      icon: faAlarmClock,
    },
    {
      value: "addTag",
      text: "Add tag",
      icon: faTag,
    },
  ];

  const unassignedIcon = [
    {
      value: "addComment",
      text: "Add comment",
      icon: faCommentAltLines,
    },
    {
      value: "addReminder",
      text: "Add reminder",
      icon: faAlarmClock,
    },
    {
      value: "addTag",
      text: "Add tag",
      icon: faTag,
    },
    {
      value: "assignToAgent",
      text: "Assign to agent",
      icon: faUsers,
    },
  ];

  const inProgressIcon = [
    {
      value: "addComment",
      text: "Add comment",
      icon: faCommentAltLines,
    },
    {
      value: "addReminder",
      text: "Add reminder",
      icon: faAlarmClock,
    },
    {
      value: "addTag",
      text: "Add tag",
      icon: faTag,
    },
    {
      value: "reassignAgent",
      text: "Re-assign agent",
      icon: faUsers,
    },
  ];

  const deletedIcon = [
    {
      value: "addComment",
      text: "Add comment",
      icon: faCommentAltLines,
    },
    {
      value: "addReminder",
      text: "Add reminder",
      icon: faAlarmClock,
    },
    {
      value: "addTag",
      text: "Add tag",
      icon: faTag,
    },
    {
      value: "recoverTickets",
      text: "Recover tickets",
      icon: faRedo,
    },
  ];

  const icons =
    tabs.currentTab === "deleted"
      ? deletedIcon
      : tabs.currentTab === "unassigned"
      ? unassignedIcon
      : tabs.currentTab === "in_progress"
      ? inProgressIcon
      : allIcon;

  const selectedTickets = Object.keys(_.pickBy(selectedRows));

  const batchOptionsHash = _.reduce(
    icons,
    (h, icon) => {
      h[icon.value] = icon;
      return h;
    },
    {}
  );

  const closeModal = () => setSelectedBatchAction(null);

  return (
    <>
      {isOpen && (
        <div className={styles.batchActions}>
          <div className={styles.batchActionButtons}>
            {icons.map((icon) => (
              <ActionButton
                icon={icon.icon}
                text={icon.text}
                onClick={() => setSelectedBatchAction(icon.value)}
              />
            ))}
          </div>
          <TicketActionModal
            isOpen={selectedBatchAction}
            selectedBatchAction={selectedBatchAction}
            selectedTickets={selectedTickets}
            batchOptionsHash={batchOptionsHash}
            closeModal={closeModal}
            toggleRefresh={refetch}
            clearAllSelections={clearAllSelections}
          />
        </div>
      )}
    </>
  );
};

export default CommunicationBatchActions;
