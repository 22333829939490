import React from "react";
import { useQuery } from "@apollo/client";
import styles from "../bookingActions.module.scss";
import classNames from "classnames";
import { AVAILABLE_VEHICLES } from "../../../queries";
import VehicleSwitchContainer from "./VehicleSwitchContainer";
import Loading from "../../../../../Shared/Loading";

const ChangeVehicle = ({ booking, closeBookingActions }) => {
  const { loading: isAvailableVehiclesLoading, data } = useQuery(
    AVAILABLE_VEHICLES,
    {
      variables: {
        bookingId: booking.id,
      },
    }
  );

  return (
    <div
      className={classNames(
        styles.actionContainer,
        styles.switchVehiclesContainer
      )}
    >
      {isAvailableVehiclesLoading ? (
        <Loading />
      ) : (
        <VehicleSwitchContainer
          booking={booking}
          availableVehicles={data.availableVehicles}
          closeBookingActions={closeBookingActions}
        />
      )}
    </div>
  );
};

export default ChangeVehicle;
