import React, { useState } from 'react';
import styles from "../../userDashboard.module.scss";
import StatusChangePopup from './StatusChangePopup';
import Modal from '../../../../../artzu-ui/src/Modal';
import { Removed } from '../../utils';
import Dropdown from '../../../../InformationTable/ActionBar/Filters/OptionsModal/Body/Filter/Dropdown';

const StatusMenu = ({userData}) => {

    const [showMenu, setShowMenu] = useState(false);
    const [showModal, setShowModal] = useState(false)
    const [showPopup, setShowPopup] = useState(false);
    const [selectedOption, setSelectedOption] = useState("");

    const options = [  
    //name = dropdown name | value = popup message
    !Removed(userData.accountStatus) && 
    { name: 'Close', value: 'close' },

    userData.accountStatus != "deleted" && userData.accountStatus != "blacklisted" && 
    { name: 'Blacklist', value: 'blacklist' },

    userData.accountStatus != "deleted" && 
    { name: 'Delete', value: 'delete' },

    userData.accountStatus == "deleted" && 
    { name: 'Recover', value: 'recover' },

    userData.accountStatus == "blacklisted" && 
    { name: 'Un-blacklist', value: 'un-blacklist' },

    ].filter(Boolean);

    const toggleMenu = () => {
        setShowMenu(!showMenu);
    };

    const toggleModal = () => {
        setShowModal(!showModal);
    };

    const togglePopup = () => {
        if (selectedOption){
            setShowPopup(!showPopup);
        }
    };

    const handleChange = (value) => {
        setSelectedOption(value);
    };

    return (
        <div className={styles.menuContainer}>
            <div className={styles.menuButton} onClick={toggleMenu}>⋮</div>
            {showMenu && 
                <div className={styles.dropdownMenu}>
                    <div className={styles.menuItem} onClick={() => {
                        toggleMenu();
                        toggleModal();
                    }}>
                    Change status
                    </div>
                </div>
            }
            <Modal open={showModal} backgroundColor="none">
                <div className={styles.statusChangeModal}>
                    <div className={styles.popupHead}>Change status</div>
                <div style={{marginBottom:"80px"}}>
                    <Dropdown
                    initialValue={""}
                    items={options}
                    onChange={handleChange}
                    width="400px"
                    optionWidth={"416px"}
                    height="32px"
                    label={"Status"}
                    />
                    </div>
                    <div className={styles.buttonsFooter}>
                        <div className={styles.cancelButton}
                            onClick={toggleModal}
                        >Cancel</div>
                        <div className={styles.confirmButton}
                            onClick={togglePopup}
                        >Save</div>
                    </div>

                </div>
            </Modal>
            <StatusChangePopup
                open = {showPopup}
                userData = {userData}
                changeMessage={selectedOption}
                togglePopup={togglePopup}
            />
        </div>
    );


}

export default StatusMenu;