import React, { Fragment, useState } from "react";
import robotIcon from "../../../../../images/autzu-robot.jpg";
import PreBookingInspectionData from "../../../../../Queries/PrebookingInspectionData";
import VehicleChangeData from "../../../../../Queries/VehicleChangeData";
import styles from "./bookingTimeline.module.scss";
import { TableRow, TableCell } from "../../../../Shared/Table";
import Action from "./Action";
import moment from "moment-timezone";

const BookingTimeline = ({ booking }) => {
  const [isInspection, setIsInspection] = useState(false);
  const [inspectionId, setInspectionId] = useState();
  const [inspectionType, setInspectionType] = useState();

  const timeZone = booking.timeZone;

  const toggleInspection = () => {
    setIsInspection(!isInspection);
  };

  const curInspecting = (child) => {
    setInspectionId(child);
  };

  const setType = (child) => {
    setInspectionType(child);
  };

  console.log("booking", booking);

  return (
    <Fragment>
      <TableRow
        justify="space-between"
        align="center"
        padding="12px 0px"
        borderB="2px solid #414141"
      >
        <TableCell width="20%" align={"left"} bold>
          Time
        </TableCell>
        <TableCell width="20%" align={"left"} bold>
          Action
        </TableCell>
        <TableCell width="20%" align={"left"} bold>
          User
        </TableCell>
        <TableCell width="30%" align={"left"} bold>
          Comment
        </TableCell>
      </TableRow>
      {booking.timeline &&
        booking.timeline.map((tl, i) => {
          return (
            <div>
              <TableRow
                justify="space-between"
                align="center"
                padding="12px 0px"
                borderT="1px solid #dedede"
              >
                <TableCell width="20%" align={"left"}>
                  {moment(tl.timestamp)
                    .tz(timeZone)
                    .format("MMM D, YYYY h:mm:ss A z")}
                </TableCell>
                <Action
                  tl={tl}
                  toggleInspection={toggleInspection}
                  curInspecting={curInspecting}
                  setType={setType}
                />
                <TableCell width="20%" display={"flex"} justify={"start"}>
                  <img
                    className={styles.avatarContainer}
                    src={(tl.actorIcon && tl.actorIcon) || robotIcon}
                  />
                  <div style={{alignContent:"center"}}>
                  {tl.actorName}
                  </div>
                </TableCell>
                <TableCell width="30%" align={"left"}>
                  {tl.comment}
                </TableCell>
              </TableRow>
            </div>
          );
        })}
      {inspectionType === "prebooking" && (
        <PreBookingInspectionData
          isInspection={isInspection}
          toggleInspection={toggleInspection}
          eventId={inspectionId}
        />
      )}
      {inspectionType === "carChange" && (
        <VehicleChangeData
          isInspection={isInspection}
          toggleInspection={toggleInspection}
          eventId={inspectionId}
        />
      )}
    </Fragment>
  );
};

export default BookingTimeline;
