import React, { Component } from "react";
import gql from "graphql-tag";
// import Plot from "../Components/Analytics/Plot";
import PlotHook from "../Components/Analytics/PlotHook";
import { analyticsString } from "./analyticsQueryStrings";
import Loading from "../Components/Shared/Loading";
import { titleToCamel } from "../utils";
import { Query } from "@apollo/client/react/components";

class AnalyticData extends Component {
  render() {
    const { analyticParams } = this.props;
    return (
      <Query
        query={gql`
          ${analyticsString}
        `}
        fetchPolicy="network-only"
        variables={{
          analyticParams,
        }}
      >
        {({ loading, error, data, refetch }) => {
          if (error) {
            return null;
          } else if (loading) {
            return (
              <div className="loading-plot">
                <Loading />
              </div>
            );
          }

          if (data.analyticData) {
            return (
              <PlotHook
                {...this.props}
                queriedData={data.analyticData}
                refetch={refetch}
              />
            );
          } else {
            return null;
          }
        }}
      </Query>
    );
  }
}

export default AnalyticData;
