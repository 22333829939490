import VehicleImage from "./VehicleImage";
import VehicleData from "./VehicleData";
import React from "react";

const AvailableVehicles = ({
  vehicles,
  selectNewCar,
  newCar,
  chargeLevelList,
}) => (
  <div className="available-vehicles">
    {vehicles.map((v, i) => {
      return (
        <div
          className={`available-vehicle${
            v.id === newCar.id ? " inverted" : ""
          }`}
          key={i}
          onClick={() => selectNewCar(v)}
        >
          <VehicleImage imageLink={v.imageLink} />
          <VehicleData title="Model" value={v.model} />
          <VehicleData title="Plate" value={v.plate} />
          <VehicleData title="Spot" value={v.parkingSpot} />
          <VehicleData
            title="Battery Level"
            value={chargeLevelList && chargeLevelList[i]}
          />
        </div>
      );
    })}
  </div>
);

export default AvailableVehicles;
