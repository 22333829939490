import React, { Component } from "react";
import Input from "@material-ui/core/Input";
import TextField from "@material-ui/core/TextField";
import { withStyles } from "@material-ui/core/styles";

import { faSearch } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "./search.css";

class SearchBar extends Component {
  constructor() {
    super();
    this.state = {};
  }

  render() {
    const { classes, onChange, placeholder, color, value, refetch, searchFilter, submit } = this.props;
    return (
      <div className={classes.search}>
        <div className={classes.searchIcon} style={{opacity: "0.5"}}>
          <FontAwesomeIcon className="search-icon" icon={faSearch} />
        </div>
        <span className="search-input">
          <Input
            placeholder={placeholder}
            disableUnderline
            value={value}
            onChange={evt => onChange(evt.target.value)}
            onKeyPress={evt => {
              if (evt.key === "Enter") {
                if (refetch) {
                  refetch(value.length > 0 ? {uuid: searchFilter.uuid, name: searchFilter.name, command: searchFilter.command, value1: value, exclude: false} : {})
                } else {
                  submit()
                }
              }
            }}
            className={classes.input}
            classes={{
              root: classes.inputRoot,
              input: classes.inputInput
            }}
          />
        </span>
      </div>
    );
  }
}

const styles = theme => ({
  search: {
    position: "relative",
    marginLeft: 0,
    height: "100%",
    display: "flex",
    alignItems: "center"
  },
  searchIcon: {
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "0",
    // marginLeft: "0.5em",
    color: "#414141"
  },
  inputRoot: {
    color: "inherit",
    width: "100%",
    height: "100%",
    border: "none"
  },
  inputInput: {
    width: "100%",
    padding: "0",
    fontSize: "inherit",
    color: "#414141"
  }
});

export default withStyles(styles)(SearchBar);
