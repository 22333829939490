import React from "react";
import Modal from "../../../../artzu-ui/src/Modal";

const RejectConfirmation = ({
  isReject,
  docToReject,
  profileRejected,
  licenseRejected,
  toggleReject,
  handleUpdate,
}) => {
  const rejectButtonFunction = () => {
    if (docToReject === "profile" && !profileRejected) {
      return () => profileRejectHelper();
    } else if (docToReject === "license" && !licenseRejected) {
      return () => licenseRejectHelper();
    }
  };

  const profileRejectHelper = () => {
    handleUpdate({
      profileImageVerification: "rejected",
    });
    toggleReject();
  };

  const licenseRejectHelper = () => {
    handleUpdate({
      driversLicenseVerification: "rejected",
    });
    toggleReject();
  };
  return (
    <Modal open={isReject} backgroundColor="none">
      <div className="delete-confirmation">
        <div className="deleteMsg">
          Are you sure you want to reject this document?
        </div>
        <div className="actionButtons">
          <div>
            <button className="delete-no" onClick={toggleReject}>
              No
            </button>
          </div>
          <div>
            <button className="delete-yes" onClick={rejectButtonFunction()}>
              Yes
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default RejectConfirmation;
