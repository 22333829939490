import React from 'react'

const SvgRedBang = ({ color = 'red', ...props }) => (
  <svg data-name="Layer 1" viewBox="0 0 120.37 132" {...props}>
    <circle
      cx={60.19}
      cy={60.19}
      r={56.69}
      fill="none"
      stroke={color}
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={7}
    />
    <text
      transform="translate(49.14 90.97)"
      fontSize={85.3}
      fill="red"
      fontFamily="HelveticaNeue,Helvetica Neue">
      {'!'}
    </text>
  </svg>
)

export default SvgRedBang
