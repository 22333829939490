import React from "react";
import BlueRadio from "../../Shared/BlueRadio";

const ChartType = ({ plotFormats, invalidPlotFormat, plotFormat, setPlotFormat }) => {
  return (
    <div className="dataset-options__field">
      <label>Chart Type</label>
      <div className="plot-options">
        {plotFormats.map((pf, i) => {
          return (
            <div className={`plot-format-option${invalidPlotFormat(pf.format) ? " translucent" : ""}`} key={i}>
              <img src={pf.img} />
              <div style={{ display: "flex", alignItems: "center" }}>
                <BlueRadio
                  disabled={invalidPlotFormat(pf.format)}
                  value={pf.format}
                  size="small"
                  checked={plotFormat === pf.format}
                  onChange={e => setPlotFormat(e.target.value)}
                />
                <div>{pf.label}</div>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  );
};

export default ChartType;
