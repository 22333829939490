import React, { Component } from 'react'
import styled, { css, keyframes } from 'styled-components'
import { getColor } from './selectHelpers.js'

const extend = keyframes`
  from {
    max-height: 0;
    overflow: hidden;
  }

  to {
    max-height: 15em;
    overflow: auto;
  }
`

const shrink = keyframes`
  from {
    max-height: 15em;
    overflow: auto;
  }

  to {
    max-height: 2.1em;
    overflow: hidden;
  }
`

const StyledOptionsBox = styled.div`
	${({ theme, primaryColor = theme.main }) => getColor(false, primaryColor)}
 	border-color: ${({ theme, primaryColor = theme.main }) => primaryColor};
  border-style: solid;
  border-width: 2px;
	border-radius: 20px;

	position: absolute;
	width: ${({ width }) => width};
	margin: 0.5em auto;
	font-weight: 500;
	min-height: 2.2em;
	cursor: pointer;
	overflow: auto;
	z-index: 100;
	animation: ${({ exiting }) =>
		exiting
			? css`
					${shrink} 0.3s
			  `
			: css`
					${extend} 0.7s
			  `};
`

class OptionsBox extends Component {
	componentDidMount() {
		document.addEventListener('click', this.props.closeOptions)
	}

	componentWillUnmount() {
		document.removeEventListener('click', this.props.closeOptions)
	}

	render() {
		const { children, ...props } = this.props
		return <StyledOptionsBox {...props}>{children}</StyledOptionsBox>
	}
}

export default OptionsBox
