import React, { useState } from "react";
import styles from "../optionsBody.module.scss";
import classNames from "classnames";
import _ from "lodash";
import CheckBox from "../../../../../../Shared/CheckBox";

const Available = ({ columnList, columnListNames, setSelectedColumnNames }) => {
  const generateOnClick = (name, isSelected) => {
    let newColumns = null;
    if (isSelected) {
      newColumns = _.filter(columnListNames, (column) => name !== column);
    } else {
      newColumns = [...columnListNames, name];
    }
    return () => setSelectedColumnNames(newColumns);
  };

  return (
    <div className={styles.column}>
      <div className={styles.columnHeader}>
        <div className={styles.columnHeaderText}>Columns</div>
      </div>
      <div className={styles.columnBody}>
        {columnList.map((column) => {
          const { name, displayedName, isSelected } = column;
          return (
            <div className={classNames(styles.columnOption)}>
              <CheckBox
                onClick={generateOnClick(name, isSelected)}
                isSelected={isSelected}
              />
              <div className={styles.name}>{displayedName}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Available;
