import React, { useState } from "react";
import "./notes.css";
import { reorderNotes } from "../../../../../../utils";
import _ from "lodash";
import ShowMoreNote from "./ShowMoreNote";
import NotesDisplay from "./NotesDisplay";

const Notes = ({ notes = [], timeZone }) => {
  const [all, setAll] = useState(false);
  const [text, setText] = useState("Show more...");
  const [selectedNote, setSelectedNote] = useState(null);
  const [selectedNoteDetails, setSelectedNoteDetails] = useState(null);

  const showAll = () => {
    setAll(true);
    setText("Show less");
  };

  const showLess = () => {
    setAll(false);
    setText("Show more...");
  };

  const selectNote = (note) => {
    setSelectedNote(note);
    setSelectedNoteDetails(note.details);
  };
  const resetNoteState = () => {
    setSelectedNote(null);
    setSelectedNoteDetails("");
  };

  const sortedNotes = reorderNotes(notes);

  const otherNotes = _.filter(sortedNotes, (note) => {
    return note.pinned !== true;
  });

  return (
    <div className="canvas">
      {sortedNotes.length > 0 ? (
        <div className="notesBox" style={{ minHeight: "304px" }}>
          <div
            className="addNoteTitle"
            style={{ marginLeft: "0", marginBottom: "24px" }}
          >
            All notes
          </div>
          <NotesDisplay
            otherNotes={otherNotes}
            all={all}
            selectedNote={selectedNote}
            resetNoteState={resetNoteState}
            selectNote={selectNote}
            timeZone={timeZone}
          />
        </div>
      ) : (
        <div className="unavailable-info">Not Available</div>
      )}
      <ShowMoreNote
        notes={notes}
        all={all}
        text={text}
        showAll={showAll}
        showLess={showLess}
      />
    </div>
  );
};

export default Notes;
