import { useQuery } from "@apollo/client";
import React, { Fragment, useEffect, useState } from "react";
import { GET_USER_INFO } from "./queries";
import TicketLeft from "./TicketLeftContent/TicketLeft";
import TicketRight from "./TicketRightContent/TicketRight";
import TicketTop from "./TicketTopContent/TicketTop";

const TicketPageContent = ({
  ticketData,
  params,
  refreshTicketCallback,
  defaultAdmin,
  token,
  presetDriver,
}) => {
  const [userId, setUserId] = useState(presetDriver ? presetDriver : undefined);
  const [bookingId, setBookingId] = useState();
  const [isEdit, setIsEdit] = useState();
  const [isCancel, setIsCancel] = useState();

  const userIdCallback = (childData) => {
    setUserId(childData);
  };

  const bookingIdCallback = (childData) => {
    setBookingId(childData);
  };

  const toggleEdit = () => {
    setIsEdit(!isEdit);
  };

  const cancelEditCallback = () => {
    setIsCancel(true);
  };

  const { data, loading } = useQuery(GET_USER_INFO, {
    variables: { id: presetDriver ? presetDriver : userId && userId },
  });

  return (
    <Fragment>
      <TicketTop
        ticketData={ticketData}
        refreshTicketCallback={refreshTicketCallback}
      />
      <div className="main-ticket">
        <TicketLeft
          params={params}
          ticketData={ticketData}
          refreshTicketCallback={refreshTicketCallback}
          userId={userId}
          bookingId={bookingId}
          defaultAdmin={defaultAdmin}
          isEdit={isEdit}
          toggleEdit={toggleEdit}
          cancelEditCallback={cancelEditCallback}
          token={token}
        />
        <TicketRight
          params={params}
          ticketData={ticketData}
          refreshTicketCallback={refreshTicketCallback}
          userIdCallback={userIdCallback}
          relatedUserData={data && data.userProfileData}
          userId={userId}
          bookingIdCallback={bookingIdCallback}
          isEdit={isEdit}
          isCancel={isCancel}
          defaultAdmin={defaultAdmin}
          presetDriver={presetDriver}
        />
      </div>
    </Fragment>
  );
};

export default TicketPageContent;
