import React from "react";
import classNames from "classnames";
import styles from "./baseValue.module.scss";
import _ from "lodash";

const RequirementValue = ({ cellData, classes, columnName }) => {
  const { text } = cellData;

  return (
    <div className={classNames(classes)}>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div className="array-cell">
          {text.map((value, index) => (
            <div className={styles.arrayCell}>{_.startCase(value)}</div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default RequirementValue;
