import {
  CellBlock,
  CircularStatsBar,
  WeekBarChart,
  lastBookingFormat,
} from "../utils";
import BookingsBlack from "../../../../assets/icons/BookingsBlack.svg";
import styles from "../userDashboard.module.scss";
import { Link } from "react-router-dom";

const BookingsTab = ({ data }) => {
  const timeZone = data.timeZone;

  return (
    <div className={styles.categoryTab}>
      <div className={styles.tabHeader}>
        <div className={styles.title}>
          <img src={BookingsBlack} />
          <div className={styles.textComponent}>
            Bookings
            {data.lifetimeHours ? (
              <div className={styles.datescript}>
                Last Booking:{" "}
                {lastBookingFormat(
                  data.lastBooking.startTime,
                  data.lastBooking.endTime,
                  timeZone
                )}
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
        <div>
          <Link
            to={{ pathname: `/users/${data.id}/bookings` }}
            style={{ textDecoration: "none", color: "#128dff" }}
          >
            See more
          </Link>
        </div>
      </div>
      {data.lifetimeHours ? (
        <div className={styles.tabContent}>
          <div className={styles.contentBlock}>
            <WeekBarChart weeklydata={data.bookingsThisWeekEachDay} />
            <div className={styles.footerCell}>
              <div className={styles.subscript}>
                <span style={{ fontWeight: "bold" }}>
                  {data.bookingsThisWeek} hours
                </span>{" "}
                booked this week
              </div>
              <div className={styles.miniscript}>
                Average of all Drivers: {data.avgBookingsThisWeekOfAllUsers}{" "}
                hours
              </div>
            </div>
          </div>
          <CellBlock subcontent="Average hours booked per week">
            <CircularStatsBar
              value={(data.avgHoursPerWeek / 80) * 100}
              count={80 / data.avgHoursPerWeekOfAllUsers}
              display={data.avgHoursPerWeek.toFixed(1)}
              miniDisplay={"hrs"}
              notchContent={`${data.avgHoursPerWeekOfAllUsers.toFixed(
                1
              )} hrs - Average of all Drivers`}
            />
          </CellBlock>
          <CellBlock
            subcontent={
              <div className={styles.footerCell}>
                <div className={styles.subscript}>Lifetime hours</div>
                <div className={styles.miniscript}>
                  Average of all Drivers: {data.lifetimeHoursOfAllUsers} hours
                </div>
              </div>
            }
          >
            <span className={styles.megascript}>
              {" "}
              {data.lifetimeHours ? data.lifetimeHours : 0}{" "}
            </span>
            <span className={styles.miniscript}> hrs</span>
          </CellBlock>
          <CellBlock subcontent="Promotion hours this week">
            <CircularStatsBar
              value={
                data.hoursThisWeek
                  ? ((data.hoursThisWeek /
                      data.avgHoursThisWeekOfAllUsers) *
                      100) /
                    3
                  : 0
              }
              count={1.5}
              display={
                data.hoursThisWeek
                  ? data.hoursThisWeek.toFixed(0)
                  : 0
              }
              miniDisplay={"hrs"}
              notchContent={`${data.avgHoursThisWeekOfAllUsers.toFixed(
                1
              )} hrs - Average of all Drivers`}
            />
          </CellBlock>
          <CellBlock subcontent="Average late time">
            <CircularStatsBar
              value={
                data.avgLateTime
                  ? ((data.avgLateTime / data.avgLateTimeOfAllUsers) * 100) / 3
                  : 0
              }
              count={3}
              display={data.avgLateTime ? data.avgLateTime.toFixed(0) : 0}
              miniDisplay={"min"}
              notchContent={`${data.avgLateTimeOfAllUsers.toFixed(
                1
              )} mins - Average of all Drivers`}
            />
          </CellBlock>
          <CellBlock subcontent="Cancellations">
            <CircularStatsBar
              value={data.cancellationRate ? data.cancellationRate * 100 : 0}
              count={1 / data.avgCancellationRateOfAllUsers}
              display={
                data.cancellationRate
                  ? (data.cancellationRate * 100).toFixed(0)
                  : 0
              }
              miniDisplay={"%"}
              notchContent={`${(
                data.avgCancellationRateOfAllUsers * 100
              ).toFixed(1)}% - Average of all Drivers`}
            />
          </CellBlock>
        </div>
      ) : (
        <div className={styles.nullItem}>No bookings</div>
      )}
    </div>
  );
};

export { BookingsTab };
