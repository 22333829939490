import { gql } from "@apollo/client";

const MANUAL_DRIVERS_LICENSE_UPLOAD = gql`
  mutation driverLicenseManualEntry(
    $userId: ID!
    $driverLicenseFields: DriverLicenseAttributes!
  ) {
    driverLicenseManualEntry(
      userId: $userId
      driverLicenseFields: $driverLicenseFields
    ) {
      driverLicenseData {
        id
        firstName
        lastName
      }
      success
      error
    }
  }
`;

const UPDATE_USER = gql`
  mutation UpdateUser($userFields: UserAttributes!, $userId: ID!) {
    updateUser(userFields: $userFields, userId: $userId) {
      user {
        profileImageVerification
        driversLicenseVerification
      }
    }
  }
`;

const MODIFY_USER_TAGS = gql`
  mutation modifyUserTags(
    $newTags: [String!]
    $tagsToRemove: [String!]
    $userId: ID!
  ) {
    modifyUserTags(
      newTags: $newTags
      tagsToRemove: $tagsToRemove
      userId: $userId
    ) {
      success
      error
    }
  }
`;

const GET_ALL_USER_TAGS = gql`
  query getAllUserTags {
    getAllUserTags {
      name
      attachedToCount
    }
  }
`;

const CREATE_TAG = gql`
  mutation createTag($tagName: String!) {
    createTag(tagName: $tagName) {
      success
      errors
    }
  }
`;

const DELETE_TAG = gql`
  mutation deleteTag($tagName: String!) {
    deleteTag(tagName: $tagName) {
      success
      errors
    }
  }
`;

const USER_DOCUMENTS_DATA = gql`
  query UserDocuments($id: ID!) {
    userProfileData(id: $id) {
      id
      fullName
      driversLicenseImage
      driversLicenseVerification
      uberProfileImage
      profileImageVerification
      employmentEligibilityImage
      createdAt
      updatedAt
      driversLicenseData {
        firstName
        lastName
        addressLine1
        addressLine2
        city
        dateOfBirth
        country
        expiryDate
        eyeColour
        height
        id
        inventoryControlNumber
        issuanceDate
        licenseClass
        licenseNumber
        restrictions
        sex
        refNumber
        endorsements
        commercialVehicleCodes
        validated
      }
      timeZone
    }
  }
`;

const USER_COMMENTS = gql`
  query UserComments($id: ID!) {
    userProfileData(id: $id) {
      id
      comments {
        id
        resourceType
        resourceId
        authorType
        authorId
        body
        pinned
        updatedAt
        namespace
        author {
          fullName
          email
          profileImage
        }
      }
      documentComments {
        resourceType
        resourceId
        authorType
        authorId
        body
        pinned
        updatedAt
        namespace
        author {
          fullName
          email
          profileImage
        }
      }
      timeZone
    }
  }
`;

const USER_DETAILS = gql`
  query UserDetails($id: ID!) {
    userProfileData(id: $id) {
      id
      email
      firstName
      lastName
      operatingCity
      driversLicenseNumber
      status
      accountStatus
      availableStatuses
      dateOfBirth
      mobile
      referralSource
      appVersion
      deviceOs
      referrals {
        referrerName
        referredUserName
      }
      parkingLot {
        region
      }
      paymentMethods {
        card {
          last4
          expYear
          expMonth
          brand
        }
        created
      }
      addresses {
        streetAddress
        postalCode
        province
        city
        updatedAt
      }
      userTags {
        name
      }
      driversLicenseData {
        addressLine1
        addressLine2
        city
        dateOfBirth
        country
        eyeColour
        height
        id
        firstName
        lastName
        licenseClass
        licenseNumber
        postalCode
        province
        sex
        refNumber
        endorsements
        commercialVehicleCodes
      }
      timeZone
    }
  }
`;

export {
  MANUAL_DRIVERS_LICENSE_UPLOAD,
  UPDATE_USER,
  MODIFY_USER_TAGS,
  GET_ALL_USER_TAGS,
  CREATE_TAG,
  DELETE_TAG,
  USER_DETAILS,
  USER_DOCUMENTS_DATA,
  USER_COMMENTS,
};
