import React, { Component, useState, useRef, useEffect } from "react";
import * as d3 from "d3";
import tip from "d3-tip";
import { m, formatNumber, titleize } from "../../utils";
import _ from "lodash";
import "./index.scss";

const PlotHook = ({
  queriedData,
  xAxisLabel,
  yAxis1Label,
  yAxis2Label,
  offsetWidth,
  setCsvData,
}) => {
  const data = _.cloneDeep(queriedData);

  console.log(data, queriedData);
  const vw = Math.max(
    document.documentElement.clientWidth || 0,
    window.innerWidth || 0
  );
  const vh = Math.max(
    document.documentElement.clientHeight || 0,
    window.innerHeight || 0
  );
  const width = Math.min(1250, vw - (offsetWidth || 388));
  const height = Math.min(650, vh - 165);
  const margin = { top: 50, right: 100, bottom: 100, left: 100 };
  const [cursor, setCursor] = useState(null);
  const [defaultDataset, setDefaultDataset] = useState([]);
  const svgRef = useRef();

  const bars = data.filter((d) => d.plotFormat === "bar");
  const groupedData =
    bars.length > 1 &&
    bars[0].dataset.map((d) => {
      d.yValues.forEach((y) => (d[y.label] = y.value));
      return d;
    });
  const stackedBars = data.filter((d) => d.plotFormat === "stacked_bar");
  const stackedData =
    stackedBars.length > 1 &&
    stackedBars[0].dataset.map((d) => {
      d.yValues.forEach((y) => (d[y.label] = y.value));
      return d;
    });

  const lines = data.filter((d) => d.plotFormat === "line");

  useEffect(() => {
    const combinedDatasets = data.reduce((cd, d) => {
      let csvDataset = d.dataset.map((obj) => ({
        ...obj,
        metric: d.metric,
        unit: d.unit,
      }));
      return cd.concat(csvDataset);
    }, []);
    setCsvData(combinedDatasets);
    if (data.length === 0) return undefined;
    const svg = d3.select(svgRef.current);
    const tooltip = tip()
      .attr("class", "d3-tip")
      .offset([-10, 0])
      .html(
        (d) =>
          `<div style="display:flex;flex-direction:column;justify-content:center;align-items:center;"><div>${m(
            d.cycleStart
          ).format("L")} - ${m(d.cycleEnd).format("L")}</div><div>${titleize(
            d.key
          )} ${d3.format(",")(d.yValue)} ${d.unit}</div></div>`
      );

    const y1Data = data.filter((d) => d.axis === "yAxis1");
    const y2Data = data.filter((d) => d.axis === "yAxis2");
    let y1Avg;
    let y2Avg;
    if (y1Data.length > 0) {
      let y1Combined = {};
      let totalCount = 0;
      let y1v = y1Data
        .map((d) => d.dataset)
        .flat()
        .forEach((d) => {
          let values = d.yValues
            ? d.yValues.map((y) => parseFloat(y.value))
            : [parseFloat(d.yValue)];
          let value = _.sum(values);
          if (y1Combined[d.cycleStart]) {
            y1Combined[d.cycleStart] += value;
          } else {
            y1Combined[d.cycleStart] = value;
          }
          totalCount += 1;
        });
      y1Avg = Math.round(_.sum(Object.values(y1Combined)) / totalCount, 0);
    }
    if (y2Data.length > 0) {
      let y2Combined = {};
      let totalCount = 0;
      let y2v = [
        y2Data.find((d) => ["stacked_bar", "bar"].includes(d.plotFormat)),
        ...y2Data.filter((d) => !["stacked_bar", "bar"].includes(d.plotFormat)),
      ]
        .filter(Boolean)
        .map((d) => d.dataset)
        .flat()
        .forEach((d) => {
          let values = d.yValues
            ? d.yValues.map((y) => parseFloat(y.value))
            : [parseFloat(d.yValue)];
          let value = _.sum(values);
          if (y2Combined[d.cycleStart]) {
            y2Combined[d.cycleStart] += value;
          } else {
            y2Combined[d.cycleStart] = value;
          }
          totalCount += 1;
        });
      y2Avg = Math.round(_.sum(Object.values(y2Combined)) / totalCount, 0);
    }
    const y1Dataset =
      y1Data.length > 0
        ? {
            metric: "Y-1",
            unit: yAxis1Label,
            total: y1Data.reduce((sum, d) => sum + d.total, 0),
            average: y1Avg,
            axis: "yAxis1",
            metricName: y1Data[0].metric,
          }
        : null;
    const y2Dataset =
      y2Data.length > 0
        ? {
            metric: "Y-2",
            unit: yAxis2Label,
            total: y2Data.reduce((sum, d) => sum + d.total, 0),
            average: y2Avg,
            axis: "yAxis2",
            metricName: y2Data[0].metric,
          }
        : null;
    setDefaultDataset([y1Dataset, y2Dataset].filter((d) => d));
    const xScale = d3.scaleTime().range([margin.left, width - margin.right]);
    const timeDomain = d3.extent(
      data
        .map((d) => d.dataset)
        .flat()
        .map((d) => [d.cycleStart, d.cycleEnd])
        .flat(),
      (d) => m(d)
    );
    xScale.domain(timeDomain);
    const xAxis = d3
      .axisBottom()
      .scale(xScale)
      .tickFormat((d) => m(d).format("L"));
    let groupMax =
      (groupedData &&
        d3.max(
          groupedData
            .map((d) => d.yValues)
            .flat()
            .map((d) => parseFloat(d.value)),
          (d) => d
        )) ||
      0;
    let groupMin =
      (groupedData &&
        d3.min(
          groupedData
            .map((d) => d.yValues)
            .flat()
            .map((d) => parseFloat(d.value)),
          (d) => d
        )) ||
      0;

    let stackMax =
      (stackedData &&
        d3.max(
          stackedData.map((d) =>
            d.yValues.reduce((sum, v) => sum + parseFloat(v.value), 0)
          ),
          (d) => d
        )) ||
      0;
    const y1Max = Math.max(
      y1Data.find((d) => d.plotFormat === "bar") ? groupMax : 0,
      y1Data.find((d) => d.plotFormat === "stacked_bar") ? stackMax : 0,
      d3.max(y1Data.map((d) => d.dataset).flat(), (d) => d.yValue)
    );
    const y1Min = Math.min(
      y1Data.find((d) => d.plotFormat === "bar") ? groupMin : 0,
      y1Data.find((d) => d.plotFormat === "stacked_bar") ? stackMax : 0,
      d3.min(y1Data.map((d) => d.dataset).flat(), (d) => d.yValue)
    );

    const y2Max = Math.max(
      y2Data.find((d) => d.plotFormat === "bar") ? groupMax : 0,
      y2Data.find((d) => d.plotFormat === "stacked_bar") ? stackMax : 0,
      d3.max(y2Data.map((d) => d.dataset).flat(), (d) => d.yValue)
    );
    const y2Min = Math.min(
      y2Data.find((d) => d.plotFormat === "bar") ? groupMin : 0,
      y2Data.find((d) => d.plotFormat === "stacked_bar") ? stackMax : 0,
      d3.min(y2Data.map((d) => d.dataset).flat(), (d) => d.yValue)
    );

    const y1Scale = d3
      .scaleLinear()
      .range([height - margin.bottom, margin.top]);
    const y2Scale = d3
      .scaleLinear()
      .range([height - margin.bottom, margin.top]);
    y1Scale.domain([y1Min < 0 ? y1Min : 0, y1Max]);
    y2Scale.domain([y2Min < 0 ? y2Min : 0, y2Max]);
    const yAxis1 = d3
      .axisLeft()
      .scale(y1Scale)
      .tickFormat((d) => d3.format(",")(d));
    const yAxis2 = d3
      .axisRight()
      .scale(y2Scale)
      .tickFormat((d) => d3.format(",")(d));
    const calculateWidth = (length) => {
      return width / (length * 2);
    };
    // svg dimensions
    svg.attr("height", height).attr("width", width);
    // x-axis
    svg
      .select(".x-axis")
      .style("transform", `translateY(${height - margin.bottom}px)`)
      .call(xAxis)
      .selectAll("text")
      .attr("transform", `rotate(-45)`)
      .attr("dx", "-3.2em");
    // y-axis 1
    svg
      .select(".y-axis")
      .style("transform", `translateX(${margin.left}px)`)
      .call(yAxis1);
    // y-axis 2, if applicable
    svg
      .select(".y-axis2")
      .style("transform", `translateX(${width - margin.right}px)`)
      .call(yAxis2);
    // data elements
    if (stackedData) {
      const stackGenerator = d3.stack().keys(stackedBars.map((d) => d.metric));
      const layers = stackGenerator(stackedData);
      const stackedTooltip = tip()
        .attr("class", "d3-tip")
        .offset([-10, 0])
        .html(
          (d) =>
            `<div style="display:flex;flex-direction:column;justify-content:center;align-items:center;"><div>${m(
              d.data.cycleStart
            ).format("L")} - ${m(d.data.cycleEnd).format(
              "L"
            )}</div><div>${titleize(d.key)} ${d3.format(",")(d.tooltipValue)} ${
              d.unit
            }</div></div>`
        );
      const yStackedScale = d3
        .scaleLinear()
        .range([height - margin.bottom, margin.top]);
      // const yStackedMax = d3.max(stackedData.map(d => d.yValues.reduce((sum, v) => sum + parseFloat(v.value), 0)), d => d)
      yStackedScale.domain([
        0,
        stackedBars[0].axis === "yAxis1" ? y1Max : y2Max,
      ]);
      // const stackedAxis = stackedBars.map(d => d.axis).filter((v, i, a) => a.indexOf(v) === i)[0]
      const stackColors = stackedBars.reduce((colors, d) => {
        colors[d.metric] = d.colorway;
        return colors;
      }, {});
      // const yStackedAxis = d3.axisLeft().scale(yStackedScale)
      //   .tickFormat(d => d3.format(",")(d));
      svg
        .selectAll(".layer")
        .data(layers)
        .join("g")
        .attr("class", "layer")
        .attr("fill", (layer) => stackColors[layer.key])
        .selectAll("rect")
        .data((layer) => layer)
        .join("rect")
        .attr("x", (sequence) => xScale(m(sequence.data.cycleStart)))
        .attr("y", height - margin.bottom)
        .attr("width", calculateWidth(stackedData.length))
        .attr("height", 0)
        .on("mouseover", function (e, d) {
          d3.select(this).attr("opacity", "0.5");
          d.key = d3.select(this.parentNode).datum().key;
          d.tooltipValue = d.data[d.key];
          d.unit = stackedBars[0].unit;
          setCursor(d.key);
          stackedTooltip.show(d, this);
        })
        .on("mouseout", function (e, d) {
          d3.select(this).attr("opacity", "1");
          d.key = d3.select(this.parentNode).datum().key;
          d.tooltipValue = d.data[d.key];
          stackedTooltip.hide(d, this);
          setCursor(null);
        })
        .transition()
        .duration(150)
        .delay((d, i) => i * 5)
        .ease(d3.easeLinear)
        .attr("y", (sequence) => yStackedScale(sequence[1]))
        .attr(
          "height",
          (sequence) => yStackedScale(sequence[0]) - yStackedScale(sequence[1])
        );
      svg.call(stackedTooltip);
    } else if (groupedData) {
      const x0 = d3
        .scaleBand()
        .range([margin.left, width - margin.right])
        .paddingInner(0.2);
      x0.domain(
        groupedData.map((d) => [d.cycleStart, d.cycleEnd]).flat(),
        (d) => m(d)
      );
      const x1 = d3.scaleBand();
      x1.domain(bars.map((d) => d.metric))
        .range([0, x0.bandwidth()])
        .padding(0.1);
      const groupedTooltip = tip()
        .attr("class", "d3-tip")
        .offset([-10, 0])
        .html(
          (d) =>
            `<div style="display:flex;flex-direction:column;justify-content:center;align-items:center;"><div>${m(
              d.cycleStart
            ).format("L")} - ${m(d.cycleEnd).format("L")}</div><div>${titleize(
              d.key
            )} ${d3.format(",")(d.tooltipValue)} ${d.unit}</div></div>`
        );
      // const yGroupedMax = d3.max(groupedData.map(d => d.yValues).flat().map(d => parseFloat(d.value)), d => d)
      const yGroupedScale = d3
        .scaleLinear()
        .range([height - margin.bottom, margin.top]);
      yGroupedScale.domain([0, bars[0].axis === "yAxis1" ? y1Max : y2Max]);
      const groupColors = bars.reduce((colors, d) => {
        colors[d.metric] = d.colorway;
        return colors;
      }, {});
      // const yGroupedAxis = d3.axisLeft().scale(yGroupedScale)
      // .tickFormat(d => d3.format(",")(d));
      // const groupedAxis = bars.map(d => d.axis).filter((v, i, a) => a.indexOf(v) === i)[0]
      // y-axis (which ever stacked bar is plotted against)
      svg
        .selectAll(".group")
        .data(groupedData)
        .join("g")
        .attr("class", "group")
        .attr("transform", (d) => "translate(" + x0(d.cycleStart) + ",0)")
        .selectAll("rect")
        .data((d) => d.yValues)
        .join("rect")
        .attr("width", x1.bandwidth())
        .attr("x", (d) => x1(d.label))
        .attr("y", height - margin.bottom)
        .attr("fill", (d) => groupColors[d.label])
        .attr("height", 0)
        .on("mouseover", function (e, d) {
          d3.select(this).attr("opacity", "0.5");
          d.cycleStart = d3.select(this.parentNode).datum().cycleStart;
          d.cycleEnd = d3.select(this.parentNode).datum().cycleEnd;
          d.key = d.label;
          d.tooltipValue = d.value || 0;
          d.unit = bars[0].unit;
          setCursor(d.key);
          groupedTooltip.show(d, this);
        })
        .on("mouseout", function (e, d) {
          d3.select(this).attr("opacity", "1");
          groupedTooltip.hide(d, this);
          setCursor(null);
        })
        .transition()
        .duration(450)
        .delay((d, i) => i * 5)
        .ease(d3.easeLinear)
        .attr("y", (d) => yGroupedScale(d.value || 0))
        .attr(
          "height",
          (d) => height - margin.bottom - yGroupedScale(d.value || 0)
        );
      svg.call(groupedTooltip);
    } else {
      [...bars, ...stackedBars].forEach((bar, i) => {
        let dataset = bar.dataset;
        svg
          .selectAll("rect")
          .data(dataset)
          .join("rect")
          .attr("x", (d) => xScale(m(d.cycleStart)))
          .attr("y", (d) => (d.yValue < 0 ? y1Scale(0) : y1Scale(d.yValue)))
          .attr("yValue", (d) => d.yValue)
          .attr("cycleStart", (d) => d.cycleStart)
          .attr("cycleEnd", (d) => d.cycleEnd)
          .attr("width", calculateWidth(dataset.length))
          .attr("height", (d) => Math.abs(y1Scale(0) - y1Scale(d.yValue)))
          .on("mouseover", function (e, d) {
            d3.select(this).attr("opacity", "0.5");
            d.key = bar.metric;
            d.unit = bar.unit;
            setCursor(bar.metric);
            tooltip.show(d, this);
          })
          .on("mouseout", function (e, d) {
            d3.select(this).attr("opacity", "1");
            tooltip.hide(d, this);
            setCursor(null);
          })
          .transition()
          .duration(150)
          .delay((d, i) => i * 5)
          .ease(d3.easeLinear)
          .attr("y", (d) => (d.yValue < 0 ? y1Scale(0) : y1Scale(d.yValue)))
          .attr("height", (d) => Math.abs(y1Scale(d.yValue) - y1Scale(0)))
          .attr("fill", (d) => bar.colorway);
        // tooltip
        svg.call(tooltip);
      });
    }
    lines.forEach((line, i) => {
      const lineGenerator = d3.line();
      lineGenerator.x((d) => xScale(m(d.cycleStart)));
      lineGenerator.y((d) =>
        line.axis === "yAxis1" ? y1Scale(d.yValue) : y2Scale(d.yValue)
      );
      const lineColor = line.colorway;
      svg
        .append("path")
        .datum(line.dataset)
        .attr("stroke", lineColor)
        .attr("fill", "none")
        .attr("class", "line")
        .attr("stroke-width", 3)
        .attr("d", lineGenerator);
      svg
        .selectAll(".dot")
        .data(line.dataset)
        .join("circle")
        .attr("cx", (d) => xScale(m(d.cycleStart)))
        .attr("cy", (d) =>
          line.axis === "yAxis1" ? y1Scale(d.yValue) : y2Scale(d.yValue)
        )
        .attr("r", 6)
        .attr("fill", lineColor)
        .attr("opacity", 0)
        .on("mouseover", function (e, d) {
          d3.select(this).attr("opacity", "1");
          d.key = line.metric;
          d.unit = line.unit;
          setCursor(line.metric);
          tooltip.show(d, this);
        })
        .on("mouseout", function (e, d) {
          d3.select(this).attr("opacity", "0");
          tooltip.hide(d, this);
          setCursor(null);
        });
    });
    svg.call(tooltip);
  }, [queriedData]);
  const cursorDataset = data.find((d) => d.metric === cursor);
  const datasets =
    data.length > 0
      ? (cursorDataset ? [cursorDataset] : null) || defaultDataset
      : [];
  return (
    <div
      className="analytics-plot"
      style={offsetWidth ? { width: `calc(100vw - ${offsetWidth}px)` } : {}}
    >
      <svg ref={svgRef}>
        <g className="axes">
          <g className="x-axis" />
          <g className="y-axis" />
          {yAxis2Label ? <g className="y-axis2" /> : null}
          <text transform={`translate(${width / 2}, ${height})`}>
            {titleize(xAxisLabel)}
          </text>
          <text
            transform={`translate(${margin.left / 2}, ${margin.top / 1.5})`}
          >
            {titleize(yAxis1Label)}
          </text>
          {yAxis2Label ? (
            <text
              transform={`translate(${width - margin.right}, ${
                margin.top / 1.5
              })`}
            >
              {titleize(yAxis2Label)}
            </text>
          ) : null}
        </g>
      </svg>
      <div>
        {datasets.length > 0
          ? datasets.map((dataset, i) => {
              return (
                <div className="metric-container" key={i}>
                  <div className="metric-field">
                    <div className="metric-label">Metric</div>
                    <div className="metric-value">
                      <span>{titleize(dataset.metric)}</span>
                    </div>
                  </div>
                  {dataset.unit === "percent" ||
                  dataset.unit === "rating" ? null : (
                    <div className="metric-field">
                      <div className="metric-label">Total</div>
                      {dataset.total === -1 ? null : (
                        <div className="metric-value">
                          {formatNumber(dataset.total)}{" "}
                          <span>{dataset.unit}</span>
                        </div>
                      )}
                    </div>
                  )}
                  <div className="metric-field">
                    <div className="metric-label">Average</div>
                    <div className="metric-value">
                      {formatNumber(Math.round(dataset.average))}{" "}
                      <span>
                        {dataset.unit === "percent"
                          ? dataset.unit
                          : dataset.unit === "rating"
                          ? "stars"
                          : `${dataset.unit}/${xAxisLabel.slice(
                              0,
                              xAxisLabel.length - 1
                            )}`}
                      </span>
                    </div>
                  </div>
                </div>
              );
            })
          : null}
      </div>
    </div>
  );
};

export default PlotHook;
