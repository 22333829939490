import { faMinusCircle } from "@fortawesome/pro-solid-svg-icons";
import { faCalendar } from "@fortawesome/pro-regular-svg-icons";
import { useMutation } from "@apollo/client";
import { BATCH_CANCEL_BOOKING, BATCH_RESTORE_BOOKING } from "../queries";

export function useBatchActions() {
  const [cancelBookingsMutation, { loading: cancelLoading }] =
    useMutation(BATCH_CANCEL_BOOKING);

  const [restoreBookingsMutation, { loading: restoreLoading }] = useMutation(
    BATCH_RESTORE_BOOKING
  );

  const createCancelBookings = (successCallback, errorCallback) => {
    return (selectedBookingIds) => {
      cancelBookingsMutation({
        variables: {
          bookingIds: selectedBookingIds,
        },
      }).then(({ data: { batchCancelBookings } }) => {
        if (batchCancelBookings.success) {
          successCallback();
        } else {
          errorCallback(batchCancelBookings.error);
        }
      });
    };
  };

  const createRestoreBookings = (successCallback, errorCallback) => {
    return (selectedBookingIds) => {
      restoreBookingsMutation({
        variables: {
          bookingIds: selectedBookingIds,
        },
      }).then(
        ({
          data: {
            batchRestoreBookings: { success, error, rescheduled, unavailable },
          },
          data,
        }) => {
          if (success) {
            if (rescheduled.length > 0) {
              successCallback();
            } else if (unavailable.length > 0) {
              const joinedIds = unavailable.join(", ");
              const errorMessage = `Booking ID ${joinedIds} could not be restored.`;
              errorCallback(errorMessage);
            }
          } else {
            errorCallback(error);
          }
        }
      );
    };
  };

  const futureBookingsBatchActions = (successCallback, errorCallback) => [
    {
      value: "cancelBooking",
      text: "Cancel booking",
      actionName: "cancel",
      icon: faMinusCircle,
      action: createCancelBookings(successCallback, errorCallback),
    },
  ];

  const cancelledBookingsBatchActions = (successCallback, errorCallback) => [
    {
      value: "restoreBooking",
      text: "Restore booking",
      actionName: "restore",
      icon: faCalendar,
      action: createRestoreBookings(successCallback, errorCallback),
    },
  ];

  function getBatchActionsForTab(tab, successCallback, errorCallback) {
    if (tab === "future") {
      return futureBookingsBatchActions(successCallback, errorCallback);
    } else if (tab === "cancelled") {
      return cancelledBookingsBatchActions(successCallback, errorCallback);
    } else {
      return [];
    }
  }

  const loading = cancelLoading || restoreLoading;

  return {
    loading,
    getBatchActionsForTab,
  };
}
