import React from "react";
import { titleize } from "../../utils";
import LocationGrey from "../../assets/icons/LocationGrey.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-regular-svg-icons";

const DatasetCard = ({
  dataset,
  index,
  removeDataset,
  setSelectedDataset,
  setDatasetIndex,
  setDatasetOptions,
  locations,
}) => {
  const vehicleClassTitle = () => {
    if (dataset.vehicleClass.length > 0) {
      return ` - ${dataset.vehicleClass
        .map((v) => v.toUpperCase())
        .join(", ")}`;
    } else {
      return "";
    }
  };

  const axisName = () => {
    if (dataset.axis === "yAxis1") {
      return "Y-1";
    } else {
      return "Y-2";
    }
  };

  return (
    <div className="dataset-display" key={index}>
      <div className="dataset-display__header">
        <div style={{ fontWeight: "600" }}>Dataset {index + 1}</div>
        <div className="remove-dataset" onClick={() => removeDataset(index)}>
          <FontAwesomeIcon
            icon={faTimes}
            size="xs"
            style={{ color: "white" }}
          />
        </div>
      </div>
      <div
        className="dataset-display__body"
        style={{ borderColor: dataset.colorway }}
        onClick={() => {
          setSelectedDataset(dataset);
          setDatasetIndex(index);
          setDatasetOptions(true);
        }}
      >
        <div style={{ fontWeight: "500" }}>
          {titleize(dataset.metric)}
          {vehicleClassTitle()}
        </div>
        <div className="flex-container">
          <div className="icon-container">
            <img style={{ height: "44px", width: "44px" }} src={LocationGrey} />
          </div>
          <div>
            {locations
              .map((l) => l.hubs)
              .flat()
              .filter((h) => dataset.parkingLotIds.includes(parseInt(h.id)))
              .map((h) => h.region)
              .join(", ")}
          </div>
        </div>
        <div>
          {axisName()} | {graphTypes[dataset.plotFormat]}
        </div>
      </div>
    </div>
  );
};

const graphTypes = {
  bar: "Bar (grouped)",
  stacked_bar: "Bar (stacked)",
  line: "Line",
};

export default DatasetCard;
