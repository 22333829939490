import { gql } from "@apollo/client";

const USER_DASHBOARD_DATA = gql`
  query userDashboardData($id: ID!) {
    userDashboardData(id: $id) {
      id
      accountStatus
      createdAt
      timeInStatus
      dateOfAppDownload
      accountStatusChangedBy
      previousStatus
      openTicketsCount
      totalTicketsCount
      pinnedCommentsCount
      deviceOs
      appVersion
      lastSignInAt
      outstandingRequirements
      driversLicenseImage
      driversLicenseBackside
      driversLicenseData {
        firstName
        lastName
        licenseClass
        expiryDate
        province
        country
        updatedAt
      }
      profileImageVerification
      uberProfileImage
      outstandingBalance
      totalCredits
      totalDeductions
      lifeTimeValues
      avgRevenuePerWeek
      lastBooking {
        id
        startTime
        endTime
      }
      bookingsThisWeekEachDay {
        date
        hours
      }
      lifetimeHours
      hoursThisWeek
      bookingsThisWeek
      avgHoursPerWeek
      hoursDrivenForPromotion
      avgBookingsThisWeekOfAllUsers
      avgLateTime
      avgLateTimeOfAllUsers
      cancellationRate
      avgHoursPerWeekOfAllUsers
      lifetimeHoursOfAllUsers
      avgHoursThisWeekOfAllUsers
      avgCancellationRateOfAllUsers
      averageOutstandingBalanceOfAllUsers
      averageTotalCreditsOfAllUsers
      averageTotalDeductionsOfAllUsers
      averageLifetimeValuesOfAllUsers
      averageRevenuePerWeekOfAllUsers
      ridesharePlatforms {
        name
        impliedStatus
        thirdPartyStatus
        accountType
        connectedOn
        lastConnectedAt
        cookiesExpiryDate
        lastCheckedAt
      }
      timeZone
    }
  }
`;

export { USER_DASHBOARD_DATA };
