import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLongArrowRight } from "@fortawesome/pro-regular-svg-icons";
import React from "react";
import styles from "../../bookingActions.module.scss";

const ArrowRight = () => (
  <div className={styles.arrowRight}>
    <FontAwesomeIcon
      icon={faLongArrowRight}
      size="2x"
      style={{ color: "#414141" }}
    />
  </div>
);
export default ArrowRight;
