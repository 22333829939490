import moment from "moment-timezone";

const dateFormatter = (dateValue) => {
  if (dateValue) {
    return moment.utc(dateValue).format("MMM D, YYYY");
  } else return "-";
};

const manualInputsInitialState = {
  firstName: undefined,
  lastName: undefined,
  licenseNumber: undefined,
  address: undefined,
  dateOfBirth: undefined,
  issueDate: undefined,
  expiryDate: undefined,
  refNumber: undefined,
  height: undefined,
  sex: undefined,
  licenseClass: undefined,
  frontImage: undefined,
  backImage: undefined,
  profileImage: undefined,
};

export { dateFormatter, manualInputsInitialState };
