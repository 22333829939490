import React from "react";
import Modal from "../../../../../artzu-ui/src/Modal";
import { useState } from "react";
import { useEffect } from "react";

const EditModal = ({
  open,
  changedBody,
  setChangedBody,
  toggleEdit,
  handleEdit,
  curId,
}) => {
  const [editedBody, setEditedBody] = useState();

  useEffect(() => {
    setEditedBody(changedBody);
  }, [changedBody]);

  const triggerEdit = (e) => {
    setEditedBody(e.target.value);
  };
  const ableToSave = editedBody;
  return (
    <Modal open={open} backgroundColor="none">
      <div className="comment-reminder-container" style={{ height: "270px" }}>
        <div className="reminder-title">Edit Note</div>
        <div className="choose-date-container" style={{ margin: "0" }}>
          <textarea
            className="comment-input-box"
            value={editedBody}
            onChange={triggerEdit}
          ></textarea>
        </div>
        <div className="relate-user-buttons" style={{ marginTop: "36px" }}>
          <div>
            <button
              className="booking-cancel-button"
              style={{ width: "130px" }}
              onClick={() => {
                setChangedBody("");
                toggleEdit();
              }}
            >
              Cancel
            </button>
          </div>
          <div>
            <button
              className={
                ableToSave
                  ? "booking-choose-button"
                  : "booking-choose-button-invalid"
              }
              style={{ width: "130px" }}
              onClick={() => {
                ableToSave && handleEdit(curId, editedBody);
                ableToSave && toggleEdit();
              }}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default EditModal;
